<template>
    <div>
        <dkc-modal-dlg
                :buttons="buttons"
                :actions="actions"
                v-show="isModalDlgVisible"
                @close="closeModalDlg">
            <div slot="body" v-html="bodyText"></div>
            <span slot="header">{{headerText}}</span>
        </dkc-modal-dlg>

        <bpk-toolbar-panel>
            <dkc-tool-button
                    title="Новая спецификация"
                    hint="Создать новую спецификацию"
                    iconclass="dbtn-create"
                    action="/spec/create">
            </dkc-tool-button>
            <dkc-tool-button
                    title="Изменить спецификацию"
                    hint="Изменить выбранную спецификацию"
                    iconclass="dbtn-edit"
                    :action="editSpec">
            </dkc-tool-button>
            <dkc-tool-button
                    title="Удалить спецификацию"
                    hint="Удалить выбранную спецификацию"
                    iconclass="dbtn-delete"
                    :action="queryDeleteSpec"></dkc-tool-button>

            <dkc-tool-button
                    title="Очистить фильтры"
                    hint="Очистить фильтры"
                    iconclass="dbtn_filter_clear"
                    control="tabulator-tableHolder"
                    :begingroup="true"
                    :action="clearFilters"></dkc-tool-button>
        </bpk-toolbar-panel>

        <dkc-grid ref="speclist" class="spec-table"></dkc-grid>
    </div>
</template>

<script>
    import dkcModalDlg from "./components/dkcModalDlg"
    import bpkToolbarPanel from './components/bpkToolbarPanel'
    import dkcToolButton from "./components/dkcToolButton"
    import DkcGrid from './components/dkcGrid'
    import qs from 'qs'

    export default {
        name: "SpecList",
        components: {dkcModalDlg, bpkToolbarPanel, dkcToolButton, DkcGrid},
        data() {
            return {
                // данные для модального окна
                isModalDlgVisible: false,
                headerText: "Заголовок модального окна",
                bodyText: "Сообщение в модальном окне",
                buttons: ["ok"],
                actions: {
                    yes: () => {},
                },
            }
        },
        computed: {
            grid: function() {
                return this.$refs.speclist
            },
            tabulator: function() {
                return this.grid.tabulator
            },
            idspec: function() {
                let id = false ;
                const row = this.grid.currentRow ;
                const data = row.getData()
                id = data.idspec
                return id
            },
            specnum: function() {
                let num = false;
                const row = this.grid.currentRow;
                const data = row.getData()
                num = data.docnum
                return num
            },
            rowPerPage: function() {
                return this.$store.state.spec.rowPerPage ;
            },
        },
        watch: {
            rowPerPage: function() {
                this.tabulator.setPageSize(this.$store.state.spec.rowPerPage) ;
            },
        },
        methods: {
            closeModalDlg: function() {
                this.isModalDlgVisible = false
            },
            showMessage: function(title, message) {
                this.headerText = title
                this.bodyText   = message
                this.buttons    = ['ok']
                this.actions    = {
                    ok: this.closeModalDlg,
                }
                this.isModalDlgVisible = true
            },
            clearFilters: function() {
                this.tabulator.clearFilter(true)
            },
            editSpec: function(idspec) {
                if (!idspec || typeof idspec == 'object') {
                    idspec = this.idspec
                }

                if (!idspec) {
                    this.showMessage('Ошибка редактирования спецификации',
                        'Не выбрана спецификация для редактирования. Выберите необходимый документ и повторите попытку')
                    return
                }

                window.location = this.baseUrl + '/spec/update?id=' + idspec
            },
            queryDeleteSpec: function() {
                let idspec = false
                if (!idspec) {
                    idspec = this.idspec
                }

                if (!idspec) {
                    this.showMessage('Ошибка удаления спецификации',
                        'Не выбрана спецификация для удаления. Выберите необходимый документ и повторите попытку')
                    return
                }

                this.headerText = 'Удаление спецификации'
                this.bodyText   = 'Вы действительно хотите удалить спецификацию № ' + this.specnum + '?'
                this.buttons    = ['yes', 'no']
                this.actions    = {
                    yes: this.deleteSpec,
                }
                this.isModalDlgVisible = true
            },
            deleteSpec: function() {
                const self = this
                let idspec = false
                if (!idspec) {
                    idspec = this.idspec
                }

                if (!idspec) {
                    this.showMessage('Ошибка удаления спецификации',
                        'Не выбрана спецификация для удаления. Выберите необходимый документ и повторите попытку')
                    return
                }

                const options = {
                    method: 'POST',
                    url: this.baseUrl + '/spec/delete',
                    headers: {
                        'content-type': 'application/x-www-form-urlencoded',
                        'X-CSRF-Token': tkn_csfr
                    },
                    data: qs.stringify({idspec}),
                }

                axios(options)
                    .then(response => {
                        self.tabulator.setData()
                    })
                    .catch(error => {
                        self.showMessage('Ошибка удаления спецификации',
                            'При удалении сецификации произошла ошибка на сервере')
                        self.tabulator.setData()
                    })
            },
            rowDblClick:function(e, row){
                const data = row.getData() ;
                this.editSpec(data.idspec)
            },
            createTable: function(filters) {
                let ffirms = []
                let ffios  = []
                if (filters) {
                    ffirms = filters.firms.map(firm => firm.name)
                    ffios  = filters.users.map(user => user.fio)
                }

                const options = {
                    columns: [
                        {title: 'idspec', field: 'idspec', visible: false},
                        {title: 'Документ',
                            columns: [
                                {
                                    title: 'номер', field: 'docnum', visible: true, widthGrow: 1,
                                    headerFilter: true, headerFilterPlaceholder: 'фильтр по номеру',
                                },
                                {title: 'дата', field: 'docdate', visible: true, widthGrow: 1,
                                    headerFilter: true, headerFilterPlaceholder: 'фильтр по дате',
                                    formatter:function(cell, formatterParams, onRendered){
                                        const value = cell.getValue()
                                        const arr   = value.split('-')
                                        if (arr.length != 3) {
                                            return value
                                        }

                                        const buffer = arr[0]
                                        arr[0] = arr[2]
                                        arr[2] = buffer
                                        return arr.join('.')
                                    },
                                },
                            ],
                        },
                        {title: 'Сведения от заказчика',
                            columns: [
                                {title: 'idFirm', field: 'idFirm', visible: false},
                                {
                                    title: 'фирма производитель', field: 'firm_name', visible: true, widthGrow: 3,
                                    headerFilter: 'list', headerFilterParams: {values: ffirms},
                                    headerFilterPlaceholder: 'фильтр по производителю',
                                },
                                {
                                    title: 'наименование', field: 'customer', visible: true, widthGrow: 3,
                                    headerFilter: true, headerFilterPlaceholder: 'фильтр по заказчику',
                                },
                            ],
                        },
                        {title: 'Данные о документе',
                            columns: [
                                {title: 'author', field: 'author', visible: false},
                                {
                                    title: 'автор документа', field: 'fio', visible: true, widthGrow: 3,
                                    headerFilter: 'list', headerFilterParams: { values: ffios},
                                    headerFilterPlaceholder: 'фильтр по автору',
                                },
                                {
                                    title: 'статус', field: 'sign', visible: true, widthGrow: 2,
                                    headerFilter: 'list', headerFilterParams: {
                                        values: ['черновик', 'утвержден', 'закрыт', 'аннулирован'],
                                    },
                                    headerFilterPlaceholder: 'фильтр по статусу',
                                },
                                {
                                    title: 'примечание', field: 'note', visible: true, widthGrow: 8,
                                    headerFilter: true, headerFilterPlaceholder: 'фильтр по примечанию',
                                },
                            ],
                        },
                    ],
                    layout: 'fitColumns',
                    locale: 'ru-RU',
                    langs: {
                        'ru-RU': {
                            'data': {
                                'loading':'загрузка',
                                'error': 'ошибочка вышла',
                            },
                            'pagination': {
                                'first': String.fromCharCode(57449),
                                'first_title':'первая страница',
                                'prev': String.fromCharCode(57457),
                                'prev_title':'предыдущая страница',
                                'next': String.fromCharCode(57461),
                                'next_title':'следущая страница',
                                'last': String.fromCharCode(57463),
                                'last_title':'последняя страница',
                            },
                        },
                    },
                    selectable: true,
                    selectableRangeMode: 'click',
                    ajaxURL: this.baseUrl + '/spec/select',
                    ajaxContentType: 'json',
                    filterMode: 'remote',
                    sortMode: 'remote',
                    pagination: true,
                    paginationMode: 'remote',
                    paginationSize: this.rowPerPage,

                    footerElement: `<span><div class="paginator-limit"><span>На странице:</span>
                        <select id="RowPerPage"
                          onchange="const sc = document.getElementById('RowPerPage') ;
                                    window.dkcapp.$store.state.spec.rowPerPage = sc.value ;
                                   "
                          >
                          <option value="5">5</option>
                          <option value="10">10</option>
                          <option value="25" selected>20</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                        </div></span>`,
                }

                const subscribe = {
                  'rowDblClick': this.rowDblClick,
                  'rowDblTap': this.rowDblClick,
                }
                this.$refs.speclist.createGrid(options, subscribe)
            },
        },
        mounted() {
            window.dkcapp.title = 'Журнал спецификаций'
            const self = this
            const options = {
                method: 'GET',
                url: this.baseUrl + '/spec/filters',
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                    'X-CSRF-Token': tkn_csfr
                },
            }

            axios(options)
                .then(response => {
                    self.createTable(response.data)
                })

          axios({
            method: 'GET',
            url: this.baseUrl + '/spec/update-codes',
            headers: {
              'content-type': 'application/x-www-form-urlencoded',
              'X-CSRF-Token': tkn_csfr
            },
          })
        }
    }
</script>

<style scoped>

</style>
