<template>
   <div class="sol_container">
      <div id="sol_header" class="sol_header">
         <bpk-ribbon :tabs="tabs">
            <!--   Панель Главная   -->
            <bpk-toolbar :index="0" >
               <bpk-toolbar-panel title="Изменение статуса">
                  <dkc-tool-button title="Статус 'Черновик'"
                                   hint="Установить выдленным строкам статус 'Черновик'"
                                   iconclass="dbtn_state_draft"
                                   :action="setStateSelectedPositions"
                                   :actiondata="{sign: 'D'}"
                  />
                  <dkc-tool-button title="Статус 'Утверждено'"
                                   hint="Установить выдленным строкам статус 'Утверждено'"
                                   iconclass="dbtn_state_approved"
                                   :action="setStateSelectedPositions"
                                   :actiondata="{sign: 'A'}"
                  />
                  <dkc-tool-button title="Статус 'Исключить'"
                                   hint="Установить выдленным строкам статус 'Исключить'"
                                   iconclass="dbtn_state_exclude"
                                   :action="setStateSelectedPositions"
                                   :actiondata="{sign: 'E'}"
                  />
               </bpk-toolbar-panel>
               <bpk-toolbar-panel title="Фильтр таблицы">
                  <dkc-tool-button title="Очистить фильтр"
                                   hint="Очистить фильтр, показать все данные"
                                   iconclass="dbtn_filter_clear"
                                   :action="clearFilters"
                  />
               </bpk-toolbar-panel>
               <bpk-toolbar-panel title="Фильтр по статусу позиции">
                  <div class="state_toolbar_panel">
                  <div class="toggle_block">
                     <div class="toggle_label">Позиции по статусом "Черновик"</div>
                     <div class="toggle_control">
                        <VueToggles
                           @click="onClickToggles_d"
                           :value="stateFilters.d"
                           height="18"
                           width="120"
                           checkedText="Показывать"
                           uncheckedText="Скрыть"
                           checkedBg="skyblue"
                           uncheckedBg="silver"
                        />
                     </div>
                  </div>

                  <div class="toggle_block">
                     <div class="toggle_label">Позиции по статусом "Утверждено"</div>
                     <div class="toggle_control">
                        <VueToggles
                           @click="onClickToggles_a"
                           :value="stateFilters.a"
                           height="18"
                           width="120"
                           checkedText="Показывать"
                           uncheckedText="Скрыть"
                           checkedBg="skyblue"
                           uncheckedBg="silver"
                        />
                     </div>
                  </div>

                  <div class="toggle_block">
                     <div class="toggle_label">Позиции по статусом "Исключить"</div>
                     <div class="toggle_control">
                        <VueToggles
                           @click="onClickToggles_e"
                           :value="stateFilters.e"
                           height="18"
                           width="120"
                           checkedText="Показывать"
                           uncheckedText="Скрыть"
                           checkedBg="skyblue"
                           uncheckedBg="silver"
                        />
                     </div>
                  </div>
                  </div>
               </bpk-toolbar-panel>

            </bpk-toolbar>

            <!--   Панель ПЕРЕХОД   -->
            <bpk-toolbar :index="2" >
               <bpk-toolbar-panel title="Открыть объект в новой вкладке">
                  <dkc-tool-button title="Открыть спецификацию"
                                   hint="Открыть спецификацию в новой вкладке"
                                   iconclass="dbtn_open_spec"
                                   :action="gotoToSpec"
                  />
                  <dkc-tool-button title="Номенклатура заказчика"
                                   hint="Открыть номенклатуру заказчика в новой вкладке"
                                   iconclass="dbtn_open_nomen_customer"
                                   :begingroup="true"
                                   :action="gotoCustomerNomen"
                  />
                  <dkc-tool-button title="Номенклатура ДКС"
                                   hint="Открыть номенклатуру ДКС в новой вкладке"
                                   iconclass="dbtn_open_nomen_dkc"
                                   :action="gotoDKCNomen"
                  />
               </bpk-toolbar-panel>
            </bpk-toolbar>
         </bpk-ribbon>
      </div>
      <div class="sol_content">
         <div class="sol_tbl_wrap">
            <div id="solutiontbl" ref="solutions"></div>
         </div>
      </div>
      <dkc-modal-dlg
         :buttons="buttons"
         :actions="actions"
         v-show="isModalDlgVisible"
         @close="closeModalDlg">
         <div slot="body" v-html="bodyText"></div>
         <span slot="header">{{headerText}}</span>
      </dkc-modal-dlg>
   </div>
</template>

<script>

import { TabulatorFull as Tabulator } from 'tabulator-tables'
import dkcModalDlg from './components/dkcModalDlg'
import dkcToolButton from './components/dkcToolButton'
import bpkRibbon from './components/bpkRibbon'
import bpkToolbar from './components/bpkToolbar'
import bpkToolbarPanel from './components/bpkToolbarPanel'
import VueToggles from 'vue-toggles'

export default {
   name: "Solutions",
   components: {dkcModalDlg, dkcToolButton, bpkRibbon, /*bpkToolbars,*/ bpkToolbar, bpkToolbarPanel, VueToggles},
   data () {
      return {
         tabulator: false,
         options: {
            columns: [
               {title: "idspec",       field: "idspec",       visible: false},
               {title: "idspecpos",    field: "idspecpos",    visible: false},
               {title: "c_idspecitem", field: "c_idspecitem", visible: false},
               {title: "d_idspecitem", field: "d_idspecitem", visible: false},
               {title: "c_idnomen",    field: "c_idnomen",    visible: false},
               {title: "d_idnomen",    field: "d_idnomen",    visible: false},
               {title: "Спецификация",
                  columns: [
                     {title: "Номер", field: "docnum", headerFilter: true, },
                     {title: "Дата", field: "docdate", hozAlign: "center", headerFilter: true,
                        formatter: function(cell, formatterParams, onRendered) {
                           let value = cell.getValue()
                           if (value) {
                              value = value.split('-').reverse().join('.')
                           }
                           return value
                        },
                        },
                     {title: "Автор", field: "fio", headerFilter: true},
                  ],},
               {title: "Исходные позиции",
                  columns: [
                     {title: "Завод-изготовитель, вендор", field: "firmname", width: 155, headerFilter: true},
                     {title: "Код", field: "c_code", width: 155, headerFilter: true},
                     {title: "Тип", field: "c_mark", width: 155, headerFilter: true},
                     {title: "Наименование", field: "c_name", width: 300, headerFilter: true, formatter: "textarea"},
                     {title: "Кол-во", field: "c_quant", hozAlign: "right", headerFilter: true},
                     {title: "Ед.Изм.", field: "c_edizm", hozAlign: "center", headerFilter: true},
                  ],},
               {title: "ДКС",
                  columns: [
                     {title: "Код", field: "d_code", width: 155, headerFilter: true},
                     {title: "Наименование", field: "d_name", width: 300, headerFilter: true, formatter: "textarea"},
                     {title: "Кол-во", field: "d_quant", hozAlign: "right", headerFilter: true, headerHozAlign: "center", headerFilterPlaceholder:"поиск..."},
                     {title: "Ед.Изм.", field: "d_edizm", hozAlign: "center", headerFilter: true},
                     {title: "Статус", field: "sign", width: 155,
                        formatter: function(cell, formatterParams, onRendered) {
                           switch (cell.getValue()) {
                              case 'A': return 'Утверждено'
                              case 'D': return 'Черновик'
                              case 'E': return 'Исключено'
                           }

                           return cell.getValue()
                        },
                     },
                     {title: "Примечание", field: "note", headerFilter: true},
                  ],},
            ],
            /* cellDblClick: this.cellDblClick, */
            groupBy: 'idspecpos',
            groupHeader: function (value, count, data, group) {
               if (count == 1) {
                  const el = group.getElement()
                  el.style.display = 'none'
                  return '*'
               }

               const rows = group.getRows()
               // подчеркиваем группу
               const lastrow = rows[count - 1]
               const el = lastrow.getElement()
               el.style.borderBottomStyle = 'ridge'
               el.style.borderBottomWidth = '3px'
               el.style.borderBottomColor = '#5d448c'

               return 'Сборная позиция  (' + count + ' элем.)';
            },
            layout: 'fitData',
            autoResize:false,
            index: "idspec",
            placeholder: 'Нет данных',
            headerFilterLiveFilterDelay: 1500,         
            locale: 'ru-RU',
            langs: {
               'ru-RU': {
                  'ajax': {
                     'loading':'загрузка',
                     'error': 'ошибочка вышла',
                  },
                  'pagination': {
                     'first': String.fromCharCode(57449),
                     'first_title':'первая страница',
                     'prev': String.fromCharCode(57457),
                     'prev_title':'предыдущая страница',
                     'next': String.fromCharCode(57461),
                     'next_title':'следущая страница',
                     'last': String.fromCharCode(57463),
                     'last_title':'последняя страница',
                  },
               },
            },
            selectable: true,
            selectableRangeMode: "click",
            pagination: true,
            paginationMode: "remote",
            paginationSize: this.rowPerPage,
            ajaxURL: this.solutionUrl,
            ajaxContentType: "json",
            filterMode: "remote",
            sortMode: "remote",
            footerElement: `<span><div class="paginator-limit"><span>На странице:</span>
            <select id="RowPerPage"
              onchange="const sc = document.getElementById('RowPerPage') ;
                        window.dkcapp.$store.state.solution.rowPerPage = sc.value ;
                       "
              >
              <option value="10">10</option>
              <option value="20" selected>20</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="200">200</option>
              <option value="300">300</option>
              <option value="500">500</option>
            </select>
            </div></span>`,
            ajaxResponse: this.ajaxResponse,
            /* ajaxError: this.ajaxError, */
         },
         isModalDlgVisible: false,
         headerText: "",
         bodyText: "",
         buttons: ["ok"],
         actions: {
            yes: () => {},
         },
         tabs: [
            {  index: 0, title: 'Главная',   hint: 'Управление содержимым таблицы' },
            {  index: 2, title: 'Переход',   hint: 'Переход к другим режимам' },
         ],
         stateFilters: {
            a: true,
            d: true,
            e: false
         },
      }
   },
   computed: {
      solutionUrl: function() {
         return  this.baseUrl + "/spec/get-solutions"
      },
      rowPerPage: function() {
         return this.$store.state.solution.rowPerPage ;
      },
      tableHeight: function() {
         const hdrEl = document.getElementById('sol_header')
         return document.documentElement.clientHeight - hdrEl.offsetTop - hdrEl.offsetHeight - 40
      },
      stateFilters_a: function () { return this.stateFilters.a },
      stateFilters_d: function () { return this.stateFilters.d },
      stateFilters_e: function () { return this.stateFilters.e },
   },
   watch: {
      rowPerPage: function() {
         this.tabulator.setPageSize(this.$store.state.solution.rowPerPage) ;
      },
      stateFilters_a: function () { this.setStateFilter() },
      stateFilters_d: function () { this.setStateFilter() },
      stateFilters_e: function () { this.setStateFilter() },
   },
   methods: {
      plug () {
         alert('ГЫГЫЦ!')
      },
      showModalDlg(afterShow = false) {
         this.isModalDlgVisible = true ;
         if (afterShow) {
            afterShow()
         }
      },
      closeModalDlg() {
         this.isModalDlgVisible = false ;
      },
      showErrorMessage (context, error) {
         this.headerText = 'Ошибка выполнения программы'
         let errorMsg = context

         if (typeof error == 'string') {
            errorMsg = errorMsg + "<p>" + error + "</p>"
         }
         else if (typeof error == 'object') {
            errorMsg = errorMsg +
               (('message' in error) ? "<p>" + error.message + "</p>" + error.stack : "") +
               (('response' in error) ? "\n" + error.response.data : "")
         }
         this.bodyText = errorMsg
         this.buttons = ['ok']
         this.actions = {ok: this.closeModalDlg}
         this.showModalDlg()


         const el = document.getElementById('debug')
         if (el != undefined) {
            el.innerHTML = errorMsg
            el.style.display = "block"
         }

         //console.error(errorMsg)
      },
      ajaxResponse (url, params, response){
         if (response.error) {
            this.showErrorMessage('Ошибка выборки данных', response.emsg)
         }
         else {
            const el = document.getElementById('debug')
            if (el) {
               el.style.display = "none"
            }
         }


         return response;
      },
      ajaxError (error){
         //error - the returned error object
         this.showErrorMessage('Ошибка выборки данных', error)
      },
      clearFilters () {
         this.tabulator.clearHeaderFilter();
      },
      onClickToggles_d (value) {
         this.stateFilters.d = !this.stateFilters.d
      },
      onClickToggles_a (value) {
         this.stateFilters.a = !this.stateFilters.a
      },
      onClickToggles_e (value) {
         this.stateFilters.e = !this.stateFilters.e
      },
      setStateSelectedPositions (event, btndata) {
         if (!this.tabulator)
            return
         const selectedData = this.tabulator.getSelectedData()
         if (!selectedData.length) {
            this.showErrorMessage('Выберите позицию в таблице!')
            return
         }

         const self = this
         const posids = []
         selectedData.forEach ( row => {
            if (btndata.sign != row.sign)
               posids.push(row.idspecpos)
         })

         const formData = new FormData()
         formData.append('sign', btndata.sign)
         formData.append('listid', [...new Set(posids)])

         const options = {
            method: 'POST',
            url: this.baseUrl + '/spec/pos-setsign',
            headers: {
               'X-Requested-With': 'XMLHttpRequest',
               'Content-Type': 'application/json; charset=utf-8',
               'X-CSRF-Token': tkn_csfr
            },
            data: formData,
         }
         axios(options)
            .then(response => {
               self.tabulator.setData() //refreshFilters()
            })
            .catch(error => {
               self.showErrorMessage('Ошибка изменения статуса выделенных строк', error)
            })
      },
      setStateFilter() {
         const value = ['"empty"']
         const filters = this.stateFilters
         const keys = Object.keys(filters)
         keys.forEach( key => {
            if (filters[key])
               value.push('"' + key.toLocaleUpperCase() + '"')
         })

         this.tabulator.setFilter([{field: 'sign', type: 'in', value}])
      },
      gotoToSpec () {
         if (!this.tabulator)
            return
         const selectedData = this.tabulator.getSelectedData()
         if (!selectedData.length) {
            this.showErrorMessage('Выберите спецификацию в таблице!')
            return
         }

         window.open(this.baseUrl + '/spec/update?id=' + selectedData[0].idspec, '_blank')
      },
      gotoNomen (side) {
         if (!this.tabulator)
            return
         const selectedData = this.tabulator.getSelectedData()
         if (!selectedData.length) {
            this.showErrorMessage('Выберите строку в таблице!')
            return
         }

         const idnomen = selectedData[0][side + '_idnomen']
         if (!idnomen) {
            this.showErrorMessage('В данной строке номенклатура не определена.<br>Выберите другую строку в таблице!')
            const rows = this.tabulator.getSelectedRows()
            rows[0].deselect()
            return
         }

         const url = this.baseUrl + '/nomen/update?idnomen=' + idnomen ;
         window.open(url, '_blank')
      },
      gotoCustomerNomen () {
         this.gotoNomen('c')
      },
      gotoDKCNomen () {
         this.gotoNomen('d')
      },
      cellDblClick (event, cell) {
         switch (cell.getField()) {
            case 'docnum':
            case 'docdate':
            case 'fio': this.gotoToSpec()
                        break
            case 'firmname':
            case 'c_code':
            case 'c_mark':
            case 'c_name':
            case 'c_quant':
            case 'c_edizm': this.gotoNomen('c')
                            break
            case 'd_code':
            case 'd_name':
            case 'd_quant':
            case 'd_edizm':
            case 'sign':
            case 'note':   this.gotoNomen('d')
                           break
         }
      },
   },
   mounted () {
      const options = this.options
      options.ajaxURL = this.solutionUrl
      this.tabulator = new Tabulator('#solutiontbl', options)
      this.tabulator.on('dataLoadError', this.ajaxError)
      this.tabulator.on('cellDblClick', this.cellDblClick)
      this.tabulator.on('tableBuilt', this.setStateFilter)
      
   },
}
</script>

<style>
   .sol_container {
      display: grid;
      /*margin: 0;*/
      height: 100%;
      max-height: 100%;
      grid-template-rows: auto 1fr;
      grid-template-areas: "header" "content";
   }

   .sol_header {
      grid-area: header;
   }

   .sol_content {
      grid-area: content;
   }

   .sol_tbl_wrap {
      height: 100%;
      max-height: 100%;
   }

   .state_toolbar_panel {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly ;
      height: 100%;
   }

   .toggle_control {
      margin-left: 12px;
   }

   .toggle_label {
      width: 32ch ;
      text-align: right;
   }

   .toggle_block {
      margin: 0;
      display: flex;
      align-items: center ;
      padding: 0 8px;
   }
</style>
