var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: { app_container: !_vm.hidden_app, app_hidden: _vm.hidden_app },
      attrs: { id: "app" },
    },
    [
      _c("header", [
        _c(
          "nav",
          { staticClass: "navbar navbar-default", attrs: { id: "NavBarMain" } },
          [
            _c("div", [
              _c("div", { staticClass: "dkc-navbar-header" }, [
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "brand" }, [
                  _c(
                    "a",
                    {
                      staticClass: "navbar-brand",
                      attrs: { href: _vm.baseUrl + "/" },
                    },
                    [
                      _c("img", {
                        attrs: { src: require("./images/logo.svg"), alt: "" },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "caption-text" }, [
                    _vm._v("СПЕЦИФИКАЦИИ"),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "collapse navbar-collapse",
                    attrs: { id: "NavBarMain-collapse" },
                  },
                  [
                    _c("div", { staticClass: "top-menu" }, [
                      _c("ul", { staticClass: "nav navbar-nav" }, [
                        _c("li", { staticClass: "nav-item" }, [
                          _c(
                            "a",
                            {
                              staticClass: "nav-link",
                              attrs: { href: _vm.baseUrl + "/spec" },
                            },
                            [_vm._v("Журнал спецификаций")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("li", { staticClass: "nav-item" }, [
                          _c(
                            "a",
                            {
                              staticClass: "nav-link",
                              attrs: { href: _vm.baseUrl + "/spec/create" },
                            },
                            [_vm._v("Новая спецификация")]
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.idRole == 1
                          ? _c("li", { staticClass: "nav-item" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "nav-link",
                                  attrs: {
                                    href: _vm.baseUrl + "/spec/solutions",
                                  },
                                },
                                [_vm._v("База решений по аналогам")]
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("li", { staticClass: "dropdown" }, [
                          _vm._m(1),
                          _vm._v(" "),
                          _c("ul", { staticClass: "dropdown-menu" }, [
                            _vm.idRole == 1
                              ? _c("li", { staticClass: "nav-item" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "nav-link",
                                      attrs: {
                                        href: _vm.baseUrl + "/user/index",
                                      },
                                    },
                                    [_vm._v("Пользователи программы")]
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("li", { staticClass: "nav-item" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "nav-link",
                                  attrs: { href: _vm.baseUrl + "/firm/index" },
                                },
                                [_vm._v("Фирмы производители")]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("li", { staticClass: "divider" }),
                            _vm._v(" "),
                            _c("li", { staticClass: "nav-item" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "nav-link",
                                  attrs: {
                                    href: _vm.baseUrl + "/nomen-group/index",
                                  },
                                },
                                [_vm._v("Группы номенклатуры")]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("li", { staticClass: "nav-item" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "nav-link",
                                  attrs: { href: _vm.baseUrl + "/nomen/index" },
                                },
                                [_vm._v("Номенклатура")]
                              ),
                            ]),
                            _vm._v(" "),
                            [1, 2].includes(_vm.idRole)
                              ? _c("li", { staticClass: "nav-item" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "nav-link",
                                      attrs: {
                                        href: _vm.baseUrl + "/nomen/import",
                                      },
                                    },
                                    [_vm._v("Импорт номенклатуры")]
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.idRole == 1
                              ? _c("li", { staticClass: "nav-item" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "nav-link",
                                      attrs: {
                                        href: _vm.baseUrl + "/nomen/twin",
                                      },
                                    },
                                    [_vm._v("Дубли номенклатуры")]
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("li", { staticClass: "dropdown" }, [
                          _vm._m(2),
                          _vm._v(" "),
                          _c("ul", { staticClass: "dropdown-menu" }, [
                            _c("li", { staticClass: "nav-item" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "nav-link'",
                                  attrs: {
                                    href: _vm.baseUrl + "/help/index.html",
                                  },
                                },
                                [_vm._v("Справка")]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("li", { staticClass: "nav-item" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "nav-link",
                                  attrs: {
                                    href:
                                      _vm.baseUrl + "/help/knowledge_base.html",
                                  },
                                },
                                [_vm._v("База знаний")]
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("ul", { staticClass: "nav navbar-nav" }, [
                        _c("li", [
                          _c(
                            "a",
                            { attrs: { href: _vm.baseUrl + "/user/logout" } },
                            [_vm._v("Выход")]
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("div", [
                  _c("div", { staticClass: "prifile" }, [
                    _c("a", { attrs: { href: _vm.userProfileUrl } }, [
                      _c("img", {
                        attrs: {
                          src: require("./images/user.svg"),
                          width: "16",
                          height: "auto",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "username" }, [
                        _vm._v(_vm._s(_vm.user.fio)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.now))]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "caption" }, [
              _c("div", { staticClass: "caption-pagetitle" }, [
                _vm._v(
                  "\n             " + _vm._s(_vm.title) + "\n             "
                ),
                _vm.modified ? _c("span", [_vm._v("*")]) : _vm._e(),
              ]),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      !_vm.errorPage
        ? _c("router-view", { staticClass: "main_view" })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "debug_area", attrs: { id: "debug" } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "navbar-toggle",
        attrs: {
          type: "button",
          "data-toggle": "collapse",
          "data-target": "#NavBarMain-collapse",
        },
      },
      [
        _c("span", { staticClass: "sr-only" }, [_vm._v("Toggle navigation")]),
        _vm._v(" "),
        _c("span", { staticClass: "icon-bar" }),
        _vm._v(" "),
        _c("span", { staticClass: "icon-bar" }),
        _vm._v(" "),
        _c("span", { staticClass: "icon-bar" }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "dropdown-toggle",
        attrs: { href: "#", "data-toggle": "dropdown" },
      },
      [_vm._v("Справочники"), _c("b", { staticClass: "caret" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "dropdown-toggle",
        attrs: { href: "#", "data-toggle": "dropdown" },
      },
      [_vm._v("Помощь"), _c("b", { staticClass: "caret" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }