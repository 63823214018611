<template>
  <dkc-modal-dlg
    :buttons="buttons"
    :actions="actions"
    v-show="invalidList"
    @close="closeModalDlg"
  >
    <span slot="header"
      >Обнаружены единицы измерения отсутствующие в справочнике.<br />Исправте
      исходные данные и повторите операцию.</span
    >
    <span slot="body">
      <div v-if="invalidList">
        <div v-for="ei in errors" :key="ei">
          <edizm-validator-item :edizmname="ei" :edizm-list="edizmList">
          </edizm-validator-item>
        </div>
      </div>
    </span>
  </dkc-modal-dlg>
</template>

<script>
import edizmValidatorItem from "./edizmValidatorItem";
import dkcModalDlg from "./dkcModalDlg";
import EdIzmExt from "../units/edizmExt";

export default {
  name: "edizmValidator",
  components: { edizmValidatorItem, dkcModalDlg },
  data() {
    return {
      errors: [],
      edizmList: [],
      invalidList: false,
      buttons: ["ok", "cancel"],
      actions: {
        ok: () => {
          this.fillSynonyms();
          if (this.doImport) {
            this.saveChanges(this.doImport(this.importParams));
          }
          else if (this.resolve) {
            this.saveChanges(false);
            this.resolve({excelData: this.excelData})
          }
        },
        cancel: () => {
          return this.reject("Отмена пользователем");
        },
      },
      doImport: false,
      importParams: false,
      resolve: false,
      reject: false,
    };
  },
  methods: {
    executeAsyc(excelData, ref, list, resolve, reject) {
      this.resolve = resolve
      this.reject = reject
      this.excelData = excelData
      if (this.execute(ref, list, false, false)) {
        resolve({excelData: this.excelData});
        return;
      }
    },
    execute(ref, list, doImport, importParams) {
      const self = this;
      this.doImport = doImport;
      this.importParams = importParams;
      this.edizmList = ref;
      this.errors = [];
      list.forEach((einame) => {
        const elem = ref.find((edizm) => {
          return self.findInRef(edizm, einame);
        });
        if (elem == undefined) self.errors.push(einame);
      });

      this.invalidList = self.errors.length > 0;

      return !this.invalidList;
    },
    findInRef(edizm, einame) {
      return edizm.compare(einame);
      const name = einame.toUpperCase().replace(/[.,:;-]/, "");
      return (
        edizm.name.toUpperCase() == name ||
        edizm.shortname.toUpperCase() == name
      );
    },
    closeModalDlg() {
      this.invalidList = false;
    },
    getEIByID(id) {
      return this.edizmList.find((ei) => {
        return ei.idedizm == id;
      });
    },
    fillSynonyms() {
      const self = this;
      const dlg = this.$children[0];
      dlg.$children.forEach((item) => {
        if (item.replacement > 0) {
          self.getEIByID(item.replacement).add(item.edizmname);        
        } else if (item.replacement < 0) {
          self.edizmList.push(
            new EdIzmExt({
              idedizm: -1,
              name: item.edizmname,
              shortname: item.edizmname.substr(0, 8),
              synonyms: "",
            })
          );
        }
      });
    },
    saveChanges(doImport) {
      const self = this;
      const savers = [];
      this.edizmList.forEach((item) => {
        if (item.changed) {
          const saver = item.save();
          savers.push(saver);
          saver.then((response) => {
            saver.idedizm = response.data.idedizm;
          });
        }
      });

      Promise.all(savers)
        .then(() => {
          if (doImport) doImport();
          if (self.resolve) {
            self.resolve()
          }
        })
        .catch((error) => {
          if (self.reject)
            self.reject(
              "Ошибка сохранения синонима единицы измерения \n" + error
            );
        });
    },
  },
};
</script>

<style scoped>
</style>
