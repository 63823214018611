<template>
  <div>
    <div id="url-tbl" ref="urltbl"></div>
    <div class="form-group help-group">
      <label class="control-label"
        >Шаблоны для формирования адресной строки</label
      >
      <div>
        При формировании адресной строки шаблон будет заменен на соответствующее
        значение номенклатуры
      </div>
      <table class="table-help">
        <tr>
          <td class="td-templ">@code@</td>
          <td class="td-desc">
            Строка <strong>@code@</strong> будет заменена кодом номенклатуры
          </td>
        </tr>
        <tr>
          <td class="td-templ">@mark@</td>
          <td class="td-desc">
            Строка <strong>@mark@</strong> будет заменена типом/маркой
            номенклатуры
          </td>
        </tr>
      </table>
    </div>
    <dkc-model-dlg
      :buttons="buttons"
      :actions="actions"
      v-show="isModalDlgVisible"
      @close="closeModalDlg"
    >
      <span slot="body">{{ bodyText }}</span>
      <span slot="header">{{ headerText }}</span>
    </dkc-model-dlg>
  </div>
</template>

<script>
import { TabulatorFull as Tabulator } from "tabulator-tables";
import dkcModelDlg from "./dkcModalDlg";

export default {
  name: "dkcNomenGroupUrl",
  components: { dkcModelDlg },
  props: ["idng", "offline"],
  data: function () {
    return {
      tabulator: null,
      tableData: [],
      mounted: false,
      isModalDlgVisible: false,
      headerText: "Подтверждение удаления",
      bodyText: "Вы действительно хотите удалить ссылку на внешний ресурс?",
      buttons: ["yes", "no"],
      actions: {
        yes: this.deleteFirmUrl,
      },
    };
  },
  watch: {
    /* tableData: {
                handler: newData => this.tabulator.replaceData(newData),
                deep: true
            }, */
    idng: function () {
      const tbl = this.tabulator;
      if (tbl != null && this.mounted) {
        window.dkcapp.$store.state.ng.selectedID = this.idng;
        tbl.clearData();
        tbl.replaceData();
      }
    },
  },
  computed: {
    grant: () => {
      return window.dkcapp.$store.state.user.idRole < 3;
    },
  },
  methods: {
    showNoGrant: function () {
      this.bodyText = "У вас нет прав на управление НСИ";
      this.buttons = ["ok"];
      this.actions = { ok: "" };
      this.showModalDlg();
    },
    createTable: function (firmList) {
      const comp = this;
      const lid = this.idng;
      const firmArr = { 0: "" };
      firmList
      .sort( (f1, f2) => f1.name < f2.name ? -1 : (f1.name > f2.name ? 1 : 0))
      .forEach(function (item) {
        firmArr[item.idFirm] = item.name;
      });

      this.tabulator = new Tabulator("#url-tbl", {
        data: this.tableData,
        layout: "fitColumns",
        autoResize: true,
        placeholder: "Нет данных",
        responsiveLayout: true,
        resizableRows: true,
        selectable: true,
        columns: [
          { formatter: "handle", widthGrow: 1 },
          {
            title: "Организация",
            field: "idFirm",
            visible: true,
            widthGrow: 6,
            editable: true,
            editor: "select",
            editorParams: { values: firmArr },
            formatter: "lookup",
            formatterParams: firmArr,
          },
          {
            title: "Ссылки на сторонние ресурсы",
            columns: [
              {
                title: "Адрес",
                field: "url_part",
                visible: true,
                widthGrow: 12,
                editable: true,
                editor: "input",
              },
              {
                widthGrow: 1,
                editable: false,
                formatter: (cell, formatterParams, onRendered) => {
                  return '<img src="' + this.baseUrl + '../images/url.svg" width="24px" height="24px"></img>';
                },
                widthGrow: 1,
                hozAlign: "center",
                cellClick: (e, cell) => {
                  const data = cell.getData();
                  let url = data.url_part;
                  const pd = url.indexOf(":");
                  if (pd < 0) {
                    url = "http://" + url;
                  }
                  url = url.replace("@code@", "").replace("@mark@", "");
                  window.open(url);
                },
              },
            ],
          },
        ],
        locale: "ru-RU",
        langs: {
          "ru-RU": {
            ajax: {
              loading: "загрузка",
              error: "ошибочка вышла",
            },
            pagination: {
              first: String.fromCharCode(57449),
              first_title: "первая страница",
              prev: String.fromCharCode(57457),
              prev_title: "предыдущая страница",
              next: String.fromCharCode(57461),
              next_title: "следущая страница",
              last: String.fromCharCode(57463),
              last_title: "последняя страница",
            },
          },
        },
        ajaxURL: this.baseUrl + "/nomen-group/get-firm-url",
        ajaxContentType: "json",
        ajaxURLGenerator: function (url, config, params) {
          const selID = window.dkcapp.$store.state.ng.selectedID;
          return url + "?id=" + selID;
        },
        footerElement: `<div class='tabulator_datactl'>
                                          <button type='button' class='glyphicon glyphicon-plus'   id='btn__url_append'></button>
                                          <button type='button' class='glyphicon glyphicon-minus'  id='btn__url_delete''></button>
                                          <div class='resultHint resultHint_success' id='errorHintFirm' />
                                    </div>`,
      });
      this.tabulator.on("cellEdited", comp.editFirmUrl);
      this.tabulator.on("tableBuilt", () => {
        comp.mounted = true;
        $("#btn__url_append").on("click", comp.appendFirmUrl);
        $("#btn__url_delete").on("click", comp.queryDeleteFirmUrl);
      });
    },
    appendFirmUrl: function () {
      if (!this.grant) {
        this.showNoGrant();
        return;
      }
      this.tabulator.addRow({ idFirm: "0", url_part: "" });
    },
    editFirmUrl: function (cell) {
      if (!this.grant) {
        this.showNoGrant();
        return;
      }
      if (this.offline) {
        return;
      }
      const data = cell.getData();
      if (data.idFirm < 1) {
        return;
      }
      if (data.url_part == "") {
        return;
      }

      this.queryA('/nomen-group/save-firm-url', {
          id: this.idng,
          idfirm: data.idFirm,
          url: data.url_part,
        })      
        .then(function (resp) {
          $("#errorHintFirm")
            .text("Ссылка на внешние ресурсы успешно сохранена")
            .addClass("resultHint_success")
            .removeClass("resultHint_error")
            .removeClass("resultHint_hidden")
            .addClass("resultHint_visible");

          setTimeout(function () {
            $("#errorHintFirm")
              .removeClass("resultHint_visible")
              .addClass("resultHint_hidden");
          }, 5000);
        })
        .catch(function (error) {
          $("#errorHintFirm")
            .text("Ошибка сохранения: " + error)
            .addClass("resultHint_error")
            .removeClass("resultHint_success")
            .removeClass("resultHint_hidden")
            .addClass("resultHint_visible");

          setTimeout(function () {
            $("#errorHintFirm")
              .removeClass("resultHint_visible")
              .addClass("resultHint_hidden");
          }, 5000);
        });
    },
    queryDeleteFirmUrl: function () {
      if (!this.grant) {
        this.showNoGrant();
        return;
      }
      if (this.offline) {
        const rows = this.tabulator.getSelectedRows();
        rows.forEach(function (item) {
          item.delete();
        });
      } else {
        this.showModalDlg();
      }
    },
    deleteFirmUrl: function () {
      if (!this.grant) {
        this.showNoGrant();
        return;
      }
      const data = this.tabulator.getSelectedData();
      if (data[0].idFirm < 1) {
        return;
      }

      const tbl = this.tabulator;
      this.queryA('/nomen-group/delete-firm-url', {
          id: this.idng,
          idfirm: data[0].idFirm,
        })      
        .then(function (resp) {
          const rows = tbl.getSelectedRows();
          rows.forEach(function (item) {
            item.delete();
          });
          $("#errorHintFirm")
            .text("Ссылка на внешние ресурсы успешно удалена")
            .addClass("resultHint_success")
            .removeClass("resultHint_error")
            .removeClass("resultHint_hidden")
            .addClass("resultHint_visible");

          setTimeout(function () {
            $("#errorHintFirm")
              .removeClass("resultHint_visible")
              .addClass("resultHint_hidden");
          }, 5000);
        })
        .catch(function (error) {
          $("#errorHintFirm")
            .text("Ошибка удаления: " + error)
            .addClass("resultHint_error")
            .removeClass("resultHint_success")
            .removeClass("resultHint_hidden")
            .addClass("resultHint_visible");

          setTimeout(function () {
            $("#errorHintFirm")
              .removeClass("resultHint_visible")
              .addClass("resultHint_hidden");
          }, 5000);
        });
    },
    showModalDlg() {
      this.isModalDlgVisible = true;
    },
    closeModalDlg() {
      this.isModalDlgVisible = false;
    },
  },
  mounted() {
    const comp = this;
    this.queryA('/firm/select', false)
      .then(function (response) {
        const firmList = response.data.data;
        comp.createTable(firmList);
      })
      .catch(function (error) {
        console.log("Ошибка загрузки таблицы:");
        console.log(error);
        console.log("^^^^^^^^^^^^^^^^^^^^^^^^");
      });
  },
};
</script>

<style scoped>
.help-group {
  margin-top: 16px;
}
.table-help {
  margin-top: 16px;
}

.td-templ {
  padding: 4px 8px;
  color: navy;
  font-weight: bold;
}

.td-desc {
  padding: 4px 0px 4px 8px;
}
</style>

<style>
.tabulator {
  background-color: transparent;
}

.tabulator_datactl {
  display: flex;
}

.resultHint {
  margin-left: 16px;
}

.resultHint_success {
  color: green;
}

.resultHint_error {
  color: red;
}

.resultHint_visible {
  opacity: 1;
  transition: opacity 0.5s ease 0s;
}

.resultHint_hidden {
  opacity: 0;
  transition: opacity 3s ease 0s;
}
</style>
