var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tunecol" }, [
    _c("label", { staticClass: "control-label", attrs: { for: "tunecol" } }, [
      _vm._v("Настройка колонок"),
    ]),
    _vm._v(" "),
    _c("div", { ref: "colmaptbl", attrs: { id: "colmaptbl" } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }