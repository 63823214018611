export default function removeStandart (text) {
  if (!text || typeof text != 'string')
                                    return text

  const buffer = text
  const standarts = ['ГОСТ', 'ОСТ', 'ТУ', 'САНПИН', 'ANSI', 'ISO', 'DIN']

  standarts.forEach(standart => {

    try {
      const index = text.indexOf(standart)
      if (index == 0) {
        text = ''
        return false
      } else if (index > 0) {
        text = text.substr(0, index - 1)
      }
    }
    catch (e) {
      console.error('Ошибка удаления стандартов: Text = "' + buffer + '"  text = "' + text + '" standart = ' + standart)
      console.error(e)
    }
    return true
  })

  return text
}
