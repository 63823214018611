<template>
  <div class="ng-container" v-bind:style="{ maxWidth: maxWidth}">
       <bpk-toolbar-panel v-if="!hideToolbar">
         <dkc-tool-button
            title="Новая группа"
            hint="Регистрация новой группы номенклатуры"
            iconclass="dbtn-create"
            :action="createNomenGroup"
            v-if="grant"
         />
         <dkc-tool-button
            title="Изменить группу"
            hint="Изменить данные о группе номенклатуры"
            iconclass="dbtn-edit"
            :action="editNomenGroup"
            v-if="grant"
         />
         <dkc-tool-button
            title="Удалить группу"
            hint="Удалить группу номенклатуры"
            iconclass="dbtn-delete"
            :action="queryDeleteNomenGroup"
            v-if="grant"
         />


         <dkc-search-control @onsearch="doSearchGroup" />
       </bpk-toolbar-panel>

    <div class="ng-tree" v-bind:style="{ maxHeight: maxHeight, height: maxHeight}">
      <div id="tree"></div>
    </div>

    <dkc-model-dlg
      :buttons="buttons"
      :actions="actions"
      v-show="isModalDlgVisible"
      @close="closeModalDlg"
    >
      <span slot="body">{{bodyText}}</span>
      <span slot="header">{{headerText}}</span>
    </dkc-model-dlg>
  </div>
</template>

<script>
  import dkcModelDlg from './dkcModalDlg'
  import dkcToolButton from './dkcToolButton'
  import bpkToolbarPanel from './bpkToolbarPanel'
  import dkcSearchControl from './dkcSearchControl'
  import $ from 'jquery';
  import 'jstree'
  import 'jstree/dist/themes/default/style.min.css'

  export default {
    name: "jsTreePort",
    components: {
       bpkToolbarPanel, dkcToolButton, dkcModelDlg, dkcSearchControl,
    },
    props: ["height", "width", "hideToolbar", ],
    data() {
      return {
        selectedID: 0,
        src: 'ng',
        isModalDlgVisible: false,
        headerText: "Подтверждение удаления",
        bodyText: "",
        buttons: ["yes", "no", "ok", "close"],
        actions: {
          yes: () => {alert("?")},
        },
      }
    },
    computed: {
        selectURL: function ()  {
          return this.baseUrl + "/nomen-group/select"
        },
        maxHeight: function() {
          if (typeof this.height == "undefined") {
            const h = window.innerHeight - 210
            return h + 'px' ;
          }

          return this.height
        },
        maxWidth: function() {
          if (typeof this.width == "undefined") {
            return '100%' ;
          }

          return this.width
        },
        grant: () => {
            return window.dkcapp.$store.state.user.idRole < 3
        }
    },
    watch: {
      selectedID: function() {
        this.selectNode()
      },
    },
    methods: {
        showNoGrant: function() {
            this.bodyText = "У вас нет прав на управление НСИ" ;
            this.buttons = ["ok"];
            this.actions = {ok: ''} ;
            this.showModalDlg() ;
        },
        selectNode: function() {
          if (this.selectedID > 0) {
            $('#tree').jstree(true).deselect_all();
            $('#tree').jstree(true).select_node(this.selectedID, false, false);
          }
        },
        doSearchGroup: search_text => {
          $('#tree').jstree(true).search(search_text);
        },
        createNomenGroup: function() {
          if (!this.grant) {
              this.showNoGrant()
              return
          }
          const ids = $('#tree').jstree(true).get_selected(true) ;
          let   url = this.baseUrl + '/nomen-group/create' ;
          if (ids.length > 0) {
            url = url + '?idprnt=' + ids[0].id + '&src=' + this.src  ;
          }
          window.location = url ;
        },
        editNomenGroup: function() {
            if (!this.grant) {
                this.showNoGrant()
                return
            }
            let ids = $('#tree').jstree(true).get_selected() ;
            if (ids.length > 0) {
              window.location = this.baseUrl + '/nomen-group/update?id=' + ids[0] + '&src=' + this.src  ;
            }
            else {
              this.headerText = 'Невозможно выполнить операцию' ;
              this.bodyText   = 'Ни одна группа не выделена! Выделите группу и повторите операцию'
              this.buttons    = ["ok"] ;
              this.showModalDlg() ;
            }
        },
        queryDeleteNomenGroup: function() {
            if (!this.grant) {
                this.showNoGrant()
                return
            }
            const idl = $('#tree').jstree(true).get_selected(true) ;
            switch (idl.length) {
              case 0: this.bodyText = "Выберите группу номенклатуры, которую хотите удалить" ;
                this.buttons = ["ok"];
                break ;
              case 1: this.bodyText = "Вы действительно хотите удалить группу номенклатуры \"" + idl[0].text + "\"?" ;
                this.buttons = ["yes", "no"];
                break ;
              default:
                this.bodyText = "Вы действительно хотите удалить из справочника " + idl.length + " групп номенклатуры?" ;
                this.buttons = ["yes", "no"];
            }

            this.actions = {yes: this.deleteNomenGroup} ;
            this.showModalDlg() ;
        },
        deleteNomenGroup: function() {
            if (!this.grant) {
                this.showNoGrant()
                return
            }
            const idl = $('#tree').jstree(true).get_selected() ;
            if (idl.length > 0) {
              const tbl = this.tabulator ;
              this.queryA('/nomen-group/delete', {id: idl[0], src: this.src})
                .then(function(response) {
                  $('#tree').jstree(true).refresh()
                })
                .catch(function(error) {
                  console.log('Ошибка удаления группы номенклатуры:', error.message);
                }) ;
            }
        },
        showModalDlg() {
          this.isModalDlgVisible = true ;
        },
        closeModalDlg() {
          this.isModalDlgVisible = false ;
        },
        sendSelectedNode(e, data) {
          this.$cookie.set('selected_group_id', data.node.id, { expires: '1Y' })
          this.$emit('selectTreeNode', data.node)
        },
        getSelectedNode() {
          return $('#tree').jstree(true).get_selected(true) ;
        },
        initSelectedNode() {
          if (this.selectedID < 1) {
            this.selectedID = this.$cookie.get('selected_group_id')
          }
          else {
            this.selectNode()
          }
        },
    },
    mounted() {
      const cmp = this ;
      $('#tree')
        .on('ready.jstree', this.initSelectedNode)
        .on('select_node.jstree', this.sendSelectedNode )
        .on('move_node.jstree', function(e, data) {
            if (!this.grant) {
                return
            }
            if (data.parent != data.old_parent) {
              $.ajax({
                type: 'POST',
                url: cmp.baseUrl + '/nomen-group/change-parent',
                headers: {'X-CSRF-Token': tkn_csfr},
                data: {
                  id: data.node.id,
                  idParent: data.parent,
                  msg: 'Перенос группы "' + data.node.text + '" завершился ошибкой! перезагрузите страницу (F5)'
                },
                dataType: 'json',
                error: function(data, textStatus) {

                },
                success: function(data, textStatus) {

                }
              }) ;
          }

        })
        .jstree({
          core: {
            data:{
                type: 'GET',
                url: this.selectURL,
                headers: {'X-CSRF-Token': tkn_csfr},
                dataType: 'json',
            },
            animation: 350,
            check_callback: true,
            expand_selected_onload: true,
            multiple: false,
            themes: {variant: 'large'}
          },
          plugins: ['contextmenu', 'dnd', 'search'],  // , 'state' - плагин убрал, ибо глушит всякую деятельность по инициализации первичного выбора
          contextmenu: {
            items: {
              create: {
                label: 'Создать новую группу',
                icon: cmp.baseUrl + '/images/create24px.png',
                action: function(obj) {
                  if (!cmp.grant) {
                      cmp.showNoGrant()
                      return
                  }
                  let ids = $('#tree').jstree(true).get_selected(true) ;
                  if (ids.length > 0) {
                    window.location = 'create?idprnt=' + ids[0].parent ;
                  }
                  else {
                    window.location = 'create' ;
                  }
                }
              },
              edit: {
                label:'Редактировать группу',
                icon: cmp.baseUrl + '/images/edit24px.png',
                action: obj => {
                  if (!cmp.grant) {
                      cmp.showNoGrant()
                      return
                  }
                  this.editNomenGroup();
                }
              },
              delete: {
                label:'Удалить группу',
                icon: cmp.baseUrl + '/images/delete24px.png',
                action: obj => {
                    if (!cmp.grant) {
                        cmp.showNoGrant()
                        return
                    }
                    this.queryDeleteNomenGroup() ;
                }
              },
            }
          },
          search: {
            'show_only_matches': true,
          },
      }) ;
    },
  }
</script>

<style scoped>
  .ng-container {

  }
  .ng-tree {
    width: 100%;
    border: 1px solid darkgray;
    overflow: scroll ;
  }


</style>
