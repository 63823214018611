var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("splitpanes", { attrs: { vertical: "" } }, [
        _c(
          "div",
          [
            _c("dkc-nomen-group", {
              ref: "tree",
              on: { selectTreeNode: _vm.selectTreeNode },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("dkc-nomen-group-info", {
              attrs: { ngInfo: _vm.ngInfo, isError: _vm.isError },
              on: { select_group: _vm.selectGroup },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }