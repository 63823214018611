export function SpecLoaderAsync (options) {
   const { spec: _spec } = options
   let total_pc = 0
   let packagecount = -1
   let packagenum = 0
   // let firstpackage = []

   function loadHeader(data) {
      _spec.idspec = data.idspec
      _spec.docnum = data.docnum
      _spec.docdate = data.docdate
      _spec.author = data.author
      _spec.customer = data.customer
      _spec.idFirm = data.idFirm
      _spec.sign = data.sign
      _spec.note = data.note

      const docdate = new Date(data.docdate).toLocaleDateString()
      dkcapp.title = `Редактирование спецификации № ${data.docnum} от ${docdate}`
   }

   function createExtColumns(data) {
      const extc = data.extcols
      if (extc) {
         extc.forEach(col => {
            if (!_spec.findColumnDefinition(col.field)) {
               _spec.appendColumn(col.field, col.title)
            } else {
               _spec.updateColumn(col.field, col.title)
            }
         })
      }
   }

   function loadTable(data) {
      if (!data.table)
        return
      data.table.forEach(row => {
         if (row.analogs && typeof row.analogs == 'string')
            try {
               row.analogs = JSON.parse(row.analogs)
            } catch {
               row.analogs = []
            }

         row.innerID = _spec.grid.sequenceID.nextval()
         if (row.c_quant && row.c_price)
            row.c_summa = row.c_quant * row.c_price
         if (row.d_quant && row.d_price)
            row.d_summa = row.d_quant * row.d_price

         if (row.c_nomen) {
            row.r_idnomen = row.c_nomen.idnomen
            row.r_firmname = row.c_nomen.firmname
            row.r_code = row.c_nomen.code
            row.r_mark = row.c_nomen.mark
            row.r_name = row.c_nomen.name
         }
      })

      _spec.tabulator.addData(data.table)
   }

   function loadFirmRef (data) {
      if (data.firms) {
          data.firms.sort((firm1, firm2) => {
            if (firm1.name < firm2.name) return -1
            if (firm1.name > firm2.name) return 1
            return 0
          })

         _spec.firmList = data.firms
         _spec.$store.state.firm.ref = data.firms
      }
   }

   function loadEdIzmRef(data) {
      if (!data.edizm)
         return

      const arr = []
      arr[0] = ''
      const ei = data.edizm
      ei.forEach(function(item) {
         arr[item.idedizm] = item.shortname.toLowerCase()
         _spec.edizmfull[item.shortname.toLowerCase()] = item.idedizm
         if (item.synonyms) {
            const syms = item.synonyms.split("\t")
            syms.forEach(sm => {
               _spec.edizmfull[sm.toLowerCase()] = item.idedizm
            })
         }
      })

      _spec.$store.commit('load', data.edizm) ;
      _spec.edizm = arr
   }

   function loadResponse(data) {
      if (packagecount === -1) {
        const firstpackage = data.spec
         loadFirmRef(data)
         loadEdIzmRef(data)
         _spec.createSpecTable()
         _spec.tabulator.on("tableBuilt", () => {
            if (firstpackage) {
              loadTable(firstpackage)
            }
          })
         total_pc = data.packagecount || 100000000
         packagecount = data.packagecount || 0
         packagenum = 1
         if (data.spec) {
            loadHeader(data.spec)
            createExtColumns(data.spec)
         }
         return
      }

      firstpackage = {}
      packagecount--
      packagenum++

      if (data.spec)
         loadTable(data.spec)
   }

   function requestSpec (idspec) {
      const formData = new FormData()
      formData.append('id', idspec)
      formData.append('package', packagenum)
      const options = {
         method: 'POST',
         url: burl + '/spec/get',
         headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json; charset=utf-8',
            'X-CSRF-Token': tkn_csfr
         },
         data: formData,
      }

      return axios(options)
   }

   function doAsyncLoad (idspec, resolve, reject) {
      if (packagecount === 0) {
         _spec.hideBckgroundMessage()
         resolve()
         return ;
      }

      if (packagecount !== -1) {
         let message = 'Загрузка спецификации'
         if (total_pc) {
            message = message + ': ' + Math.round(100 * packagenum / total_pc) + '%'
         }
         _spec.showBckgroundMessage(message)
         _spec.progresstitle = message
      }

      requestSpec(idspec)
         .then(response => {
            loadResponse(response.data)
            doAsyncLoad(idspec, resolve, reject)
         })
         .catch(error => {
            reject(error)
         })
   }

   this.execute = function (idspec) {
      //_spec.createSpecTable()
      return new Promise(function (resolve, reject) {
         doAsyncLoad(idspec, resolve, reject)
      })
   }
}
