var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "twin_box" }, [
    !_vm.userIsLoaded
      ? _c("div", { staticClass: "prep_box" }, [
          _vm._v("\n    Выполняется загрузка данных...\n  "),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.userIsLoaded && !_vm.userIsAdmin
      ? _c("div", { staticClass: "error_box" }, [
          _vm._v(
            "\n    У вас нет прав на использование этой функции программы\n  "
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.userIsLoaded && _vm.userIsAdmin
      ? _c(
          "div",
          { staticClass: "main_box" },
          [
            _c("splitpanes", { attrs: { vertical: "" } }, [
              _c(
                "div",
                { attrs: { "splitpanes-default": "50" } },
                [_c("nomen-twin-refs")],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "panel panel-default" }, [
                _c("div", { staticClass: "panel-heading" }, [
                  _vm._v("Информация по дублям"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "panel-body" }, [
                  _vm._v("В этой панели будет информация по дублям"),
                ]),
              ]),
            ]),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }