import Nomen from './Nomen'

export default function NomenGroup(name, parent, level = -1, row = 0) {
    this.children = []
    this.nomens = []
    this.name = name
    this.parent = parent
    this.row = row
    this.level = level
    this.idNomenGroup = 0

  /**
   * Добавляет к группе дочернюю группу
   * @param name - наменование группы
   * @param level - уровень группы
   * @param row - номер строки с данными из Excel
   * @returns {NomenGroup} - объект с данными дочерней группы
   */
    this.appendChild = function (name, level, row) {
        const group = new NomenGroup(name, this, level, row)
        this.children.push(group)

        return group
    }

  /**
   * Добавляет к группе номенклатуру.
   * Если данные в коде, типе или наименовании имеются, то номенклатура создается, добавляется к группе и возвращается функцией.
   * Если данных нет, то номенклатура не добавляется, а функция возвращает false
   * @param code - Код
   * @param mark - Тип/марка
   * @param name - наименование
   * @param edizm - единица измерения
   * @param price - цена
   * @param rest - остаток на складе
   * @returns {Nomen|boolean} - объект с новой номенклатурой или false.
   */
    this.appendNomen = function(code, mark, name, edizm, price, rest) {
        const nomen = new Nomen(code, mark, name, edizm, price, rest)
        if (nomen.isEmpty())
            return false

        this.nomens.push(nomen)
        return nomen
    }

  /**
   * Возвращает индекс дочерней группы с заданным наименованием
   * @param name - наименование группы, которую ищем
   * @returns {number} - индекс дочерней группы в массиве
   */
    this.indexOf = function(name) {
        return this.children.findIndex((group) => {
            return group.name == name
        })
    }

  /**
   * Разбивает группу на две, что бы новая группа содержала не более count номенклатуры.
   * Остальная номенклатура остается в исходной группе.
   * @param count - максимальное количество номенклатуры в группе
   * @returns {NomenGroup|boolean} - новая группа или false, если в исходной группе номенклатуры меньше чем count
   */
    this.split = function(count) {
        if (this.nomens.length < count)
            return false

        const maxindex = this.nomens.length - count - 1
        const group = new NomenGroup(this.name, this.parent, this.level)
        group.nomens = this.nomens.filter((nomen, index) => index > maxindex)
        this.nomens.splice(maxindex + 1, count)

        return group
    }

  /**
   * Возвращает имена текущей гуппы и всех родительских групп разделенных символом "/"
   * @returns {string|*}
   */
  this.getFullPath = function() {
        if (this.level < 0)
            return ''

        if (!this.parent)
            return this.name

        return this.parent.getFullPath() + '/' + this.name
    }

  /**
   * Клонирование структуры групп номенклатуры, без самой номенклатуры
   * @returns {NomenGroup} - новая группа номенклатуры, клон текущей.
   */
  this.cloneGroup = function () {
        const group = new NomenGroup(this.name, false, this.level, this.row)
        group.nomencount = this.nomens.length
        let child = false
        for (let i = 0; i < this.children.length; i++) {
            child = this.children[i]
            group.children.push(child.cloneGroup()) ;
        }

        return group
    }

  /**
   * Поиск группы, по номеру строки в таблице Excel
   * @param row - номер строки
   * @returns {NomenGroup|boolean|*|boolean} - группа с заданным номером строки или false, если не найдено
   */
    this.findFoliageByRow = function (row, name) {
        if (this.row == row && this.name == name && this.nomens.length > 0)
            return this

        let group  = false
        for (let i = 0; i < this.children.length; i++) {
            group = this.children[i].findFoliageByRow(row, name)
            if (group)
                return group
        }

        return false
    }
}
