<template>
   <div class="toolbar" v-if="visible">
      <slot></slot>
   </div>
</template>

<script>
export default {
   name: "bpkToolbar",
   props: {
      index: {type: Number, required: true}
   },
   data () {
      return {
         visible: false,
      }
   },
}
</script>

<style scoped>
   .toolbar {
      margin: 0;
      min-height: 84px;
   }
</style>
