export const grantctl = {
  computed: {
    grant: () => {
      return window.dkcapp.$store.state.user.idRole < 3
    },
  },
  methods: {
    showNoGrant: function() {
      this.bodyText = "У вас нет прав на управление НСИ"
      this.buttons = ["ok"]
      this.actions = {ok: ''}
      this.showModalDlg()
    },
  },
}
