<template>
  <dkc-modal-dlg :buttons="btns" :actions="acts" v-if="visible" @close="click_close">
    <div slot="body">
        <div>
          <span>Для продолжения поиска номенклатуры заказчика необходимо выбрать метод поиска:</span>
        </div>
        <div>
          <div>
            <input type="radio" id="all_rows" value="0" v-model="methodIndex">
            <label for="all_rows">поиск для всех строк спецификации</label>
          </div>
          <div>
            <input type="radio" id="empty_rows" value="1" v-model="methodIndex">
            <label for="empty_rows">поиск для строк без выбранной номенклатуры</label>
          </div>
          <div>
            <input type="radio" id="selected_rows" value="2" v-model="methodIndex">
            <label for="selected_rows">поиск для выбранных строк спецификации</label>
          </div>
          <div>
            <input type="radio" id="current_rows" value="3" v-model="methodIndex">
            <label for="current_rows">поиск для текущей строки спецификации</label>
          </div>
        </div>
      </div>
      <span slot="header">Выберите вариант поиска номенклатуры</span>
  </dkc-modal-dlg>
</template>

<script>
  import dkcModalDlg from './dkcModalDlg.vue'

  export default {
    name: 'NomenCollectSelector',
    components: { dkcModalDlg },
    data() {
      return {
        methodIndex: 1,
        btns: ['ok', 'close'],
        acts: {
          ok: this.click_ok,
          close: this.click_close,
        },
        resolve: false,
        reject: false,
        visible: false,
      }
    },
    methods: {
      execute () {
        return new Promise(this.promiseProc)
      },
      promiseProc (resolve, reject) {
        this.resolve = resolve
        this.reject = reject
        this.visible = true
      },
      click_ok () {
        if (this.resolve)
          this.resolve(this.methodIndex)
      },
      click_close () {
        this.visible = false ;
        if (this.reject)
          this.reject()
      },
    },
  }
</script>

<style scoped>

</style>
