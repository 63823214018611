<template>
    <div>
        <splitpanes vertical>
            <div splitpanes-min="18"  splitpanes-default="28">
                <dkc-nomen-group ref="groups" @selectTreeNode="selectTreeNode">
                </dkc-nomen-group>
            </div>

            <div splitpanes-default="70">
                <nomen-table @setNomenGroup="setNomenGroup"></nomen-table>
            </div>

        </splitpanes>

    </div>
</template>

<script>
    import DkcNomenGroup from './components/jsTreePort'
    import NomenTable from './components/NomenTable'
    import getUrlVar from './units/urlparser'
    import Splitpanes from 'splitpanes'
    import 'splitpanes/dist/splitpanes.css'
    import '../css/splitstyle.css'

    export default {
        name: "NomenRef",
        components: {
            DkcNomenGroup,
            NomenTable,
            Splitpanes
        },
        methods: {
            selectTreeNode: function(node) {
                this.$store.state.nomen.idgroup = node.id
            },
            setNomenGroup: function(nomen) {
                this.doSelectNomenGroup(nomen['idNomenGroup'])
            },
            doSelectNomenGroup: function (idgroup, idnomen) {
                if (idgroup) {
                    this.$store.state.nomen.idnomen = idnomen
                    this.$refs.groups.selectedID    = idgroup ;
                }
            },
            setURLParams(idgroup, idnomen, search_text) {
                this.$store.state.nomen.idgroup     = idgroup
                this.$store.state.nomen.idnomen     = idnomen
                this.$store.state.nomen.search_text = search_text ? search_text : ''
            }
        },
        mounted() {
            const idgroup     = getUrlVar('idNG')
            const idnomen     = getUrlVar('idnomen')
            const search_text = getUrlVar('search_text') ? decodeURI(getUrlVar('search_text')) : ''

            if (!search_text)
                this.doSelectNomenGroup(idgroup, idnomen)

            this.setURLParams(idgroup, idnomen, search_text)
            window.dkcapp.title = 'Справочник номенклатуры'
        },
    }
</script>

<style scoped>

</style>

<style>
  .splitpanes__pane {
    padding-top: 16px;
  }
  .navbar {
    margin-bottom: 0;
  }
</style>
