<template>
  <div class="component">
    <div v-if="grant" class="eit-container">
      <div class="eit-header">
        <div class="eit-file-selector">
          <div
            class="eit-file-title"
            onclick="document.getElementById('file-selecter').click()"
          >
            <span v-show="!file">Импорт номенклатуры из Excel-файла</span>
            <span v-show="file">{{ file.name }}</span>
          </div>
          <input
            id="src"
            type="file"
            name="src"
            style="display: none"
            @change="checkSelectedFiles"
          />
          <label id="file-selecter" for="src">
            выбери <span v-show="file">другой</span> файл
          </label>
          <div
            id="drop-selector"
            class="eit-drop-selector"
            onclick="document.getElementById('file-selecter').click()"
            @dragover="fileSelecterOver"
            @dragleave="fileSelecterLeave"
            @drop="fileSelecterDrop"
          >
            или перетащи сюда файл
            <span v-show="!xls.active">из проводника</span>
          </div>
        </div>

        <div v-if="fileLoading">
          <circle-anim
            :maxvalue="leafcout"
            :value="leafindex"
            :title="progresstitle"
          />
        </div>

        <div
          v-show="xls.active"
          class="eit-controls"
          style="flex-direction: column; justify-content: space-between"
        >
          <div
            class="eit-ctlr-group"
            style="width: 700px; align-items: flex-end"
          >
            <div
              class="ctrl-group"
              :class="{ 'has-warning': isNewFirm }"
              style="width: 450px"
            >
              <label class="control-label">
                Фирма - производитель<span v-if="isNewFirm">(новый)</span>
              </label>

              <!-- https://github.com/darrenjennings/vue-autosuggest -->
              <div class="autosuggest-container">
                <vue-autosuggest
                  v-model="firm"
                  :suggestions="suggestFirms"
                  :input-props="{
                    id: 'autosuggest__input',
                    placeholder: 'Введи фирму - производитель номенклатуры',
                    class: 'form-control',
                  }"
                  component-attr-class-autosuggest-results-container="autosuggest-list-container"
                  @focus="autosuggestFocus"
                  @blur="autosuggestBlur"
                  @selected="autosuggestSelected"
                >
                  <div
                    slot-scope="{ suggestion }"
                    style="display: flex; align-items: center"
                  >
                    <div>{{ suggestion.item }}</div>
                  </div>
                </vue-autosuggest>
              </div>
            </div>
            <div
              class="ctrl-group"
              :class="{ 'has-warning': isNewFirm }"
              style="width: 450px"
            >
              <button
                type="button"
                class="btn btn-primary btn-block"
                aria-disabled="true"
                :disabled="!checkTunes"
                @click="execImport"
              >
                ИМПОРТ
              </button>
            </div>
          </div>

          <div class="eit-controls" style="margin-left: 0">
            <div class="eit-ctlr-group">
              <div class="ctrl-group sheetname">
                <label for="sheetname" class="control-label"
                  >Закладки в файле:</label
                >
                <select
                  id="sheetname"
                  v-model="sheetName"
                  name="sheetname"
                  class="form-control"
                >
                  <option
                    v-for="sn in xls.sheetNames"
                    :label="sn"
                    :value="sn"
                  />
                </select>
              </div>

              <div class="ctrl-group">
                <label for="dataRange" class="control-label"
                  >Диапазон с данными:</label
                >
                <input
                  id="dataRange"
                  v-model="xls.dataRange"
                  type="text"
                  name="dataRange"
                  readonly
                  class="form-control"
                />
              </div>
            </div>

            <div class="eit-ctlr-group">
              <div class="ctrl-group">
                <label for="firstRow" class="control-label"
                  >Первая строка с данными:</label
                >
                <input
                  id="firstRow"
                  v-model="firstRow"
                  type="number"
                  class="form-control"
                  name="firstRow"
                  :min="rangeFR"
                  :max="lastRow"
                  step="1"
                />
              </div>

              <div class="ctrl-group">
                <label for="lastRow" class="control-label"
                  >Последняя строка с данными:</label
                >
                <input
                  id="lastRow"
                  v-model="lastRow"
                  type="number"
                  class="form-control"
                  name="lastRow"
                  :min="firstRow"
                  :max="rangeLR"
                  step="1"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="tunecol"
        :class="{ it_band: xls.active }"
        style="background-color: skyblue"
      >
        <label
          v-show="xls.active"
          for="tunecol"
          class="control-label band-label"
          >Настройка колонок</label
        >
        <dkc-grid
          id="tunecol"
          ref="tunecol"
          name="tunecol"
          tableid="tbltunecol"
        />
      </div>

      <details v-show="xls.active" open :class="{ it_band: xls.active }">
        <summary>Содержимое файла "{{ file.name }}"</summary>
        <div id="tblxlsdata" ref="xlsdata" name="xlsdata" />
      </details>

      <details v-show="xls.active" open :class="{ it_band: xls.active }">
        <summary>Образец импортируемой информации</summary>
        <div class="result-container">
          <div class="rc-tree-container">
            <label class="control-label">Группы номенклатуры</label>
            <div id="tree" />
          </div>
          <div class="rc-grid-container">
            <label class="control-label"
              >Номенклатура (без учёта выбранной группы)</label
            >
            <div id="tblresex" ref="resex" name="resex" />
          </div>
        </div>
      </details>
    </div>
    <dkc-model-dlg
      v-show="isModalDlgVisible"
      :buttons="buttons"
      :actions="actions"
      @close="closeModalDlg"
    >
      <span slot="body">{{ bodyText }}</span>
      <span slot="header">{{ headerText }}</span>
    </dkc-model-dlg>

    <ed-izm-validator ref="eiv" />
  </div>
</template>

<script>
import DkcGrid from "./dkcGrid";
import DkcToolButton from "./dkcToolButton";
import dkcModelDlg from "./dkcModalDlg";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import { grantctl } from "../mixins/grantcontroller";
/* import Autocomplete from 'v-autocomplete' */
import { VueAutosuggest } from "vue-autosuggest";
import circleAnim from "./circleAnimation";
import NomenImporter from "../units/NomenImportEngine";
import qs from "qs";
import $ from "jquery";
import "jstree";
import "jstree/dist/themes/default/style.min.css";
import TXLSX from "../units/TXLSClass";
import EdIzmValidator from "../components/edizmValidator";
import EdIzmExt from "../units/edizmExt";

export default {
  name: "NomenImportTuner",
  components: {
    DkcGrid,
    DkcToolButton,
    /* Autocomplete, */ VueAutosuggest,
    circleAnim,
    dkcModelDlg,
    EdIzmValidator,
  },
  mixins: [grantctl],
  data() {
    return {
      nomenImp: false,
      file: false,
      xls: new TXLSX(),
      sheetName: "",
      dataRange: "-",
      firstRow: 0,
      lastRow: 0,
      rangeFR: 0,
      rangeLR: 0,
      fileLoading: false,

      // данные для выбора производителя и выбранный производитель
      firmList: [], // справочник фирм - производителей
      firm: "", // наименование выбранной фирмы
      idFirm: 0, // ID выбранной фирмы

      // Справочник единиц измерения. Для контроля исходных данных
      edizmList: [],

      // данные для таблицы с данными из Excel
      xlsdata: null,
      xlsdataHeight: 130,
      resex: null,
      resexHeight: 165,

      // данные для модального окна
      isModalDlgVisible: false,
      headerText: "Заголовок модального окна",
      bodyText: "Сообщение в модальном окне",
      buttons: ["ok"],
      actions: {},

      // данные для кольца прогресса
      leafcout: 0,
      leafindex: 0,
      groupcount: 1,
      progresstitle: "",
    };
  },
  computed: {
    SheetNames: function () {
      return this.xls.sheetNames;
    },
    isNewFirm: function () {
      if (!this.firm) {
        return false;
      }

      const frm = this.firm.toLowerCase();
      const firmExists = this.firmList.some((firm) => {
        return firm.name.toLowerCase() == frm;
      });

      return this.idFirm == 0 || !firmExists;
    },
    tunecol: function () {
      return this.$refs.tunecol;
    },
    checkTunes: function () {
      return !this.nomenImp.cols.isEmptyNomen && (this.firm || this.idFirm);
    },
    suggestFirms: function () {
      const firms = [];
      this.firmList.forEach((firm) => {
        if (firm.name.toLowerCase().indexOf(this.firm.toLowerCase()) > -1) {
          firms.push(firm.name);
        }
      });

      return [{ data: firms.sort() }];
    },
  },
  watch: {
    file: function () {
      if (!this.file) {
        return;
      }

      this.fileLoading = true;
      const self = this;
      this.xls.onload = () => {
        self.fileLoading = false;
      };
      this.xls.onactivateSheet = this.changeActiveSheet;
      this.xls.load(this.file);
    },
    sheetName: function () {
      if (!this.sheetName || this.sheetName == this.xls.activeSheetName) {
        return;
      }

      this.xls.activateSheet(this.sheetName);
    },
    firstRow: function () {
      this.nomenImp.rows.firstRow = this.firstRow;
    },
    lastRow: function () {
      this.nomenImp.rows.lastRow = this.lastRow;
    },
    firm: function () {
      // при изменении наименования фирмы обновляем ID фирмы
      if (!this.firm) {
        this.idFirm = 0;
        return;
      }

      const self = this;
      let idFirm = 0;
      this.firmList.some((firm) => {
        if (firm.name.toLowerCase() == self.firm.toLowerCase()) {
          idFirm = firm.idFirm;
          return true;
        }

        return false;
      });

      self.idFirm = idFirm;
    },
  },
  created() {
    this.nomenImp = new NomenImporter();
    this.nomenImp.xls = this.xls;
    this.nomenImp.onchangeFirstRow = this.onchangeFirstRow;
    this.nomenImp.onchangeGroup = this.onchangeGroup;
    this.nomenImp.onchangeGrid = this.onchangeGrid;
  },
  mounted() {
    const self = this;
    const sd = {
      page: 1,
    };
    const options = {
      method: "POST",
      url: this.baseUrl + "/nomen/norma",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        "X-CSRF-Token": tkn_csfr,
      },
      data: qs.stringify(sd),
    };
    axios(options)
      .then((response) => {
        self.firmList = response.data.firms;
        const ref = response.data.edizm;
        ref.forEach((eiref) => {
          self.edizmList.push(new EdIzmExt(eiref));
        });
      })
      .catch((error) =>
        self.showErrorMessage(
          "Ошибка загрузки списка фирм-производителей и справочника единиц измерения",
          error
        )
      );
  },
  methods: {
    showModalDlg(afterShow = false) {
      this.isModalDlgVisible = true;
      if (afterShow) {
        afterShow();
      }
    },
    closeModalDlg() {
      this.isModalDlgVisible = false;
    },
    showErrorMessage: function (context, error) {
      this.headerText = "Ошибка выполнения программы";
      this.bodyText = context;
      this.buttons = ["ok"];
      this.actions = { ok: this.closeModalDlg };
      this.showModalDlg();

      if (error != undefined && error) {
        const el = document.getElementById("debug");
        if (
          el != undefined &&
          Object.keys(error).includes("response") &&
          error.response.data != undefined
        ) {
          el.innerHTML = error.response.data;
        }
      }
    },
    setRowIndexes() {
      this.dataRange = this.xls.dataRange;
      this.rangeFR = this.xls.firstRow;
      this.rangeLR = this.xls.lastRow;
      this.firstRow = this.xls.firstRow + 1;
      this.lastRow = this.xls.lastRow;
    },
    stylingFileSelector() {
      const el = document.getElementById("drop-selector");
      el.style.paddingTop = "8px";
      el.style.paddingBottom = "8px";
    },
    createGrids() {
      this.createXLSData();
      this.createTuneCol();
      this.createResultExample()
    },
    locateDataHeader() {
      this.nomenImp.locate();
      this.firstRow = this.nomenImp.rows.firstRow;

      /* const row = this.tunecol.tabulator.getRowFromPosition(0);
      const rd = row.getData(); */

      const rows = this.tunecol.tabulator.getRows()
      const row = rows.length ? rows[0] : false 
      const rd = row ? row.getData() : {}

      if (this.nomenImp.cols.firstGroup !== "") {
        rd.firstGroup = this.xls.encode_col(this.nomenImp.cols.firstGroup);
      }
      if (this.nomenImp.cols.lastGroup !== "") {
        rd.lastGroup = this.xls.encode_col(this.nomenImp.cols.lastGroup);
      }
      if (this.nomenImp.cols.code !== "") {
        rd.code = this.xls.encode_col(this.nomenImp.cols.code);
      }
      if (this.nomenImp.cols.mark !== "") {
        rd.mark = this.xls.encode_col(this.nomenImp.cols.mark);
      }
      if (this.nomenImp.cols.name !== "") {
        rd.name = this.xls.encode_col(this.nomenImp.cols.name);
      }
      if (this.nomenImp.cols.price !== "") {
        rd.price = this.xls.encode_col(this.nomenImp.cols.price);
      }
      if (this.nomenImp.cols.rest !== "") {
        rd.rest = this.xls.encode_col(this.nomenImp.cols.rest);
      }

      if (row) {
        row.update(rd);
      } else {
        this.tunecol.tabulator.addData([rd])
      }
    },
    changeActiveSheet() {
      if (!this.xls.active) {
        return;
      }

      if (this.xls.activeSheetName != this.sheetName) {
        this.sheetName = this.xls.activeSheetName;
        // return
      }
      this.setRowIndexes();
      this.stylingFileSelector();
      this.createGrids();
      setTimeout(this.locateDataHeader, 400);
    },

    fileSelecterOver: function (e) {
      e.stopPropagation();
      e.preventDefault();

      const el = document.getElementById("drop-selector");
      el.classList.add("hover");
    },
    fileSelecterLeave: function (e) {
      e.stopPropagation();
      e.preventDefault();

      const el = document.getElementById("drop-selector");
      el.classList.remove("hover");
    },
    fileSelecterDrop: function (e) {
      this.fileSelecterLeave(e);
      this.findExcelInFileList(e.dataTransfer.files);
    },
    checkSelectedFiles: function (e) {
      this.findExcelInFileList(event.currentTarget.files);
    },
    findExcelInFileList: function (files) {
      for (let i = 0; i < files.length; i++) {
        if (this.fileIsExcel(files[i])) {
          this.file = files[i];
          return;
        }
      }
    },
    fileIsExcel: function (file) {
      const fileType = file.type.toLowerCase();
      const ind = [
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ].indexOf(fileType);
      return ind >= 0;
    },
    loadFile: function () {
      this.workbook = XLSX.read(this.reader.result, { type: "binary" });
      this.sheetName = this.workbook.SheetNames[0];
      this.fileLoading = false;
    },
    createXLSData: function () {
      // const range = this.xls.decode_range(this.sheet["!ref"])
      const columns = [
        { title: "", field: "rownum", visible: true, hozAlign: "center" },
      ];
      let fld = "";
      for (let i = this.xls.firstCol; i <= this.xls.lastCol; i++) {
        fld = this.xls.encode_col(i);
        columns.push({ title: fld, field: fld, visible: true });
      }
      const options = {
        columns,
        layout: "fitData",
        height: this.xlsdataHeight,
      };
      this.xlsdata = new Tabulator(this.$refs.xlsdata, options);
      this.xlsdata.on("tableBuilt", this.loadXLSData);

      const el = document.getElementById("tblxlsdata");
      if (el) {
        el.style.transition = "all 0.5s ease-in-out";
      }
    },
    loadXLSData: function () {
      const xls = this.xls;
      const lastR = Math.min(xls.firstRow + 22, xls.lastRow);
      const data = [];
      let col = "";
      let row = false;
      const adr = "";
      const cell = false;

      for (let r = xls.firstRow + 1; r < lastR; r++) {
        row = { rownum: r };
        for (let i = xls.firstCol; i <= xls.lastCol; i++) {
          col = xls.encode_col(i);
          row[col] = xls.value(col, r);
        }
        data.push(row);
      }

      const tbl = this.xlsdata;
      this.xlsdata.clearData();
      this.xlsdata.setData(data).then(function () {
        tbl.redraw(true);
      });
    },
    getNomenGridColumns: function () {
      return {
        columns: [
          {
            title: "Группы номенклатуры",
            columns: [
              {
                title: "Расположение групп номенклатуры",
                field: "groupRange",
                visible: true,
                hozAlign: "center",
              },
              {
                title: "Первая колонка",
                field: "firstGroup",
                visible: true,
                hozAlign: "center",
              },
              {
                title: "Последняя колонка",
                field: "lastGroup",
                visible: true,
                hozAlign: "center",
              },
            ],
          },
          {
            title: "Номенклатура",
            columns: [
              {
                title: "Код",
                field: "code",
                visible: true,
                hozAlign: "center",
              },
              {
                title: "Тип",
                field: "mark",
                visible: true,
                hozAlign: "center",
              },
              {
                title: "Наименование",
                field: "name",
                visible: true,
                hozAlign: "center",
              },
              {
                title: "Ед.Изм.",
                field: "edizm",
                visible: true,
                hozAlign: "center",
              },
            ],
          },
          {
            title: "Доп. информация",
            columns: [
              {
                title: "Стоимость за ед. измерения",
                field: "price",
                visible: true,
                hozAlign: "center",
              },
              {
                title: "Остаток",
                field: "rest",
                visible: true,
                hozAlign: "center",
              },
            ],
          },
        ],
        layout: "fitColumns",
      };
    },
    columnValidator: function (cell, value, parameters) {
      if (!value) {
        return true;
      }
      const colIndex = this.xls.decode_col(value);
      return colIndex >= this.xls.firstCol && colIndex <= this.xls.lastCol;
    },
    createTuneCol: function () {
      const self = this;
      const options = this.getNomenGridColumns()
      options.height = '90px'
      this.tunecol.createGrid(options)
      this.tunecol.tabulator.on("tableBuilt", function () {
        const coldefs = self.tunecol.tabulator.getColumnDefinitions();

        function addEditor(collist, component) {
          collist.forEach((coldef) => {
            if ("columns" in coldef) {
              addEditor(coldef.columns, component);
            } else {
              if (coldef.field == "groupRange") {
                coldef.editor = "select";
                coldef.editorParams = {
                  defaultValue: "во всех колонках",
                  values: ["во всех колонках", "только в указанных колонках"],
                };
                coldef.cellEdited = function (cell) {
                  const coldef = cell.getColumn().getDefinition();
                  self.nomenImp.cols.groupRange =
                    coldef.editorParams.values.indexOf(cell.getValue()) == 0;
                };
              } else {
                coldef.editor = "input";
                coldef.validator = [
                  {
                    type: self.columnValidator,
                  },
                ];
                coldef.mutator = component.tunecolMutator;
                coldef.cellEdited = function (cell) {
                  self.nomenImp.cols[cell.getField()] = cell.getValue();
                };
              }
            }
          });
        }

        addEditor(coldefs, this);

        self.tunecol.tabulator.setColumns(coldefs);
        self.tunecol.tabulator.addRow({ groupRange: "во всех колонках" });
      });
    },
    loadResultTree: function () {
      const groups = this.nomenImp.groups;
      $("#tree").jstree(true).settings.core.data = groups;
      $("#tree").jstree(true).refresh();
    },
    loadResultGrid: function () {
      if (!this.resex) {
        return;
      }
      const self = this;
      const nomens = this.nomenImp.flatlistNomen();
      if (nomens.length) {
        this.resex.setData(nomens);
      }
    },
    loadResultExample: function (cellInfo) {
      this.nomenImp.load(20);
      if (!this.nomenImp.cols.isEmptyGroup) {
        this.loadResultTree();
      }
      if (!this.nomenImp.cols.isEmptyGrid) {
        this.loadResultGrid();
      }
    },
    clearResultTree: function () {
      $("#tree").jstree({
        core: {
          data: [],
        },
      });
    },
    clearResultGrid: function () {},
    clearResultExample: function () {
      this.clearResultTree();
      this.clearResultGrid();
    },
    createResultTree: function () {
      $("#tree").jstree({
        core: {
          data: [],
          animation: 350,
          check_callback: true,
          expand_selected_onload: true,
          multiple: false,
          themes: { variant: "large" },
        },
      });
    },
    createResultGrid: function () {
      if (this.resex != null) {
        return;
      }

      const options = this.getNomenGridColumns();
      options.columns.shift();
      options.height = this.resexHeight;
      this.resex = new Tabulator(this.$refs.resex, options);
      this.resex.on('tableBuilt', () => {
        for (let i = 0; i < 20; i++) {
          this.resex.addRow();
        }
      })

      const el = document.getElementById("tblresex");
      if (el) {
        el.style.transition = "all 0.5s ease-in-out";
      }
    },
    createResultExample: function () {
      this.createResultTree();
      this.createResultGrid();
    },
    onchangeFirstRow: function (oldRow, newRow) {
      if (newRow < 1) {
        this.clearResultExample();
      } else {
        this.loadResultExample();
      }
    },
    onchangeGroup: function (colName, oldCol, newCol) {
      if (this.nomenImp.cols.isEmptyGroup || this.firstRow < 1) {
        this.clearResultExample();
      } else {
        this.loadResultExample();
      }
    },
    onchangeGrid: function (colName, oldCol, newCol) {
      this.loadResultExample();
    },
    getGroupBranch: function (group) {
      group.children = [];
      if (group.level < 0) {
        return group;
      }

      let parent = group;
      while (parent.level > -1) {
        parent = parent.parent;
        parent.children = [];
      }

      return group;
    },
    sendFoliage: function () {
      if (this.nomenImp._foliage.length == 0) {
        return;
      }

      const self = this;
      const group = this.getGroupBranch(this.nomenImp._foliage.pop());

      this.progresstitle =
        group.getFullPath() + "  [" + group.nomens.length + " поз.]";
      this.leafindex = this.nomenImp._foliage.length;

      const formData = new FormData();
      formData.append("leaf", JSON.stringify(group));
      formData.append("firm", this.firm);
      formData.append("firmID", this.firmID);
      formData.append("rootID", this.rootID);

      const options = {
        method: "POST",
        url: this.baseUrl + "/nomen/import",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "application/json; charset=utf-8",
          "X-CSRF-Token": tkn_csfr,
        },
        data: formData,
      };

      axios(options)
        .then((response) => {
          this.rootID = response.data.rootID;
          const nomencount = response.data.nomencount;
          const duration = response.data.duration;
          console.log(
            `Импорт ${nomencount} позиций номенклауры.\tДлительность: ${duration}`
          );

          if (self.nomenImp._foliage.length > 0) {
            self.sendFoliage();
          } else {
            self.fileLoading = false;
            self.headerText = "Импорт номенклатуры";
            self.bodyText =
              'Импорт номенклатуры выполнен успешно. Нажмите "Ок" для перехода в справочник номенклатуры';
            self.buttons = ["ok"];
            self.actions = {
              ok: () => {
                window.location = this.baseUrl + "/nomen/index";
              },
            };
            self.showModalDlg();
          }
        })
        .catch((error) => {
          self.fileLoading = false;
          self.showErrorMessage("Ошибка записи данных в справочник", error);
        });
    },
    /**
     * Отсылает дерево групп номенклатуры для импорта их в базу данных
     * Назад должны приехать эти же гуппы, но с заполненными ID
     */
    sendNomenGroups: function () {
      const self = this;
      this.progresstitle = "Импорт групп номенклатуры";
      this.leafindex = 1;
      const group = this.nomenImp._branch[0].cloneGroup(false);

      const formData = new FormData();
      formData.append("group", JSON.stringify(group));
      formData.append("firm", this.firm);
      formData.append("firmID", this.firmID);

      const options = {
        method: "POST",
        url: this.baseUrl + "/nomen-group/import",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "application/json; charset=utf-8",
          "X-CSRF-Token": tkn_csfr,
        },
        data: formData,
      };

      axios(options)
        .then((responce) => {
          //                        console.log('Время импорта групп номенклатуры: ', responce.data.duration )
          // this.nomenImp.splitFoliage()
          self.linkNomenGroups(responce.data.group);
          self.flattenNomenList();
          //self.groupcount = Math.ceil(self.nomenImp._foliage.length / (this.leafcout - 1)) ;
          // попробуем посылать каждую группу отдельно
          self.leafcout = self.nomenImp._foliage.length + 1;
          self.groupcount = Math.ceil(
            self.nomenImp._foliage.length / self.leafcout
          );
          self.sendNomens(responce.data.idFirm);
        })
        .catch((error) =>
          self.showErrorMessage("Ошибка импорта групп номенклатуры", error)
        );
    },
    /**
     * Всем группам содержащим номенклатуру назначаем ID,
     * полученный при импорте групп номенклатуры
     * @param group - корневая группа номенклатуры. Для ДКС фиктивная
     */
    linkNomenGroups: function (group) {
      if (group.name == "Продукция, выведенная из ассортимента") {
        console.log(group);
      }
      const row = group.row;
      const grp = this.nomenImp._branch[0].findFoliageByRow(
        group.row,
        group.name
      );
      if (grp) {
        grp.idNomenGroup = group.idNomenGroup;
      }
      for (let i = 0; i < group.children.length; i++) {
        this.linkNomenGroups(group.children[i]);
      }
    },
    /**
     * Для групп с номенклатурой убираем сведения о родиетльской и дочерних группах
     * что бы при передаче на сервер не было циклов
     */
    flattenNomenList: function () {
      this.nomenImp._foliage.forEach((group) => {
        group.parent = false;
        group.children = [];
      });
    },
    /**
     * Отправляет массив с группами номенклауры,
     * которые содержат саму номенклауру для записи из в БД
     * @param idFirm - ID фирмы производителя
     */
    sendNomens: function (idFirm) {
      const self = this;
      this.progresstitle = "Импорт номенклатуры";
      this.leafindex++;

      const groups = [];
      for (
        let i = 0;
        i < this.groupcount && this.nomenImp._foliage.length > 0;
        i++
      ) {
        groups.push(this.nomenImp._foliage.pop());
      }

      if (groups.length == 0) {
        self.fileLoading = false;
        window.location = this.baseUrl + "/nomen";
      }

      const formData = new FormData();
      formData.append("groups", JSON.stringify(groups));
      formData.append("firm", this.firm);
      formData.append("idFirm", idFirm);

      const options = {
        method: "POST",
        url: this.baseUrl + "/nomen/import",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "application/json; charset=utf-8",
          "X-CSRF-Token": tkn_csfr,
        },
        data: formData,
      };

      axios(options)
        .then((responce) => {
          const nmcnt = responce.data.nomencount;
          const duration = responce.data.duration;
          console.log(
            "Импорт " +
              nmcnt +
              " позиций номенклатуры. Длительность: " +
              duration +
              " сек."
          );

          if (self.nomenImp._foliage.length > 0) {
            self.sendNomens(idFirm);
          } else {
            self.fileLoading = false;
            window.location = this.baseUrl + "/nomen";
          }
        })
        .catch((error) => {
          self.fileLoading = false;
          self.showErrorMessage("Ошибка импорта номенклатуры", error);
        });
    },
    doImport: function () {
      this.fileLoading = true;

      // Грузим результаты настроек
      if (!this.nomenImp.load()) {
        this.fileLoading = false;
        this.showErrorMessage("Ошибка чтения Excel-файла");
        return;
      }

      this.nomenImp.close();
      this.sendNomenGroups();
      return;

      this.nomenImp.splitFoliage(); // TODO: удалить метод nomenImp.splitFoliage()
      this.progresstitle = "";
      this.leafindex = 0;
      this.leafcout = this.nomenImp._foliage.length;
      this.sendFoliage(); // TODO: удалить метод sendFoliage()
    },
    execImport: function () {
      this.leafcout = 20;
      this.fileLoading = true;

      if (this.nomenImp.cols.edizm) {
        if (
          !this.$refs.eiv.execute(
            this.edizmList,
            this.nomenImp.edizmList,
            this.doImport
          )
        ) {
          this.fileLoading = false;
          return;
        }
      }

      this.doImport();
    },
    setAutosuggestListStyle: function (width = 0, color = "#ccc") {
      const els = document.getElementsByClassName("autosuggest-list-container");
      if (els.length > 0) {
        els[0].style.borderWidth = width + "px";
        els[0].style.borderColor = color;
      }
    },
    autosuggestFocus: function () {
      this.setAutosuggestListStyle(1, "#66afe9");
    },
    autosuggestBlur: function () {
      this.setAutosuggestListStyle();
    },
    autosuggestSelected: function (firm) {
      this.firm = firm.item;
    },
  },
};
</script>

<style scoped>
.component {
  max-width: 99vw;
}

.eit-container {
  padding: 0 8px;
  /*background-color: #c4e3f3; */
}

.eit-header {
  display: flex;
}

.eit-controls {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 24px;
}

.eit-ctlr-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 470px;
}

.eit-file-selector {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 250px;
  padding: 8px 16px;
  border: 3px ridge gray;
  border-radius: 8px;
  box-shadow: 5px 5px 5px darkgray;
  /*background-color: papayawhip;*/
}

.eit-file-title {
  font-size: 20px;
  font-weight: bolder;
  cursor: pointer;
}

#file-selecter {
  font-size: 16px;
  font-weight: normal;
  color: blue;
  cursor: pointer;
}

#file-selecter.hover {
  background-color: #afd9ee;
  font-weight: lighter;
}

.eit-drop-selector {
  padding: 18px 8px;
  background-color: #afd9ee;
  border: 1px solid gray;
  border-radius: 6px;
}

.eit-drop-selector.hover {
  background-color: #5fa2db;
  font-weight: bolder;
}

.ctrl-group {
  margin-right: 16px;
  width: 215px;
}

.result-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.rc-tree-container,
.rc-grid-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.rc-tree-container {
  width: 30%;
}

.rc-grid-container {
  width: 69%;
}

#tree {
  height: 100%;
  border: 1px solid silver;
}

.it_band {
  margin-top: 16px;
  padding-top: 4px;
}

summary,
.band-label {
  margin-bottom: 0;
  padding: 8px;
  width: 100%;
  font-weight: bold;
  color: #fcf8e3;
  background-color: #333333;
}
</style>

<style>
.v-autocomplete-input {
  width: 450px;
}

.autosuggest-container input {
  width: 450px;
  padding: 0.5rem;
}

.autosuggest-container ul {
  width: 100%;
  color: rgba(30, 39, 46, 1);
  list-style: none;
  margin: 0;
  padding: 0.5rem 0 0.5rem 0;
}

.autosuggest-container li {
  margin: 0 0 0 0;
  border-radius: 5px;
  padding: 0.75rem 0 0.75rem 0.75rem;
  display: flex;
  align-items: center;
}

.autosuggest-container li:hover {
  cursor: pointer;
}

.autosuggest-container {
  display: flex;
  justify-content: center;
  width: 450px;
}

.autosuggest-list-container {
  position: absolute;
  width: 450px;
  z-index: 100;
  background-color: #eeeeee;
  border: 0px solid #ccc;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

#autosuggest {
  width: 100%;
  display: block;
}

.autosuggest__results-item--highlighted {
  background-color: rgba(51, 217, 178, 0.2);
}
</style>
