<template>
  <div class="site-index">

    <div class="jumbotron">
      <p class="lead">Выберите справочник:</p>
    </div>

    <div class="body-content">
      <div class="task">
        <a :href="baseUrl + '/firm/index'">
          <div class="task-img">
            <img :src="baseUrl+ '/images/ctl_firm.png'">
          </div>
          <div class="task-desc">
            Фирмы производители
          </div>
        </a>
      </div>

      <div class="task">
        <a :href="baseUrl + '/nomen-group/index'">
          <div class="task-img">
            <img :src="baseUrl+ '/images/ctl_nomen_group.png'">
          </div>
          <div class="task-desc">
            Группы номенклатуры
          </div>
        </a>
      </div>

      <div class="task">
        <a :href="baseUrl + '/nomen/index'">
          <div class="task-img">
            <img :src="baseUrl+ '/images/ctl_nomen.jpg'">
          </div>
          <div class="task-desc">
            Номенклатура
          </div>
        </a>
      </div>

      <div class="task" v-if="userIsAdmin">
        <a :href="baseUrl + '/nomen/twin'">
          <div class="task-img">
            <img :src="baseUrl+ '/images/twin.jpg'">
          </div>
          <div class="task-desc">
            Дубли номенклатуры
          </div>
        </a>
      </div>

      <div class="task" v-if="userIsAdmin">
        <a :href="baseUrl + '/user/index'">
          <div class="task-img">
            <img :src="baseUrl+ '/images/ctl_user.png'">
          </div>
          <div class="task-desc">
            Пользователи программы
          </div>
        </a>
      </div>

    </div>
  </div>

</template>

<script>
    export default {
        name: "NSI",
        computed: {
            userIsAdmin: function () {
                return window.dkcapp.$store.state.user.idRole == 1
            },
        },
    }
</script>

<style scoped>

</style>
