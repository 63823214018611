var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "bpk-toolbar-panel",
        [
          _c("dkc-tool-button", {
            attrs: {
              title: "Новый пользователь",
              hint: "Регистрация нового пользователя",
              iconclass: "dbtn-create",
              action: "/user/create",
            },
          }),
          _vm._v(" "),
          _c("dkc-tool-button", {
            attrs: {
              title: "Профиль пользователя",
              hint: "Изменить данные пользователя",
              iconclass: "dbtn-edit",
              action: _vm.editUser,
            },
          }),
          _vm._v(" "),
          _c("dkc-tool-button", {
            attrs: {
              title: "Удалить пользователя",
              hint: "Удалить пользователя",
              iconclass: "dbtn-delete",
              action: _vm.queryDeleteUser,
            },
          }),
          _vm._v(" "),
          _c("dkc-tool-button", {
            attrs: {
              title: "Обновить данные",
              hint: "Обновить данные в списке пользователей",
              iconclass: "dbtn_refresh",
              begingroup: true,
              action: _vm.refreshGrid,
              control: "tabulator-tableHolder",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { ref: "table" }),
      _vm._v(" "),
      _c(
        "dkc-model-dlg",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isModalDlgVisible,
              expression: "isModalDlgVisible",
            },
          ],
          attrs: { buttons: _vm.buttons, actions: _vm.actions },
          on: { close: _vm.closeModalDlg },
        },
        [
          _c("span", { attrs: { slot: "body" }, slot: "body" }, [
            _vm._v(_vm._s(_vm.bodyText)),
          ]),
          _vm._v(" "),
          _c("span", { attrs: { slot: "header" }, slot: "header" }, [
            _vm._v(_vm._s(_vm.headerText)),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }