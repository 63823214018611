function itemscodeByRow(row) {
  const group = row.getGroup()
  if (!group)
    return ''
  const idl = []
  group.getRows().forEach(r => {
    idl.push(r.getData().d_idnomen)
  })
  if (!idl.length)
    return ''
  return '%' + idl.sort().join('%') + '%'
}

function analogindexByCell(cell) {
  if (!cell)
    return -1

  const data = cell.getData()
  if (!data.analogs || !data.analogs.length)
    return -1
  const itmcode = itemscodeByRow(cell.getRow())
  return data.analogs.findIndex(a => {
    return a.itemscode == itmcode
  })
}

const emptyNomen = (row, side) => !(row[side + '_idnomen'] > 0 ||
                                    row[side + '_code'] ||
                                    row[side + '_mark'] ||
                                    row[side + '_name'])

const checkCell = (cell) => {
  if (!cell)
    return false

  if (!cell.getRow())
    return false

  return true
}

const CellFormatters = [
  { // форматируем пустую номенклатуру заказчика
    handle: (cell) => {
      if (!checkCell(cell))
        return false

      const data = cell.getData()
      return data.c_idnomen == 0 && (data.c_code || data.c_mark || data.c_name)
    },
    fields: ['c_code', 'c_mark', 'c_name', 'c_idedizm', 'c_quant', 'c_price', 'c_summa'],
    class: 'cs_newnomen',
    caption: 'Будет создана новая номенклатура'
  },
  { // стиль для номенклатуры заказчика с исправленным наименованием
    handle: (cell) => {
      if (!checkCell(cell))
        return false

      const data = cell.getData()
      if (['c', 'r'].some(side => emptyNomen(data, side)))
        return false

      if (data.c_idnomen < 1)
        return false

      const field = cell.getField().substring(1)
      return data['c' + field] != data['r' + field]
    },
    fields: ['c_name', 'c_code', 'c_mark'],
    class: 'cs_modifiednamen',
    caption: 'Исправлено наименование, код или тип номенклатуры'
  },
  { // форматируем номенклатуру ДКС выбранную из списка аналогов программы
    handle: (cell) => {
      const index = analogindexByCell(cell)
      return index > 0
    },
    fields: ['d_code', 'd_mark', 'd_name', 'd_idedizm', 'd_quant', 'd_price', 'd_summa'],
    class: 'cs_modifyanalog',
    caption: 'Выбран реже используемый аналог'
  },
  { // форматируем номенклатуру ДКС выбранную НЕ из списка аналогов программы
    handle: (cell) => {
      const index = analogindexByCell(cell)
      return index < 0
    },
    fields: ['d_code', 'd_mark', 'd_name', 'd_idedizm', 'd_quant', 'd_price', 'd_summa'],
    class: 'cs_manual_select_analog',
    caption: 'Ручной выбор аналога'
  },
  { // форматируем не учитываемую номенклатуру заказчика
    handle: (cell) => {
      if (!checkCell(cell))
        return false

      const data = cell.getData()
      return data.r_idnomen == -1
    },
    fields: ['c_name', 'c_code', 'c_mark'],
    class: 'cs_unlinked_nomen',
    caption: 'Не учитываем номенклатуру'
  },
]

export default CellFormatters
