<template>
    <li @click="handleClick(item, $event)" :class="itemClass(item)">
        <div class="cm-label" v-if="!beginGroup">
            <img :src="item.icon" :width="item.icon_size ? item.icon_size : 16" v-if="item.icon">
            <span class="cm-item-label">{{item.label}}</span>
            <span class="children-indicator" v-if="item.children">▶</span>
        </div>
        <div class="begin-menu-group"  v-if="beginGroup">
        </div>
        <ul class="context-menu" v-if="item.children">
            <context-menu-item
                    v-for="(childItem, index) in item.children"
                    :item="childItem"
                    :key="index">
            </context-menu-item>
        </ul>
    </li>
</template>

<script>
export default {
  name: 'context-menu-item',
  props: {
    item: {
      type: Object,
    },
  },
  computed: {
      beginGroup: function() {
          return this.item.label == '-'
      },
  },
  methods: {
    handleClick(item, event) {
      if (typeof item.handler !== 'undefined') {
        if (!this.isItemDisabled(item)) {
          item.handler(item.data, event);
        } else {
          event.stopPropagation();
        }
      }
    },

    itemClass(item) {
      return {
        'cm-item-disabled': this.isItemDisabled(item),
      };
    },

    isItemDisabled(item) {
      if (typeof item.disabled !== 'undefined') {
        return item.disabled;
      }

      return false;
    },
  },
};
</script>
