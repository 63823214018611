<template>

</template>

<script>
export default {
   name: "HiddenApp",
   mounted() {
      this.$store.commit('app_hidden', true)
   },
   beforeDestroy () {
      this.$store.commit('app_hidden', false)
   },
}
</script>

<style scoped>

</style>
