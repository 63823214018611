var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "eiv-container" }, [
    _c("div", [_vm._v(_vm._s(_vm.edizmname))]),
    _vm._v(" "),
    _c("div", [
      _c("label", { attrs: { for: "edizmList" } }, [_vm._v("заменить на:")]),
      _vm._v(" "),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.replacement,
              expression: "replacement",
            },
          ],
          attrs: { name: "edizmList", id: "edizmList" },
          on: {
            change: function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.replacement = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            },
          },
        },
        [
          _vm._l(_vm.edizmList, function (edizm) {
            return _c("option", { domProps: { value: edizm.idedizm } }, [
              _vm._v(_vm._s(edizm.name)),
            ])
          }),
          _vm._v(" "),
          _c("option", { staticClass: "new-item", attrs: { value: "-1" } }, [
            _vm._v("[ создать новую ]"),
          ]),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }