var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "component" },
    [
      _vm.grant
        ? _c("div", { staticClass: "eit-container" }, [
            _c("div", { staticClass: "eit-header" }, [
              _c("div", { staticClass: "eit-file-selector" }, [
                _c(
                  "div",
                  {
                    staticClass: "eit-file-title",
                    attrs: {
                      onclick:
                        "document.getElementById('file-selecter').click()",
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.file,
                            expression: "!file",
                          },
                        ],
                      },
                      [_vm._v("Импорт номенклатуры из Excel-файла")]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.file,
                            expression: "file",
                          },
                        ],
                      },
                      [_vm._v(_vm._s(_vm.file.name))]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("input", {
                  staticStyle: { display: "none" },
                  attrs: { id: "src", type: "file", name: "src" },
                  on: { change: _vm.checkSelectedFiles },
                }),
                _vm._v(" "),
                _c("label", { attrs: { id: "file-selecter", for: "src" } }, [
                  _vm._v("\n          выбери "),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.file,
                          expression: "file",
                        },
                      ],
                    },
                    [_vm._v("другой")]
                  ),
                  _vm._v(" файл\n        "),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "eit-drop-selector",
                    attrs: {
                      id: "drop-selector",
                      onclick:
                        "document.getElementById('file-selecter').click()",
                    },
                    on: {
                      dragover: _vm.fileSelecterOver,
                      dragleave: _vm.fileSelecterLeave,
                      drop: _vm.fileSelecterDrop,
                    },
                  },
                  [
                    _vm._v("\n          или перетащи сюда файл\n          "),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.xls.active,
                            expression: "!xls.active",
                          },
                        ],
                      },
                      [_vm._v("из проводника")]
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _vm.fileLoading
                ? _c(
                    "div",
                    [
                      _c("circle-anim", {
                        attrs: {
                          maxvalue: _vm.leafcout,
                          value: _vm.leafindex,
                          title: _vm.progresstitle,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.xls.active,
                      expression: "xls.active",
                    },
                  ],
                  staticClass: "eit-controls",
                  staticStyle: {
                    "flex-direction": "column",
                    "justify-content": "space-between",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "eit-ctlr-group",
                      staticStyle: {
                        width: "700px",
                        "align-items": "flex-end",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "ctrl-group",
                          class: { "has-warning": _vm.isNewFirm },
                          staticStyle: { width: "450px" },
                        },
                        [
                          _c("label", { staticClass: "control-label" }, [
                            _vm._v("\n              Фирма - производитель"),
                            _vm.isNewFirm
                              ? _c("span", [_vm._v("(новый)")])
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "autosuggest-container" },
                            [
                              _c("vue-autosuggest", {
                                attrs: {
                                  suggestions: _vm.suggestFirms,
                                  "input-props": {
                                    id: "autosuggest__input",
                                    placeholder:
                                      "Введи фирму - производитель номенклатуры",
                                    class: "form-control",
                                  },
                                  "component-attr-class-autosuggest-results-container":
                                    "autosuggest-list-container",
                                },
                                on: {
                                  focus: _vm.autosuggestFocus,
                                  blur: _vm.autosuggestBlur,
                                  selected: _vm.autosuggestSelected,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ suggestion }) {
                                        return _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              "align-items": "center",
                                            },
                                          },
                                          [
                                            _c("div", [
                                              _vm._v(_vm._s(suggestion.item)),
                                            ]),
                                          ]
                                        )
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3777596537
                                ),
                                model: {
                                  value: _vm.firm,
                                  callback: function ($$v) {
                                    _vm.firm = $$v
                                  },
                                  expression: "firm",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "ctrl-group",
                          class: { "has-warning": _vm.isNewFirm },
                          staticStyle: { width: "450px" },
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary btn-block",
                              attrs: {
                                type: "button",
                                "aria-disabled": "true",
                                disabled: !_vm.checkTunes,
                              },
                              on: { click: _vm.execImport },
                            },
                            [_vm._v("\n              ИМПОРТ\n            ")]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "eit-controls",
                      staticStyle: { "margin-left": "0" },
                    },
                    [
                      _c("div", { staticClass: "eit-ctlr-group" }, [
                        _c("div", { staticClass: "ctrl-group sheetname" }, [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "sheetname" },
                            },
                            [_vm._v("Закладки в файле:")]
                          ),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.sheetName,
                                  expression: "sheetName",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { id: "sheetname", name: "sheetname" },
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.sheetName = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                              },
                            },
                            _vm._l(_vm.xls.sheetNames, function (sn) {
                              return _c("option", {
                                attrs: { label: sn },
                                domProps: { value: sn },
                              })
                            }),
                            0
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "ctrl-group" }, [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "dataRange" },
                            },
                            [_vm._v("Диапазон с данными:")]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.xls.dataRange,
                                expression: "xls.dataRange",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "dataRange",
                              type: "text",
                              name: "dataRange",
                              readonly: "",
                            },
                            domProps: { value: _vm.xls.dataRange },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.xls,
                                  "dataRange",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "eit-ctlr-group" }, [
                        _c("div", { staticClass: "ctrl-group" }, [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "firstRow" },
                            },
                            [_vm._v("Первая строка с данными:")]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.firstRow,
                                expression: "firstRow",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "firstRow",
                              type: "number",
                              name: "firstRow",
                              min: _vm.rangeFR,
                              max: _vm.lastRow,
                              step: "1",
                            },
                            domProps: { value: _vm.firstRow },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.firstRow = $event.target.value
                              },
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "ctrl-group" }, [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "lastRow" },
                            },
                            [_vm._v("Последняя строка с данными:")]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.lastRow,
                                expression: "lastRow",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "lastRow",
                              type: "number",
                              name: "lastRow",
                              min: _vm.firstRow,
                              max: _vm.rangeLR,
                              step: "1",
                            },
                            domProps: { value: _vm.lastRow },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.lastRow = $event.target.value
                              },
                            },
                          }),
                        ]),
                      ]),
                    ]
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "tunecol",
                class: { it_band: _vm.xls.active },
                staticStyle: { "background-color": "skyblue" },
              },
              [
                _c(
                  "label",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.xls.active,
                        expression: "xls.active",
                      },
                    ],
                    staticClass: "control-label band-label",
                    attrs: { for: "tunecol" },
                  },
                  [_vm._v("Настройка колонок")]
                ),
                _vm._v(" "),
                _c("dkc-grid", {
                  ref: "tunecol",
                  attrs: {
                    id: "tunecol",
                    name: "tunecol",
                    tableid: "tbltunecol",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "details",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.xls.active,
                    expression: "xls.active",
                  },
                ],
                class: { it_band: _vm.xls.active },
                attrs: { open: "" },
              },
              [
                _c("summary", [
                  _vm._v('Содержимое файла "' + _vm._s(_vm.file.name) + '"'),
                ]),
                _vm._v(" "),
                _c("div", {
                  ref: "xlsdata",
                  attrs: { id: "tblxlsdata", name: "xlsdata" },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "details",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.xls.active,
                    expression: "xls.active",
                  },
                ],
                class: { it_band: _vm.xls.active },
                attrs: { open: "" },
              },
              [
                _c("summary", [_vm._v("Образец импортируемой информации")]),
                _vm._v(" "),
                _c("div", { staticClass: "result-container" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("div", { staticClass: "rc-grid-container" }, [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v("Номенклатура (без учёта выбранной группы)"),
                    ]),
                    _vm._v(" "),
                    _c("div", {
                      ref: "resex",
                      attrs: { id: "tblresex", name: "resex" },
                    }),
                  ]),
                ]),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "dkc-model-dlg",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isModalDlgVisible,
              expression: "isModalDlgVisible",
            },
          ],
          attrs: { buttons: _vm.buttons, actions: _vm.actions },
          on: { close: _vm.closeModalDlg },
        },
        [
          _c("span", { attrs: { slot: "body" }, slot: "body" }, [
            _vm._v(_vm._s(_vm.bodyText)),
          ]),
          _vm._v(" "),
          _c("span", { attrs: { slot: "header" }, slot: "header" }, [
            _vm._v(_vm._s(_vm.headerText)),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("ed-izm-validator", { ref: "eiv" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "rc-tree-container" }, [
      _c("label", { staticClass: "control-label" }, [
        _vm._v("Группы номенклатуры"),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { id: "tree" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }