var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.hideToolbar
        ? _c(
            "bpk-toolbar-panel",
            [
              _vm.grant
                ? _c("dkc-tool-button", {
                    attrs: {
                      title: "Новая номенклатура",
                      hint: "Регистрация новой номенклатуры",
                      iconclass: "dbtn-create",
                      control: "tabulator-tableHolder",
                      action: _vm.createNomen,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.grant
                ? _c("dkc-tool-button", {
                    attrs: {
                      title: "Изменить номенклатуру",
                      hint: "Изменить данные о номенклатуре",
                      iconclass: "dbtn-edit",
                      control: "tabulator-tableHolder",
                      action: _vm.editNomen,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.grant
                ? _c("dkc-tool-button", {
                    attrs: {
                      title: "Удалить номенклатуру",
                      hint: "Удалить номенклатуру из справочника",
                      iconclass: "dbtn-delete",
                      control: "tabulator-tableHolder",
                      action: _vm.queryDeleteNomen,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("dkc-tool-button", {
                attrs: {
                  title: "Копировать",
                  hint: "Копировать в буфер обмена",
                  iconclass: "sbtn-copy",
                  begingroup: true,
                  control: "tabulator-tableHolder",
                  action: _vm.selectClipboardOperation,
                },
              }),
              _vm._v(" "),
              _c("dkc-search-control", {
                ref: "nmsearch",
                on: { onsearch: _vm.doSearchNomen },
              }),
              _vm._v(" "),
              _c("dkc-tool-button", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.searchText,
                    expression: "searchText",
                  },
                ],
                attrs: {
                  title: "Перейти в группу",
                  hint: "Перейти в группу номенклатуры",
                  iconclass: "dbtn-prior",
                  control: "tabulator-tableHolder",
                  action: _vm.gotoNomenGroup,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { ref: "table" }),
      _vm._v(" "),
      _c(
        "dkc-modal-dlg",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isModalDlgVisible,
              expression: "isModalDlgVisible",
            },
          ],
          attrs: { buttons: _vm.buttons, actions: _vm.actions },
          on: { close: _vm.closeModalDlg },
        },
        [
          _c("div", {
            attrs: { slot: "body" },
            domProps: { innerHTML: _vm._s(_vm.bodyText) },
            slot: "body",
          }),
          _vm._v(" "),
          _c("span", { attrs: { slot: "header" }, slot: "header" }, [
            _vm._v(_vm._s(_vm.headerText)),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("ContextMenu", {
        ref: "contextMenu",
        attrs: { "menu-items": _vm.menuItems },
      }),
      _vm._v(" "),
      _c("div", { staticStyle: { opacity: "0" }, attrs: { id: "clpbuffer" } }),
      _vm._v(" "),
      _c("br"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }