import { TabulatorFull as Tabulator } from 'tabulator-tables'
import qs from 'qs'

export default function autocompleteAJAX(cell, onRendered, success, cancel, editorParams){
    var self = this,
        cellEl = cell.getElement(),
        initialValue = cell.getValue(),
        input = document.createElement("input"),
        listEl = document.createElement("div"),
        allItems = [],
        displayItems = [],
        values = [],
        currentItem = {},
        blurable = true,
        timeoutID = false;

    const timeoutDuration = 2000
    const beforeSearch = editorParams.beforeSearch || false
    const afterSearch  = editorParams.afterSearch || false

    if (initialValue == undefined)
        initialValue = ''

    function getUniqueColumnValues(){
        var output = {},
            column = cell.getColumn()._getSelf(),
            data = self.table.getData();

        data.forEach(function(row){
            var val = column.getFieldValue(row);

            if(val !== null && typeof val !== "undefined" && val !== ""){
                output[val] = true;
            }
        });


        if(editorParams.sortValuesList){
            if(editorParams.sortValuesList == "asc"){
                output = Object.keys(output).sort();
            }else{
                output = Object.keys(output).sort().reverse();
            }
        }else{
            output = Object.keys(output);
        }

        return output;
    }

    function parseItems(inputValues, curentValue){
        var itemList = [];

        if(Array.isArray(inputValues)){
            inputValues.forEach(function(value){
                var item = {
                    title:editorParams.listItemFormatter ? editorParams.listItemFormatter(value, value) : value,
                    value:value,
                    element:false,
                };

                if(item.value === curentValue || (!isNaN(parseFloat(item.value)) && !isNaN(parseFloat(item.value)) && parseFloat(item.value) === parseFloat(curentValue))){
                    setCurrentItem(item);
                }

                itemList.push(item);
            });
        }else{
            for(var key in inputValues){
                var item = {
                    title:editorParams.listItemFormatter ? editorParams.listItemFormatter(key, inputValues[key]) : inputValues[key],
                    value:key,
                    element:false,
                };

                if(item.value === curentValue || (!isNaN(parseFloat(item.value)) && !isNaN(parseFloat(item.value)) && parseFloat(item.value) === parseFloat(curentValue))){
                    setCurrentItem(item);
                }

                itemList.push(item);
            }
        }

        if(editorParams.searchFunc){
            itemList.forEach(function(item){
                item.search = {
                    title:item.title,
                    value:item.value,
                };
            });
        }

        allItems = itemList;
    }

    function filterList(term, intialLoad){
      if (timeoutID) {
        clearTimeout(timeoutID)
      }
      timeoutID = setTimeout(doFilterList, timeoutDuration, term, intialLoad)
    }

    function doFilterList(term, intialLoad){
        var matches = [],
            searchObjs = [],
            searchResults = [];

        timeoutID = false
        if (term && term.length > 2) {
            if (beforeSearch) {
              const scache = beforeSearch(term)
              if (scache) {
                processingSearchResults(scache, intialLoad)
                return
              }
            }

            axios({
                method: editorParams.AJAX.method,
                url: editorParams.AJAX.url,
                headers: editorParams.AJAX.headers,
                data: qs.stringify({
                    search_text: term,
                    where: editorParams.AJAX.data.where,
                    limit: editorParams.AJAX.data.limit,
                })
            })
                .then(response => {
                    searchResults = editorParams.AJAX.searchFunc(response.data)
                    if (afterSearch) {
                      afterSearch(term, searchResults)
                    }
                    processingSearchResults(searchResults, intialLoad)
                })
                .catch(error => {
                    console.error('Ошибка поиска для автоподбора')
                    console.error(error)
                })
        }
        else {
            displayItems = []
            fillList(intialLoad);
        }
    }

    function processingSearchResults(searchResults, intialLoad) {
      parseItems(searchResults)
      displayItems = allItems
      fillList(intialLoad)
    }

    function fillList(intialLoad){
        var current = false;

        while(listEl.firstChild) listEl.removeChild(listEl.firstChild);

        displayItems.forEach(function(item){
            var el = item.element;

            if(!el){
                el = document.createElement("div");
                el.classList.add("tabulator-edit-select-list-item");
                el.tabIndex = 0;
                el.innerHTML = item.title;

                el.addEventListener("click", function(){
                    setCurrentItem(item);
                    chooseItem();
                });

                el.addEventListener("mousedown", function(){
                    blurable = false;

                    setTimeout(function(){
                        blurable = true;
                    }, 10);
                });

                item.element = el;

                if(intialLoad && item.value == initialValue){
                    input.value = item.value;
                    item.element.classList.add("active");
                    current = true;
                }

                if(item === currentItem){
                    item.element.classList.add("active");
                    current = true;
                }
            }

            listEl.appendChild(el);
        });

        if(!current){
            setCurrentItem(false);
        }
    }

    function setCurrentItem(item, showInputValue){
        if(currentItem && currentItem.element){
            currentItem.element.classList.remove("active");
        }

        currentItem = item;

        if(item && item.element){
            item.element.classList.add("active");
        }
    }

    function chooseItem(){
        hideList();

        if(currentItem && currentItem.value != undefined){
            if(initialValue !== currentItem.value){
                initialValue = currentItem.value;
                //input.value = currentItem.title;
                input.value = currentItem.value;
                success(currentItem.value);
            }else{
                cancel();
            }
        }else{
            if(editorParams.freetext){
                initialValue = input.value;
                success(input.value);
            }else{
                if(editorParams.allowEmpty && input.value === ""){
                    initialValue = input.value;
                    success(input.value);
                }else{
                    cancel();
                }
            }
        }
    }

    function cancelItem(){
        hideList();
        cancel();
    }

    function elOffset(el){
      var box = el.getBoundingClientRect();

      return {
        top: box.top + window.pageYOffset - document.documentElement.clientTop,
        left: box.left + window.pageXOffset - document.documentElement.clientLeft
      };
    }

    function showList(){
        if(!listEl.parentNode){
            while(listEl.firstChild) listEl.removeChild(listEl.firstChild);

           /*  if(editorParams.values === true){
                values = getUniqueColumnValues();
            }else{
                values = editorParams.values || [];
            }

            parseItems(values, initialValue); */

            var offset = elOffset(cellEl);

            listEl.style.minWidth = cellEl.offsetWidth + "px";

            listEl.style.top = (offset.top + cellEl.offsetHeight) + "px";
            listEl.style.left = offset.left + "px";
            document.body.appendChild(listEl);
        }
    }

    function hideList(){
        if(listEl.parentNode){
            listEl.parentNode.removeChild(listEl);
        }
    }

    //style input
    input.setAttribute("type", "search");

    input.style.padding = "4px";
    input.style.width = "100%";
    input.style.boxSizing = "border-box";

    //allow key based navigation
    input.addEventListener("keydown", function(e){
        var index;

        switch(e.keyCode){
            case 38: //up arrow
                e.stopImmediatePropagation();
                e.stopPropagation();
                e.preventDefault();

                index = displayItems.indexOf(currentItem);

                if(index > 0){
                    setCurrentItem(displayItems[index - 1]);
                }else{
                    setCurrentItem(false);
                }
                break;

            case 40: //down arrow
                e.stopImmediatePropagation();
                e.stopPropagation();
                e.preventDefault();

                index = displayItems.indexOf(currentItem);

                if(index < displayItems.length - 1){
                    if(index == -1){
                        setCurrentItem(displayItems[0]);
                    }else{
                        setCurrentItem(displayItems[index + 1]);
                    }
                }
                break;


            /*case 37: //left arrow
            case 39: //right arrow
                e.stopImmediatePropagation();
                e.stopPropagation();
                e.preventDefault();
                break;*/

            case 13: //enter
                chooseItem();
                break;

            case 27: //escape
                cancelItem();
                break;

            case 36: //home
            case 35: //end
                //prevent table navigation while using input element
                e.stopImmediatePropagation();
                break;
        }
    });

    input.addEventListener("keyup", function(e){

        switch(e.keyCode){
            case 38: //up arrow
            //case 37: //left arrow
            //case 39: //up arrow
            case 40: //right arrow
            case 13: //enter
            case 27: //escape
                break;

            default:
                filterList(input.value);
        }

    });

    input.addEventListener("search", function(e){
        filterList(input.value);
    });

    input.addEventListener("blur", function(e){
        if(blurable){
            chooseItem();
        }
    });

    input.addEventListener("focus", function(e){
        showList();
        input.value = initialValue;
        filterList(initialValue, true);
    });

    //style list element
    listEl = document.createElement("div");
    // listEl.classList.add("tabulator-edit-select-list");
    listEl.classList.add("tabulator-edit-list");
    listEl.classList.add("tabulator-popup-container");

    onRendered(function(){
        input.style.height = "100%"
        input.focus()
    });

    return input;
}
