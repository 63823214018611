var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", {
      ref: "table",
      staticClass: "spec-table",
      attrs: { id: _vm.tblid },
      on: { keydown: _vm.tableKeyDown },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }