import XLSX from "xlsx";

export default function VerNomenGroupReader(data) {
  const {xls, colMap, foliage, branch} = data
  const colGroup = colMap.firstGroup ? colMap.firstGroup : colMap.lastGroup
  const nomenCols = getNomenColumns(colGroup)
  let _row = -1

  /**
   * Возвращает массив с именами колонок, где распологаются данные о номенклатуре
   * за исключением кологки с группами
   * @param colGroup - имя колонки, где находятся группы номенклатуры
   * @returns {Array} - имена колонок с дванными номенклатуры
   */
  function getNomenColumns (colGroup) {
    const nomenCols = []
    const cols = [colMap.code, colMap.mark, colMap.name, colMap.price]
    cols.forEach(col => {
      if (col && col != colGroup) {
        nomenCols.push(col)
      }
    })

    return nomenCols
  }


  /**
   * Чтение данных из колонок с группами номенклатуры
   * @param row
   * @returns {Array}
   */
  function readGroups (row) {
    if (!colGroup)
      return []

    const data = []
    nomenCols.forEach(col => {
      if (col)
        data.push(xls.value(col, row))
    })

    return data.some(value => value) ? false : xls.value(colGroup, row)
  }

  /**
   * Возвращает группу номенклауры по прочинатым в Excel-данным
   * @param row - строка, где пытаемся получить группу номенклатуры
   * @returns {*|NomenGroup}
   */
  function getGroups(row) {
    const groups = []
    let group = true
    for (let r = row; group; r++) {
      group = readGroups(r)
      if (group) {
        _row = r
        groups.push({name: group, row: r})
      }
    }

    return groups
  }

  this.getRow = () => _row
  this.getGroup = () => branch[branch.length - 1]
  this.read = (row) => {
    const groups = getGroups(row)
    if (row == 1461) {
      console.log('Отладка')
    }
    if (groups.length > 0) {
      if (groups.length < branch.length) {
        branch.splice(branch.length - groups.length, groups.length)
      }
      else {
        branch.splice(1, branch.length - 1)
      }

      groups.forEach(group => {
        try {
          branch.push(branch[branch.length - 1].appendChild(group.name, branch.length - 1, group.row))
        }
        catch (e) {
          console.error('Ошибка в строке Excel: ' + _row + ' / ' + row)
          console.error(e)
        }
      })

      _row = _row + 1
    }

    return branch[branch.length - 1]
  }
}

VerNomenGroupReader.prototype = {
  get row() {
    return this.getRow()
  },
  get group() {
    return this.getGroup()
  },
}
