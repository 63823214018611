var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "dkc-modal-dlg",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isModalDlgVisible,
              expression: "isModalDlgVisible",
            },
          ],
          attrs: { buttons: _vm.buttons, actions: _vm.actions },
          on: { close: _vm.closeModalDlg },
        },
        [
          _c(
            "div",
            { attrs: { slot: "body" }, slot: "body" },
            [
              _c("clipboard-data", { ref: "cbrddt" }),
              _vm._v(" "),
              _c("clipboard-colmap", {
                ref: "cbrdcm",
                on: { celledited: _vm.colmapChange },
              }),
              _vm._v(" "),
              _c("clipboard-result", { ref: "cbrdres" }),
            ],
            1
          ),
          _vm._v(" "),
          _c("span", { attrs: { slot: "header" }, slot: "header" }, [
            _vm._v("Настройка вставки данных в таблицу из буфера обмена"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "toggle_block",
              attrs: { slot: "buttons" },
              slot: "buttons",
            },
            [
              _c("div", { staticClass: "toggle_label" }, [
                _vm._v("Поиск номенклатуры заказчика после импорта"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "toggle_control" },
                [
                  _c("VueToggles", {
                    attrs: {
                      value: _vm.searchNomenAfterImport,
                      height: "18",
                      width: "120",
                      checkedText: "Выполнить",
                      uncheckedText: "Не выполнять",
                      checkedBg: "skyblue",
                      uncheckedBg: "gray",
                    },
                    on: { click: _vm.toggleSearch },
                  }),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("ed-izm-validator", { ref: "eiv" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }