<template>
  <div id="nomenSelector"></div>
</template>

<script>
import { TabulatorFull as Tabulator } from "tabulator-tables";

export default {
  name: "NomenSelectorTable",
  props: ["firms", "inSearch", "inDeepSearch", "nomenForSearch", "visible"],
  components: { Tabulator },
  data() {
    return {
      tabulator: false,
      deepSearchStep: 0,
    };
  },
  computed: {
    ajaxProgressiveLoad: function () {
      return this.inDeepSearch ? "load" : "scroll";
    },
    selected_nomen: function () {
      return this.tabulator || this.tabulator.getSelectedData().length
        ? this.tabulator.getSelectedData()[0]
        : {};
    },
  },
  watch: {
    deepSearchStep: function (value) {
      if (value < 0) {
        this.$emit("stopdeepsearch");
      } else if (value > 0) {
        this.$emit("nextdeepsearchstep", value);
        this.doDeepSearch(value);
      }
    },
    inSearch: function (value) {
      if (value) {
        this.doSearch()
      }
    },
    inDeepSearch: function (value) {
      if (value) {
        this.tabulator.setData([]);
        this.doDeepSearch(1);
      }
    },
    visible: function (value) {
      if (value && !this.tabulator) {
        this.createTable();
      }
    },
  },
  methods: {
    clearTable: function () {
      this.tabulator.setData([]) ;
    },
    doSearch: function () {
      const filters = []
      if (this.nomenForSearch.idFirm) {
        filters.push({'field': 'idFirm', 'value': this.nomenForSearch.idFirm, 'type': '='})
      }

      if (this.nomenForSearch.code) {
        filters.push({'field': 'code', 'value': this.nomenForSearch.code, 'type': 'like'})
      }

      if (this.nomenForSearch.mark) {
        filters.push({'field': 'mark', 'value': this.nomenForSearch.mark, 'type': 'like'})
      }

      if (this.nomenForSearch.name) {
        filters.push({'field': 'name', 'value': this.nomenForSearch.name, 'type': 'like'})
      }

      const options = {
        method: 'GET',
        url: this.baseUrl + '/nomen/select?idng=-1&page=1&size=200&filters=' + JSON.stringify(filters),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json; charset=utf-8',
          'X-CSRF-Token': tkn_csfr,
        },
      };

      axios(options)
        .then((response) => {
          this.tabulator.setData(response.data.data)
          this.$emit("stopsearch")
        })
        .catch((error) => {
          // Остановить поиск, сообщить об ошибке
          this.$emit("stopsearch")
        });
    },
    doDeepSearch: function (step) {
      const formData = new FormData();
      formData.append("step", step);
      formData.append("idFirm", this.nomenForSearch.idFirm);
      formData.append("firmname", '');
      formData.append("code", this.nomenForSearch.code);
      formData.append("mark", this.nomenForSearch.mark);
      formData.append("name", this.nomenForSearch.name);
      const options = {
        method: "POST",
        url: this.baseUrl + "/nomen/deep-search",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "application/json; charset=utf-8",
          "X-CSRF-Token": tkn_csfr,
        },
        data: formData,
      };
      axios(options)
        .then((response) => {
          // Загнать найденные данные в таблицу
          const data = this.tabulator.getData();
          if (!data.length) {
            response.data.data.forEach( item => { data.push(item) })
          } else {
            response.data.data.forEach( row => {
              const rd = data.find((item) => item.idnomen == row.idnomen);
              if (!rd) {
                data.push(row);
              }
            });
          }
          this.tabulator.setData(data);

          // Если есть продолжение, возобновить поиск. Или остановить все.
          // Если итоговый массив подошел к точке маразма, то тормозим все
          this.deepSearchStep = data.length > 200 ? -1 : response.data.step;
        })
        .catch((error) => {
          // Остановить поиск, сообщить об ошибке
          this.$emit("stopdeepsearch");
        });
    },
    firmMutator: function (value, data, type, params, component) {
      if (isNaN(value)) {
        return value;
      }

      const ind = parseInt(value)
      const firm = this.firms.find( firm => firm.idFirm == ind )
      const firmname = firm ? firm.name : '-'
      return value == 1 ? "ДКС" : firmname;
    },
    rowDblClick: function (event, row) {
      if (!row.isSelected()) {
        row.select();
      }
      this.$emit("select");
    },
    selectNomen(url, config, params) {
      const idng = -1;
      let purl =
        url +
        "?idng=" +
        idng +
        "&page=" +
        params.page +
        "&size=" +
        params.size +
        "&fm=and" +
        (params.sorters.length
          ? "&sorters=" + JSON.stringify(params.sorters)
          : "") +
        (params.filters.length
          ? "&filters=" + JSON.stringify(params.filters)
          : "");
      return purl;
    },
    formatcell(cell) {
      const cellval = cell.getValue() || "";
      if (!cellval) return "";

      return '<div class="cs_longtext">' + cellval + "</div>";
    },
    createTable() {
      this.tabulator = new Tabulator("#nomenSelector", {
        columns: [
          { field: "idnomen", title: "idnomen", visible: false },
          { field: "step", title: "№а", width: 36, visible: true },
          {
            field: "idFirm",
            title: "Завод-изготовитель, вендор",
            visible: true,
            mutatorData: this.firmMutator,
            formatter: this.formatcell,
          },
          { field: "code", title: "Код",            visible: true, formatter: this.formatcell, },
          { field: "mark", title: "Тип",            visible: true, formatter: this.formatcell, },
          { field: "name", title: "Наименование",   visible: true, formatter: this.formatcell, },
        ],

        layout: "fitColumns",
        height: "100%",
        columnDefaults:{
          resizable: "header",
        },
        layoutColumnsOnNewData: true,
        placeholder: "нет данных",
        selectable: 1,
        locale: "ru-RU",
        langs: {
          "ru-RU": {
            ajax: {
              loading: "загрузка",
              error: "ошибочка вышла",
            },
            pagination: {
              first: String.fromCharCode(57449),
              first_title: "первая страница",
              prev: String.fromCharCode(57457),
              prev_title: "предыдущая страница",
              next: String.fromCharCode(57461),
              next_title: "следущая страница",
              last: String.fromCharCode(57463),
              last_title: "последняя страница",
            },
          },
        },
      });
      this.tabulator.on('rowDblClick', this.rowDblClick)
    },
  },
};
</script>

<style>
.tabulator-selectable {
  background-color: yellow;
}
</style>
