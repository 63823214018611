export function SpecChecker (spec) {

    this.spec = spec ;

    this.checkEdIzm = function () {
        const self = this

        return new Promise(function (resolve, reject) {
          const edizmarr = []
          const data  = self.spec.tabulator.getData()
          data.forEach(row => {
            if (row.c_idedizm && parseInt(row.c_idedizm) == NaN)
              edizmarr.push(row.c_idedizm)
            if (row.d_idedizm && parseInt(row.d_idedizm) == NaN)
              edizmarr.push(row.d_idedizm)
          })
          const edizmList = [...new Set(edizmarr)]
          if (!edizmList.length)
            return resolve()

          self.spec.$refs.eiv.executeAsyc(self.spec.$store.state.edizmRef.ref, edizmList, resolve, reject)
        })
    }

    this.checkFirm = function () {
      return  // Фирмы пока не проверяем, надо запустить пока без проверки
      const self = this
      return new Promise(function (resolve, reject) {
        const firmarr = []
        const data  = self.spec.tabulator.getData()

        data.forEach( row => {
          if (!row.c_idFirm)
            return

          firmarr.push(row.c_idFirm)
          }
        )
        const firmList = [...new Set(firmarr)]
        if (!firmList.length)
          return resolve()

        const signFirmList = self.spec.$store.state.firm.ref.filter(firm => firm.sign == '1')


        self.spec.$refs.fv.executeAsyc({
          'spec': self.spec,
          'ref': signFirmList,
          'list': firmList,
          'resolve': resolve,
          'reject': reject
        })
      })
    }

    this.check = function () {
        const self = this
        return new Promise(function (resolve, reject) {
            const msg = !!self.spec.docnum ? 'Номер на месте' : 'б/н'
            self.checkEdIzm()
              .then(result => {
                resolve(result + ' ' + msg)
                /* self.checkFirm()
                  .then ( result => {
                    resolve(result + ' ' + msg)
                  })
                  .catch(error => {
                    reject( error + ' ' + msg)
                  }) */
              })
              .catch(error => {
                reject( error + ' ' + msg)
              })
        })
    }

/*
    const checkEdIzm = (spec) => {
        return new Promise(function (resolve, reject) {
          const edizmarr = []
          const data  = spec.tabulator.getData()
          data.forEach(row => {
            if (row.c_idedizm && parseInt(row.c_idedizm) == NaN)
              edizmarr.push(row.c_idedizm)
            if (row.d_idedizm && parseInt(row.d_idedizm) == NaN)
              edizmarr.push(row.d_idedizm)
          })
          const edizmList = [...new Set(edizmarr)]
          if (!edizmList.length)
            return resolve()

          spec.$refs.eiv.executeAsyc(spec.$store.state.edizmRef.ref, edizmList, resolve, reject)
        })
      }

    return new Promise(function (resolve, reject) {

        const oops = function (spec) {
          alert('Старт!')
          return new Promise(function (resolve, reject) {
            if (!!spec.docnum)
              return resolve('oops ok')

            return reject('oops error')
          })
        }


        oops(spec)
          .this(() => resolve('ГЫГЫЦ!'))
          .catch(error => reject(error))
        /*checkEdIzm(spec)
            .this(() => resolve('ГЫГЫЦ!'))
            .catch(error => reject(error)) */
/*  }) */
}
