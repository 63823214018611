var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ng-container" }, [
    _c("div", { staticClass: "toolbar" }),
    _vm._v(" "),
    _c("div", { staticClass: "ng-dtl" }, [
      _vm.isError
        ? _c("div", { staticClass: "ng-dtl__error" }, [
            _vm._v("Ошибка получения данных о текущей группе"),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "ng-dtl__info" }, [
        _c("div", { staticClass: "ng-dtl__title" }, [
          _c("div", [_vm._v("Информация по группе номенклатуры")]),
          _vm._v(" "),
          _c("div", { staticClass: "ng-dtl__name" }, [
            _vm._v(_vm._s(_vm.ngInfo.group_name)),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ng-dtl__block" },
          [
            _c("label", { staticClass: "control-label" }, [
              _vm._v("Родительская группа номенклатуры"),
            ]),
            _vm._v(" "),
            _c("dkc-ng-namy", {
              attrs: { idgroup: _vm.ngInfo.idParent, "hide-toolbar": "true" },
              on: { select_group: _vm.selectGroup },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "ng-dtl__block" }, [
          _c("label", { staticClass: "control-label" }, [
            _vm._v("Количество позиций номенклатуры в группе: "),
          ]),
          _vm._v(" "),
          _c("a", { attrs: { href: _vm.nomenUrl } }, [
            _c("span", { staticClass: "nomencount" }, [
              _vm._v(_vm._s(_vm.ngInfo.nomen_count)),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "ng-dtl__block" }, [
          _c("div", { staticClass: "ng-dtl__tbls" }, [
            _c(
              "div",
              { staticClass: "ng-dtl__url" },
              [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v("Ссылки на сторонние ресурсы"),
                ]),
                _vm._v(" "),
                _c("dkc-nomen-group-url", { attrs: { idng: _vm.ngInfo.id } }),
                _vm._v(" "),
                _c("div", { attrs: { id: "ng-dtl__url_tbl" } }),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }