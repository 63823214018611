<template>
   <popper trigger="hover"
           :options="{placement: 'top', modifiers: { offset: { offset: '10px,10px' } }}" >
      <div class="popper">{{hint}}</div>

      <button class="dtb_btn" :class="{dtb_bigbtn: bigbtn, dtb_smallbtn: !bigbtn, dbtn_begingroup: begingroup}"
              @click="buttonClick" slot="reference" >
         <div :class="class4icon" />
         <div class="dtb_title">{{title}}</div>
      </button>
   </popper>
</template>

<script>
  import Popper from 'vue-popperjs'
  import 'vue-popperjs/dist/vue-popper.css' ;
  export default {
    props: {
       action:       {type: [Function, String], required: true},
       actiondata:   {type: Object},
       begingroup:   {type: Boolean, default: false},
       control:      {type: String, default: ''},
       hint:         {type: String, default: ''},
       iconclass:    {type: [String, Object], required: true},
       size:         {type: String, default: 'big'},
       title:        {type: String, required: true},
    },
    components: {Popper},
    computed: {
       bigbtn: function () {
          return this.size.toLocaleLowerCase() == 'big'
       },
       class4icon: function () {
          return [this.iconclass, this.bigbtn ? "dtb_bigicon" : "dtb_smallicon"]
       }
    },
    methods: {
      buttonClick: function(event) {
        const typeaction = typeof this.action ;

        switch (typeaction) {
          case "string": window.location = this.baseUrl + this.action ; break ;
          case "function": this.action(event, this.actiondata) ; break ;
        }

        const elems = document.getElementsByClassName(this.control || 'tabulator-tableHolder')
        if (elems.length > 0) {
            elems[0].focus()
        }
        event.preventDefault()
      }
    }
  }
</script>

<style scoped>

   .dtb_btn {
      border: 0;
      vertical-align: top;
   }

   .dtb_btn:hover {
      background-color: silver;
   }

   .dtb_btn:hover .dtb_title {
      color: red;
   }

   .dtb_smallbtn {
      display: flex;
      padding: 4px;
   }

   .dtb_bigbtn {
      display: flex;
      flex-direction: column;
      justify-content: flext-start;
      /* height: 70px; */
      max-height: 70px;
      max-width: 90px;
      text-align: center;
   }

   .dtb_bigicon {
      margin: 1px auto;
      width: 32px ;
      height: 32px;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat ;
   }

   .dtb_smallicon {
      margin-right: 4px;
      width: 16px ;
      height: 16px;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat ;
   }

   .dtb_title {
      font-size: 9pt;
   }

  .dbtn {
    position: relative;
    width: 32px;
    height: 32px;
    margin-bottom: 0px;
    cursor: pointer;
    user-select: none;
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: white;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat ;
  }

  .dbtn::after {
    content: attr(data-title);
    position: absolute;
    left: 8px;
    bottom: +24px;
    background: rgba(255, 255, 150, .85);
    color: #cc0000;
    overflow: visible;
    white-space: nowrap;
    text-align: center;
    border: 2px solid rgba(255, 255, 50, .85);
    border-radius: 8px;
    padding: 3px 16px;
    z-index: 100;
    opacity: 0;
    transition: all 0.6s ease-in-out;
  }

  .dbtn:hover:after {
    opacity: 1;
  }

  .dbtn_transition {
      transition: all 0.5s ease-in-out;
  }

  .dbtn_rotate__90 {
      transform: rotate(-90deg);
  }

  .dbtn-begingroup, .dbtn_begingroup {
     margin-left: 16px;
  }

  .dbtn-create {
    background-image: url("../../images/create.svg");
  }

  .dbtn-copy {
    background-image: url("../../images/copy2.svg");
  }

  .dbtn-edit {
    background-image: url("../../images/edit.svg");
  }

  .dbtn-delete {
    background-image: url("../../images/delete.svg");
  }

  .dbtn-nomen-link  {
    background-image: url("../../images/nomen_link.svg");
  }

  .dbtn-nomen-unlink  {
    background-image: url("../../images/nomen_unlink.svg");
  }

  .dbtn-show-refnomen  {
    background-image: url("../../images/show_refdata.svg");
  }

  .dbtn-show-specrow-info  {
    background-image: url("../../images/about.svg");
  }

  .dbtn-search {
    background-image: url("../../images/binoculars.svg");
  }

  .dbtn-list {
    background-image: url("../../images/list.svg");
  }

  .dbtn-tree {
    background-image: url("../../images/tree.svg");
  }

  .dbtn-import {
    background-image: url("../../images/import.svg");
  }

  .dbtn-down {
      background-image: url("../../images/down1.svg");
  }
  .dbtn-right {
      background-image: url("../../images/right1.svg");
  }

  .dbtn-prior {
      background-image: url("../../images/prior.svg");
  }

  .dbtn-next {
      background-image: url("../../images/next.svg");
  }

  .dbtn_refresh {
    background-image: url("../../../web/images/refresh.svg");
  }

   .dbtn_filter_clear {
      background-image: url("../../../web/images/filter_clear.svg");
   }

   .dbtn_state_approved {
      background-image: url("../../images/state_approve.svg");
   }

   .dbtn_state_draft {
      background-image: url("../../images/state_draft.svg");
   }

   .dbtn_state_exclude {
      background-image: url("../../images/state_exclude.svg");
   }

   .dbtn_open_spec {
      background-image: url("../../images/open_spec.svg");
   }

   .dbtn_open_nomen_dkc {
      background-image: url("../../images/nomen_dkc.svg");
   }

   .dbtn_open_nomen_customer {
      background-image: url("../../images/nomen_customer.svg");
   }

   .spec-save {
      background-image: url("../../images/save.svg");
   }

   .spec-saveandclose {
      background-image: url("../../images/save_close.svg");
   }

   .spec-close {
      background-image: url("../../images/close.svg");
   }

   .spec-plus {
      background-image: url("../../images/plus.svg");
   }

   .spec-plus2 {
      background-image: url("../../images/plus2.svg");
   }

   .spec-minus {
      background-image: url("../../images/minus.svg");
   }

   .spec-cancel{
      background-image: url("../../images/cancel.svg");
   }

   .spec-pin {
      background-image: url("../../images/pin2.svg");
   }

   .spec-column-add {
      background-image: url("../../images/coladd.svg");
   }

   .spec-column-edit {
      background-image: url("../../images/coled.svg");
   }

   .spec-column-delete {
      background-image: url("../../images/coldel.svg");
   }

   .spec-export-excel {
      background-image: url("../../images/export_excel.svg");
   }

   .spec_show_money {
      background-image: url("../../images/ruble.svg");
   }

   .spec_hide_money {
      background-image: url("../../images/cancel.svg");
   }

   .spec_recalc_money {
      background-image: url("../../images/price_change.svg");
   }

   .spec_pos_visible {
      background-image: url("../../images/groupshow.svg");
   }

   .spec_pos_hidden {
      background-image: url("../../images/grouphide.svg");
   }

   .sbtn-copy {
      background-image: url('../../images/copy.svg');
   }

   .spec_nomen_selector {
      background-image: url('../../images/select_refdata.svg');
   }

   .spec_nomen_search {
      background-image: url('../../images/magnifier.svg');
   }

   .spec_show_selector {
      background-image: url('../../images/show_select_all.svg');
   }

   .spec_select_all {
      background-image: url('../../images/checkbox_select_all.svg');
   }

   .spec_deselect_all {
      background-image: url('../../images/checkbox_deselect_all.svg');
   }

   .spec_select_inverse {
      background-image: url('../../images/checkbox_select_inverse.svg');
   }
</style>
