<template>
   <ul class="tabs">
      <bpk-tab v-for="tab in tabs" :key="tab.index"
               :title="tab.title"
               :hint="tab.hint"
               :index="tab.index"
               @tab-click="changeActiveTab"
      />
   </ul>
</template>

<script>
import bpkTab from './bpkTab'
export default {
   name: "bpkTabs",
   components: {bpkTab},
   props: {
      tabs: {type: Array, required: true},
   },
   data () {
      return {
         activeTabIndex: 0
      }
   },
   computed: {
      activeTab: function () {
         const self = this
         return this.$children.find( tab => tab.index == self.activeTabIndex )
      },
   },
   methods: {
      changeActiveTab (index) {
         let tab = this.activeTab
         if (tab)
            tab.deActivateTab()

         this.$emit('change-tab', {from: this.activeTabIndex, to: index})
         this.activeTabIndex = index
      }
   },
   mounted() {
      const tab = this.activeTab ;
      if (tab)
         tab.activateTab()
   },
}
</script>

<style scoped>
   .tabs {
      margin: 0;
      padding: 0;
   }
</style>
