<template>
   <div class="ribbon">
      <bpk-tabs :tabs="tabs" @change-tab="changeTab" />
      <bpk-toolbars :activeTabIndex="activeTabIndex">
         <slot></slot>
      </bpk-toolbars>
   </div>
</template>

<script>
import bpkTabs from './bpkTabs'
import bpkToolbars from './bpkToolbars'
export default {
   name: "bpkRibbon",
   components: { bpkTabs, bpkToolbars},
   props: {
      tabs: {type: Array, required: true},
   },
   data () {
      return {
         activeTabIndex: 0
      }
   },
   methods: {
      changeTab (tabs) {
         this.activeTabIndex = tabs.to
      }
   },
}
</script>

<style scoped>
   .ribbon {
      padding: 3px;

   }
</style>
