<template>
    <div class="eiv-container">
      <div>{{edizmname}}</div>
      <div>
        <label for="edizmList">заменить на:</label>
        <select name="edizmList" id="edizmList" v-model="replacement">
          <option :value="edizm.idedizm" v-for="edizm in edizmList">{{edizm.name}}</option>
          <option value="-1" class="new-item">[ создать новую ]</option>
        </select>
      </div>
    </div>
</template>

<script>
    export default {
        name: "edizmValidatorItem",
        props: ["edizmname", "edizmList"],
        data() {
            return {
                replacement: 0,
            }
        },
        watch: {
           /* replacement: function() {
                const self = this
                const ei = this.edizmList.find( item => { return item.idedizm = self.replacement })
                if (ei)
                    ei.add(self.edizmname)
            },*/
        },

    }
</script>

<style scoped>
  .eiv-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding-left: 16px;
    padding-right: 8px;
  }

  .eiv-container label {
    margin-right: 4px;
  }

  .new-item {
    color: grey;
    font-style: italic;
  }
</style>
