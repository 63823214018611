import NomenGroup from './NomenGroup'
import Nomen from './Nomen'
import RowsMap from './NomenImportEngineRows'
import ColsMap from './NomenImportEngineCols'
import Locator from './NomenImportLocator'
import GroupReaderFactory from './NomenGroupReaderFactory'
import XLSX from 'xlsx'

export default function ExcelImporter () {
    this._xls = false
    //this._sheet = false
    this._rowsMap = new RowsMap()
    this._colsMap = new ColsMap()
    this._branch  = []
    this._foliage = []

    this._rowsMap.onchange = (oldRow, newRow) => {
        if (this._onchangeFirstRow)
            this._onchangeFirstRow(oldRow, newRow)
    }

    this._colsMap.onchangeGroup = (colName, oldCol, newCol) => {
        if (this._onchangeGroup)
            this._onchangeGroup(colName, oldCol, newCol)
    }

    this._colsMap.onchangeGrid = (colName, oldCol, newCol) => {
        if (this._onchangeGrid)
            this._onchangeGrid(colName, oldCol, newCol)
    }

    this._onchangeFirstRow  = false
    this._onchangeGroup     = false
    this._onchangeGrid      = false
}

ExcelImporter.prototype = {
    /**
     * Возвращает значение ячейки в открытом Excel-файле
     * @param col - колонка
     * @param row - строка
     * @returns {string | number | boolean | Date|string}
     */
    cellval: function(col, row) {
        return this.xls.value(col, row)
    },
  /**
   * Возвращает объект с данными заданной индексом строки. Данные возвращаются только для колонок, указанных в colMap
   * @param rowIndex - индекс строки
   * @returns {row} - объект с данными строки
   */
  getRow(rowIndex) {
        const row = {}
        this._colsMap.cols.forEach(col => {
          row[col] = this.sheet[col + rowIndex].v
        })
        return row
    },
    /**
     * Конвертирует группу номенклатуры в дерево для компонента jsTree
     * @param group - корневая группа номенклатуры
     */
    convertGroup2Tree(group) {
        const data = {}

        data.text = group.name
        data.state = {'opened' : true}

        if (group.children.length > 0) {
            const self = this
            data.children = []
            group.children.forEach(child => {
                data.children.push(self.convertGroup2Tree(child))
            })
        }

        return data
    },
    /**
     * Возвращает массив номенклатуры заданной и всех дочерних групп
     * @param group - группа, для которой возвращается массив номенклатур
     * @returns {Array|*|Array}
     */
    flatlistNomen(group) {
        const self = this
        if (group == undefined) {
            if (this._branch.length == 0) {
                return []
            }

            group = this._branch[0]
        }

        let nomens = group.nomens
        group.children.forEach(child => nomens = nomens.concat(self.flatlistNomen(child)))
        return nomens
    },
    /**
     * Возвращает группу номенклауры по прочинатым в Excel-данным
     * @param groupcols - значения ячеек с наименованием группы
     * @param row - строка, где пытаемся получить группу номенклатуры
     * @returns {*|NomenGroup}
     */
    getGroup(groupcols, row) {
        if (groupcols.length > 0) {
            let group = false
            let index = -1
            for (let i = 0; i < groupcols.length; i++) {
                if (groupcols[i]) {
                    index = this._branch[i].indexOf(groupcols[i])
                    group = index < 0 ? this._branch[i].appendChild(groupcols[i], i) : this._branch[i].children[index]
                    group.row = row

                    // обрезаем ветку до родительской группы
                    this._branch.splice(i + 1, this._branch.length - i, group)
                }
            }
        }
        return this._branch[this._branch.length - 1]
    },
    /**
     * Загрузка данных из Excel
     * @param rowCount - количество читаемых строк. Если не определено, то весь файл.
     * @returns {boolean}
     */
    load(rowCount) {
        const lastRow = rowCount == undefined ? this._rowsMap.lastRow : this._rowsMap.firstRow + rowCount
        this._branch  = [new NomenGroup('root', false)]
        this._foliage = []
        const groupReader = GroupReaderFactory({
          xls: this._xls,
          colMap: this._colsMap,
          foliage: this._foliage,
          branch: this._branch
        })
        let groupcols = []
        let group  = this._branch[0]
        let nomen  = {}
        for (let row = this._rowsMap.firstRow; row < lastRow + 1; row++) {
          group     = groupReader.read(row)
          if (group) {
            row = Math.max(row, groupReader.row)
            nomen = this.readNomen(row)

            group.appendNomen(nomen.code, nomen.mark, nomen.name, nomen.edizm, nomen.price, nomen.rest)
            if (group.nomens.length > 0 && this._foliage.indexOf(group) < 0)
              this._foliage.push(group)
          }
        }

        return this._branch.length > 0
    },
    /**
     * Ищет по наименованиям требуемые колонки
     */
    locate: function() {
        const self = this
        const locator = new Locator(this._xls)
        const headerRow = locator.searchHeader(this.sheet)
        this.rows.firstRow = headerRow + 1
        if (headerRow < 1)
            return
        const mark = locator.searchColumns(this.sheet, headerRow)
        mark.fields.forEach(field => {
            if (field.col > -1)
                self.cols[field.field]  = field.col
        })
    },
    /**
     * Чтение данных из колонок с группами номенклатуры
     * @param row
     * @returns {Array}
     */
    readGroups: function(row) {
       if (!this._colsMap.firstGroup && !this._colsMap.lastGroup)
           return []

        const firstCol = XLSX.utils.decode_col(this._colsMap.firstGroup ? this._colsMap.firstGroup : this._colsMap.lastGroup)
        const lastCol = XLSX.utils.decode_col(this._colsMap.lastGroup ? this._colsMap.lastGroup : this._colsMap.firstGroup) + 1
        const cols = []
        for (let col = firstCol; col < lastCol; col++)
            cols.push(this.cellval(col, row))

        return cols
    },
    /**
     * Чтение данных из колонок с данными о номенклатуре
     * @param row
     * @returns {{rest: *, code: *, price: *, name: *, edizm: *, mark: *}}
     */
    readNomen: function (row) {
        const nomen = {
            code: this._colsMap.code !== '' ? this.cellval(this._colsMap.code, row) : '',
            mark: this._colsMap.mark !== '' ? this.cellval(this._colsMap.mark, row) : '',
            name: this._colsMap.name !== '' ? this.cellval(this._colsMap.name, row) : '',
            edizm: this._colsMap.edizm !== '' ? this.cellval(this._colsMap.edizm, row) : '',
            price: this._colsMap.price !== '' ? this.cellval(this._colsMap.price, row) : 0,
            rest: this._colsMap.rest !== '' ? this.cellval(this._colsMap.rest, row) : 0,
        }

        return nomen
    },
    /**
     * Разбивка групп на более мелкие (устрелоЮ не требутся)
     */
    splitFoliage: function() {
        const MAX_GROUP_SIZE = 200
        let group = {}
        let part  = {}
        for (let i = this._foliage.length - 1; i > -1; i--) {
            group = this._foliage[i]

            while (group.nomens.length > MAX_GROUP_SIZE) {
                part = group.split(MAX_GROUP_SIZE)
                if (part)
                    this._foliage.push(part)
            }
        }
    },
  /**
   * Отдает загруженный файл сборщику мусора
   */
  close: function () {
      this._sheet = false
    },
    /**
     * Геттеры и сеттеры
     * @returns {boolean|*}
     */
    get xls () {
      return this._xls
    },
    set xls (newxls) {
      this._xls = newxls
    },
    get sheet () {
        return this.xls.activeSheet
    },
    get cols() {
        return this._colsMap
    },
    get rows () {
        return this._rowsMap
    },
    get onchangeFirstRow () {
        return this._onchangeFirstRow
    },
    set onchangeFirstRow (f) {
        this._onchangeFirstRow = f
    },
    get onchangeGroup () {
        return this._onchangeGroup
    },
    set onchangeGroup (f) {
        this._onchangeGroup = f
    },
    get onchangeGrid () {
        return this._onchangeGrid
    },
    set onchangeGrid (f) {
        this._onchangeGrid = f
    },
    get groups () {
        if (this._branch.length == 0)
            return []

        const self = this
        const tree = []
        if (this._branch.length > 0 && this._branch[0].children.length > 0) {
            this._branch[0].children.forEach(group => {
                tree.push(self.convertGroup2Tree(group))
            })
        }

        return tree
    },
    get edizmList () {
      if (!this._colsMap.edizm)
        return []

      const eil = []
      let ei = false
      for (let row = this._rowsMap.firstRow; row < this._rowsMap.lastRow + 1; row++) {
        ei = this.xls.value(this._colsMap.edizm, row)
        if (ei && eil.indexOf(ei) < 0)
          eil.push(ei)
      }

      return eil
    },
}
