<template>
  <div>
    <div class="toolbar" id="toolbar">
      <dkc-tool-button
        title="Новая фирма"
        hint="Регистрация новой фирмы"
        size="big"
        iconclass="dbtn-create"
        action="/firm/create"
        v-if="grant"
      ></dkc-tool-button>
      <dkc-tool-button
        title="Изменить данные"
        hint="Изменить данные о фирме"
        size="big"
        iconclass="dbtn-edit"
        :action="editFirm"
        v-if="grant"
      ></dkc-tool-button>
      <dkc-tool-button
        title="Удалить фирму"
        hint="Удалить фирму из справочника"
        size="big"
        iconclass="dbtn-delete"
        :action="queryDeleteFirm"
        v-if="grant"
      >
      </dkc-tool-button>
    </div>

    <div ref="table"></div>

    <dkc-model-dlg
      :buttons="buttons"
      :actions="actions"
      v-show="isModalDlgVisible"
      @close="closeModalDlg"
    >
      <span slot="body">{{ bodyText }}</span>
      <span slot="header">{{ headerText }}</span>
    </dkc-model-dlg>
  </div>
</template>

<script>
import dkcModelDlg from "./components/dkcModalDlg";
import dkcToolButton from "./components/dkcToolButton";
import { TabulatorFull as Tabulator } from "tabulator-tables";

export default {
  data() {
    return {
      tabulator: null,
      isModalDlgVisible: false,
      headerText: "Подтверждение удаления",
      bodyText: "Ну я улетаю на большом воздушном шаре!",
      buttons: ["yes", "no", "ok", "close"],
      actions: {
        yes: () => {
          alert("Пардон, нафих блин!");
        },
      },
    };
  },
  watch: {
    tableData: {
      handler: (newData) => this.tabulator.replaceData(newData),
      deep: true,
    },
    rowPerPage: function () {
      this.tabulator.setPageSize(this.$store.state.firm.rowPerPage);
    },
  },
  computed: {
    selectURL: function() {
      return this.baseUrl + "/firm/select";
    },
    rowPerPage: function () {
      return this.$store.state.firm.rowPerPage;
    },
    grant: () => {
      return window.dkcapp.$store.state.user.idRole < 3;
    },
  },
  methods: {
    showNoGrant: function () {
      this.bodyText = "У вас нет прав на управление НСИ";
      this.buttons = ["ok"];
      this.actions = { ok: "" };
      this.showModalDlg();
    },
    editFirm: function () {
      if (!this.grant) {
        this.showNoGrant();
        return;
      }
      const idl = this.tabulator.getSelectedData();
      if (idl.length > 0) {
        const id = idl[0].idFirm;
        window.location = this.baseUrl + "/firm/update?id=" + id;
      }
    },
    queryDeleteFirm: function () {
      if (!this.grant) {
        this.showNoGrant();
        return;
      }
      const idl = this.tabulator.getSelectedData();
      switch (idl.length) {
        case 0:
          this.bodyText = "Выберите фирму, которую хотите удалить";
          this.buttons = ["ok"];
          break;
        case 1:
          this.bodyText =
            'Вы действительно хотите удалить фирму "' + idl[0].name + '"?';
          this.buttons = ["yes", "no"];
          break;
        default:
          this.bodyText =
            "Вы действительно хотите удалить из справочника " +
            idl.length +
            " фирм?";
          this.buttons = ["yes", "no"];
      }

      this.actions = { yes: this.deleteFirm };
      this.showModalDlg();
    },
    deleteFirm: function () {
      if (!this.grant) {
        this.showNoGrant();
        return;
      }
      const idl = this.tabulator.getSelectedData().map((firm) => firm.idFirm)
      if (!idl.length) {
        return
      }
      const tbl = this.tabulator;
      const fd = new FormData();
      fd.append("id", JSON.stringify(idl));
      this.queryAjax("/firm/delete", fd)
        .then(function (response) {
          tbl.clearData();
          tbl.replaceData();
        })
        .catch(function (error) {
          console.log("Ошибка удаления фирмы:", error.message);
        });
    },
    showModalDlg() {
      this.isModalDlgVisible = true;
    },
    closeModalDlg() {
      this.isModalDlgVisible = false;
    },
  },
  mounted() {
    window.dkcapp.title = 'Справочник "Фирмы производители"';
    const self = this
    this.tabulator = new Tabulator(this.$refs.table, {
      columns: [
        { title: "id фирмы", field: "idFirm", visible: false },
        {
          title: "Наименование",
          field: "name",
          widthGrow: 2,
          variableHeight: true,
          headerFilter: true,
          headerFilterPlaceholder: "поиск по наименованию",
        },
        {
          title: "Телефоны",
          field: "phone",
          widthGrow: 1,
          variableHeight: true,
          headerFilter: true,
          headerFilterPlaceholder: "поиск по телефону",
          formatter: function (cell, formatterParams) {
            let data = cell.getValue();
            if (!data) return "";
            let urls = data.split(";");
            let url = "";
            for (let i = 0; i < urls.length; i++) {
              url = urls[i];
              urls[i] = "<span>" + url + "</span><br>";
            }

            return urls.join("");
          },
        },
        {
          title: "Адрес e-mail",
          field: "email",
          widthGrow: 1,
          variableHeight: true,
          headerFilter: true,
          headerFilterPlaceholder: "поиск по адресу почты",
          formatter: function (cell, formatterParams) {
            let data = cell.getValue();
            if (!data) return "";
            let urls = data.split(";");
            let url = "";
            for (let i = 0; i < urls.length; i++) {
              url = urls[i];
              if (url.substr(0, 4).toLowerCase() != "mail") {
                url = "mailto://" + url;
              }
              urls[i] =
                '<a href="' + url + '" target="_blank">' + urls[i] + "</a><br>";
            }

            return urls.join("");
          },
        },
        {
          title: "Web-ресурсы",
          field: "url",
          widthGrow: 1,
          variableHeight: true,
          headerFilter: true,
          headerFilterPlaceholder: "поиск по адресу web-ресурса",
          formatter: function (cell, formatterParams) {
            let data = cell.getValue();
            if (!data) return "";
            let urls = data.split(";");
            let url = "";
            for (let i = 0; i < urls.length; i++) {
              url = urls[i];
              if (url.substr(0, 4).toLowerCase() != "http") {
                url = "http://" + url;
              }
              urls[i] =
                '<a href="' + url + '" target="_blank">' + urls[i] + "</a><br>";
            }

            return urls.join("");
          },
        },
        {
          title: "Примечание",
          field: "note",
          widthGrow: 4,
          variableHeight: true,
          formatter: "textarea",
          headerFilter: true,
          headerFilterPlaceholder: "поиск по примечанию",
        },
        {
          title: "Проверено",
          field: "sign",
          widthGrow: 1,
          formatter: "tickCross",
          hozAlign: "center",
        },
      ],
      placeholder: "Нет данных",
      layout: "fitColumns",
      /* variableHeight: true, */
      height: "80%",
      locale: "ru-RU",
      langs: {
        "ru-RU": {
          ajax: {
            loading: "загрузка",
            error: "ошибочка вышла",
          },
          pagination: {
            first: String.fromCharCode(57449),
            first_title: "первая страница",
            prev: String.fromCharCode(57457),
            prev_title: "предыдущая страница",
            next: String.fromCharCode(57461),
            next_title: "следущая страница",
            last: String.fromCharCode(57463),
            last_title: "последняя страница",
          },
        },
      },
      selectable: true,
      pagination: true,
      paginationMode: "remote",
      paginationSize: this.rowPerPage,
      ajaxURL: this.selectURL,
      ajaxContentType: "json",
      sortMode: "remote",
      filterMode: "remote",
      rowFormatter: function (row) {
        const data = row.getData();

        if (data.isUs == 1) {
          const el = row.getElement();
          el.style.color = "maroon";
          el.style.fontWeight = "bolder";
          el.style.textShadow = "0px 0px 16px yellow";
        }
      },
      footerElement: `<span><div class="paginator-limit"><span>На странице:</span>
            <select id="RowPerPage"
              onchange="const sc = document.getElementById('RowPerPage') ;
                        window.dkcapp.$store.state.firm.rowPerPage = sc.value ;
                       "
              >
              <option value="5">5</option>
              <option value="10" selected>10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            </div></span>`,
    });

    if (this.grant) {
      this.tabulator.on("rowDblClick", function (e, row) {
        const data = row.getData();
        const id = data.idFirm;
        if (id > 0) {
          window.location = self.baseUrl + "/firm/update?id=" + id;
        }
      }.bind(this));
    }
  },
  components: {
    dkcToolButton,
    dkcModelDlg,
  },
};
</script>

<style scoped>
</style>
