var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "search-container" }, [
    _c("label", [_vm._v("Найти:")]),
    _vm._v(" "),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.search_text,
          expression: "search_text",
        },
      ],
      staticStyle: { "margin-left": "8px" },
      attrs: {
        type: "search",
        id: "search-inp",
        placeholder: "Введите строку поиска",
      },
      domProps: { value: _vm.search_text },
      on: {
        keyup: _vm.doKeyUp,
        input: function ($event) {
          if ($event.target.composing) return
          _vm.search_text = $event.target.value
        },
      },
    }),
    _vm._v(" "),
    _vm.search_text
      ? _c(
          "button",
          {
            staticClass: "btn",
            staticStyle: {
              "background-color": "transparent",
              border: "0px solid transparent",
            },
            attrs: { type: "button" },
            on: { click: _vm.clearSearchText },
          },
          [_c("div", { staticClass: "close-btn" }, [_vm._v("+")])]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }