var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "component" }, [
    _c("div", { staticClass: "eit-container" }, [
      _c("div", { staticClass: "eit-header" }, [
        _c("div", { staticClass: "eit-file-selector" }, [
          _c(
            "div",
            {
              staticClass: "eit-file-title",
              attrs: {
                onclick: "document.getElementById('file-selecter').click()",
              },
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.file,
                      expression: "!file",
                    },
                  ],
                },
                [_vm._v("Импорт Excel-файла")]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.file,
                      expression: "file",
                    },
                  ],
                },
                [_vm._v(_vm._s(_vm.file.name))]
              ),
            ]
          ),
          _vm._v(" "),
          _c("input", {
            staticStyle: { display: "none" },
            attrs: { type: "file", name: "src", id: "src" },
            on: { change: _vm.checkSelectedFiles },
          }),
          _vm._v(" "),
          _c("label", { attrs: { for: "src", id: "file-selecter" } }, [
            _vm._v("\n                        выбери "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.file,
                    expression: "file",
                  },
                ],
              },
              [_vm._v("другой")]
            ),
            _vm._v(" файл\n                    "),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.workbook,
                  expression: "!workbook",
                },
              ],
              staticClass: "eit-drop-selector",
              attrs: {
                id: "drop-selector",
                onclick: "document.getElementById('file-selecter').click()",
              },
              on: {
                dragover: _vm.fileSelecterOver,
                dragleave: _vm.fileSelecterLeave,
                drop: _vm.fileSelecterDrop,
              },
            },
            [
              _vm._v("\n                        или перетащи сюда файл "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.workbook,
                      expression: "!workbook",
                    },
                  ],
                },
                [_vm._v("из проводника")]
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.workbook,
                expression: "workbook",
              },
            ],
            staticClass: "eit-controls",
          },
          [
            _c("div", { staticClass: "eit-ctlr-group" }, [
              _c("div", { staticClass: "ctrl-group sheetname" }, [
                _c(
                  "label",
                  { staticClass: "control-label", attrs: { for: "sheetname" } },
                  [_vm._v("Закладки в файле:")]
                ),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.sheetName,
                        expression: "sheetName",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { name: "sheetname", id: "sheetname" },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.sheetName = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  _vm._l(_vm.workbook.SheetNames, function (sn) {
                    return _c("option", {
                      key: sn,
                      attrs: { label: sn },
                      domProps: { value: sn },
                    })
                  }),
                  0
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "ctrl-group" }, [
                _c(
                  "label",
                  { staticClass: "control-label", attrs: { for: "dataRange" } },
                  [_vm._v("Диапазон с данными:")]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.dataRange,
                      expression: "dataRange",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    name: "dataRange",
                    id: "dataRange",
                    readonly: "",
                  },
                  domProps: { value: _vm.dataRange },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.dataRange = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "eit-ctlr-group" }, [
              _c("div", { staticClass: "ctrl-group" }, [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v("Диапазон строк с данными:"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "rownumbers" }, [
                  _c(
                    "label",
                    {
                      staticClass: "control-label row_num_label",
                      attrs: { for: "firstRow" },
                    },
                    [_vm._v("с:")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.firstRow,
                        expression: "firstRow",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "number",
                      name: "firstRow",
                      id: "firstRow",
                      min: _vm.rangeFR,
                      max: _vm.lastRow,
                      step: "1",
                    },
                    domProps: { value: _vm.firstRow },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.firstRow = $event.target.value
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "control-label row_num_label",
                      attrs: { for: "lastRow" },
                    },
                    [_vm._v("по:")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.lastRow,
                        expression: "lastRow",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "number",
                      name: "lastRow",
                      id: "lastRow",
                      min: _vm.firstRow,
                      max: _vm.rangeLR,
                      step: "1",
                    },
                    domProps: { value: _vm.lastRow },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.lastRow = $event.target.value
                      },
                    },
                  }),
                ]),
              ]),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "xlsdata" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.workbook,
                expression: "workbook",
              },
            ],
            staticClass: "labelgroup",
          },
          [
            _c("i", {
              staticClass: "glyphicon",
              class: [
                _vm.visibleXlsData
                  ? "glyphicon-triangle-bottom"
                  : "glyphicon-triangle-right",
              ],
              staticStyle: { "font-size": "large" },
              attrs: { "aria-hidden": "true" },
              on: { click: _vm.changeVisibleXlsData },
            }),
            _vm._v(" "),
            _c(
              "label",
              { staticClass: "control-label", attrs: { for: "xlsdata" } },
              [_vm._v('Содержимое файла "' + _vm._s(_vm.file.name) + '"')]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.visibleXlsData,
                    expression: "visibleXlsData",
                  },
                ],
                staticClass: "more_src",
                on: { click: _vm.switchMoreSrc },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.moresrc
                      ? "убрать исходные данные"
                      : "показать больше данных"
                  )
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "widetable-container" }, [
          _c("div", { staticClass: "widetable-wrap" }, [
            _c("div", {
              ref: "xlsdata",
              attrs: { id: "tblxlsdata", name: "xlsdata" },
            }),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "tunecol" }, [
        _c(
          "label",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.workbook,
                expression: "workbook",
              },
            ],
            staticClass: "control-label",
            attrs: { for: "tunecol" },
          },
          [_vm._v("Настройка колонок")]
        ),
        _vm._v(" "),
        _c(
          "label",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.workbook && _vm.firmColIsEmpty,
                expression: "workbook && firmColIsEmpty",
              },
            ],
            staticClass: "control-label",
            staticStyle: { "margin-left": "36px", color: "firebrick" },
            attrs: { for: "tunecol" },
          },
          [
            _vm._v(
              "При иимпорте завода-производителя повышается скорость и качество поиска номенклатуры заказчика"
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "widetable-container" }, [
          _c(
            "div",
            { staticClass: "widetable-wrap" },
            [
              _c("dkc-grid", {
                ref: "tunecol",
                attrs: {
                  name: "tunecol",
                  id: "tunecol",
                  tableid: "tbltunecol",
                },
                on: { after_celledited: _vm.loadResultExemple },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.workbook && !_vm.moresrc,
              expression: "workbook && !moresrc",
            },
          ],
          staticClass: "resex",
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.workbook,
                  expression: "workbook",
                },
              ],
              staticClass: "labelgroup",
            },
            [
              _c("i", {
                staticClass: "glyphicon",
                class: [
                  _vm.visibleResEx
                    ? "glyphicon-triangle-bottom"
                    : "glyphicon-triangle-right",
                ],
                staticStyle: { "font-size": "large" },
                attrs: { "aria-hidden": "true" },
                on: { click: _vm.changeVisibleResEx },
              }),
              _vm._v(" "),
              _c(
                "label",
                { staticClass: "control-label", attrs: { for: "resex" } },
                [_vm._v("Образец заполненной спецификации")]
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "widetable-container" }, [
            _c("div", { staticClass: "widetable-wrap" }, [
              _c("div", {
                ref: "resex",
                attrs: { id: "tblresex", name: "resex" },
              }),
            ]),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }