<template>
    <div class="component">
<!--        <a href="https://github.com/sheetjs/js-xlsx">https://github.com/sheetjs/js-xlsx</a> -->



        <div class="eit-container">
            <div class="eit-header">
                <div class="eit-file-selector">
                    <div class="eit-file-title" onclick="document.getElementById('file-selecter').click()">
                        <span v-show="!file">Импорт Excel-файла</span>
                        <span v-show="file">{{file.name}}</span>
                    </div>
                    <input type="file" name="src" id="src" style="display: none" @change="checkSelectedFiles">
                    <label for="src" id="file-selecter">
                        выбери <span v-show="file">другой</span> файл
                    </label>
                    <div class="eit-drop-selector" id="drop-selector"
                         onclick="document.getElementById('file-selecter').click()"
                         v-show="!workbook"
                         v-on:dragover="fileSelecterOver"
                         v-on:dragleave="fileSelecterLeave"
                         v-on:drop="fileSelecterDrop"
                    >
                        или перетащи сюда файл <span v-show="!workbook">из проводника</span>
                    </div>
                </div>

                <div class="eit-controls" v-show="workbook">
                    <div class="eit-ctlr-group">
                        <div class="ctrl-group sheetname">
                            <label for="sheetname" class="control-label">Закладки в файле:</label>
                            <select name="sheetname" id="sheetname" v-model="sheetName" class="form-control">
                                <option v-for="sn in workbook.SheetNames"
                                        :label="sn"
                                        :value="sn"
                                        :key="sn"
                                ></option>
                            </select>
                        </div>

                        <div class="ctrl-group">
                            <label for="dataRange" class="control-label">Диапазон с данными:</label>
                            <input type="text" name="dataRange" id="dataRange" v-model="dataRange" readonly class="form-control">
                        </div>


                    </div>

                    <div class="eit-ctlr-group">
                      <div class="ctrl-group">
                            <label class="control-label">Диапазон строк с данными:</label>
                            <div class="rownumbers">
                              <label for="firstRow" class="control-label row_num_label">с:</label>
                              <input type="number" class="form-control" name="firstRow" id="firstRow"
                                    v-model="firstRow"
                                    :min="rangeFR"
                                    :max="lastRow"
                                    step="1" >

                              <label for="lastRow" class="control-label row_num_label">по:</label>
                              <input type="number" class="form-control" name="lastRow" id="lastRow"
                                    v-model="lastRow"
                                    :min="firstRow"
                                    :max="rangeLR"
                                    step="1" >
                            </div>
                      </div>
                    </div>

                </div>
            </div>

            <div class="xlsdata">
                <div class="labelgroup"  v-show="workbook">
                  <i  class="glyphicon" :class="[visibleXlsData ? 'glyphicon-triangle-bottom' : 'glyphicon-triangle-right']"
                      style="font-size: large;"
                      aria-hidden="true"
                      @click="changeVisibleXlsData"
                            />
                  <label for="xlsdata" class="control-label">Содержимое файла "{{file.name}}"</label>
                  <div class="more_src" v-show="visibleXlsData" @click="switchMoreSrc">{{moresrc ? 'убрать исходные данные' : 'показать больше данных'}}</div>
                </div>
                <div class="widetable-container">
                    <div class="widetable-wrap">
                        <div ref="xlsdata" id="tblxlsdata" name="xlsdata"></div>
                    </div>
                </div>
            </div>

            <div class="tunecol">
                <label for="tunecol" class="control-label" v-show="workbook">Настройка колонок</label>
                <label for="tunecol" class="control-label" v-show="workbook && firmColIsEmpty" style="margin-left: 36px; color: firebrick">При иимпорте завода-производителя повышается скорость и качество поиска номенклатуры заказчика</label>
                <div class="widetable-container">
                    <div class="widetable-wrap">
                        <dkc-grid ref="tunecol" name="tunecol" id="tunecol" tableid="tbltunecol"
                                @after_celledited="loadResultExemple"
                        ></dkc-grid>
                    </div>
                </div>
            </div>
            <div class="resex" v-show="workbook && !moresrc">
              <div class="labelgroup"  v-show="workbook">
                  <i  class="glyphicon" :class="[visibleResEx ? 'glyphicon-triangle-bottom' : 'glyphicon-triangle-right']"
                        style="font-size: large;"
                        aria-hidden="true"
                        @click="changeVisibleResEx"
                              />
                  <label for="resex" class="control-label">Образец заполненной спецификации</label>
              </div>
              <div class="widetable-container">
                    <div class="widetable-wrap">
                        <div ref="resex" id="tblresex" name="resex"></div>
                    </div>
                </div>    
            </div>
        </div>
    </div>
</template>

<script>
    import XLSX from 'xlsx'
    import DkcGrid from './dkcGrid'
    import DkcToolButton from './dkcToolButton'
    import { TabulatorFull as Tabulator } from 'tabulator-tables'

    export default {
        name: "ExcelImportTuner",
        components: {DkcGrid, DkcToolButton},
        props: ["fieldDefs", "colTitles"],
        data() {
            return {
                file: false,
                reader: false,
                workbook: false,
                sheet: false,
                sheetName: '',
                firstRow: 0,
                lastRow: 0,
                rangeFR: 0,
                rangeLR: 0,
                xlsdata: null,
                visibleXlsData: true,
                xlsdataHeight: 130,
                visibleTuneCol: true,
                resex: null,
                visibleResEx: true,
                resexHeight: 165,
                moresrc: false,
            }
        },
        computed: {
            rangeS: function() {
                if (!this.sheet) {
                  return {s: {r:0, c:0}, e: {r:0, c:0}}
                }

                const range = XLSX.utils.decode_range(this.sheet["!ref"])
                const lastR = 50
                let col = ''
                let maxcol = range.s.c

                for (let r = +range.s.r + 1; r < lastR; r++) {
                    for (let i = maxcol; i <= range.e.c; i++) {
                        col = XLSX.utils.encode_col(i)
                        if (this.cellval(col, r))
                          maxcol = Math.max(maxcol, i)
                    }
                }

                range.e.c = maxcol
                return range
            },
            dataRange: function() {
                if (!this.sheet) {
                  return '-'
                }

                const range = this.rangeS
                return XLSX.utils.encode_col(range.s.c) + range.s.r + ':' + XLSX.utils.encode_col(range.e.c) + range.e.r
            },
            tunecol: function() {
                return this.$refs.tunecol
            },
            firmColIsEmpty: function() {
                const data = this.tunecol.tabulator.getData() 
                return data.length && !data[0].c_idFirm
            },
            excelData: function() {
                const map = []
                // Получаем имена колонок, которые ввел пользователь в окне настроек импорта
                const ctdata = this.tunecol.tabulator.getData()[0]
                // Получаем список введенных колонок. Пустые и первичный ключ в массив map не попадают
                let ct = false
                let col = false
                for (ct in ctdata) {
                    col = ctdata[ct]
                    if (ct != 'innerID' && col != undefined && col != '')
                        map.push({field: ct, column: col})
                }

                // с первой по последнюю строку считываем колонки, указанные в массиве map
                // (только указанные пользователем данные)
                const data = []
                let item
                for (let r = this.firstRow; r < (+this.lastRow) + 1; r++) {
                    item = {}
                    for (let m = 0; m < map.length; m++) {
                        item[map[m].field] = this.cellval(map[m].column, r)
                    }

                    if (Object.values(item).some(itm => !!itm)) {
                      data.push(item)
                    }
                }

                return data
            },
        },
        watch: {
            file: function() {
                if (!this.file)
                    return
                this.reader = new FileReader()
                this.reader.onload = this.loadFile
                this.reader.readAsBinaryString(this.file)
            },
            workbook: function (newWB, oldWB) {
              this.$emit('loaded', newWB)
            },
            sheetName: function() {
                if (!this.sheetName) {
                    this.sheet = false
                    return
                }

                this.sheet = this.workbook.Sheets[this.sheetName]

                const range = this.rangeS  //XLSX.utils.decode_range(this.sheet["!ref"])
                this.rangeFR  = XLSX.utils.encode_row(range.s.r)
                this.rangeLR  = XLSX.utils.encode_row(range.e.r)
                this.firstRow = this.findFirstDataRow() // +XLSX.utils.encode_row(range.s.r) + 1
                this.lastRow  = +XLSX.utils.encode_row(range.e.r) - 1

                const el = document.getElementById('drop-selector')
                el.style.paddingTop = '8px'
                el.style.paddingBottom = '8px'

                this.createXLSData()
                this.createTuneCol()
                setTimeout(this.createResultExemple, 500)
            },
            firstRow: function (newRow, oldRow) {
              if (this.xlsdata) {
                // Грузим исходные данные
                this.createXLSData()
              }

              if (this.resex) {
                // Грузи результат
                this.reloadResultExemple(newRow)
              }
            },
            visibleXlsData: function(newV, oldV) {
                this.changeVisibleTable(this.xlsdata, this.visibleXlsData, this.getCurrentXLSDataHeight(this.moresrc, newV))
            },
            visibleResEx: function() {
                this.changeVisibleTable(this.resex, this.visibleResEx, this.resexHeight)
            },
            moresrc: function ( newMS, oldMS ) {
              this.changeVisibleTable(this.xlsdata, this.visibleXlsData, this.getCurrentXLSDataHeight(newMS))
            },
        },
        methods: {
            getCurrentXLSDataHeight: function (moresrc, visiblexls) {
                if (moresrc == undefined) {
                    moresrc = this.moresrc
                }

                if (visiblexls == undefined) {
                    visiblexls = this.visibleXlsData
                }
                return visiblexls ? (moresrc ? 3.4 * this.xlsdataHeight : this.xlsdataHeight) : 0
            },
            cloneColumnDefs: function(colDefs) {
                const cmp = this
                const cloneDefs = []
                colDefs.forEach(colDef =>{
                    const cloneDef = {title: colDef.title}
                    if ('columns' in colDef) {
                        cloneDef.columns = cmp.cloneColumnDefs(colDef.columns)
                    }
                    else {
                        cloneDef.field = colDef.field
                        cloneDef.visible = colDef.visible
                        if ('align' in colDef) {
                            cloneDef.align = colDef.align
                        }
                        if ('width' in colDef) {
                            cloneDef.width = colDef.width
                        }
                        if ('widthGrow' in colDef) {
                            cloneDef.widthGrow = colDef.widthGrow
                        }
                    }

                    cloneDefs.push(cloneDef)
                })

                return cloneDefs
            },
            tunecolMutator: function(value) {
                if (!value || typeof value != 'string')
                                                        return value
                const lc = 'QWERTYUIOPASDFGHJKLZXCVBNM'
                const cc = 'ЙЦУКЕНГШЩЗФЫВАПРОЛДЯЧСМИТЬ'
                let col = value.toUpperCase()
                const ind = cc.indexOf(col)
                if (ind < 0)
                    return col

                return lc.charAt(ind)
            },
            cellval: function(col, row) {
                const cell = this.sheet[col + row]
                if (cell) {
                    return cell.v
                }

                return ''
            },
            changeVisibleTable: function(table, visible, height) {
                table.setHeight( visible ? height : 0)
                return


                let h = 0
                let v = 0
                if (visible) {
                    h = height + 'px'
                    v = 1
                }

                const el = document.getElementById(table)
                el.style.height  = h
                el.style.opacity = v
            },
            changeVisibleResEx: function() {
                this.visibleResEx = !this.visibleResEx
            },
            changeVisibleXlsData: function() {
                this.visibleXlsData = !this.visibleXlsData
                if (!this.visibleXlsData) {
                  this.moresrc = false
                }
            },
            switchMoreSrc: function () {
              this.moresrc = !this.moresrc
            },
            loadResultExemple: function(cellInfo, firstRow) {
                if (this.resex == null)
                  return

                const tbl = this.resex
                const rowcount = tbl.getRows().length
                const firstrow = firstRow || +this.firstRow
                const lastrow  = +firstrow + rowcount
                const rows = tbl.getRows()
                let data = false
                let row = false
                let rowindex = firstrow
                for (let r = firstrow; r < lastrow; r++) {
                    rowindex = r - firstrow
                    row = rows[rowindex]
                    data = row.getData()
                    data[cellInfo.field] = this.cellval(cellInfo.value, r)
                    row.update(data)
                }
            },
            reloadResultExemple: function (newRow) {
                const self = this
                const loadproc = this.loadResultExemple
                const tbl = this.tunecol
                const row = tbl.currentRow ;
                const cells = row.getCells()
                cells.forEach( cell => {
                  const value = cell.getValue()
                  if (!value)
                    return

                  const field = cell.getField()
                  loadproc({ field, value, cell, row }, newRow)
                })
            },
            createResultExemple: function() {
                if (this.resex != null)
                    return

                const self = this
                const options = {
                    columns: this.cloneColumnDefs(this.fieldDefs),
                    height: this.resexHeight,
                    debugInvalidComponentFuncs: false
                }
                this.resex = new Tabulator(this.$refs.resex, options)
                this.resex.on('tableBuilt', function() {
                  for (let i = 0; i < 5; i++) {
                      self.resex.addRow()
                  }

                  const el = document.getElementById('tblresex')
                  if (el)
                      el.style.transition = 'all 0.5s ease-in-out' ;

                  setTimeout(self.loadTuneCol(), 10000) 
                })  
            },
            loadTuneCol: function() {
                if (!this.colTitles)
                    return

                const cmp = this
                const range = this.rangeS // XLSX.utils.decode_range(this.sheet["!ref"])

                this.colTitles.forEach(colTitle => {
                    const firstRow = range.s.r
                    const lastRow = Math.min(firstRow + 5, range.e.r) + 1
                    const firstCol = range.s.c
                    const lastCol = range.e.c + 1

                    function norm(text) {
                        return ('' + text).toLowerCase().replace('-', '').replace('.', '').replace(',', '').replace(' ', '')
                    }

                    const row = cmp.tunecol.tabulator.getRows()[0]
                    let cell
                    let fieldIndex = 0
                    let cellVal
                    let title

                    for (let r = firstRow; r < lastRow; r++) {
                        for (let c = firstCol; c < lastCol; c++) {
                            cellVal = norm(cmp.cellval(XLSX.utils.encode_col(c), XLSX.utils.encode_row(r)))
                            for (let t = 0; t < colTitle.titles.length; t++) {
                                title = norm(colTitle.titles[t])
                                if (cellVal.indexOf(title) > -1) {
                                    cell = row.getCell(colTitle.fields[fieldIndex])
                                    if (cell) {
                                        cell.setValue(XLSX.utils.encode_col(c))
                                        t = colTitle.titles.length
                                    }

                                    fieldIndex++
                                    if (fieldIndex > colTitle.fields.length - 1) {
                                        return
                                    }
                                }
                            }
                        }
                    }
                })

            },
            createTuneCol: function() {
                const self = this
                const lc = 'QWERTYUIOPASDFGHJKLZXCVBNM'
                const cc = 'ЙЦУКЕНГШЩЗФЫВАПРОЛДЯЧСМИТЬ'
                const range = this.rangeS // XLSX.utils.decode_range(this.sheet["!ref"])
                const xlscols = []
                let col = ''
                let ind
                for (let i = range.s.c; i <= range.e.c; i++) {
                    col = XLSX.utils.encode_col(i)
                    xlscols.push(col)
                    xlscols.push(col.toLowerCase())

                    ind = lc.indexOf(col)
                    if (ind > -1) {
                        col = cc.charAt(ind)
                        xlscols.push(col)
                        xlscols.push(col.toLowerCase())
                    }
                }
                const colvalidator = 'in:' + xlscols.join('|')

                const options = {columns: this.fieldDefs, debugInvalidComponentFuncs: false}
                this.tunecol.createGrid(options, {

                  'tableBuilt': function () {
                    const coldefs = self.tunecol.tabulator.getColumnDefinitions()
                    function addEditor(collist, component) {
                        collist.forEach(coldef => {
                            if ('columns' in coldef) {
                                addEditor(coldef.columns, component)
                            }
                            else {
                                coldef.editor = 'input'
                                coldef.validator = colvalidator
                                coldef.mutator = component.tunecolMutator
                            }

                        })
                    }
                    addEditor(coldefs, self)

                    self.tunecol.tabulator.setColumns(coldefs)
                    self.tunecol.tabulator.addRow()
                  }

                })


            },
            loadXLSData: function(startRow) {
                const range = this.rangeS
                const firstR = startRow ? Math.max(startRow - 10, 0) : +range.s.r
                const lastR = Math.min(firstR + 100, +range.e.r)
                const data = []
                let col = ''
                let row = false
                let adr = ''
                let cell = false

                for (let r = +range.s.r + 1; r < lastR; r++) {
                    row = {rownum: r}
                    for (let i = range.s.c; i <= range.e.c; i++) {
                        col = XLSX.utils.encode_col(i)
                        row[col] = this.cellval(col, r)
                    }
                    data.push(row)
                }

                return data
            },
            createXLSData: function() {
                if (this.xlsdata) { return }
                const range = this.rangeS
                const columns = [{title: '', field: 'rownum', visible: true, hozAlign: 'center'}] ;
                let fld = ''
                for (let i = range.s.c; i <= range.e.c; i++) {
                    fld = XLSX.utils.encode_col(i)
                    columns.push({title: fld, field: fld, visible: true})
                }
                const options = {
                    columns,
                    height: this.xlsdataHeight,
                    /* data: this.loadXLSData(this.startRow), */
                    /* debugInvalidComponentFuncs: false, */
                    layout: 'fitData',
                }
                this.xlsdata = new Tabulator(this.$refs.xlsdata, options)
                const data = this.loadXLSData(this.startRow)
                const grid = this.xlsdata
                this.xlsdata.on('tableBuilt', function () { grid.setData(data)})

                /* const el = document.getElementById('tblxlsdata')
                if (el)
                    el.style.transition = 'all 0.5s ease-in-out' ; */
            },
            fileSelecterOver: function(e) {
                e.stopPropagation()
                e.preventDefault()

                const el = document.getElementById('drop-selector')
                el.classList.add('hover')
            },
            fileSelecterLeave: function(e) {
                e.stopPropagation()
                e.preventDefault()

                const el = document.getElementById('drop-selector')
                el.classList.remove('hover')
            },
            fileSelecterDrop: function(e) {
                this.fileSelecterLeave(e)
                this.findExcelInFileList(e.dataTransfer.files)
            },
            checkSelectedFiles: function (e) {
                this.findExcelInFileList(event.currentTarget.files)
            },
            findExcelInFileList: function (files) {
                for (let i = 0; i < files.length; i++)
                    if (this.fileIsExcel(files[i])) {
                        this.file = files[i]
                        return
                    }
            },
            fileIsExcel: function (file) {
                const fileType = file.type.toLowerCase()
                const ind = [   'application/vnd.ms-excel',
                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].indexOf(fileType)
                return  ind >=0
            },
            loadFile: function() {
                this.workbook = XLSX.read(this.reader.result, {type : 'binary'});
                this.sheetName = this.workbook.SheetNames[0]
            },
            findHeaderRow: function () {
                const range = this.rangeS
                const headers = ['НАИМЕНОВАНИЕ', 'КОД', 'АРТИКУЛ', 'ТИП', 'КОЛ-ВО', 'КОЛ.', 'КОЛИЧЕСТВО', 'ЕД. ИЗМ.']
                let value = false
                for (let r = range.s.r; r < range.s.r + 50; r++) {
                    for (let c = range.s.c; c <= range.e.c; c++) {
                        value = this.cellval(XLSX.utils.encode_col(c), r)
                        if (value && headers.includes(('' + value).toLocaleUpperCase()))
                            return r
                    }
                }

                return range.s.r
            },
            /**
             *  Ищет строку с заголовком, в потом в ближайших 50  строках строку с значенеим в ячейке более 5 символов
             */
            findFirstDataRow: function () {
                const range = this.rangeS
                const firstrow = this.findHeaderRow() + 1
                let value = false
                for (let r = firstrow + 1; r < firstrow + 50; r++) {
                    for (let c = range.s.c; c <= range.e.c; c++) {
                        value = this.cellval(XLSX.utils.encode_col(c), r)
                        if (value && (''+value).length > 5) {
                            return r
                        }
                    }
                }

                return firstrow
            },
        },
        mounted() {
            this.rangeLR = 0
        },
    }
</script>

<style scoped>

.widetable-container {
    width: 100%;
    overflow-x: auto;
}

.widetable-wrap {
    display: inline-block;
}

.eit-container {
    padding: 0 8px;
    /*background-color: #c4e3f3; */
}

.eit-header {
    display: flex;
}

.eit-controls {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 24px;
}

.eit-ctlr-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* width: 470px; */
}

.eit-file-selector {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 250px;
    padding: 8px 16px;
    border: 3px ridge gray;
    border-radius: 8px;
    box-shadow: 5px 5px 5px darkgray;
    /*background-color: papayawhip;*/
}

.eit-file-title {
    font-size: 20px;
    font-weight: bolder;
    cursor: pointer;
}

#file-selecter {
    font-size: 16px;
    font-weight: normal;
    color: blue;
    cursor: pointer;
}

#file-selecter.hover {
    background-color: #afd9ee;
    font-weight: lighter;
}

.eit-drop-selector {
    padding: 18px 8px;
    background-color: #afd9ee;
    border: 1px solid gray;
    border-radius: 6px;
}

.eit-drop-selector.hover {
    background-color: #5fa2db;
    font-weight: bolder;
}

.ctrl-group {
    margin-right: 16px;
    width: 215px;
}

.sheetname {
    /*width: 250px;*/
}

.labelgroup {
    display: flex;
    align-items: flex-start;
    color: navy;
}

.hiden_grid {
    display: none;
}

.rownumbers {
  display: flex;
  align-items: baseline;
}

.row_num_label {
  margin: 0 4px;
}

.xlsdata {
  margin-top: 8px;
}

.more_src {
  margin-left: 36px;
  font-size: small ;
  color: dodgerblue;
  border-bottom: 1px dotted dodgerblue;
  cursor: pointer;
}

@media (max-width: 1366px) {

  .eit-file-title {
    font-size: 16px;
  }

}


</style>
