/**
 * Расчет сумм и обновление их в таблице.
 * @param cellInfo - объект содержащий информацию об изменяемой ячейке
 *                  - field - имя поля
 *                  - value - новое значение
 *                  - cell  - объект ячейки
 *                  - row   - объект строки
 */
export default function moneycalc(cellInfo) {
  // получаем группу строк для редактируемой ячейки
  const group = cellInfo.row ? cellInfo.row.getGroup() : cellInfo.cell.getRow().getGroup()
  // для каждой строки группы пересчитываем сумму и заносим её в строку
  group.getRows().forEach(row => {
    moneycalcRow(row)
  })

  function checkParam(prm) {
    return +prm || 0
  }

  function calcSumma(quant, price) {
    return Math.round(100 * checkParam(quant) * checkParam(price))/100
  }

  function moneycalcRow(row) {
    const rd = row.getData()
    if (rd.c_summa != calcSumma(rd.c_quant, rd.c_price) || rd.d_summa != calcSumma(rd.d_quant, rd.d_price)) {
      rd.c_summa = calcSumma(rd.c_quant, rd.c_price)
      rd.d_summa = calcSumma(rd.d_quant, rd.d_price)

      row.update(rd)
    }
  }
}
