var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    {
      staticClass: "tab",
      class: { active_tab: _vm.isActiveTab },
      attrs: { index: _vm.index },
      on: { click: _vm.doOnClick },
    },
    [_vm._v(_vm._s(_vm.title))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }