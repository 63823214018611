<template>
    <div>
        <div :id="tblid" ref="table" class="spec-table" @keydown="tableKeyDown"></div>
    </div>
</template>

<script>
    import { TabulatorFull as Tabulator } from 'tabulator-tables'
    import autocompleteAJAX from '../units/autocompleteAJAX'
    import RowFormatter from '../units/RowFormatter'
    import SequenceID from '../units/SequenceID'

    export default {
        name: "dkcGrid",
        props: ['tableid'],
        data() {
            return {
                tabulator: null,
                tableData: [],
                state: 'empty',
                sequenceID: false,
                actCol: 0,
                actRow: 0,
                fromRow: 0,
                rowFrmObj: false,
                focusRowColor: 'lemonchiffon',
                focusCellColor: 'LightBlue',
                focusBorderColor: 'darkblue',

                keyData: 0,
                logs: [],
            }
        },
        computed: {
            focusedEl: function() {
                return document.activeElement ? document.activeElement.nodeName : 'никого'
            },
            visibleCols: function() {
                return this.tabulator.getColumns().filter( col => col.isVisible())
            },
            colCount: function() {
                return this.visibleCols.length
            },
            rowCount: function() {
                const rows = this.tabulator.getRows()
                return rows.length
            },
            currentRow: function() {            
                return this.actRow
                /* if (!this.tabulator) return false
                const rows = this.tabulator.getRows()
                return rows[this.actRow] */
            },
            currentCell: function() {
                return this.actCol 
                /* const col = this.visibleCols[this.actCol]
                const row = this.currentRow
                if (!row)
                  return false
                return row.getCell(col) */
            },
            tblid: function() {
                return this.tableid ? this.tableid : 'dkctbl'
            },
        },
        watch: {
            tableData: function(newData) {
                this.tabulator.replaceData(newData)
            },
            actCol: function() {
                const cell = this.currentCell
                if (cell) {
                  const field = cell.getField()
                  const el = cell.getElement()
                  const box = el.getBoundingClientRect()

                  let X = pageXOffset
                  let Y = pageYOffset

                  if (box.left < pageXOffset)
                      X = Math.max(0, box.left - 24)
                  if (document.documentElement.clientWidth + pageXOffset < box.right)
                      X = box.right + 24
                  if (box.bottom > document.documentElement.clientHeight + pageYOffset)
                      Y = box.bottom + 24
                  if (box.top < pageXOffset)
                      Y = Math.max(0, box.top - 24)

                  if (X != pageXOffset || Y != pageYOffset)
                      window.scrollTo(X, Y)
                }

                this.rowFormatter(this.currentRow)
                this.currentCellChanged()
            },
            actRow: function(newRow, oldRow) {
                if (oldRow) {
                    this.tabulator.deselectRow(oldRow)
                }
                const row = newRow
                //row.scrollTo() 
                this.rowFormatter(oldRow)                        
                this.rowFormatter(newRow)                        
                this.fromRow = oldRow
                this.currentCellChanged()
                /* const rows = this.tabulator.getRows()
                if (!rows.length) {
                    return
                }
                const row = rows[this.actRow] */
                /* if (rows.length > 1 && row) {                
                    row.scrollTo() 
                } */
                /* if (this.fromRow < rows.length)
                    this.rowFormatter(rows[this.fromRow])
                if (this.actRow < rows.length)
                    this.rowFormatter(rows[this.actRow]) */
            },
        },
        methods: {
            focusedElement() {
                return document.activeElement.nodeName
            },
            editCell() {
                const cell = this.currentCell
                if (this.isEditableCell(cell))
                    cell.edit()
            },
            getData: function() {
                return this.tabulator.getData()
            },
            rowFormatter: function(row){
                //row - row component
                if (!row)
                  return

                if (this.outerRowFormatter) {
                    if (this.outerRowFormatter(row))
                        return
                }

                this.rowFrmObj.execute({row, 'actRow': this.actRow, 'actCol': this.actCol})
            },
            innerIDMutator: function(value, data, type, params, component){
                if (!value)
                  value = this.sequenceID.nextval() // ++this.innerID
                return value
            },
            getRowByIndex: function (rowIndex){
                const rows = this.tabulator.getRows()                 
                return rows.length > rowIndex ? rows[rowIndex] : false
            },  
            getRowByInnerID: function(innerID) {
                const rows = this.tabulator.getRows() 
                return rows.find( row => row.getData().innerID == innerID)
            },
            isEditableCell: function (cell) {
               if (!cell)
                  return false

               const column = cell.getColumn()
               const coldef = column.getDefinition()
               return column.isVisible() && coldef.editor != undefined
            },
            getCellCoordinates: function(cell) {
                return {cell, row: cell?.getRow()}
                const coord = {row: -1, col: -1}
                const field = cell.getField()
                const row = cell.getRow()
                coord.row = row.getIndex() //this.tabulator.getRowPosition(row)
                this.visibleCols.some((col, index) => {
                    const colfield = col.getField()
                    if (colfield == field) {
                        coord.col = index
                        return true
                    }

                    return false
                })

                return coord
            },
            setCellCoordinates: function(cell) {
                const coord = this.getCellCoordinates(cell)
                if (this.actRow != coord.row) {
                    this.actRow = coord.row
                }

                if (this.actCol != coord.cell) {
                    this.actCol = coord.cell
                }
            },
            currentCellChanged: function() {
                const cell = this.currentCell
                if (cell) {
                  const el = cell.getElement()
                  el.onpaste = this.cellPasted
                }
            },
            cellPasted: function(event) {
                this.$emit('cell_pasted', {cell: this.currentCell, event: event})
            },
            setTableFocus: function() {
                const div = this.tabulator.element
                const tbl = div?.querySelector('.tabulator-tableholder')
                tbl?.focus()
                /* const tbl = document.getElementsByClassName('tabulator-tableholder')
                if (tbl.length > 0) {
                    tbl[0].focus()
                } */
            },
            getFieldValue: function(fieldName, rowIndex=false) {
                /* if (rowIndex === false) {
                    rowIndex = this.actRow
                } */
                const row = rowIndex === false ? this.actRow : this.getRowByIndex(rowIndex)
                if (!row)
                  return
                const data = row.getData()
                if (fieldName in data)
                    return data[fieldName]

                return false
            },
            setFieldValue: function(fieldName, fieldValue, rowIndex=false) {
                const row = rowIndex === false ? this.actRow : this.getRowByIndex(rowIndex)
                if (!row)
                  return

                const cell = row.getCell(fieldName)  
                if (cell) {
                    cell.setValue(fieldValue)
                }
                else {
                    const data = row.getData()
                    data[fieldName] = fieldValue 
                    row.update(data)
                }
                this.$emit('after_celledited', {field: fieldName, value: fieldValue, cell, row})    

                /* if (rowIndex === false) {
                    rowIndex = this.actRow
                }
                const row = this.getRowByIndex(rowIndex)
                const cell = row.getCell(fieldName)
                if (cell) {
                    cell.setValue(fieldValue)
                }
                else {
                    const data      = {}
                    data[fieldName] = fieldValue
                    row.update(data)
                    this.$emit('after_celledited', {field: fieldName, value: fieldValue, cell, row})
                } */
            },
            cellEditing: function (cell) {
                // console.log('cellEditing:\n' + cell.getValue())
                this.state = 'edit'
                this.setCellCoordinates(cell)
            },
            cellEditCancelled: function (cell) {
                if (this.outerCellEditCancelled) {
                    this.outerCellEditCancelled(cell)
                }

                this.state = 'browsing'
                this.setTableFocus()
                /* const div = this.tabulator.getElement()
                const tbl = div?.querySelector('.tabulator-tableholder')
                tbl?.focus() */
                /* const tbl = document.getElementsByClassName('tabulator-tableholder')
                tbl[0].focus() */
            },
            cellEdited: function (cell) {
                // console.log('cellEdited:\n' + cell.getValue())
                const field = cell.getField()
                const value = cell.getValue()

                this.state = 'browsing'
                this.setTableFocus()
                this.$emit('after_celledited',  { field, value, cell, row: cell.getRow() })
            },
            dataLoading: function(data) {
                this.state = 'loading'
            },
            dataLoaded: function (data) {
                if (!data || data.length === 0) {
                    this.state = 'empty'
                }
                else {
                    //this.state = 'browsing'
                    //this.editCell()
                }
            },
            rowClick: function(event, row) {
                if (this.actRow != row) {
                    this.actRow = row
                }
                /* const rowIndex = row.getIndex() 
                if (this.actRow != rowIndex)
                        this.actRow = rowIndex */
            },
            cellClick: function (event, cell) {
                this.setCellCoordinates(cell)
            },
            nextCol: function() {
                const cols = this.visibleCols
                const index = cols.indexOf(this.actCol)
                this.actCol = cols[index < 0 || index == cols.length - 1 ? 0 : index + 1]
                /* const colCount = this.colCount - 1
                if (this.actCol < colCount) {
                    this.actCol++
                } */
            },
            prevCol: function() {
                const cols = this.visibleCols
                const index = cols.indexOf(this.actCol)
                this.actCol = cols[index < 1 ? 0 : index - 1]
                /* if (this.actCol > 0) {
                    this.actCol--
                } */
            },
            prevRow: function() {
                const row = this.currentRow
                this.actRow = this.currentRow.getPrevRow() || row

                /* const crow = this.currentRow
                if (crow) {
                    const row = crow.getPrevRow()
                    const rowIndex = row.getIndex()
                    if (this.actRow != rowIndex) {
                        this.actRow = rowIndex
                    }
                }             */
            },
            nextRow: function() {
                const row = this.currentRow
                this.actRow = this.currentRow.getNextRow() || row
                /* const crow = this.currentRow
                if (crow) {
                    const row = crow.getNextRow()
                    const rowIndex = row.getIndex()
                    if (this.actRow != rowIndex) {
                        this.actRow = rowIndex
                    }
                }    */
            },
            pressEnter: function(event) {
                event.preventDefault()
                if (document.activeElement.nodeName == 'body') {
                    this.setTableFocus()
                }

                this.nextCol()            
            },
            pressLeftArrow: function(event) {
                if (this.state == 'browsing') {
                    this.prevCol()
                    event.preventDefault()
                }
            },
            pressRightArrow: function(event) {
                if (this.state == 'browsing') {
                    this.nextCol()
                    event.preventDefault()
                }
            },
            pressUpArrow: function (event) {                
                this.prevRow()
                event.preventDefault()
            },
            pressDownArrow: function (event) {
                this.nextRow()
                event.preventDefault()
            },
            pressF2: function (event) {
                const cell = this.currentCell
                const el   = cell.getElement()
                el.focus()
            },
            pressInsert: function(event) {
                const insertData = {}
                this.$emit('get_insertdata', insertData)
                const cmp = this
                this.tabulator.addRow(insertData)
                    .then(function(row){
                        cmp.$emit('after_insert', row)
                        const cells = row.getCells()
                        const cell = cells.find(c => {
                            const col = c.getColumn()
                            const coldef = column.getDefinition()
                            return col.isVisible && coldef.editor != 'undefined' 
                        })

                        if (cell) {
                            cmp.setCellCoordinates(cell)
                            cmp.setTableFocus()
                        }

                        /* const cells = row.getCells()
                        let firstCell = false
                        cells.forEach(function(cell) {
                            const column = cell.getColumn()
                            const coldef = column.getDefinition()
                            if (typeof coldef.editor != 'undefined' && !firstCell) {
                                firstCell = cell
                            }
                        })

                        if (firstCell) {
                            cmp.setCellCoordinates(firstCell)
                            cmp.setTableFocus()
                        //    firstCell.edit(true)
                        } */
                    })
                    .catch(error => {console.error('Ошибка добавления строки в таблицу', error)})
            },
            pressTab: function(event) {
                this.nextCol()
                event.preventDefault()
            },
            pressDataKey: function(event) {
                /*if (this.state == 'browsing') {
                    this.currentCell.edit()
                }*/
            },
            codeKeyCode: function(keyCode) {
                const dataKey = (keyCode == 32) ||
                                (keyCode >=  48 && keyCode <=  57) ||
                                (keyCode >=  65 && keyCode <=  90) ||
                                (keyCode >=  96 && keyCode <= 111) ||
                                (keyCode >= 186 && keyCode <= 192) ||
                                (keyCode >= 219 && keyCode <= 222)
                if (dataKey) {
                    return 999
                }

                return keyCode
            },
            tableKeyDown: function(event) {
                this.keyData = event.keyCode
                switch (this.codeKeyCode(event.keyCode)) {
                    case  9: this.pressTab(event)
                        break
                    case 13: this.pressEnter(event)
                        break
                    case 37: this.pressLeftArrow(event)
                        break
                    case 38: this.pressUpArrow(event)
                        break
                    case 39: this.pressRightArrow(event)
                        break
                    case 40: this.pressDownArrow(event)
                        break
                    case 45: this.pressInsert(event)
                        break
                    case 113: this.pressF2(event)
                        break
                    case 999: this.pressDataKey(event)
                        break
                }
            },
            /** Создает таблицу со своими событиями и служебным полем
             * @param {Object} options  - объект с опциями создания таблицы
             * @param {Object} subscribe - объект с подпиской на события. Имя поля = имя события, значение поля = обработчик события
             * */
            createGrid: function (options, subscribe) {
                // инициализируем генератор ID
                if (!this.sequenceID)
                  this.sequenceID = this.$store.state.sequenceID ;  //new SequenceID()

                // добавляем колонку innerID
                const iidColumn = options.columns.find( column => column.field == 'innerID' )
                if (!iidColumn) {
                    options.columns[options.columns.length] = {
                      title: 'innerID',
                      field: 'innerID',
                      visible: false,
                      mutator: this.innerIDMutator
                      }
                }
                else {
                    iidColumn.mutator = this.innerIDMutator
                }

               if (typeof options.scrollToColumnPosition == 'undefined') {
                  options.scrollToColumnPosition = 'right'
               }

               if (typeof options.scrollToColumnIfVisible  == 'undefined') {
                  options.scrollToColumnIfVisible  = true
               }

               options.index = 'innerID'

                const tid = '#' + this.tblid
                this.tabulator = new Tabulator(tid, options)

              const doSubscribe = ( table, subscribe ) => {
                if (!subscribe) return
                const keys = Object.keys(subscribe)
                keys.forEach( key => { table.on(key, subscribe[key]) })
              }

              const l_subscribe = {
                  'rowFormatter': this.rowFormatter,
                  'rowClick': this.rowClick,
                  'cellClick': this.cellClick,
                  'cellEditing': this.cellEditing,
                  'cellEditCancelled': this.cellEditCancelled,
                  'cellEdited': this.cellEdited,
                  'dataLoading': this.dataLoading,
                  'dataLoaded': this.dataLoaded
                }

              doSubscribe(this.tabulator, l_subscribe) ;
              doSubscribe(this.tabulator, subscribe) ;
            },
        },
        mounted() {
            Tabulator.extendModule('edit', 'editors', {autocompleteAJAX})
            this.rowFrmObj = new RowFormatter()
        },
    }
</script>

<style>

.tabulator-tableholder {
    background-color: #fff;
}

</style>
