export function SpecSaverAsync(handlers) {
   const _handlers = handlers
   let _idspec = 0
   let _package_count = 0
   let _package_number = 1

   function hideCircle() {
      if (_handlers.hideCircle)
         _handlers.hideCircle()
   }

   function showMessage (message) {
      if (_handlers.showMessage) {
         _handlers.showMessage(message)
      }
   }

   function showErrors (error) {
      if (_handlers.showError)
         _handlers.showError('Ошибка сохранения спецификации', error)
   }

   function commit(specData, promiseData) {
      showMessage('Фиксируем изменения в спецификации')
      const formData = new FormData()
      formData.append('command', 'commit')
      formData.append('idspec', specData.idspec)
      const options = {
         method: 'POST',
         url: burl + '/spec/save',
         headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json; charset=utf-8',
            'X-CSRF-Token': tkn_csfr
         },
         data: formData,
      }
      axios(options)
         .then(response => {
            /*hideCircle()
            window.location = '/spec/update?id=' + response.data.idspec */
            promiseData.resolve({
               idspec: response.data.idspec
            })
         })
         .catch(error => {
            promiseData.reject(error)
         })
   }

   function doSaveSpec(specData, promiseData) {
      if (specData.package_count < 0) {
         commit(specData, promiseData)
         return
      }
      
      let message = 'Сохранение спецификации'
      if (specData.package_count) {
         const prec = specData.package_count > 100 ? 1 : 0
         message = message + ': ' + Math.round(100 * specData.package_number / specData.package_count, prec) + '%'
      }
      showMessage(message)


      const formData = new FormData()
      formData.append('command', 'save')
      formData.append('idspec', specData.idspec)
      formData.append('package_number', specData.package_number)
      const options = {
         method: 'POST',
         url: burl + '/spec/save',
         headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json; charset=utf-8',
            'X-CSRF-Token': tkn_csfr
         },
         data: formData,
      }
      axios(options)
         .then(response => {
            if (response.data.package_number == specData.package_count) {
               commit(specData, promiseData)
               return
            }
            specData.package_number++
            doSaveSpec(specData, promiseData)

         })
         .catch(error => {
            promiseData.reject(error)
         })
   }

   this.execute = function (formData) {
      showMessage('Начинаем записывать изменения спецификации в базу данных')
      const self = this
      return new Promise(function (resolve, reject) {
         const options = {
            method: 'POST',
            url: burl + '/spec/save',
            headers: {
               'X-Requested-With': 'XMLHttpRequest',
               'Content-Type': 'application/json; charset=utf-8',
               'X-CSRF-Token': tkn_csfr
            },
            data: formData,
         }
         axios(options)
            .then(response => {
               const package_count = response.data.package_count ;
               if (!package_count) {
                  reject('Ошибка формирования пакетов спецификации')
               }
               doSaveSpec({
                        'idspec': response.data.idspec,
                        'package_number': 1,
                        'package_count': package_count
                     },
                     {resolve, reject})
            })
            .catch(error => {
               reject(error)
            })
      })
   }
}
