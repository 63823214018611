import Vue from 'vue'
import Vuex from 'vuex'
import { EdIzmRef } from './modules/edizmRef.js'
import { Solutions } from './modules/solutions.js'
import SequenceID from '../pages/units/SequenceID.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    edizmRef: EdIzmRef,
    solution: Solutions,
  },
  state: {
    roles: [],
    user: {id: 0, fio: '', idRole: 0},
    firm: {
      rowPerPage: 10,
      ref: [],
    },
    ng: {
      selectedID: 0,
    },
    nomen: {
      idgroup: 0,
      idnomen: 0,
      search_text: '',
      rowPerPage: 25,
    },
    nomentwin: {
      rowPerPage: 20,
    },
    spec: {
      rowPerPage: 20,
    },
    modified: false,
    hidden_app: false,
    sequenceID: new SequenceID(),
  },
  mutations: {
    app_hidden (state, active) {
      state.hidden_app = active
    }
  },
})
