var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "toolbar" }, [
      _c("div", { staticClass: "btn-group" }, [
        _c(
          "button",
          {
            staticClass: "btn",
            class: {
              "btn-primary": !_vm.panelIndex,
              "btn-default": _vm.panelIndex,
            },
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.setPI(0)
              },
            },
          },
          [_vm._v("Полные дубли")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn",
            class: {
              "btn-primary": _vm.panelIndex,
              "btn-default": !_vm.panelIndex,
            },
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.setPI(1)
              },
            },
          },
          [_vm._v("Поиск дублей")]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", [
      !_vm.panelIndex ? _c("div", [_c("nomen-twin-full-twins")], 1) : _vm._e(),
      _vm._v(" "),
      _vm.panelIndex ? _c("div") : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }