<template>
  <div class="ng-container">
    <div class="toolbar"></div>
    <div class="ng-dtl">
      <div class="ng-dtl__error" v-if="isError">Ошибка получения данных о текущей группе</div>
      <div class="ng-dtl__info">
        <div class="ng-dtl__title">
          <div>Информация по группе номенклатуры</div>
          <div class="ng-dtl__name">{{ngInfo.group_name}}</div>
        </div>
        <div class="ng-dtl__block">
          <label class="control-label">Родительская группа номенклатуры</label>
          <dkc-ng-namy :idgroup="ngInfo.idParent"
                       hide-toolbar="true"
                       @select_group="selectGroup">
          </dkc-ng-namy>
        </div>

        <div class="ng-dtl__block">
          <label class="control-label">Количество позиций номенклатуры в группе: </label>
          <a :href="nomenUrl">
              <span class="nomencount">{{ngInfo.nomen_count}}</span>
          </a>
        </div>

        <div class="ng-dtl__block">
          <div class="ng-dtl__tbls">
            <div class="ng-dtl__url">
              <label class="control-label">Ссылки на сторонние ресурсы</label>
              <dkc-nomen-group-url :idng="ngInfo.id"></dkc-nomen-group-url>
              <div id="ng-dtl__url_tbl"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import DkcNgNamy from './dkcNgNavy'
  import DkcNomenGroupUrl from './dkcNomenGroupURL'

  export default {
    name: "dkcNomenGroupInfo",
    components: {DkcNgNamy, DkcNomenGroupUrl},
    props: ['ngInfo'],
    data() {
      return {
        isError: false,
        group_name: '',
        nomen_count: 0,
      }
    },
    computed: {
      nomenUrl: function() {
        return this.baseUrl + '/nomen/index?idgroup=' + this.ngInfo.id
      }
    },
    methods: {
      selectGroup: function (idNG) {
        this.$emit('select_group', idNG) ;
      }
    },
  }
</script>

<style scoped>
  .ng-container {
    /*width: 50%;*/
  }

  .ng-dtl {
    width: 100%;
    border-top: 1px solid darkgray;
  }

  .ng-dtl__error {
    padding: 32px;
    font-size: 20px;
    font-weight: bolder;
    color: red;
    text-align: center;
  }

  .ng-dtl__info {
    padding: 16px 16px;
  }

  .ng-dtl__title {
    font-size: 20px;
    text-align: center;
  }

  .ng-dtl__name {
    font-weight: bolder;
    color: navy;
  }

  .ng-dtl__block {
    margin-top: 16px;
  }
  .ng-dtl__path {

  }

  .ng-dtl__tbls {
/*      display: flex;
    justify-content: space-around;  */
  }

  .nomencount {
    margin-left: 16px;
    font-size: 1.2em;
    font-weight: bolder;
    color: navy;
  }
</style>
