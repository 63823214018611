var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ribbon" },
    [
      _c("bpk-tabs", {
        attrs: { tabs: _vm.tabs },
        on: { "change-tab": _vm.changeTab },
      }),
      _vm._v(" "),
      _c(
        "bpk-toolbars",
        { attrs: { activeTabIndex: _vm.activeTabIndex } },
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }