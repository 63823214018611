import XLSX from 'xlsx'

export default function TXLSX(file) {

  const self = this
  let _workbook = false  // открытая книга XLS
  let _sheet = false

  /**
   * Загружает файл и активизирует первый лист в книге
   * @param file - объект полученный с помощью элемента <input>, через drag and drop из объекта DataTransfer
   *               (https://developer.mozilla.org/ru/docs/Web/API/FileReader)
   */
  this.load = (file) =>{
    const reader = new FileReader()
    reader.onload = function (e) {
      _workbook = XLSX.read(this.result, {type : 'binary'})
      self.activateSheet(0)
      if (self.onload) {
        self.onload(this)
      }
    }
    reader.readAsBinaryString(file)
  }


  this.workbook =  () => _workbook
  this.currentSheet = () => _sheet

  /**
   * Активизирует заданную страницу в книге
   * @param sheetNameOrIndex - имя страницы или её индекс (начинается с нуля)
   */
  this.activateSheet = (sheetNameOrIndex) => {
    const sheetName = Number.isInteger(sheetNameOrIndex) ? _workbook.SheetNames[sheetNameOrIndex] : sheetNameOrIndex
    _sheet = _workbook.Sheets[sheetName]
    /*window.xlssheet = _sheet
    window.workbook = _workbook*/
    if (this.onactivateSheet)
      this.onactivateSheet(_sheet)
    return _sheet
  }

  /**
   * Преобразует координаты ячейки в адрес, по которому осуществляется доступ к ячейке
   * @param col {string | number} - индекс колонки (1,2, 3 - number), имя колонки ("А", "В", "С" - string) или
   *                                уже готовый адрес. В последнем случае параметр row должен равняться undefined
   * @param row {number} - индекс строки. Если в параметр col передан готовый адрес ("А1"), то должен равняться undefined
   * @returns {string} - адресс ячейки (A1, D15, AF33 и т.д.)
   */
  function getCellAddress(col, row) {
    let addr = col
    if (Number.isInteger(addr))
      addr = XLSX.utils.encode_col(addr)

    if (row == undefined)
      return addr

    return addr + row
  }

  /**
   * Возвращает ячейку по заданным координатам
   * @param col {string | number} - индекс колонки (1,2, 3 - number), имя колонки ("А", "В", "С" - string) или
   *                                уже готовый адрес. В последнем случае параметр row должен равняться undefined
   * @param row {number} - индекс строки. Если в параметр col передан готовый адрес ("А1"), то должен равняться undefined
   * @returns {Object|boolean} - объект ячейки (https://github.com/SheetJS/js-xlsx  ищи "Cell Object"
   */
  this.cell = (col, row) => {
    if (!_sheet)
      return false

    return _sheet[getCellAddress(col, row)]
  }

  this.value = (col, row) => {
    const cell = this.cell(col, row)
    if (!cell)
      return ''

    return cell.v
  }

  if (file)
    this.load(file)
}

TXLSX.prototype = {
  _onload: false,
  _onactivateSheet: false,

  decode_col(col) {
    return XLSX.utils.decode_col(col)
  },
  encode_col(col) {
    return XLSX.utils.encode_col(col)
  },
  decode_row(row) {
    return XLSX.utils.decode_row(row)
  },
  encode_row(row) {
    return XLSX.utils.encode_row(row)
  },
  get active() {
    return this.activeSheet !== false
  },
  get activeSheet () {
    return this.currentSheet()
  },
  get activeSheetName () {
    if (!this.activeSheet)
      return ''

    const name = this.sheetNames.find( sheet_name => this.workbook().Sheets[sheet_name] == this.activeSheet )
    return name == undefined ? '' : name
  },
  set activeSheetName (newSheetName) {
    if (this.active && this.SheetNames.indexOf(newSheetName) > -1)
      this.activateSheet(newSheetName)
  },
  get sheetNames () {
    if (!this.workbook())
      return ['']

    return this.workbook().SheetNames
  },
  get dataRange () {
    if (this.active)
      return this.activeSheet['!ref']

    return '-'
  },
  get firstRow () {
    if (!this.active)
      return 0
    const range = XLSX.utils.decode_range(this.dataRange)
    return +XLSX.utils.encode_row(range.s.r)
  },
  get lastRow () {
    if (!this.active)
      return 0
    const range = XLSX.utils.decode_range(this.dataRange)
    return +XLSX.utils.encode_row(range.e.r)
  },
  get firstCol () {
    if (!this.active)
      return 0
    const range = XLSX.utils.decode_range(this.dataRange)
    return range.s.c
  },
  get lastCol () {
    if (!this.active)
      return 0
    const range = XLSX.utils.decode_range(this.dataRange)
    return range.e.c
  },
  get onload() {
    return this._onload
  },
  set onload(handler) {
    this._onload = handler
  },
  get onactivateSheet() {
    return this._onactivateSheet
  },
  set onactivateSheet(handler) {
    this._onactivateSheet = handler
  }
}
