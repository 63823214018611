var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "modal-fade" } }, [
    _c("div", { staticClass: "dkc-modal-backdrop" }, [
      _c(
        "div",
        {
          staticClass: "dkc-modal",
          attrs: {
            role: "dialog",
            "aria-labelledby": "modalTitle",
            "aria-describedby": "modalDescription",
          },
        },
        [
          _c(
            "header",
            { staticClass: "modal-header", attrs: { id: "modalTitle" } },
            [
              _vm._t("header", function () {
                return [_vm._v("This is the default tile!")]
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "section",
            { staticClass: "modal-body", attrs: { id: "modalDescription" } },
            [
              _vm._t("body", function () {
                return [_vm._v("I'm the default body!")]
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c("footer", { staticClass: "modal-dlg-footer" }, [
            _c("div", { staticClass: "extra_buttons" }, [_vm._t("buttons")], 2),
            _vm._v(" "),
            _c("div", { staticClass: "standart_buttons" }, [
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showButton("ok"),
                      expression: "showButton('ok')",
                    },
                  ],
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.execButton("ok")
                    },
                  },
                },
                [_vm._v("Ok")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showButton("yes"),
                      expression: "showButton('yes')",
                    },
                  ],
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.execButton("yes")
                    },
                  },
                },
                [_vm._v("Да")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showButton("no"),
                      expression: "showButton('no')",
                    },
                  ],
                  staticClass: "btn btn-danger",
                  attrs: { type: "button" },
                  on: { click: _vm.close },
                },
                [_vm._v("Нет")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showButton("cancel"),
                      expression: "showButton('cancel')",
                    },
                  ],
                  staticClass: "btn btn-danger",
                  attrs: { type: "button" },
                  on: { click: _vm.close },
                },
                [_vm._v("Отмена")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showButton("close"),
                      expression: "showButton('close')",
                    },
                  ],
                  staticClass: "btn btn-danger",
                  attrs: { type: "button" },
                  on: { click: _vm.close },
                },
                [_vm._v("Закрыть")]
              ),
            ]),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }