export default function Nomen(code, mark, name, edizm, price, rest) {
    this.code  = code
    this.mark  = mark
    this.name  = name
    this.edizm = edizm
    this.price = price
    this.rest  = rest

    this.isEmpty = function () {
        return !this.code && !this.mark && !this.name
    }
}
