<template>
  <div id="app" :class="{app_container: !hidden_app, app_hidden: hidden_app}">
    <header>
      <nav id="NavBarMain" class="navbar navbar-default">
        <div>
          <div class="dkc-navbar-header">
            <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#NavBarMain-collapse">
              <span class="sr-only">Toggle navigation</span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
            </button>

               <div class="brand">
                 <a class="navbar-brand" :href="baseUrl + '/'"><img src="./images/logo.svg" alt=""></a>
                 <div class="caption-text">СПЕЦИФИКАЦИИ</div>
               </div>

             <div class="collapse navbar-collapse" id="NavBarMain-collapse">
                <div class="top-menu">
                   <ul class="nav navbar-nav">

                      <li class="nav-item">
                         <a class="nav-link" :href="baseUrl + '/spec'">Журнал спецификаций</a>
                      </li>
                      <li class="nav-item">
                         <a class="nav-link" :href="baseUrl + '/spec/create'">Новая спецификация</a>
                      </li>
                      <li class="nav-item"  v-if="idRole == 1">
                         <a class="nav-link" :href="baseUrl + '/spec/solutions'">База решений по аналогам</a>
                      </li>
                      <li class="dropdown">
                         <a href="#" class="dropdown-toggle" data-toggle="dropdown">Справочники<b class="caret"></b></a>
                         <ul class="dropdown-menu">
                            <li class="nav-item" v-if="idRole == 1">
                               <a class="nav-link" :href="baseUrl + '/user/index'">Пользователи программы</a>
                            </li>
                            <li class="nav-item">
                               <a class="nav-link" :href="baseUrl + '/firm/index'">Фирмы производители</a>
                            </li>
                            <li class="divider"></li>
                            <li class="nav-item">
                               <a class="nav-link" :href="baseUrl + '/nomen-group/index'">Группы номенклатуры</a>
                            </li>
                            <li class="nav-item">
                               <a class="nav-link" :href="baseUrl + '/nomen/index'">Номенклатура</a>
                            </li>
                            <li class="nav-item" v-if="[1, 2].includes(idRole)">
                               <a class="nav-link" :href="baseUrl + '/nomen/import'">Импорт номенклатуры</a>
                            </li>
                            <li class="nav-item" v-if="idRole == 1">
                               <a class="nav-link" :href="baseUrl + '/nomen/twin'">Дубли номенклатуры</a>
                            </li>
                         </ul>
                      </li>

                      <li class="dropdown">
                         <a href="#" class="dropdown-toggle" data-toggle="dropdown">Помощь<b class="caret"></b></a>
                         <ul class="dropdown-menu">
                            <li class="nav-item">
                              <a :href="baseUrl + '/help/index.html'" class="nav-link'">Справка</a>
                            </li>
                            <li class="nav-item">
                              <a :href="baseUrl + '/help/knowledge_base.html'" class="nav-link">База знаний</a>
                            </li>
                         </ul>
                      </li>

                   </ul>
                   <ul class="nav navbar-nav">
                      <li><a :href="baseUrl + '/user/logout'">Выход</a></li>
                   </ul>
                </div>
             </div>

               <div>
                 <div class="prifile">
                   <a :href="userProfileUrl">
                     <img src="./images/user.svg" width="16" height="auto">
                     <span class="username">{{user.fio}}</span>
                   </a>
                 </div>
                 <div >{{ now }}</div>
               </div>

          </div>
        </div>

         <div class="caption">
            <div class="caption-pagetitle">
               {{title}}
               <span v-if="modified">*</span>
            </div>
         </div>
      </nav>
    </header>
    <router-view class="main_view" v-if="!errorPage"></router-view>
    <div id="debug" class="debug_area"></div>
  </div>
</template>

<script>
  //import Entities from 'html-entities'
  /* import { base_url } from './pages/mixins/BaseUrlGetter' 
  import { queryAJAX } from './pages/mixins/QueryAJAX'  */

  export default {
      name: 'app',
      // mixins: [base_url, queryAJAX],
      data () {
        return {
          title: "",
        }
      },
      computed: {
        userProfileUrl: function() {
          return this.baseUrl + '/user/update?id=' + this.user.id ;
        },
        now: function() {
          const date = new Date();
          const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            weekday: 'long'
          };

          return date.toLocaleString("ru", options) ;
        },
        user: function() {
          return this.$store.state.user ;
        },
        idRole: function () {
            return window.dkcapp.$store.state.user.idRole
        },
        errorPage: function () {
          const el = document.getElementById('exceptioMsg') ;
          return el != null ;
        },
        modified: function() {
          return window.dkcapp.$store.state.modified
        },
        hidden_app: function () {
           return window.dkcapp.$store.state.hidden_app
        }
      },
      created() {window.dkcapp = this ;},
      mounted() {
        const Entities = require('html-entities').AllHtmlEntities;
        const entities = new Entities() ;
        this.title = entities.decode(dkc_page_title) ;
        this.queryAjax('/user/current-user')  
          .then(function(response) {
            window.dkcapp.$store.state.user.id = response.data.id
            window.dkcapp.$store.state.user.fio = response.data.fio
            window.dkcapp.$store.state.user.idRole = response.data.idRole
            window.dkcapp.$store.state.roles = response.data.roles
          })
          .catch(function(error) {
            console.log('Ошибка получения данных о текущем пользователе:', error.message);
          }) ;
      },

    }
</script>


<style scoped>

   .app_container {
      display: grid;
      margin: 0;
      max-height: 100vh;
      grid-template-rows: auto 1fr auto;
      grid-template-areas: "header" "content" "debug";
   }

   .app_hidden {
      display: block;
   }

   header {
      grid-area: header;
      max-width: 99vw;
   }

   .main_view {
      grid-area: content;
   }

   .debug_area {
      grid-area: debug;
      display: none;
      padding-top: 50px;
   }

  .dkc-navbar-header {
     display: flex;
     justify-content: space-between;
     align-items: center;
     max-width: 98.5vw;
     padding-left: 24px;
     padding-right: 48px;
  }

  .brand {
    display: flex;
    justify-content: flex-start ;
    align-items: center;
  }

  .username {
    color: #cc0000;
    font-weight: bolder;
  }

  .caption {
     display: block;
     max-width: 98.5vw;
     text-align: center;

  }

  #mainMenu {
    width: 100%;
  }

</style>
