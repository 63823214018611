import { render, staticRenderFns } from "./NomenTwinRefs.vue?vue&type=template&id=216a0a03&scoped=true&"
import script from "./NomenTwinRefs.vue?vue&type=script&lang=js&"
export * from "./NomenTwinRefs.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "216a0a03",
  null
  
)

export default component.exports