var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sol_container" },
    [
      _c(
        "div",
        { staticClass: "sol_header", attrs: { id: "sol_header" } },
        [
          _c(
            "bpk-ribbon",
            { attrs: { tabs: _vm.tabs } },
            [
              _c(
                "bpk-toolbar",
                { attrs: { index: 0 } },
                [
                  _c(
                    "bpk-toolbar-panel",
                    { attrs: { title: "Изменение статуса" } },
                    [
                      _c("dkc-tool-button", {
                        attrs: {
                          title: "Статус 'Черновик'",
                          hint: "Установить выдленным строкам статус 'Черновик'",
                          iconclass: "dbtn_state_draft",
                          action: _vm.setStateSelectedPositions,
                          actiondata: { sign: "D" },
                        },
                      }),
                      _vm._v(" "),
                      _c("dkc-tool-button", {
                        attrs: {
                          title: "Статус 'Утверждено'",
                          hint: "Установить выдленным строкам статус 'Утверждено'",
                          iconclass: "dbtn_state_approved",
                          action: _vm.setStateSelectedPositions,
                          actiondata: { sign: "A" },
                        },
                      }),
                      _vm._v(" "),
                      _c("dkc-tool-button", {
                        attrs: {
                          title: "Статус 'Исключить'",
                          hint: "Установить выдленным строкам статус 'Исключить'",
                          iconclass: "dbtn_state_exclude",
                          action: _vm.setStateSelectedPositions,
                          actiondata: { sign: "E" },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "bpk-toolbar-panel",
                    { attrs: { title: "Фильтр таблицы" } },
                    [
                      _c("dkc-tool-button", {
                        attrs: {
                          title: "Очистить фильтр",
                          hint: "Очистить фильтр, показать все данные",
                          iconclass: "dbtn_filter_clear",
                          action: _vm.clearFilters,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "bpk-toolbar-panel",
                    { attrs: { title: "Фильтр по статусу позиции" } },
                    [
                      _c("div", { staticClass: "state_toolbar_panel" }, [
                        _c("div", { staticClass: "toggle_block" }, [
                          _c("div", { staticClass: "toggle_label" }, [
                            _vm._v('Позиции по статусом "Черновик"'),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "toggle_control" },
                            [
                              _c("VueToggles", {
                                attrs: {
                                  value: _vm.stateFilters.d,
                                  height: "18",
                                  width: "120",
                                  checkedText: "Показывать",
                                  uncheckedText: "Скрыть",
                                  checkedBg: "skyblue",
                                  uncheckedBg: "silver",
                                },
                                on: { click: _vm.onClickToggles_d },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "toggle_block" }, [
                          _c("div", { staticClass: "toggle_label" }, [
                            _vm._v('Позиции по статусом "Утверждено"'),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "toggle_control" },
                            [
                              _c("VueToggles", {
                                attrs: {
                                  value: _vm.stateFilters.a,
                                  height: "18",
                                  width: "120",
                                  checkedText: "Показывать",
                                  uncheckedText: "Скрыть",
                                  checkedBg: "skyblue",
                                  uncheckedBg: "silver",
                                },
                                on: { click: _vm.onClickToggles_a },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "toggle_block" }, [
                          _c("div", { staticClass: "toggle_label" }, [
                            _vm._v('Позиции по статусом "Исключить"'),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "toggle_control" },
                            [
                              _c("VueToggles", {
                                attrs: {
                                  value: _vm.stateFilters.e,
                                  height: "18",
                                  width: "120",
                                  checkedText: "Показывать",
                                  uncheckedText: "Скрыть",
                                  checkedBg: "skyblue",
                                  uncheckedBg: "silver",
                                },
                                on: { click: _vm.onClickToggles_e },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "bpk-toolbar",
                { attrs: { index: 2 } },
                [
                  _c(
                    "bpk-toolbar-panel",
                    { attrs: { title: "Открыть объект в новой вкладке" } },
                    [
                      _c("dkc-tool-button", {
                        attrs: {
                          title: "Открыть спецификацию",
                          hint: "Открыть спецификацию в новой вкладке",
                          iconclass: "dbtn_open_spec",
                          action: _vm.gotoToSpec,
                        },
                      }),
                      _vm._v(" "),
                      _c("dkc-tool-button", {
                        attrs: {
                          title: "Номенклатура заказчика",
                          hint: "Открыть номенклатуру заказчика в новой вкладке",
                          iconclass: "dbtn_open_nomen_customer",
                          begingroup: true,
                          action: _vm.gotoCustomerNomen,
                        },
                      }),
                      _vm._v(" "),
                      _c("dkc-tool-button", {
                        attrs: {
                          title: "Номенклатура ДКС",
                          hint: "Открыть номенклатуру ДКС в новой вкладке",
                          iconclass: "dbtn_open_nomen_dkc",
                          action: _vm.gotoDKCNomen,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "sol_content" }, [
        _c("div", { staticClass: "sol_tbl_wrap" }, [
          _c("div", { ref: "solutions", attrs: { id: "solutiontbl" } }),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "dkc-modal-dlg",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isModalDlgVisible,
              expression: "isModalDlgVisible",
            },
          ],
          attrs: { buttons: _vm.buttons, actions: _vm.actions },
          on: { close: _vm.closeModalDlg },
        },
        [
          _c("div", {
            attrs: { slot: "body" },
            domProps: { innerHTML: _vm._s(_vm.bodyText) },
            slot: "body",
          }),
          _vm._v(" "),
          _c("span", { attrs: { slot: "header" }, slot: "header" }, [
            _vm._v(_vm._s(_vm.headerText)),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }