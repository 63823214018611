<template>
  <div class="tunecol">
    <label for="tunecol" class="control-label">Настройка колонок</label>
  <!--  <dkc-grid ref="colmaptbl" name="colmaptbl" id="colmaptbl" tableid="colmaptbl"
    ></dkc-grid> -->
    <div id="colmaptbl" ref="colmaptbl"></div>
  </div>
</template>

<script>
    import { coordConv } from '../mixins/cbrdCoorConverter'
    import { TabulatorFull as Tabulator } from "tabulator-tables"

    export default {
        name: "specClipbrdTunerColmap",
        mixins: [coordConv],
        data() {
          return {
            tabulator: false,
            colmap: {},
            colCount: 26, // Количество символов в латинском алфавите
            fieldDefs: [],
          }
        },
        computed: {
          colmaptbl: function() {
            return this.tabulator
          },
          validator: function() {
            const cols = []
            for (let i = 0; i < this.colCount; i++) {
              cols.push(this.toChar(i))
            }

            return 'in:' + cols.join('|')
          },
        },
        methods: {
          execute(options) {
            this.colmap     = options.colmap
            this.colCount  = options.colCount ? options.colCount : this.colCount
            this.fieldDefs  = options.fieldDefs
            this.createTable(options.fieldDefs)
          },
          createTable(fieldDefs) {
            const self = this

            function convertFieldDefs(fieldDef, cols) {
              if (fieldDef.visible == false)
                return

              const coldef = {
                field: fieldDef.field == undefined ? 'f' + cols.length : fieldDef.field,
                title: fieldDef.title || 'пусто было',
                visible: true,
              }


              if ('columns' in fieldDef) {
                coldef.columns = []
                fieldDef.columns.forEach(fd => convertFieldDefs(fd, coldef.columns))
              }
              else {
                coldef.editor = 'input'
                coldef.validator = self.validator
                coldef.mutator = self.colMutator
              }

              cols.push(coldef)
            }

            const columns = []
            fieldDefs.forEach(fieldDef => {
              if (fieldDef.visible == undefined || fieldDef.visible)
                convertFieldDefs(fieldDef, columns)
            })

            const data = this.getTableData()
            if (this.tabulator) {
              this.tabulator.setColumns(columns)
              this.tabulator.setData(data)
            } else {
              const options = {
                columns,
                data,
                height: 90,
                layout: 'fitDataFill',
              }

              this.tabulator = new Tabulator('#colmaptbl', options)
              this.tabulator.on('cellEdited', this.cellEdited)
            }
            setTimeout(() => {self.tabulator.redraw(true)}, 100)
          },
          colMutator: function(value) {
            if (!value || typeof value != 'string')
              return value
            const lc = 'QWERTYUIOPASDFGHJKLZXCVBNM'
            const cc = 'ЙЦУКЕНГШЩЗФЫВАПРОЛДЯЧСМИТЬ'
            let col = value.toUpperCase()
            const ind = cc.indexOf(col)
            if (ind < 0)
              return col

            return lc.charAt(ind)
          },
          getTableData() {
            const self = this
            const rd = {}
            const fields = Object.keys(this.colmap)
            fields.forEach(field => {
              if (self.colmap[field] < 0)
                return

              rd[field] = String.fromCharCode( 'A'.charCodeAt(0) + self.colmap[field] )
            })

            return [rd] ;
          },
          cellEdited(cell) {
            const field = cell.getField()
            const value = cell.getValue()
            this.colmap[field] = this.toIndex(value)
            this.$emit('celledited')
          },
        },
    }
</script>

<style scoped>

</style>
