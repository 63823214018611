var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "site-index" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "body-content" }, [
      _c("div", { staticClass: "task" }, [
        _c("a", { attrs: { href: _vm.baseUrl + "/firm/index" } }, [
          _c("div", { staticClass: "task-img" }, [
            _c("img", { attrs: { src: _vm.baseUrl + "/images/ctl_firm.png" } }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "task-desc" }, [
            _vm._v("\n          Фирмы производители\n        "),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "task" }, [
        _c("a", { attrs: { href: _vm.baseUrl + "/nomen-group/index" } }, [
          _c("div", { staticClass: "task-img" }, [
            _c("img", {
              attrs: { src: _vm.baseUrl + "/images/ctl_nomen_group.png" },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "task-desc" }, [
            _vm._v("\n          Группы номенклатуры\n        "),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "task" }, [
        _c("a", { attrs: { href: _vm.baseUrl + "/nomen/index" } }, [
          _c("div", { staticClass: "task-img" }, [
            _c("img", {
              attrs: { src: _vm.baseUrl + "/images/ctl_nomen.jpg" },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "task-desc" }, [
            _vm._v("\n          Номенклатура\n        "),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.userIsAdmin
        ? _c("div", { staticClass: "task" }, [
            _c("a", { attrs: { href: _vm.baseUrl + "/nomen/twin" } }, [
              _c("div", { staticClass: "task-img" }, [
                _c("img", { attrs: { src: _vm.baseUrl + "/images/twin.jpg" } }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "task-desc" }, [
                _vm._v("\n          Дубли номенклатуры\n        "),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.userIsAdmin
        ? _c("div", { staticClass: "task" }, [
            _c("a", { attrs: { href: _vm.baseUrl + "/user/index" } }, [
              _c("div", { staticClass: "task-img" }, [
                _c("img", {
                  attrs: { src: _vm.baseUrl + "/images/ctl_user.png" },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "task-desc" }, [
                _vm._v("\n          Пользователи программы\n        "),
              ]),
            ]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "jumbotron" }, [
      _c("p", { staticClass: "lead" }, [_vm._v("Выберите справочник:")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }