<template>
    <div class="search-container">
        <label>Найти:</label>
        <input type="search" id="search-inp" style="margin-left: 8px;"
               placeholder="Введите строку поиска" 
               v-model="search_text" 
               @keyup="doKeyUp">
        <button type="button"
                class="btn"
                style="background-color: transparent; border: 0px solid transparent"
                v-if="search_text"
                @click="clearSearchText"
        >
            <div class="close-btn">+</div>
        </button>
    </div>
</template>

<script>
    export default {
      name: "dkcSearchControl",
      data() {
        return {
          search_text: '',
          timeout: false
        }
      },
      methods: {
        doKeyUp() {
          if(this.timeout) {
            clearTimeout(this.timeout);
          }
          const st = this.search_text ;
          this.timeout = setTimeout(() => {
            this.$emit('onsearch', this.search_text) ;
          }, 500);
        },
          clearSearchText() {
              this.search_text = ''
              if(this.timeout) {
                  clearTimeout(this.timeout)
              }
              this.$emit('onsearch', this.search_text)
          },
      },
    }
</script>

<style scoped>
.search-container {
  display: flex;
  align-items: center;
  margin-left: 16px;
}

.close-btn {
    margin-left: -10px;
    font-size: 2em;
    font-weight: bolder;
    color: #CC0000;
    transform: rotate(45deg);
    border-color: transparent;
}

</style>
