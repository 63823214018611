var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "dkc-modal-dlg",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isModalDlgVisible,
              expression: "isModalDlgVisible",
            },
          ],
          attrs: { buttons: _vm.buttons, actions: _vm.actions },
          on: { close: _vm.closeModalDlg },
        },
        [
          _c(
            "div",
            {
              staticClass: "form-group col-3",
              attrs: { slot: "body" },
              slot: "body",
            },
            [
              _c("label", { staticClass: "control-label" }, [
                _vm._v(
                  "Перед вставкой, программа может проанализировать данные в буфере обмена и осуществить вставку в наиболее подходящие колонки."
                ),
                _c("br"),
                _vm._v('Выберите метод всавки и нажмите кнопку "Ок":'),
              ]),
              _vm._v(" "),
              _c("div", { staticStyle: { "margin-left": "15%" } }, [
                _c(
                  "div",
                  { staticStyle: { display: "flex", "align-items": "center" } },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.pmethod,
                          expression: "pmethod",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        name: "pmethod",
                        value: "spec",
                        checked: "",
                      },
                      domProps: { checked: _vm._q(_vm.pmethod, "spec") },
                      on: {
                        change: function ($event) {
                          _vm.pmethod = "spec"
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticStyle: { "margin-left": "16px" } }, [
                      _vm._v("вставка с анализом данных"),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticStyle: { display: "flex", "align-items": "center" } },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.pmethod,
                          expression: "pmethod",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        name: "pmethod",
                        value: "standart",
                      },
                      domProps: { checked: _vm._q(_vm.pmethod, "standart") },
                      on: {
                        change: function ($event) {
                          _vm.pmethod = "standart"
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticStyle: { "margin-left": "16px" } }, [
                      _vm._v("cтандартная вставка"),
                    ]),
                  ]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("span", { attrs: { slot: "header" }, slot: "header" }, [
            _vm._v("Вставка данных в таблицу из буфера обмена"),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "dkc-modal-dlg",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isConfirmVisible,
              expression: "isConfirmVisible",
            },
          ],
          attrs: { buttons: _vm.confirmbtn, actions: _vm.confirmactions },
          on: { close: _vm.closeConfirmDlg },
        },
        [
          _c("div", { attrs: { slot: "body" }, slot: "body" }, [
            _vm._v(
              "Количество колонок в буфере обмена превышает количество доступных колонок в таблице. Продолжить вставку с обрезкой данных?"
            ),
          ]),
          _vm._v(" "),
          _c("span", { attrs: { slot: "header" }, slot: "header" }, [
            _vm._v("Вставка данных в таблицу из буфера обмена"),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("spec-clipbrd-tuner", { ref: "cbrdtuner" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }