<template>
  <div class="fulltwin_box">
    <div v-if="circleVisible">
      <circle-animation :maxvalue="twins_count" :value="twins_index" :title="progresstitle"></circle-animation>
    </div>

    <div class="toolbar">
      <div class="btn-group">
        <div class="btn-group">
          <button
            type="button"
            class="btn btn-default dropdown-toggle"
            data-toggle="dropdown"
          >
            Заменить дубли основной номенклатурой
            <span class="caret"></span>
          </button>
          <ul class="dropdown-menu">
            <li>
              <a href="#" @click="deleteFullTwins(false)"
                >Для выделенных строк</a
              >
            </li>
            <li>
              <a href="#" @click="deleteFullTwins(true)"
                >Для всех строк текущей страницы</a
              >
            </li>
          </ul>
        </div>

        <button type="button" class="btn btn-default" @click="loadFullTwins()">
          Загрузить дубли
        </button>
      </div>
    </div>
    <div class="fulltwin_body">
      <div id="fulltwins"></div>
    </div>
  </div>
</template>

<script>
import { TabulatorFull as Tabulator } from "tabulator-tables";
import circleAnimation from "./circleAnimation";

export default {
  name: "NomenTwinFullTwins",
  props: {},
  components: { circleAnimation },
  data() {
    return {
      table: false,
      twins: [],
      twins_count: 0,
      circleVisible: false,      
    };
  },
  computed: {
    rowPerPage: function () {
      return this.$store.state.nomentwin.rowPerPage;
    },
    twins_index: function () {
      return this.twins_count - this.twins.length 
    }
  },
  watch: {
    rowPerPage: function () {
      this.table.setPageSize(this.$store.state.nomentwin.rowPerPage);
    },
  },
  methods: {
    deleteFullTwins(all) {
      const data = all ? this.table.getData() : this.table.getSelectedData();
      if (data.length) {
        this.twins = data 
        this.twins_count = data.length
        this.showProgress()
        this.requestDeleteFullTwins()
      }
    },
    showProgress () {
      this.circleVisible = true 
    },
    hideProgress () {
      this.circleVisible = false 
    },
    requestDeleteFullTwins () {
      if (!this.twins.length) {
        this.table.setData()
        this.hideProgress()
        return 
      }

      const self = this
      const item = this.twins.shift()
      const idnomen = item['twinstart']
      const formData = new FormData();
      formData.append("idnomen", idnomen);

      const options = {
        method: "POST",
        url: "/nomen/delete-full-twins",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "application/json; charset=utf-8",
          "X-CSRF-Token": tkn_csfr,
        },
        data: formData,
      };
      axios(options)
      .then( response => {
        self.requestDeleteFullTwins()
      })
      .catch( error => {
        self.hideProgress() 
        consolelog(error)
      })

    },
  },
  mounted() {
    this.table = new Tabulator("#fulltwins", {
      columns: [
        { title: "Код", field: "code", width: 200, headerFilter: true },
        { title: "Тип, марка", field: "mark", width: 200, headerFilter: true },
        { title: "Наименование", field: "name", headerFilter: true },
        { title: "Количество дублей", field: "twincount", width: 100 },
        { title: "ID первой записи", field: "twinstart", visible: false },
      ],
      height: "100%",
      layout: "fitColumns",
      locale: "ru-RU",
      langs: {
        "ru-RU": {
          data: {
            loading: "загрузка",
            error: "ошибочка вышла",
          },
          pagination: {
            first: String.fromCharCode(57449),
            first_title: "первая страница",
            prev: String.fromCharCode(57457),
            prev_title: "предыдущая страница",
            next: String.fromCharCode(57461),
            next_title: "следущая страница",
            last: String.fromCharCode(57463),
            last_title: "последняя страница",
          },
        },
      },
      selectable: true,
      selectableRangeMode: "click",
      ajaxURL: "/nomen/get-full-twins",
      filterMode: "remote",
      sortMode: "remote",
      pagination: true,
      paginationMode: "remote",
      paginationSize: this.rowPerPage,
      headerFilterLiveFilterDelay: 1000,
      footerElement: `<span><div class="paginator-limit"><span>На странице:</span>
                        <select id="RowPerPage"
                          onchange="const sc = document.getElementById('RowPerPage') ;
                                    dkcapp.$store.state.nomentwin.rowPerPage = sc.value ;
                                   "
                          >
                          <option value="20" selected>20</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="200">200</option>
                          <option value="500">500</option>
                        </select>
                        </div></span>`,
    });
  },
};
</script>

<style scoped>
.fulltwin_box {
  width: 100%;
}
.fulltwin_body {
  width: 100%;
}
</style>