var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "fv-container" }, [
    _c("div", [_vm._v(_vm._s(_vm.firmname))]),
    _vm._v(" "),
    _c("div", [
      _c("label", { attrs: { for: "firmList" } }, [_vm._v("заменить на:")]),
      _vm._v(" "),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.replacement,
              expression: "replacement",
            },
          ],
          attrs: { name: "firmList", id: "firmList" },
          on: {
            change: function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.replacement = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            },
          },
        },
        [
          _vm._l(_vm.firmlist, function (firm) {
            return _c(
              "option",
              { key: firm.idFirm, domProps: { value: firm.idFirm } },
              [_vm._v(_vm._s(firm.name))]
            )
          }),
          _vm._v(" "),
          _c("option", { staticClass: "new-item", attrs: { value: "0" } }, [
            _vm._v("[ не учитывать фирму при поиске ]"),
          ]),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }