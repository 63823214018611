export const coordConv = {
  methods: {
    toIndex(char) {
      return char.charCodeAt(0) - 'A'.charCodeAt(0)
    },
    toChar(index) {
      return String.fromCharCode( 'A'.charCodeAt(0) + index )
    },
  },
}
