import EdIzmExt from "../../pages/units/edizmExt.js"

export const EdIzmRef = {
  state: {
    ref: []
  },
  getters: {
    name: state => id => {
      const item = state.ref.find(ei => ei.idedizm == id)
      if (item)
        return item.shortname
      return ''
    },
    idedizm: state => einame => {
      const item = state.ref.find(edizm => edizm.match(einame))
      if (item)
        return item.idedizm
      return 0
    },
    basename: (state, getters, rootState) => einame => {
      const id = getters.idedizm(einame)
      if (id < 1)
        return einame
      return getters.name(id)
    },
    errorlist: state => edizmarr => {
      return edizmarr.filter(einame => !state.ref.some(item => {return item.match(einame)}))
    },
  },
  mutations: {
    /**
     * Загрузка справочника единиц измерения в хранилище
     * @param state - хранилище
     * @param edizmlist - массив объектов, элементов справочника единиц измерения
     */
    load(state, edizmlist) {
      state.ref = []
      if (!edizmlist)
        return

      edizmlist.forEach(edizm => {
        state.ref.push(new EdIzmExt(edizm))
      })
    },
  },
}
