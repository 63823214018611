<template>
  <div class="twin_box">
    <div class="prep_box" v-if="!userIsLoaded">
      Выполняется загрузка данных...
    </div>

    <div class="error_box" v-if="userIsLoaded && !userIsAdmin">
      У вас нет прав на использование этой функции программы
    </div>

    <div class="main_box" v-if="userIsLoaded && userIsAdmin">
      <splitpanes vertical>
        <div splitpanes-default="50">
          <nomen-twin-refs />
        </div>

        <div class="panel panel-default">
          <div class="panel-heading">Информация по дублям</div>
          <div class="panel-body">В этой панели будет информация по дублям</div>
        </div>
      </splitpanes>
    </div>
  </div>
</template>

<script>
import NomenTwinRefs from './components/NomenTwinRefs'
import Splitpanes from "splitpanes"
import "splitpanes/dist/splitpanes.css"
import "../css/splitstyle.css"

export default {
  name: "NomenTwin",
  props: {},
  components: { NomenTwinRefs, Splitpanes },
  data() {
    return {};
  },
  computed: {
    userIsAdmin: function () {
      return this.$store.state.user.idRole == 1;
    },
    userIsLoaded: function () {
      return this.$store.state.user.id;
    },
  },
  methods: {},
};
</script>

<style scoped>
.twin_box {
  width: 100%;
  height: 80vh;
}

.prep_box {
  width: 500px;
  margin: 10% auto 0 auto;
  padding: 60px;
  border: 2px solid black;
  text-align: center;
  font-size: 40px;
  font-weight: bolder;
  color: #333;
}

.error_box {
  width: 500px;
  margin: 10% auto 0 auto;
  padding: 60px;
  border: 2px solid black;
  text-align: center;
  font-size: 40px;
  font-weight: bolder;
  color: red;
}
</style>