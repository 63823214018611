var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "popper",
    {
      attrs: {
        trigger: "hover",
        options: {
          placement: "top",
          modifiers: { offset: { offset: "10px,10px" } },
        },
      },
    },
    [
      _c("div", { staticClass: "popper" }, [_vm._v(_vm._s(_vm.hint))]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "dtb_btn",
          class: {
            dtb_bigbtn: _vm.bigbtn,
            dtb_smallbtn: !_vm.bigbtn,
            dbtn_begingroup: _vm.begingroup,
          },
          attrs: { slot: "reference" },
          on: { click: _vm.buttonClick },
          slot: "reference",
        },
        [
          _c("div", { class: _vm.class4icon }),
          _vm._v(" "),
          _c("div", { staticClass: "dtb_title" }, [_vm._v(_vm._s(_vm.title))]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }