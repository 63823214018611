export const queryAJAX = {
    methods: {
        queryAjaxAps: function(url, data) {
            return axios({
                method: 'post',
                headers: {'X-CSRF-Token': tkn_csfr},
                url,
                data,
            })
        },

        queryAjax: function(url, data) {
            return this.queryAjaxAps(this.baseUrl + url, data)        
        },

        queryA: function(url, data) {
            if (!data) {                
                return this.queryAjax(url, false)
            }
            const keys = Object.keys(data)
            const fd = new FormData() 
            keys.forEach(key => { fd.append(key, JSON.stringify(data[key]))})
            return this.queryAjax(url, fd)
        }
    },
}