<template>
  <details open>
    <summary>Содержимое буфера обмена</summary>
    <div id="cbrddata" ref="cbrddata"></div>
  </details>
</template>

<script>
    import { TabulatorFull as Tabulator } from 'tabulator-tables'
    import {coordConv} from '../mixins/cbrdCoorConverter'
    export default {
        name: "specClipbrdTunerClipboard",
        components: {Tabulator},
        mixins: [coordConv],
        data() {
          return {
            tabulator: false,
          }
        },
        methods: {
          execute(data) {
            const fields = []
            for (let i = 0; i < data[0].length && i < 26; i++) {
              fields.push({
                'field': this.toChar(i),
                'title': this.toChar(i),
                'visible': true
              })
            }

            const tableData = []
            data.forEach(rd => {
              const row = {}
              rd.forEach(fd => {
                const fld = this.toChar(Object.keys(row).length)
                row[fld] = fd
              })
              tableData.push(row)
            })

            if (this.tabulator) {
              this.tabulator.setColumns(fields)
              this.tabulator.setData(tableData)
            }
            else {
              const options = {
                data: tableData,
                columns: fields,
                height: 150,
                placeholder: 'данные в буфере обмена не обнаружены',
                layout: "fitColumns",
              }

              this.tabulator = new Tabulator('#cbrddata', options)
            }
          },
        },
        mounted() {

        },
    }
</script>

<style scoped>

</style>
