<template>
   <transition name="modal-fade">
      <div class="dkc-modal-backdrop">
         <div class="dkc-modal"
              role="dialog"
              aria-labelledby="modalTitle"
              aria-describedby="modalDescription">
            <header class="modal-header" id="modalTitle">
               <slot name="header">This is the default tile!</slot>
            </header>
            <section class="modal-body" id="modalDescription">
               <slot name="body">I'm the default body!</slot>
            </section>
            <footer class="modal-dlg-footer">
                <div class="extra_buttons">
                  <slot name="buttons"></slot>
                </div>
                <div class="standart_buttons">
                  <button type="button" class="btn btn-primary" v-show="showButton('ok')" @click="execButton('ok')">Ok</button>
                  <button type="button" class="btn btn-primary" v-show="showButton('yes')" @click="execButton('yes')">Да</button>
                  <button type="button" class="btn btn-danger" v-show="showButton('no')" @click="close">Нет</button>
                  <button type="button" class="btn btn-danger" v-show="showButton('cancel')" @click="close">Отмена</button>
                  <button type="button" class="btn btn-danger" v-show="showButton('close')" @click="close">Закрыть</button>
                </div>
            </footer>
         </div>
      </div>
   </transition>
</template>

<script>
export default {
   name: 'dkcModalDlg',
   props: {
      buttons: Array,
      actions: Object,
      autoclose: {
         type: Boolean,
         default: true
      },
   },
   methods: {
      close() {
        this.$emit('close');
      },
      execButton(buttonCode) {
         const action = this.actions[buttonCode];
         switch (typeof action) {
            case "string":
               window.location = action;
               break;
            case "function":
               action();
               break;
         }
         if (this.autoclose) {
            this.close()
         }
         //this.$emit('close');
      },
      showButton(buttonCode) {
         for (let i = 0; i < this.buttons.length; i++) {
            if (this.buttons[i].toLowerCase() == buttonCode.toLowerCase()) {
               return true;
            }
         }
         return false;
      },
   },
};
</script>

<style scoped>
.dkc-modal-backdrop {
   position: fixed;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.3);
   display: flex;
   justify-content: center;
   align-items: center;
   z-index: 1000;
}

.dkc-modal {
   display: flex;
   flex-direction: column;
   max-height: 99vh;
   overflow-x: auto;
   background: #FFFFFF;
   box-shadow: 2px 2px 20px 1px;
}

.modal-header {
   padding: 15px;
   display: flex;
}

.modal-header {
   border-bottom: 1px solid #eeeeee;
   color: #4AAE9B;
   justify-content: space-between;
}

.modal-dlg-footer {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1ft 1fr ;
  grid-template-areas: "extra_buttons standart_buttons" ;
  border-top: 1px solid #eeeeee;
}

.standart_buttons {
  text-align: right;
  margin: 8px 16px 12px 0;
}

.extra_buttons {
  margin: 8px 0 12px 16px;
  text-align: left;
}

.modal-body {
   position: relative;
   padding: 20px 10px;
}

.btn-close {
   border: none;
   font-size: 20px;
   padding: 20px;
   cursor: pointer;
   font-weight: bold;
   color: #4AAE9B;
   background: transparent;
}

.btn-green {
   color: white;
   background: #4AAE9B;
   border: 1px solid #4AAE9B;
   border-radius: 2px;
}
</style>
