<template>
  <div class="fv-container">
    <div>{{firmname}}</div>
    <div>
      <label for="firmList">заменить на:</label>
      <select name="firmList" id="firmList" v-model="replacement">
        <option :value="firm.idFirm" v-for="firm in firmlist" :key="firm.idFirm">{{firm.name}}</option>
        <option value="0" class="new-item">[ не учитывать фирму при поиске ]</option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: "firmValidatorItem",
  props: ["firmname", "firmlist"],
  data() {
    return {
      replacement: -1,
    }
  },
  watch: {
    replacement(newValue, oldValue) {
      this.$emit('change', { 'new': newValue, 'old': oldValue })
    },
  },
}
</script>

<style scoped>
  .fv-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding-left: 16px;
    padding-right: 8px;
  }

  .fv-container label {
    margin-right: 4px;
  }

  .new-item {
    color: grey;
    font-style: italic;
  }
</style>
