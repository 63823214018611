import RangeReader from './RangeNomenGroupReader'
import HorReader from './HorNomenGroupReader'
import VerReader from './VerNomenGroupReader'

export default function NomenGroupReaderFactory (data) {

  const {xls, colMap: colmap, foliage, branch} = data
  const ver = 1
  const hor = 2
  const range = 3

  /**
   * Определяет, находится ли колонка среди колонок с данными номенклатуры
   * @param column - имя колонки
   * @param colmap - объект ColsMap с наименованием колонок для данных номенклатуры и гупп номенклатуры
   * @returns {boolean} - возвращает true, если заданная колонка указывает на данные номенклатуры
   */
  function inNomenZone(column, colmap) {
    if (!column)
      return false

    const cols = Object.values({...colmap,
      _col_firstGroup: '',
      _col_lastGroup: ''
    })
    cols.forEach((col, index) => {
      if (typeof (col) == 'number')
        cols[index] = xls.encode_col(col)
    })

    return cols.indexOf(column) > 0
  }

  /**
   * Определяет тип размещения групп номенклатуры в таблице Excel
   * 'ver' - вертикальное размещение групп исключающее данные номенклатуры в той же строчке
   * 'hor' - горизонтальное расположение групп, при котором группы находятся в своих колонках
   * @param colmap - объект ColsMap с наименованием колонок для данных номенклатуры и групп номенклатуры
   * @return {string} - тип размещения групп: ver/hor
   */
  function getAllocationType (colmap)  {
    if (inNomenZone(colmap.firstGroup, colmap) || inNomenZone(colmap.lastGroup, colmap))
      return ver

    if (colmap.groupRange)
      return range

    return hor
  }

  switch (getAllocationType(colmap)) {
    case ver: return new VerReader(data)
    case hor: return new HorReader(data)
    case range: return new RangeReader(data)
  }
}
