var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "dkc-modal-dlg",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isModalDlgVisible,
              expression: "isModalDlgVisible",
            },
          ],
          attrs: { buttons: _vm.buttons, actions: _vm.actions },
          on: { close: _vm.closeModalDlg },
        },
        [
          _c("div", {
            attrs: { slot: "body" },
            domProps: { innerHTML: _vm._s(_vm.bodyText) },
            slot: "body",
          }),
          _vm._v(" "),
          _c("span", { attrs: { slot: "header" }, slot: "header" }, [
            _vm._v(_vm._s(_vm.headerText)),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "bpk-toolbar-panel",
        [
          _c("dkc-tool-button", {
            attrs: {
              title: "Новая спецификация",
              hint: "Создать новую спецификацию",
              iconclass: "dbtn-create",
              action: "/spec/create",
            },
          }),
          _vm._v(" "),
          _c("dkc-tool-button", {
            attrs: {
              title: "Изменить спецификацию",
              hint: "Изменить выбранную спецификацию",
              iconclass: "dbtn-edit",
              action: _vm.editSpec,
            },
          }),
          _vm._v(" "),
          _c("dkc-tool-button", {
            attrs: {
              title: "Удалить спецификацию",
              hint: "Удалить выбранную спецификацию",
              iconclass: "dbtn-delete",
              action: _vm.queryDeleteSpec,
            },
          }),
          _vm._v(" "),
          _c("dkc-tool-button", {
            attrs: {
              title: "Очистить фильтры",
              hint: "Очистить фильтры",
              iconclass: "dbtn_filter_clear",
              control: "tabulator-tableHolder",
              begingroup: true,
              action: _vm.clearFilters,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("dkc-grid", { ref: "speclist", staticClass: "spec-table" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }