import getNormEdIzm from './edizmnorm'

export default function EdIzmExt (edizmref) {
  const self = this
  var _idedizm = edizmref.idedizm
  let _name = edizmref.name
  let _shortname = edizmref.shortname
  let _synonyms = edizmref.synonyms == undefined ? '' : edizmref.synonyms
  let _changed = false

  Object.defineProperty(this, 'idedizm', {
    enumerable: true,
    get: function() {return _idedizm},
    set: function (id) {
      _idedizm = id
    }
  })

  Object.defineProperty(this, 'name', {
    enumerable: true,
    get: function() {return _name},
    set: function (name) {
      _name = name
    }
  })

  Object.defineProperty(this, 'shortname', {
    enumerable: true,
    get: function() {return _shortname},
    set: function (shname) {
      _shortname = shname
    }
  })

  Object.defineProperty(this, 'synonyms', {
    enumerable: true,
    get: function() {return _synonyms},
  })

  Object.defineProperty(this, 'changed', {
    enumerable: true,
    get: function() {return _changed || _idedizm < 0},
  })

  Object.defineProperty(this, 'symlst', {
    enumerable: true,
    get: function() {return _synonyms.split('\t')},
  })

  this.add = function (variant) {
    const sl = this.symlst
    if (!sl.includes(variant)) {
      sl.push(variant)
      _synonyms = sl.join('\t')
      _changed  = true
    }

    return this
  }

  this.compare = function (variant) {
    const vr = getNormEdIzm(variant)
    return  getNormEdIzm(this.name) == vr
      ||  getNormEdIzm(this.shortname) == vr
      || this.symlst.some(item => { return getNormEdIzm(item) == vr})
  }

  this.match = function (variant) {
    return this.compare(variant)
  }

  this.save = function () {
    return axios({
      method: 'post',
      url: '/edizm/save',
      headers: {'X-CSRF-Token': tkn_csfr},
      params: {
        'idedizm': _idedizm,
        'name': _name,
        'shortname': _shortname,
        'synonyms': _synonyms
      },
    })
  }
}
