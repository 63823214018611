var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    {
      class: _vm.itemClass(_vm.item),
      on: {
        click: function ($event) {
          return _vm.handleClick(_vm.item, $event)
        },
      },
    },
    [
      !_vm.beginGroup
        ? _c("div", { staticClass: "cm-label" }, [
            _vm.item.icon
              ? _c("img", {
                  attrs: {
                    src: _vm.item.icon,
                    width: _vm.item.icon_size ? _vm.item.icon_size : 16,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("span", { staticClass: "cm-item-label" }, [
              _vm._v(_vm._s(_vm.item.label)),
            ]),
            _vm._v(" "),
            _vm.item.children
              ? _c("span", { staticClass: "children-indicator" }, [_vm._v("▶")])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.beginGroup
        ? _c("div", { staticClass: "begin-menu-group" })
        : _vm._e(),
      _vm._v(" "),
      _vm.item.children
        ? _c(
            "ul",
            { staticClass: "context-menu" },
            _vm._l(_vm.item.children, function (childItem, index) {
              return _c("context-menu-item", {
                key: index,
                attrs: { item: childItem },
              })
            }),
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }