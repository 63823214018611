<template>
    <div class="col-lg-8 col-lg-offset-2">
        <form @submit.prevent="onSubmit">
            <div class="form-group">
                <label class="control-label">Группа номенклатуры</label>
                <dkc-ng-navy :idgroup="idgroup" @select_group="selectGroup"></dkc-ng-navy>
            </div>

            <div class="control-group">
                <div class="form-group field-nomen-code">
                    <label for="code" class="control-label">Код</label>
                    <input type="text" class="form-control" name="code" v-model="code">
                    <div class="help-block"></div>
                </div>
                <div class="form-group field-nomen-code">
                  <label for="mark" class="control-label">Тип/марка</label>
                  <input type="text" class="form-control" name="mark" v-model="mark">
                  <div class="help-block"></div>
                </div>
                <div class="form-group field-nomen-name"
                     :class="{'has-error': $v.name.$error,
                              'has-success': $v.name.$dirty && $v.name.required }">
                    <label for="name" class="control-label">Наименование</label>
                    <input type="text" class="form-control" name="name" v-model="name"  @blur="$v.name.$touch()">
                    <div class="help-block" v-if="$v.name.$error">Наименование номенклатуры должно быть заполнено</div>
                </div>
                <div class="link-container">
                    <img src="../images/url.svg" width="32px" height="32px" class="link" @click="URLBtnClick">
                </div>
            </div>
            <div class="control-group">
              <div class="form-group">
                  <label for="idFirm" class="control-label">Изготовитель</label>
                  <select name="idFirm" id="idFirm" v-model="idFirm" class="form-control">
                      <option v-for="firm in firmList"
                              :label="firm.name"
                              :value="firm.idFirm">
                      </option>
                  </select>
                  <div class="help-block"></div>
              </div>
              <div class="form-group">
                <label for="idedizm" class="control-label">Базовая eдиница измерения</label>
                <select name="idedizm" id="idedizm" v-model="idedizm" class="form-control">
                  <option v-for="edizm in edizmList"
                          :label = "edizm.shortname"
                          :value="edizm.idedizm"
                  >
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="isActive" class="control-label">Статус</label>
                <select name="isActive" id="isActive" v-model="isActive" class="form-control">
                  <option value="0">аннулированная номенклатура</option>
                  <option value="1">действующая номенклатура</option>
                </select>
              </div>
            </div>

            <div class="control-group">

                <div class="control-group">
                    <div class="form-group">
                        <label for="price" class="control-label">цена</label>
                        <input type="text" class="form-control" id="price" v-model="price">
                    </div>
                    <div class="form-group">
                        <label for="priceDate" class="control-label">на дату</label>
                        <input type="text" class="form-control" id="priceDate" readonly v-model="priceDate">
                    </div>
                </div>
                <div class="control-group">
                    <div class="form-group">
                        <label for="rest" class="control-label">количество</label>
                        <input type="text" class="form-control" id="rest" v-model="rest">
                    </div>
                    <div class="form-group">
                        <label for="restDate" class="control-label">на дату</label>
                        <input type="text" class="form-control" id="restDate" readonly v-model="restDate">
                    </div>
                </div>
            </div>

            <div class="form-group has-error" v-if="sverror">
                <div class="control-label error-message">{{sverrormsg}}</div>
            </div>

            <div class="form-group button-group">
                <button type="submit" class="btn btn-success" :disabled="$v.$invalid">Сохранить</button>
                <a :href="backUrl" class="btn btn-danger" >Отмена</a>
            </div>
        </form>
    </div>
</template>

<script>
    import DkcNgNavy from './components/dkcNgNavy'
    import getUrlVar from './units/urlparser'
    import required from 'vuelidate/lib/validators/required'
    import qs from 'qs'

    export default {
        name: "NomenEditor",
        components: {DkcNgNavy},
        data() {
            return {
                idnomen: 0,
                idgroup: 0,
                idFirm:  1,
                idedizm: 1,
                code: '',
                mark: '',
                name: '',
                rest: 0,
                restDate: '',
                price: 0,
                priceDate: '',
                isActive: 1,
                firmList: [],
                edizmList: [],
                sverror: false,
                sverrormsg: '',
            }
        },
        computed: {
            backUrl: function() {
                let idng = getUrlVar('idgroup')
                if (!idng) {
                    idng = this.idgroup
                }

                return this.getReturnUrl(this.idnomen, idng)
            },
        },
        watch: {
            idnomen: function(){
                if (this.idnomen) {
                    const cmp = this
                    axios({
                        method: 'get',
                        url: this.baseUrl + '/nomen/get',
                        params: {
                            idnomen: this.idnomen,
                        }
                    })
                        .then(response => {
                            const nomen     = response.data
                            this.idgroup    = nomen['idNomenGroup']
                            this.idFirm     = nomen['idFirm']
                            this.idedizm    = nomen['idedizm']
                            this.code       = nomen['code']
                            this.mark       = nomen['mark']
                            this.name       = nomen['name']
                            this.rest       = nomen['rest']
                            this.restDate   = nomen['restDate']
                            this.price      = nomen['price']
                            this.priceDate  = nomen['priceDate']
                            this.isActive   = nomen['isActive']
                        })
                        .catch(error => {
                            console.error('Ошибка загрузки данных по номенклатуре: ' + error.message)
                        })
                }
            },
            price: function() {
                const date = new Date() ;
                const options = {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                };
                const ds = date.toLocaleDateString('ru', options) ;
            },
        },
        methods: {
            selectGroup: function(idng) {
                this.idgroup = idng
            },
            getReturnUrl: function(idnomen, idgroup) {
                return this.baseUrl + '/nomen/index?idgroup=' + idgroup + '&idnomen=' + idnomen
            },
            returnToRef: function (respData) {
                window.location = this.getReturnUrl(respData.idnomen, respData.idgroup)
            },
          getNomenInfo: function () {
            return {
              idnomen:  this.idnomen,
              idgroup:  this.idgroup,
              idFirm:   this.idFirm,
              code:     this.code,
              mark:     this.mark,
              name:     this.name,
              isActive: this.isActive,
            }
          },
          URLBtnClick: function() {
            const nomen = this.getNomenInfo()

            axios({
              method: 'post',
              url: this.baseUrl + '/nomen/get-url',
              headers: {
                'content-type': 'application/x-www-form-urlencoded',
                'X-CSRF-Token': tkn_csfr
              },
              data: qs.stringify(nomen)
            })
              .then(response => {
                const url = response.data.url
                if (!url || url == undefined)
                  return

                const win = window.open(url, '_blank')
                win.focus()
              })
              .catch(error => this.showErrorMessage('Ошибка поиска номенклатуры в сети Интернет', error))
          },

            onSubmit() {
                const cmp  = this ;
                const url = this.baseUrl + '/nomen/save'
                axios({
                    method: 'post',
                    url,
                    headers: {'X-CSRF-Token': tkn_csfr},
                    params: {
                        idnomen: this.idnomen,
                        idgroup: this.idgroup,
                        idFirm:  this.idFirm,
                        idedizm: this.idedizm,
                        code:    this.code,
                        mark:    this.mark,
                        name:    this.name,
                        rest:    this.rest,
                        price:   this.price,
                        isActive: this.isActive,
                    },
                })
                    .then(response => {cmp.returnToRef(response.data)})
                    .catch(error => {
                        const msg = 'Ошибка сохранения: ' + error.message ;
                        cmp.sverrormsg = msg ;
                        cmp.sverror = true ;
                    }) ;
            },
        },
        mounted() {
            const cmp = this
            axios({
                method: 'get',
                url: this.baseUrl + '/nomen/norma'
            })
                .then(response => {
                    cmp.firmList  = response.data['firms']
                    cmp.edizmList = response.data['edizm']
                })
                .catch(error => {console.error('Ошибка загрузки списка производителей: ' + error.message)})

            this.idgroup = getUrlVar('idgroup')
            this.idnomen = getUrlVar('idnomen')
            window.dkcapp.title = 'Редактирование справочника номенклатуры'
        },
        validations: {
            name: {
                required
            },
        },
    }
</script>

<style scoped>
    .control-group {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    .field-nomen-code {
        width: 20%;
    }

    .field-nomen-name {
        width: 80%;
    }

    .link-container {
        margin-top: 23px;
    }

    .link {
        cursor: pointer;
    }

    .error-message {
        text-align: center;
    }
</style>
