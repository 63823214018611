import XLSX from 'xlsx'

export default function NomenImportLocator(xls) {

    const _xls = xls

    this.searchHeader = function (sheet) {
        const map = new Array(20)
        const fr  = getFirstRow(sheet)
        for (let i = 0; i < map.length; i++) {
            map[i] = getRowData(sheet, i + fr)
        }
        let max = 0
        let row = 0
        let count = 0
        map.forEach(item => {
            count = item.count()
            if (count > max) {
                max = count
                row = item.row
            }
        })

        return row
    }

    this.searchColumns = function (sheet, row) {
        if (row == undefined)
                row = searchHeader(sheet)
        return getRowData(sheet, row)
    }

    function getFirstRow(sheet) {
        return _xls.firstRow
    }

    function getFirstCol(sheet) {
        return _xls.firstCol
    }

    function getLastCol(sheet) {
        return _xls.lastCol
    }

    function getMark() {
        return {
            fields: [
                {field: 'firstGroup', col: -1, row: 0, titles: []},
                {field: 'lastGroup', col: -1, row: 0, titles: []},
                {field: 'code', col: -1, row: 0, titles: ['Код', 'Артикул', 'Код EAN13', 'Референс']},
                {field: 'mark', col: -1, row: 0, titles: ['Артикул', 'Тип']},
                {field: 'name', col: -1, row: 0, titles: ['Описание', 'Расширенное наименование', 'Полное наименование',
                                                         'Наименование', 'Номенклатура', 'Описание референса']},
                {field: 'price', col: -1, row: 0, titles: ['Цена', 'Стоимость', 'Базовая цена, с НДС', 'цена с НДС',
                                                          'Розница,         с НДС', 'Цена с НДС, руб.', 'Цена розница', 'Цена РРЦ']},
                {field: 'rest', col: -1, row: 0, titles: ['Свободный остаток', 'Остаток', 'Кол-во']},
            ],
            row: 0,
            busy: function (col) {
              return this.fields.some((field, index) => {
                if (index > 1)
                  return field.col == col
                return false
              })
            },
            count: function() {
                const self = this
                let count = 0
                this.fields.forEach((field) => {
                    if (field.col > 0)
                        count++
                })

                return count ;
            }
        }
    }

    function cellval(sheet, col, row) {
        return _xls.value(col, row)
    }

    function getRowData(sheet, row) {
        const self = this
        const mark = getMark()
        mark.row = row
        mark.fields.forEach(field => {
            field.row = row
            if (field.titles.length == 0)
                return

            let value = ''
            let col = ''
            for (let i = getFirstCol(sheet); i <= getLastCol(sheet); i++) {
                col = i
                value = cellval(sheet, i, row)
                if (value && typeof value == 'string') {
                    if (field.titles.find(title => {
                        try {
                            return value.toLowerCase().indexOf(title.toLowerCase()) > -1
                        }
                        catch (e) {
                            console.error(e)
                            return title.toLowerCase() == value
                        }
                    }) != undefined
                    && !mark.busy(i)) {
                        field.col = i
                        i = getLastCol(sheet) + 1
                    }
                }
            }
        })

        return mark
    }
}
