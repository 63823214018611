export default function RowsMap() {
    this._firstRow = 0
    this._lastRow = 0
    this._onchange = false
}

RowsMap.prototype = {
    get firstRow () {
        return +this._firstRow
    },
    set firstRow (newRow) {
        const oldRow = this._firstRow
        this._firstRow = newRow
        if (this._onchange && newRow != oldRow)
            this._onchange(oldRow, newRow)
    },
    get lastRow () {
        return +this._lastRow
    },
    set lastRow (newRow) {
        this._lastRow = newRow
    },
    get onchange () {
        return this._onchange
    },
    set onchange (f) {
        this._onchange = f
    },
}
