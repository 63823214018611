var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { width: "100%" } }, [
    _vm.pathLen > 1
      ? _c(
          "div",
          { staticClass: "link-container" },
          [
            _vm.selectedID > 0
              ? _c(
                  "div",
                  { staticClass: "link-panel" },
                  _vm._l(_vm.paths, function (path) {
                    return path.id != 0
                      ? _c("div", { staticClass: "link-block" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-link",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.selectGroup(path.id)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(path.name) +
                                  "\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "path-delim" }, [
                            _vm._v(">"),
                          ]),
                        ])
                      : _vm._e()
                  }),
                  0
                )
              : _c("div", [_vm._v("Корневая группа")]),
            _vm._v(" "),
            !_vm.hideToolbar
              ? _c("div", { staticClass: "speed-btn-container" }, [
                  _c(
                    "button",
                    {
                      staticClass: "speed-btn",
                      attrs: { type: "button" },
                      on: { click: _vm.showModalDlg },
                    },
                    [_vm._v("...")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "speed-btn",
                      attrs: { type: "button" },
                      on: { click: _vm.setRootGroup },
                    },
                    [_vm._v("/")]
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "dkc-modal-dlg",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isModalDlgVisible,
                    expression: "isModalDlgVisible",
                  },
                ],
                attrs: { buttons: _vm.buttons, actions: _vm.actions },
                on: {
                  close: function ($event) {
                    _vm.isModalDlgVisible = false
                  },
                },
              },
              [
                _c("span", { attrs: { slot: "header" }, slot: "header" }, [
                  _vm._v("Выберите группу номенклатуры"),
                ]),
                _vm._v(" "),
                _c("dkc-nomen-group", {
                  ref: "ngtree",
                  attrs: {
                    slot: "body",
                    height: "400px",
                    width: "400px",
                    "hide-toolbar": "true",
                  },
                  slot: "body",
                }),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }