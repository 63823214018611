<template>
  <div>
    <dkc-modal-dlg
      :buttons="excelbtns"
      :actions="excelacts"
      :autoclose="false"
      @close="click_close"
    >
      >
      <span slot="header">Импорт спецификации из Excel</span>
      <excel-import-tuner
        ref="excelimp"
        slot="body"
        :fieldDefs="fieldDefs"
        :colTitles="colTitles"
        @loaded="changeWorkBook"
      >
      </excel-import-tuner>

      <div class="toggle_block" slot="buttons" v-show="workbook_exists">
        <div class="toggle_label">
          Поиск номенклатуры заказчика после импорта
        </div>
        <div class="toggle_control">
          <VueToggles
            :value="searchNomenAfterImport"
            @click="toggleSearch"
            height="18"
            width="120"
            checkedText="Выполнить"
            uncheckedText="Не выполнять"
            checkedBg="skyblue"
            uncheckedBg="gray"
          />
        </div>
      </div>
    </dkc-modal-dlg>

    <edizm-validator-vue ref="eivld" />
    <firm-validator ref="fvld" />
  </div>
</template>

<script>
import dkcModalDlg from "./dkcModalDlg.vue";
import ExcelImportTuner from "./ExcelImportTuner.vue";
import edizmValidatorVue from "./edizmValidator.vue";
import FirmValidator from "./firmValidator";
import VueToggles from "vue-toggles";
import SequenceID from "../units/SequenceID";

export default {
  name: "ExcelImportTunerDialog",
  components: {
    dkcModalDlg,
    ExcelImportTuner,
    VueToggles,
    edizmValidatorVue,
    FirmValidator,
  },
  props: ["fieldDefs", "colTitles", "firm"],
  data() {
    return {
      workbook_exists: false,
      searchNomenAfterImport: false,
      excelbtns: ["ok", "close"],
      excelacts: {
        ok: this.click_ok,
        close: this.click_close,
      },
    };
  },
  computed: {
    excelData() {
      return this.$refs.excelimp.excelData;
    },
  },
  methods: {
    getUniqueFieldValues(excelData, fields) {
      const values = [];
      excelData.forEach((row) => {
        fields.forEach((field) => {
          if (row[field]) {
            values.push(row[field]);
          }
        });
      });

      return [...new Set(values)];
    },
    generateData() {
      const excelData = this.excelData;
      if (!excelData.length) {
        return;
      }

      const seqID = new SequenceID();
      let pos = 0;
      for (let i = 0; i < excelData.length; i++) {
        if (!("specpos" in excelData[i])) {
          excelData[i].specpos = "pos" in excelData[i] ? excelData[i].pos : "";
        }

        if (!("pos" in excelData[i])) {
          if (excelData[i].c_code || excelData[i].c_name) pos++;

          excelData[i].pos = pos;
        } else if (!excelData[i].pos && i > 0) {
          excelData[i].pos = excelData[i - 1].pos;
        }

        if (excelData[i].c_code == undefined) excelData[i].c_code = "";
        if (excelData[i].c_mark == undefined) excelData[i].c_mark = "";
        if (excelData[i].c_name == undefined) excelData[i].c_name = "";

        excelData[i].sign = "D";
        excelData[i].innerID = seqID.nextval();
      }

      return excelData;
    },
    ready() {
      this.$emit("click-ok", {
        excel: this.excelData,
        search_immediate: this.searchNomenAfterImport,
      });
    },
    checkEdIzm(excelData) {
      const ref = this.$store.state.edizmRef.ref;
      const eilist = this.getUniqueFieldValues(excelData, ["c_idedizm", "d_idedizm"]);
      return new Promise((resolve, reject) => {
        this.$refs.eivld.executeAsyc(excelData, ref, eilist, resolve, reject);
      });
    },
    checkFirms(excelData) {
      const ref = this.$store.state.firm.ref;
      const firms = this.getUniqueFieldValues(excelData, ["c_idFirm"]);
      return new Promise((resolve, reject) => {
        this.$refs.fvld.executeAsyc({excelData, ref, firms, resolve, reject});
      });
    },
    click_ok() {
      this.checkEdIzm(this.generateData())
        .then((data) => {
          return this.checkFirms(data.excelData);
        })
        .then((data) => {
          this.ready(data.excelData);
          this.close();
        })
        .catch((error) => {
          console.error(error)
          this.close();
        });
    },
    click_close() {
      this.cancel()
    },
    cancel() {
      this.close()
    },
    close() {
      this.$emit("click-close")
    },
    changeWorkBook(workbook) {
      this.workbook_exists = !!workbook
    },
    toggleSearch() {
      this.searchNomenAfterImport = !this.searchNomenAfterImport;
    },
  },
};
</script>

<style scoped>
.toggle_label {
  width: 44ch;
  text-align: right;
}
</style>
