export default function SequenceID() {
  let _id = 0

  this.nextval = () => {
    return ++_id
  }

  this.reset = () => {
    _id = 0
  }
}
