<template>
  <div style="width: 1000px;">

    <dkc-modal-dlg
      :buttons="buttons"
      :actions="actions"
      v-show="visibleDialog"
      @close="closeModalDlg"
    >
      <span slot="header">Выбери номенклатуру</span>
      <div slot="body">
        <div v-if="circleAnimationVisible">
          <circle-animation :title="progresstitle"></circle-animation>
        </div>
        <nomen-selector-header :firms="firms" :c_nomen="source[0]" :r_nomen="source[1]"
                                :inDeepSearch="inDeepSearch"
                                :deepSearchStep="deepSearchStep"
                                :deepSearchMessage="deepSearchMessage"
                                @dosearch="dosearch($event)"></nomen-selector-header>

        <div class="nomen_selector_table">
          <nomen-selector-table
            ref="nst"
            :firms="firms"
            :visible="visible"
            :inSearch="inSearch"
            :inDeepSearch="inDeepSearch"
            :idFirm="idFirm"
            :nomenForSearch="nomenForSearch"
            @select="select"
            @nextdeepsearchstep="nextDeepSearchStep"
            @stopdeepsearch="stopDeepSearch"
            @stopsearch="stopSearch"
          ></nomen-selector-table>
        </div>
      </div>
    </dkc-modal-dlg>
  </div>
</template>

<script>
import dkcModalDlg from './dkcModalDlg.vue'
import NomenSelectorHeader from './NomenSelectorHeader.vue'
import NomenSelectorTable from './NomenSelectorTable.vue'
import circleAnimation from "./circleAnimation.vue"
export default {
  name: "NomenSelectorDialog",
  props: ["firms", "idFirm", "source", "visible"],
  components: { dkcModalDlg, NomenSelectorHeader, NomenSelectorTable, circleAnimation },
  data() {
    return {
      nomenForSearch: {},
      inSearch: false,
      inDeepSearch: false,
      deepSearchStep: 1,
      deepSearchMessage: '',
      /* useDeepSearch: false, */
      visibleDialog: false,
      buttons: ["ok", "close"],
      actions: {
        ok: this.select,
      },
      circleAnimationVisible: false,
      progresstitle: 'Выполняется поиск',
    };
  },
  watch: {
    visible: function (value) {
      this.visibleDialog = value;
    },
  },
  methods: {
    closeModalDlg() {
      this.$emit("close")
      this.$refs.nst.clearTable()
    },
    showCircleAnimation () {
      this.circleAnimationVisible = true
    },
    hideCircleAnimation () {
      this.circleAnimationVisible = false
    },
    dosearch (data) {
      this.nomenForSearch = data
      if (data.useDeepSearch) {
        this.runDeepSearch()
      }
      else {
        this.showCircleAnimation()
        this.inSearch = true
      }
    },
    stopSearch () {
      this.inSearch = false
      this.hideCircleAnimation()
    },
    runDeepSearch () {
      this.deepSearchStep = 1
      this.inDeepSearch = true
      this.deepSearchMessage = 'Выполняется углубленный поиск...'
    },
    stopDeepSearch () {
      this.inDeepSearch = false
      this.deepSearchMessage = ''
    },
    nextDeepSearchStep ( step ) {
      this.deepSearchStep = step
    },
    select() {
      const data = this.$refs.nst.selected_nomen;
      const idFirm = +data.idFirm;
      if (isNaN(idFirm) && data.idFirm) {
        data.firmname = data.idFirm;
        data.idFirm = this.firms.indexOf(data.firmname);
      }
      this.$emit("selectnomen", data);
      this.closeModalDlg();
    },
  },
};
</script>

<style scoped>
.srccell {
  padding: 4px 8px 4px 4px;
}

.srctitle {
  font-weight: bold;
}

.srcheader {
  text-align: center;
  font-weight: bold;
}

.nomen_selector_table {
  height: 440px ;
}

</style>
