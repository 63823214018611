<template>
    <div>
        <bpk-toolbar-panel v-if="!hideToolbar">
            <dkc-tool-button
                  title="Новая номенклатура"
                  hint="Регистрация новой номенклатуры"
                  iconclass="dbtn-create"
                  control="tabulator-tableHolder"
                  :action="createNomen"
                  v-if="grant"
            />
            <dkc-tool-button
                  title="Изменить номенклатуру"
                  hint="Изменить данные о номенклатуре"
                  iconclass="dbtn-edit"
                  control="tabulator-tableHolder"
                  :action="editNomen"
                  v-if="grant"
            />
            <dkc-tool-button
                 title="Удалить номенклатуру"
                 hint="Удалить номенклатуру из справочника"
                 iconclass="dbtn-delete"
                 control="tabulator-tableHolder"
                 :action="queryDeleteNomen"
                 v-if="grant"
            />

            <dkc-tool-button
               title="Копировать"
               hint="Копировать в буфер обмена"
               iconclass="sbtn-copy"
               :begingroup="true"
               control="tabulator-tableHolder"
               :action="selectClipboardOperation"
            />


            <dkc-search-control ref="nmsearch" @onsearch="doSearchNomen" />
            <dkc-tool-button
               v-show="searchText"
               title="Перейти в группу"
               hint="Перейти в группу номенклатуры"
               iconclass="dbtn-prior"
               control="tabulator-tableHolder"
               :action="gotoNomenGroup"
            />            
        </bpk-toolbar-panel>

        <div ref="table" />
        <dkc-modal-dlg
                :buttons="buttons"
                :actions="actions"
                v-show="isModalDlgVisible"
                @close="closeModalDlg">
            <div slot="body" v-html="bodyText"></div>
            <span slot="header">{{headerText}}</span>
        </dkc-modal-dlg>
        <ContextMenu ref="contextMenu" :menu-items="menuItems"/>
      <div id="clpbuffer" style="opacity: 0"></div> <!-- --> <br>
    </div>
</template>

<script>
    import dkcModalDlg from './dkcModalDlg'
    import bpkToolbarPanel from './bpkToolbarPanel'
    import dkcToolButton from './dkcToolButton'
    import dkcSearchControl from './dkcSearchControl'
    import {grantctl} from '../mixins/grantcontroller'
    import { TabulatorFull as Tabulator } from 'tabulator-tables'
    import ContextMenu from './dkcContextMenu'
    import qs from 'qs'

    export default {
        name: "NomenTable",
        mixins: [grantctl],
        components: {
            dkcModalDlg,
            bpkToolbarPanel,
            dkcToolButton,
            dkcSearchControl,
            ContextMenu,
        },
        data() {
            return {
                tabulator: null,
                tableData: [],
                hideToolbar: false,
                searchText: '',
                selectedID: 0,
                ourFirmID: 0,

                // данные для модального окна
                isModalDlgVisible: false,
                headerText: "Заголовок модального окна",
                bodyText: "Сообщение в модальном окне",
                buttons: ["yes", "no", "ok", "close"],
                actions: {
                    yes: () => {alert("YESSSS!")},
                },

                // Контекстное меню для буфера обмена
                menuItems: [],
                menuIndex: 0,
            }
        },
        computed: {
            grid: function() {
                return this.tabulator ;
            },
            rowPerPage: function() {
                const rowcnt = this.$store.state.nomen.rowPerPage ;
                return rowcnt ;
            },
            idgroup: function () {
                return this.$store.state.nomen.idgroup ;
            },
            idnomen: function () {
                return this.$store.state.nomen.idnomen ;
            },
            search_text: function () {
                return this.$store.state.nomen.search_text ;
            },
            restcols: function () {
                if (this.grid)
                  return [
                      this.grid.getColumn('rest'),
                      this.grid.getColumn('restDate')
                  ]

                return [false, false]
            },
            tableHeight: () => window.innerHeight - 210,
        },
        watch: {
            tableData: {
                handler: newData =>this.tabulator.replaceData(newData),
                deep: true
            },
            rowPerPage: function() {
                this.tabulator.setPageSize(this.$store.state.nomen.rowPerPage) ;
            },
            idgroup: function() {this.fillAndLocateGrid()},
            grid: function() {this.fillAndLocateGrid()},
            search_text: function() {
                this.$refs.nmsearch.search_text = this.search_text
            },
        },
        methods: {
            selectClipboardOperation(event) {
              event.stopPropagation()
              event.preventDefault()

              const count = this.grid.getSelectedRows().length
              switch (count) {
                case 0: this.buildPM4EmptySelection()
                        break
                case 1: this.buildPM4SingleSelection()
                        break
                default:  this.buildPM4MultipleSelection()
              }

              const menu = this.$refs.contextMenu
              menu.open(event)
            },
            buildPM4EmptySelection() {
              const rows = this.grid.getRows()
              if (rows.length)
                  rows[0].select()
              this.buildPM4SingleSelection()
            },
            buildPM4SingleSelection() {
                const sd = this.grid.getSelectedData()
                // const sa = [sd[0].code, sd[0].mark, sd[0].name + "<%n%>"]
                this.menuItems = []
                this.appendClipboardMenuItem('Копировать код', sd[0].code, !sd[0].code)
                this.appendClipboardMenuItem('Копировать тип/марку', sd[0].mark, !sd[0].mark)
                this.appendClipboardMenuItem('Копировать наименование', sd[0].name, !sd[0].name)
                // this.appendClipboardMenuItem('Копировать код/тип/наименование', sa.join("<%t%>"), false)
            },
            buildPM4MultipleSelection() {
              this.buildPM4SingleSelection()
            },
            appendClipboardMenuItem(label, data, disabled) {
              this.menuIndex++
              this.menuItems.push({
                  label,
                  name: 'cmi' + this.menuIndex,
                  'disabled': !!disabled,
                  data,
                  handler: this.copyStringToClipboard,
                })
            },
            copyStringToClipboard(text) {
              if (navigator.clipboard) {
                navigator.clipboard.writeText(text)
              }
              else {
                const self = this
                const el = document.getElementById('clpbuffer')
                el.innerHTML = text

                setTimeout(() => {
                  const dv = document.getElementById('clpbuffer')
                  const range = document.createRange()
                  range.selectNode(dv)
                  window.getSelection().removeAllRanges()
                  window.getSelection().addRange(range)
                  const successful = document.execCommand('copy')
                  window.getSelection().removeAllRanges()
                  if (!successful)
                    self.showErrorMessage('Ваш браузер не поддерживает копирование в буфер обмена!')
                }, 100)
              }
            },
            changeVisibleRest: function () {
                if (!this.grid)
                    return

                self = this
                const data = this.grid.getData()
                if (!data.length)
                    return

                const firmID = data[0].idFirm
                this.restcols.forEach(col => {
                    if (firmID == self.ourFirmID)
                        col.show()
                    else
                        col.hide()
                })
            },
            fillAndLocateGrid() {
                if (this.search_text)
                    return

                if (this.grid != null) {
                    const self = this
                    const g = this.grid

                    setTimeout(() => {
                        g.setData()
                            .then(() => {
                                self.selectedID = self.idnomen
                                self.selectNomenByID()
                                self.changeVisibleRest()
                            })
                    }, 500)
                }
            }
            ,
            showModalDlg(afterShow = false) {
                this.isModalDlgVisible = true
                if (afterShow) {
                    afterShow()
                }
            },
            closeModalDlg() {
                this.isModalDlgVisible = false
            },
            showErrorMessage: function(message) {
                this.buttons = ['Ok']
                this.actions = {
                    ok: this.closeModalDlg()
                }
                this.headerText = 'Ошибка'
                this.bodyText   = message
                this.showModalDlg()
            },
            createNomenTable(firmList, edizmList) {
                const comp = this
                const lid = this.idng
                const firmArr = {'0': ''}
                firmList.forEach(function(item){
                    firmArr[item.idFirm] = item.name
                    comp.ourFirmID = comp.ourFirmID || item.isUs
                })
                const edizmArr = {'0': ''}
                edizmList.forEach( edizm => {edizmArr[edizm.idedizm] = edizm.shortname})

                this.tabulator  = new Tabulator(this.$refs.table, {
                    columns: [
                        {title: 'id', field: 'idnomen', visible: false},
                        {title: 'idng', field: 'idNomenGroup', visible: false},
                        {title: 'isActive', field: 'isActive', visible: false},
                        {
                            title: 'Код', field: 'code', visible: true, widthGrow: 2,
                            headerFilter: true, headerFilterPlaceholder: 'фильтр по коду',
                        },
                        {
                            title: 'Тип/марка', field: 'mark', visible: true, widthGrow: 2,
                            headerFilter: true, headerFilterPlaceholder: 'фильтр по типу',
                        },
                        {
                            title: 'Название', field: 'name', visible: true, widthGrow: 6,
                            headerFilter: true, headerFilterPlaceholder: 'фильтр по наименованию',
                            formatter: this.nomenNameFormat,
                        },
                        {title: 'Ед.Изм.', field: 'idedizm', visible: true, hozAlign: 'center',
                                formatter: 'lookup', formatterParams: edizmArr,},
                        {title: 'Производитель', field: 'idFirm', visible: true, widthGrow: 2,
                                formatter:'lookup', formatterParams: firmArr,},
                        {title: 'Остатки на складе',
                         columns: [
                             {title: 'кол-во', field: 'rest', visible: true, widthGrow: 1,
                               hozAlign: 'right',},
                             {title: 'дата', field: 'restDate', visible: true, widthGrow: 1,
                               hozAlign: 'center',},
                         ]},
                        {title: 'Цена',
                            columns: [
                                {title: 'рублей', field: 'price', visible: true, widthGrow: 1,
                                  hozAlign: 'right',},
                                {title: 'дата', field: 'priceDate', visible: true, widthGrow: 1,
                                  hozAlign: 'center',},

                            ]},
                    ],
                    data: this.tableData,
                    layout: 'fitColumns',
                    height: this.tableHeight, 
                    autoResize: true,
                    placeholder: 'Нет данных',
                    responsiveLayout: true,
                    resizableRows: true,
                    movableRows: true,
                    selectable: true,
                    locale: 'ru-RU',
                    langs: {
                        'ru-RU': {
                            'ajax': {
                                'loading':'загрузка',
                                'error': 'ошибочка вышла',
                            },
                            'pagination': {
                                'first': String.fromCharCode(57449),
                                'first_title':'первая страница',
                                'prev': String.fromCharCode(57457),
                                'prev_title':'предыдущая страница',
                                'next': String.fromCharCode(57461),
                                'next_title':'следущая страница',
                                'last': String.fromCharCode(57463),
                                'last_title':'последняя страница',
                            },
                        },
                    },
                    ajaxURL: this.baseUrl + '/nomen/select',
                    ajaxContentType: "json",
                    ajaxURLGenerator: this.selectNomen,
                    pagination: true, 
                    paginationMode: "remote",                     
                    paginationInitialPage: 1,                    
                    paginationSize: this.rowPerPage,                
                    filterMode: 'remote',
                    sortMode: 'remote',                    
                    footerElement: `<span><div class="paginator-limit"><span>На странице:</span>
                                        <select id="RowPerPage"
                                          onchange="const sc = document.getElementById('RowPerPage') ;
                                                    window.dkcapp.$store.state.nomen.rowPerPage = sc.value ;
                                                   "
                                          >
                                          <option value="5">5</option>
                                          <option value="10">10</option>
                                          <option value="25" selected>25</option>
                                          <option value="50">50</option>
                                          <option value="100">100</option>
                                        </select>
                                    </div></span>`,
                    rowFormatter: this.formatRow,                
                }) ;
                this.tabulator.on('tableBuilt', this.setURLBtnEventProc)
                this.tabulator.on('dataLoaded', this.selectNomenByID)
                this.tabulator.on('rowDblClick', this.rowDblClick)
            },
            formatRow(row) {
                const data = row.getData()
                if (data.isActive == 0) {
                    const style = row.getElement().style
                    style.backgroundColor = 'silver'
                    style.color = 'gray'
                    style.fontStyle = 'italic'
                }
            },
            selectNomen(url, config, params) {
                const idng = this.searchText ? -1: this.idgroup
                const filters = this.searchText ? [
                    {field: 'code', type: 'like', value: this.searchText},
                    {field: 'mark', type: 'like', value: this.searchText},
                    {field: 'name', type: 'like', value: this.searchText},
                ] : params.filter
                const fm = this.searchText ? 'or' : 'and'

                let purl = url + '?idng=' + idng
                               + '&page=' + (params.page || 1)
                               + '&size=' + (params.size || 20)
                               + '&fm='   + fm

                if (params.sort.length > 0) {
                    purl = purl + '&sorters='+JSON.stringify(params.sort) ;
                }

                if (filters.length > 0) {
                    purl = purl + '&filters='+JSON.stringify(filters) ;
                }

                return purl ;
            },
            createNomen() {
                if (!this.grant) {
                    this.showNoGrant()
                    return
                }
                const url = this.baseUrl + '/nomen/create?idgroup='+this.$store.state.nomen.idgroup ;
                window.location = url ;
            },
            editNomen() {
                if (!this.grant) {
                    this.showNoGrant()
                    return
                }
                const nomens = this.tabulator.getSelectedData()
                if (nomens.length == 0) {
                    console.log('А тут следовало бы ругнуться на невыбранную строку')
                    return
                }

                const nomen = nomens[0] ;
                const url = this.baseUrl + '/nomen/update?idgroup=' + nomen.idNomenGroup + '&idnomen=' + nomen.idnomen ;
                window.location = url ;
            },
            rowDblClick (e, row){
                if (!this.grant)
                    return
                const nomen = row.getData()
                const url = this.baseUrl + '/nomen/update?idgroup=' + nomen.idNomenGroup + '&idnomen=' + nomen.idnomen ;
                window.location = url ;
            },
            queryDeleteNomen() {
                if (!this.grant) {
                    this.showNoGrant()
                    return
                }
                const rows = this.grid.getSelectedData()
                if (rows.length == 0) {
                    this.showErrorMessage('Номенклатура не выбрана!')
                    return
                }

                let message = ''
                if (rows.length == 1) {
                    const nm = rows[0]
                    if (nm.code == undefined)
                        nm.code = ''
                    if (nm.mark == undefined)
                        nm.mark = ''
                    if (nm.name == undefined)
                        nm.name = ''
                    message = nm.code + (nm.mark && nm.code ? '/' : '') + nm.mark + '  ' + nm.name
                    message = 'Вы действительно хотите удалить номенклатуру ' + message + '?'
                }
                else {
                    message = 'Вы действительно хотите удалить ' + rows.length + ' позиций номенклауры?'
                }

                this.buttons = ['yes', 'no']
                this.actions = {
                    yes: this.doDeleteNomen
                }
                this.headerText = 'Удаление номенклатуры'
                this.bodyText   = message
                this.showModalDlg()
            },
            doDeleteNomen: function() {
                if (!this.grant) {
                    this.showNoGrant()
                    return
                }
                const self = this
                const rows = this.grid.getSelectedData()
                if (rows.length == 0) {
                    this.showErrorMessage('Номенклатура не выбрана!')
                    return
                }
                const nomen = []
                rows.forEach(row => nomen.push(row.idnomen))

                axios({
                    method: 'post',
                    url: this.baseUrl + '/nomen/delete',
                    headers: {
                        'content-type': 'application/x-www-form-urlencoded',
                        'X-CSRF-Token': tkn_csfr
                    },
                    data: qs.stringify({
                        'id': nomen,
                        'mode': 'ajax',
                    })
                })
                    .then(response => this.grid.setData())
                    .catch(error => this.showErrorMessage('Ошибка удаления номенклатуры'))
            },
            doSearchNomen(search_text) {
                this.searchText = search_text
                if (this.grid.getFilters(true)) {
                    this.grid.clearHeaderFilter()
                }
                else {
                    this.grid.setData()
                }
            },
            nomenNameFormat: function(cell, formatterParams, onRendered) {
                const row = cell.getRow()
                if (!row) {
                    return ''
                }
                const rd = row.getData()
                if (rd.idnomen == undefined && rd.code == undefined &&
                    rd.mark == undefined && rd.name == undefined   )
                    return ''

                const cellvalue = cell.getValue()
                const rowpos = row.getTable().getRowPosition(row)
                setTimeout(this.setURLBtnEventProc, 500)
                return `<div>
                           <div class="nomen-beacon"> ${cellvalue} </div>
                           <button type="button" class="nomen-web btn btn-link" data-row-pos="${rowpos}"></button>
                        </div>`
            },
            setURLBtnEventProc: function() {
                const btns = [].slice.call(document.querySelectorAll('.nomen-web'))
                btns.forEach(btn => {
                    if (btn.onclick == undefined)
                        btn.onclick = this.URLBtnClick
                })
            },
            getNomenInfo: function (row) {
                const rd = row.getData()
                const nomen = {
                    idnomen: rd['idnomen'],
                    code: rd['code'],
                    mark: rd['mark'],
                    name: rd['name'],
                    idFirm: rd['idFirm'],
                }

                if (nomen.idFirm == undefined)
                    nomen.idFirm = 0

                return nomen
            },
            URLBtnClick: function() {
                const rowpos = event.target.dataset.rowPos
                const row = this.grid.getRows()[rowpos]
                 if (!row)
                     return
                const nomen = this.getNomenInfo(row)

                 axios({
                     method: 'post',
                     url: this.baseUrl + '/nomen/get-url',
                     headers: {
                         'content-type': 'application/x-www-form-urlencoded',
                         'X-CSRF-Token': tkn_csfr
                     },
                     data: qs.stringify(nomen)
                 })
                     .then(response => {
                         const url = response.data.url
                         if (!url || url == undefined)
                             return

                         const win = window.open(url, '_blank')
                         win.focus()
                     })
                     .catch(error => this.showErrorMessage('Ошибка поиска номенклатуры в сети Интернет', error))
            },
            getRowByIDNomen: function(idnomen) {
                const rows = this.grid.getRows() ;
                if (rows.length == 0) {
                    return false
                }
                let rr = false
                rows.some(row =>{
                    const data = row.getData()
                    if (data.idnomen == idnomen) {
                        rr = row
                        return true
                    }
                    return false
                })

                return rr
            },
            gotoNomenGroup: function() {
                const self = this
                const rows = this.grid.getSelectedData()
                if (rows.length == 0)
                    return

                // Очищаем фильтры
                this.$refs.nmsearch.search_text = ''
                this.searchText = ''

                // просим уйти ни нужную группу
                this.$emit('setNomenGroup', rows[0])
                this.selectedID = rows[0].idnomen
            },
            selectNomenByID: function() {
                const self = this
                if (this.selectedID != 0) {
                    // Получаем номер страницы
                    const idnomen = this.selectedID
                    const options = {
                        method: 'get',
                        url: this.baseUrl + '/nomen/get-page',
                        params: {
                            'idNG': this.idgroup,
                            'idnomen': idnomen,
                            'size': this.rowPerPage,
                            'filters': this.grid.getFilters(true),
                            'sorters': this.grid.getSorters(true),
                        }
                    }
                    axios(options)
                        .then(response => {
                            const page = response.data.page ;
                            if (!page)
                                return

                            self.grid.setPage(page)
                                .then(function() {
                                    const row = self.getRowByIDNomen(idnomen)
                                    if (row)
                                        self.grid.selectRow(row)
                                })
                                .catch(error => {

                                })
                        })
                        .catch(error => {})
                    this.selectedID = 0
                }
            },
        },
        mounted() {
            const comp = this
            axios({
                method: 'get',
                url: this.baseUrl + '/nomen/norma',
            })
                .then(function(response) {
                    comp.createNomenTable(response.data['firms'], response.data['edizm'])
                })
                .catch(function(error) {
                    console.log('Ошибка загрузки таблицы:')
                    console.log(error)
                    console.log('^^^^^^^^^^^^^^^^^^^^^^^^')
                }) ;
        },
    }
</script>

<style scoped>

</style>

<style>
    .nomen-web {
        position: absolute;
        right: 0px;
        top: 0px;
        width: 28px;
        height: 28px;
        background-image: url("../../images/url.svg");
        background-size: cover;
        z-index: 100;
        transition: all 500ms ease;
        opacity: 0;
    }

    .nomen-web:hover {
        width: 28px;
        height: 28px;
        background-color: #FFFFFF;
        border: 1px solid #cc0000;
        border-radius: 2px;
        opacity: 1;
    }

    .nomen-beacon {

    }

    .nomen-beacon:after {
        content: '*';
        position: absolute;
        top: 0;
        right: 0;
        width: 0px;
        height: 0px;
        border-top: 6px solid #cc0000;
        border-left: 6px solid transparent;
        opacity: 0.7;
    }

</style>
