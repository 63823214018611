var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dkc-modal-dlg",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.invalidList,
          expression: "invalidList",
        },
      ],
      attrs: { buttons: _vm.buttons, actions: _vm.actions },
      on: { close: _vm.closeModalDlg },
    },
    [
      _c("span", { attrs: { slot: "header" }, slot: "header" }, [
        _vm._v("Обнаружены единицы измерения отсутствующие в справочнике."),
        _c("br"),
        _vm._v("Исправте\n    исходные данные и повторите операцию."),
      ]),
      _vm._v(" "),
      _c("span", { attrs: { slot: "body" }, slot: "body" }, [
        _vm.invalidList
          ? _c(
              "div",
              _vm._l(_vm.errors, function (ei) {
                return _c(
                  "div",
                  { key: ei },
                  [
                    _c("edizm-validator-item", {
                      attrs: { edizmname: ei, "edizm-list": _vm.edizmList },
                    }),
                  ],
                  1
                )
              }),
              0
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }