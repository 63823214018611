<template>
  <div class="nsh_container">
    <table>
      <tr class="header">
        <td class="title"></td>
        <td class="firm">Вендор</td>
        <td class="code">Код</td>
        <td class="mark">Тип/марка</td>
        <td class="name">Наименование</td>
      </tr>
      <tr class="bottom_border">
        <td class="title" @click="setSeachControlsC()">Заказчик:</td>
        <td @click="setSeachControl('c', 'idFirm')" :title="hint">
            {{ getFirmname("c") }}
        </td>
        <td @click="setSeachControl('c', 'code')" :title="hint">
              {{ getCode("c") }}
        </td>
        <td @click="setSeachControl('c', 'mark')" :title="hint">{{ getMark("c") }}</td>
        <td @click="setSeachControl('c', 'name')" :title="hint">{{ getName("c") }}</td>
      </tr>
      <tr class="bottom_border">
        <td class="title" @click="setSeachControlsR()">Найдено:</td>
        <td @click="setSeachControl('r', 'idFirm')" :title="hint">{{ getFirmname("r") }}</td>
        <td @click="setSeachControl('r', 'code')" :title="hint">{{ getCode("r") }}</td>
        <td @click="setSeachControl('r', 'mark')" :title="hint">{{ getMark("r") }}</td>
        <td @click="setSeachControl('r', 'name')" :title="hint">{{ getName("r") }}</td>
      </tr>
      <tr>
        <td class="title" @click="clearSeachControls()">Ручной поиск:</td>
        <td>
          <select
            name="st_idFirm"
            id="st_idFirm"
            class="form-control"
            placeholder="Выбери вендора"
            v-model="s_idFirm"
          >
            <option label="" value="0"></option>
            <option
              v-for="firm in firms"
              :key="firm.idFirm"
              :label="firm.name"
              :value="firm.idFirm"
            ></option>
          </select>
        </td>
        <td>
          <input
            type="text"
            id="st_code"
            class="form-control"
            placeholder="Введите код"
            v-model="s_code"
          />
        </td>
        <td>
          <input
            type="text"
            id="st_mark"
            class="form-control"
            placeholder="Введите тип/марку"
            v-model="s_mark"
          />
        </td>
        <td>
          <div class="search_control_group">
            <input
              type="text"
              id="st_name"
              class="form-control"
              placeholder="Введите наименование"
              v-model="s_name"
            />
          </div>
        </td>
      </tr>
      <tr>
        <td />
        <td colspan="2">
          <div class="top_toolbar">
            <button type="button"  @click="doSearch()">
              <span>Поиск</span>
            </button>
            <button type="button" @click="doDeepSearch()">
              <span>Углубленный поиск</span>
            </button>
            <button type="button" @click="clearSeachControls()">
              <span>Очистить</span>
            </button>
          </div>
        </td>
        <td colspan="2">
          <div>
            <span v-show="inDeepSearch">Шаг {{ deepSearchStep }}. {{ deepSearchMessage }}</span>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>

export default {
  name: "NomenSelectorHeader",
  props: ["firms", "c_nomen", "r_nomen", "inDeepSearch", "deepSearchStep", "deepSearchMessage"],
  data() {
    return {
      s_code: "",
      s_mark: "",
      s_name: "",
      s_idFirm: 0,
      useDeepSearch: false,
      hint: 'Щелкни по тексту для его копирования в строку поиска',
    };
  },
  methods: {
    getNomen(side) {
      return side == "r" ? this.r_nomen : this.c_nomen;
    },
    getFirmname(side) {
      const nomen = this.getNomen(side);
      return nomen && nomen.firmname ? nomen.firmname : "";
    },
    getCode(side) {
      const nomen = this.getNomen(side);
      return nomen && nomen.code ? nomen.code : "";
    },
    getMark(side) {
      const nomen = this.getNomen(side);
      return nomen && nomen.mark ? nomen.mark : "";
    },
    getName(side) {
      const nomen = this.getNomen(side);
      return nomen && nomen.name ? nomen.name : "";
    },
    doSearch() {
      this.useDeepSearch = false
      this.runSearch()
    },
    doDeepSearch() {
      this.useDeepSearch = true
      this.runSearch()
    },
    runSearch() {
      this.$emit("dosearch", {
        idFirm: this.s_idFirm,
        code: this.s_code,
        mark: this.s_mark,
        name: this.s_name,
        useDeepSearch: this.useDeepSearch
      });
    },
    setSeachControlsC() {
      this.setSeachControls(this.c_nomen);
    },
    setSeachControlsR() {
      this.setSeachControls(this.r_nomen);
    },
    setSeachControls(nomen) {
      this.s_code = nomen.code;
      this.s_mark = nomen.mark;
      this.s_name = nomen.name;
      if (!nomen.firmname) {
        this.s_idFirm = 0;
      } else {
        const firm = this.firms.find((firm) => firm.name == nomen.firmname);
        this.s_idFirm = firm ? firm.idFirm : 0;
      }
    },
    setSeachControl (side, dest) {
      const nomen = side == 'c' ? this.c_nomen : this.r_nomen ;

      switch (dest) {
        case 'code': this.s_code = nomen.code
                     break
        case 'mark': this.s_mark = nomen.mark
                     break
        case 'name': this.s_name = nomen.name
                     break
        case 'idFirm':
                      if (!nomen.firmname) {
                        this.s_idFirm = 0;
                      } else {
                        const firm = this.firms.find((firm) => firm.name == nomen.firmname);
                        this.s_idFirm = firm ? firm.idFirm : 0;
                      }
                     break
      }
    },
    clearSeachControls() {
      this.s_code = "";
      this.s_mark = "";
      this.s_name = "";
      this.s_idFirm = 0;
    },
  },
};
</script>

<style scoped>
.nsh_container {
  margin-bottom: 8px;
}

table {
  width: 100%;
}

tr {
  padding-top: 2px;
  padding-bottom: 2px;
}

td {
  padding: 6px 4px;
}

.header {
  font-weight: bolder;
}

.title {
  width: 80px;
  padding-left: 4px;
  font-weight: bold;
  cursor: pointer;
}

.firm {
  width: 200px;
}

.code,
.mark {
  width: 180px;
}

.name {
  width: 340px;
}

.search_control_group {
  display: flex;
}

.search_control_group button {
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
}

.search_control_group img {
  width: 30px;
  height: 30px;
}

.bottom_border {
  border-bottom: #ccc 1px solid;
}

.top_toolbar {
  display: flex;
  justify-content: space-between;
}

button span {
  padding: 0 12px;
}

button span:hover {
  color: navy ;
  font-weight: bolder;
}
</style>
