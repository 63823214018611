<template>
   <li class="tab" :class="{active_tab: isActiveTab}" :index="index" @click="doOnClick">{{title}}</li>
</template>

<script>
export default {
   name: "bpkTab",
   props: {
      title: {type: String, required: true},
      hint:  {type: String, default: ''},
      index: {type: Number, required: true}
   },
   data () {
      return {
         isActiveTab: false,
      }
   },
   methods: {
      doOnClick () {
         this.$emit('tab-click', this.index)
         this.activateTab()
      },
      activateTab () {
         this.isActiveTab = true
      },
      deActivateTab () {
         this.isActiveTab = false
      }
   },
}
</script>

<style scoped>
   .tab {
      display: inline-block;
      padding: 4px 8px;
      list-style-type: none;
      border-left: 1px gray solid ;
      border-top: 1px gray solid ;
      border-right: 1px gray solid ;
      border-radius: 4px 4px 0 0;
      cursor: pointer;
      background-color: #dddddd;
   }

   .active_tab {
      font-weight: bold;
      border-left: 1px black solid ;
      border-top: 1px black solid ;
      border-right: 1px black solid ;
      background-color: transparent;
   }
</style>
