var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "dkc-modal-dlg",
        {
          attrs: {
            buttons: _vm.excelbtns,
            actions: _vm.excelacts,
            autoclose: false,
          },
          on: { close: _vm.click_close },
        },
        [
          _vm._v("\n    >\n    "),
          _c("span", { attrs: { slot: "header" }, slot: "header" }, [
            _vm._v("Импорт спецификации из Excel"),
          ]),
          _vm._v(" "),
          _c("excel-import-tuner", {
            ref: "excelimp",
            attrs: {
              slot: "body",
              fieldDefs: _vm.fieldDefs,
              colTitles: _vm.colTitles,
            },
            on: { loaded: _vm.changeWorkBook },
            slot: "body",
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.workbook_exists,
                  expression: "workbook_exists",
                },
              ],
              staticClass: "toggle_block",
              attrs: { slot: "buttons" },
              slot: "buttons",
            },
            [
              _c("div", { staticClass: "toggle_label" }, [
                _vm._v(
                  "\n        Поиск номенклатуры заказчика после импорта\n      "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "toggle_control" },
                [
                  _c("VueToggles", {
                    attrs: {
                      value: _vm.searchNomenAfterImport,
                      height: "18",
                      width: "120",
                      checkedText: "Выполнить",
                      uncheckedText: "Не выполнять",
                      checkedBg: "skyblue",
                      uncheckedBg: "gray",
                    },
                    on: { click: _vm.toggleSearch },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("edizm-validator-vue", { ref: "eivld" }),
      _vm._v(" "),
      _c("firm-validator", { ref: "fvld" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }