<template>
    <div class="col-lg-6 col-lg-offset-3">
        <form @submit.prevent="onSubmit">
            <div class="form-group required"
                 :class="{'has-error': $v.name.$error,
                          'has-success': $v.name.$dirty && $v.name.required }">
                <label for="name" class="control-label">Наименование</label>
                <input type="text" id="name"
                       class="form-control"
                       v-model="name" @blur="$v.name.$touch()">
                <div class="help-block" v-if="$v.name.$error">Наименование группы должно быть заполнено!</div>
            </div>
            <div class="form-group">
                <label class="control-label" @dblclick="idprnt = 0">Родительская группа</label>
                <div class="path-control-group">
                    <dkc-ng-namy :idgroup="idprnt" @select_group="selectGroup"></dkc-ng-namy>
                </div>
            </div>
            <div class="form-group">
                <label  class="control-label">Ссылки на сторонние ресурсы</label>
                <dkc-nomen-group-url
                        ref="urls"
                        :idng="rid"
                        offline="true"
                >
                </dkc-nomen-group-url>
            </div>
            <div class="form-group has-error" v-if="sverror">
                <div class="help-block">Ошибка сохранения группы номенклатуры: {{sverrormsg}}</div>
            </div>
            <div class="form-group button-group">
                <button type="submit" class="btn btn-success"
                        :disabled="$v.$invalid">
                    Сохранить
                </button>
                <a :href="backUrl" class="btn btn-danger">Отмена</a>
            </div>
        </form>
    </div>
</template>

<script>
    import DkcNgNamy from './components/dkcNgNavy'
    import DkcNomenGroupUrl from './components/dkcNomenGroupURL'
    import required from 'vuelidate/lib/validators/required'
    import getUrlVar from './units/urlparser'

    export default {
        name: "NomenGroupEditor",
        components: {DkcNgNamy, DkcNomenGroupUrl,},
        data() {
            return {
                id: 0,
                rid: 0, // id группы пришедшей от сервера. Требуется для загрузки таблицы с url.
                name: '',
                idprnt: 0,
                path: [],
                sverror: false,
                sverrormsg: '',
            }
        },
        computed: {
            backUrl: function() {
                if (getUrlVar('src') == 'nomen') {
                    return this.baseUrl + '/nomen'
                }
                return this.baseUrl + '/nomen-group'
            },
            urls: function() {
                const tbl = this.$refs.urls.tabulator ;
                return tbl.getData() ;
            },
        },
        watch: {
            id: function () {
                if (this.id < 1) {
                    return
                }
                const cmp = this ;
                axios({
                    method: 'get',
                    url: this.baseUrl + '/nomen-group/info',
                    headers: {'X-CSRF-Token': tkn_csfr},
                    params: {
                        id: this.id,
                    }
                })
                    .then(function (response) {
                        const data = response.data ;
                        cmp.rid         = data.id   ;
                        cmp.name        = data.name ;
                        cmp.path        = data.path ;
                        cmp.idprnt      = data.idprnt ;
                    })
                    .catch(function (response) {

                    });
            },
            idprnt: function () {
                if (this.idprnt < 1) {
                    this.path = [{id: 0, name: ''}, {id: -1, name: 'Корневая группа'}] ;
                    return ;
                }
                const comp = this ;
                axios({
                    method: 'get',
                    url: this.baseUrl + '/nomen-group/parent-path',
                    headers: {'X-CSRF-Token': tkn_csfr},
                    params: {
                        id: this.idprnt,
                    },
                })
                    .then(function(response) {
                        comp.path = response.data ;
                    })
                    .catch(function(error) {
                        console.log('Ошибка получения пути к родительской группе:', error.message);
                    }) ;
            },
        },
        methods: {
            selectGroup: function(idNG) {
                if (this.idprnt != idNG ) {
                    this.idprnt = idNG
                }
            },
            returnToRef(id) {
                let url =this.baseUrl + '/nomen-group'
                if (getUrlVar('src') == 'nomen') {
                     url = this.baseUrl + '/nomen'
                }

                if (!id) {
                    url = url + '?=' + id
                }

                window.location = url
            },
            onSubmit() {
                const urls = JSON.stringify(this.urls) ;
                const cmp  = this ;
                axios({
                    method: 'post',
                    url: this.baseUrl + '/nomen-group/save',
                    headers: {'X-CSRF-Token': tkn_csfr},
                    params: {
                        id: this.id || 0,
                        idprnt: this.idprnt,
                        name: this.name,
                        urls,
                    },
                })
                    .then(response => {cmp.returnToRef(response.data)})
                    .catch(error => {
                        cmp.sverrormsg = error.message ;
                        cmp.sverror = true ;
                    }) ;
            },
        },
        mounted() {
            this.idprnt = getUrlVar('idprnt') ;
            this.id     = getUrlVar('id') ;
            window.dkcapp.title = 'Редактирование группы номенклатуры'
        },
        validations: {
            name: {
                required
            },
        },
    }
</script>

<style scoped>
    .path-control-group {
        display: flex;
        justify-content: space-between;
    }
</style>
