export default function SpecStatus() {
    const stats = [
        {key: 'A', title: 'утверждено'},
        {key: 'D', title: 'черновик'},
        {key: 'E', title: 'исключить'},
    ]

    this.key = function(title) {
        const item = stats.find(itm => itm.title.toLowerCase() == title.toLowerCase())
        if (item == undefined)
            return '-'

        return item.key ;
    }

    this.title = function(key) {
        const item = stats.find(itm => itm.key.toUpperCase() == key.toUpperCase())
        if (item == undefined)
            return '-'

        return item.title ;
    }
}
