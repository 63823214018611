<template>
  <div v-if="dialogVisible">
    <dkc-modal-dlg
      :buttons="buttons"
      :actions="actions"
      @close="closeModalDlg">
      <div slot="body">
        <div>
          <span v-show="exists_filled_rows">В спецификации обнаружены строки с выбранными аналогами. </span>
          <span>Для продолжения поиска аналогов номенклатуры заказчика необходимо выбрать метод поиска.</span>
        </div>
        <div>
          <div>
            <input type="radio" id="all_rows" value="0" v-model="methodIndex">
            <label for="all_rows">поиск для всех строк спецификации</label>
          </div>
          <div>
            <input type="radio" id="empty_rows" value="1" v-model="methodIndex">
            <label for="empty_rows">поиск для строк без выбранных аналогов</label>
          </div>
          <div>
            <input type="radio" id="current_rows" value="2" v-model="methodIndex">
            <label for="current_rows">поиск для текущей строки спецификации</label>
          </div>
        </div>
      </div>
      <span slot="header">Выберите вариант поиска аналогов номенклатуры</span>
    </dkc-modal-dlg>
  </div>
</template>

<script>
    import dkcModalDlg from "./dkcModalDlg"

    export default {
        name: "MethodSearchAnaloguesSelector",
        components: {dkcModalDlg},
        data() {
          return {
            methodIndex: 0,
            exists_filled_rows: false,
            dialogVisible: false,
            buttons: ['ok', 'close'],
            actions: {
              ok: this.selectMethod,
            },
          }
        },
        methods: {
          execute (filledRows) {
            this.exists_filled_rows = filledRows
            this.dialogVisible  = true
          },
          selectMethod () {
            this.$emit('select', this.methodIndex)
          },
          closeModalDlg () {
            this.dialogVisible = false
          }
        },
    }
</script>

<style scoped>

</style>
