var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dkc-modal-dlg",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.invalidList,
          expression: "invalidList",
        },
      ],
      attrs: { buttons: _vm.buttons, actions: _vm.actions },
      on: { close: _vm.closeModalDlg },
    },
    [
      _c("span", { attrs: { slot: "header" }, slot: "header" }, [
        _vm._v("Обнаружены фирмы производители отсутствующие в справочнике."),
        _c("br"),
        _vm._v("Исправте\n    исходные данные и повторите операцию."),
      ]),
      _vm._v(" "),
      _c("span", { attrs: { slot: "body" }, slot: "body" }, [
        _vm.invalidList
          ? _c(
              "div",
              _vm._l(_vm.errors, function (firm) {
                return _c(
                  "div",
                  { key: firm },
                  [
                    _c("firm-validator-item", {
                      attrs: { firmname: firm, firmlist: _vm.firmList },
                      on: { change: _vm.checkChoises },
                    }),
                  ],
                  1
                )
              }),
              0
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "buttons" }, slot: "buttons" }, [
        _c(
          "button",
          {
            staticClass: "btn",
            attrs: { id: "btn_sign_firm" },
            on: {
              click: function ($event) {
                return _vm.querySignFirm()
              },
            },
          },
          [
            _c("i", { staticClass: "fa-solid fa-envelope-circle-check" }),
            _vm._v("\n        Запрос на утверждение\n      "),
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn",
            attrs: { id: "btn_firm_update" },
            on: {
              click: function ($event) {
                return _vm.updateFirmList()
              },
            },
          },
          [
            _c("i", { staticClass: "fa-solid fa-arrows-rotate" }),
            _vm._v("\n        Обновить\n      "),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }