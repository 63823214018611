var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "site-index" }, [
    _c("div", { staticClass: "jumbotron" }, [
      _c("h1", [
        _vm._v("Рады приветствовать Вас"),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.userfio,
                expression: "userfio",
              },
            ],
          },
          [_vm._v(","), _c("br"), _vm._v(_vm._s(_vm.userfio))]
        ),
        _vm._v("!"),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "lead" }, [
        _vm._v(
          "Вы авторизовались в программе расчёта спецификаций DKC. В данной программе вы можете:"
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "body-content" },
      [
        _c("div", { staticClass: "task" }, [
          _c("a", { attrs: { href: _vm.baseUrl + "/spec/index" } }, [
            _c("div", { staticClass: "task-img" }, [
              _c("img", {
                attrs: { src: _vm.baseUrl + "/images/doc_list.jpg" },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "task-desc" }, [
              _vm._v("\n          Открыть журнал спецификаций\n        "),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "task" }, [
          _c("a", { attrs: { href: _vm.baseUrl + "/spec/create" } }, [
            _c("div", { staticClass: "task-img" }, [
              _c("img", {
                attrs: { src: _vm.baseUrl + "/images/doc_new.jpg" },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "task-desc" }, [
              _vm._v("\n          Создать новую спецификацию\n        "),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _vm.userIsAdmin
          ? _c("div", { staticClass: "task" }, [
              _c("a", { attrs: { href: _vm.baseUrl + "/spec/solutions" } }, [
                _c("div", { staticClass: "task-img" }, [
                  _c("img", {
                    attrs: { src: _vm.baseUrl + "/images/solutions.jpg" },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "task-desc" }, [
                  _vm._v(
                    "\n              База решений по аналогам\n           "
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "router-link",
          {
            staticClass: "task",
            attrs: { tag: "div", to: _vm.baseUrl + "/nsi" },
          },
          [
            _c("a", [
              _c("div", { staticClass: "task-img" }, [
                _c("img", {
                  attrs: { src: _vm.baseUrl + "/images/catalog.jpg" },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "task-desc" }, [
                _vm._v(
                  "\n          Управлять нормативно-справочной информацией\n        "
                ),
              ]),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }