var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible
    ? _c(
        "div",
        {
          directives: [
            {
              name: "click-outside",
              rawName: "v-click-outside",
              value: _vm.close,
              expression: "close",
            },
          ],
          ref: "contextMenu",
          staticClass: "context-menu context-menu-container",
          class: _vm.openPosition,
          style: _vm.contextMenuPosition,
          on: { click: _vm.close },
        },
        [
          _c(
            "ul",
            _vm._l(_vm.menuItems, function (menuItem, index) {
              return _c("context-menu-item", {
                key: index,
                attrs: { item: menuItem },
              })
            }),
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }