<template>
   <div class="toolbarpanel">
      <div class="panel_content">
         <slot></slot>
      </div>
      <div class="panel_title">{{title}}</div>
   </div>
</template>

<script>
export default {
   name: "bpkToolbarPanel",
   props: {
      title: {type: String}
   },
}
</script>

<style scoped>
   .toolbarpanel {
      display: grid;
      grid-template-rows: 1fr auto;
      grid-template-areas: "panel" "title";
      min-height: 84px;
      border-right: 1px solid gray;
      background-color: #efefef;
   }

   .panel_content {
      grid-area: panel;
      display: flex;
      align-items: flext-start''
   }

   .panel_title {
      grid-area: title;
      font-size: 7pt;
      text-align: center;
      /*border-top: 1px solid gray;
      background-color: #dddddd;*/
   }
</style>
