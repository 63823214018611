import VueRouter from 'vue-router'
import Home from './pages/Home'
import NSI from './pages/NSI'
import UserList from './pages/UserList'
import FirmList from './pages/FirmList'
import NomenGroupTree from './pages/NomenGroupTree'
import NomenGroupEditor from './pages/NomenGroupEditor'
import NomenRef from './pages/NomenRef'
import NomenEditor from './pages/NomenEditor'
import SpecEditor from './pages/SpecEditor'
import SpecList from './pages/SpecList'
import Solutions from './pages/Solutions'
import ExcelImportTuner from './pages/components/ExcelImportTuner'
import NomenImportTuner from './pages/components/NomenImportTuner'
import HiddenApp from './pages/HiddenApp'
import NomenTwin from './pages/NomenTwin'

const routes = [
  {
    path: '',
    component: Home
  },
  {
    path: '/nsi',
    component: NSI
  },
  {
    path: '/user',
    component: UserList
  },
  {
    path: '/user/index',
    component: UserList
  },
  {
    path: '/user/create',
    component: HiddenApp
  },
  {
    path: '/user/update',
    component: HiddenApp
  },
  {
    path: '/firm',
    component: FirmList
  },
  {
    path: '/firm/index',
    component: FirmList
  },
  {
    path: '/firm/create',
    component: HiddenApp
  },
  {
    path: '/firm/update',
    component: HiddenApp
  },
  {
    path: '/nomen-group',
    component: NomenGroupTree
  },
  {
    path: '/nomen-group/index',
    component: NomenGroupTree
  },
  {
    path: '/nomen-group/create',
    component: NomenGroupEditor
  },
  {
    path: '/nomen-group/update',
    component: NomenGroupEditor
  },
  {
    path: '/nomen',
    component: NomenRef
  },
  {
    path: '/nomen/index',
    component: NomenRef
  },
  {
    path: '/nomen/create',
    component: NomenEditor
  },
  {
    path: '/nomen/update',
    component: NomenEditor
  },
  {
    path: '/nomen/import',
    component: NomenImportTuner
  },
  {
    path: '/nomen/twin',
    component: NomenTwin
  },
  {
    path: '/spec',
    component: SpecList
  },
  {
    path: '/spec/index',
    component: SpecList
  },
  {
    path: '/spec/create',
    component: SpecEditor
  },
  {
    path: '/spec/update',
    component: SpecEditor
  },
  {
    path: '/spec/eit',
    component: ExcelImportTuner
  },
  {
    path: '/spec/solutions',
    component: Solutions
  }
]

routes.forEach(route => {route.path = burl + route.path})

export default new VueRouter({
  routes,
  mode: 'history'
}
)
