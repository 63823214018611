<template>
  <div>
    <div class="toolbar">
      <div class="btn-group">
        <button type="button" class="btn" :class="{ 'btn-primary': !panelIndex, 'btn-default': panelIndex }" @click="setPI(0)">Полные дубли</button>
        <button type="button" class="btn" :class="{ 'btn-primary': panelIndex, 'btn-default': !panelIndex }" @click="setPI(1)">Поиск дублей</button>
      </div>      
    </div>
    <div>
      <div v-if="!panelIndex"><nomen-twin-full-twins /></div>
      <div v-if="panelIndex"></div>
    </div>
  </div>
</template>

<script>

import NomenTwinFullTwins from './NomenTwinFullTwins' 

export default {
  name: "NomenTwinRefs",
  props: {},
  components: { NomenTwinFullTwins },
  data() {
    return {
      panelIndex: 0
    };
  },
  methods: {
    setPI ( index ) {
      this.panelIndex = index 
    },
  },
};
</script>

<style scoped></style>