<template>
   <div class="toolbars">
      <slot></slot>
   </div>
</template>

<script>
export default {
   name: "bpkToolbars",
   props: {
      activeTabIndex: {type: Number, required: true}
   },
   watch: {
      activeTabIndex: function (newIndex, oldIndex) {
         this.hideToolbar(oldIndex)
         this.showToolbar(newIndex)
      },
   },
   methods: {
      getToolbarByIndex ( index ) {
         return this.$children.find( child => child.index == index )
      },
      changeToolbarVisible (index, visible) {
         toolbar = this.getToolbarByIndex(index)
         if (toolbar)
            toolbar.visible = visible
      },
      hideToolbar (index) {
         this.changeToolbarVisible(index, false)
      },
      showToolbar (index) {
         this.changeToolbarVisible(index, true)
      },
   },
   mounted () {
      this.showToolbar(this.activeTabIndex)
   }
}
</script>

<style scoped>

</style>
