<template>
  <div>
    <dkc-modal-dlg
      :buttons="buttons"
      :actions="actions"
      v-show="isModalDlgVisible"
      @close="closeModalDlg">
      <div slot="body">
        <clipboard-data ref="cbrddt"></clipboard-data>
        <clipboard-colmap ref="cbrdcm" v-on:celledited="colmapChange"></clipboard-colmap>
        <clipboard-result ref="cbrdres"></clipboard-result>
      </div>

      <span slot="header">Настройка вставки данных в таблицу из буфера обмена</span>

      <div class="toggle_block"  slot="buttons">
          <div class="toggle_label">Поиск номенклатуры заказчика после импорта</div>
          <div class="toggle_control">
            <VueToggles
                :value="searchNomenAfterImport"
                @click="toggleSearch"
                height="18"
                width="120"
                checkedText="Выполнить"
                uncheckedText="Не выполнять"
                checkedBg="skyblue"
                uncheckedBg="gray"
            />
          </div>
        </div>
    </dkc-modal-dlg>
    <ed-izm-validator ref="eiv" />
  </div>
</template>

<script>
    import dkcModalDlg from "./dkcModalDlg"
    import ClipboardData from './specClipbrdTunerClipboard'
    import ClipboardColmap from './specClipbrdTunerColmap'
    import ClipboardParser from "./specClipboardComp"
    import ClipboardResult from './specClipbrdTunerResult'
    import EdIzmValidator from './edizmValidator'
    import VueToggles from 'vue-toggles'

    export default {
        name: "specClipbrdTuner",
        components: {ClipboardData, ClipboardColmap, ClipboardResult, ClipboardParser,
                     dkcModalDlg, EdIzmValidator, VueToggles},
        data () {
            return {
              data: [],
              colmap: {},
              edizm: [],
              fieldDefs: [],
              handler: false,

              searchNomenAfterImport: false,
              isModalDlgVisible: false,
              modalResult: false,
              buttons: ["ok", "cancel"],
              actions: {
                ok: this.buildColMap,
                cancel:  this.closeModalDlg,
              },
            }
        },
        watch: {
          data: function() {
            if (!this.data || this.data.length == 0)
                return

            this.$refs.cbrddt.execute(this.data)
            this.$refs.cbrdcm.execute({
              colmap: this.colmap,
              fieldDefs: this.fieldDefs,
              colCount: this.data[0].length,
            })
            this.$refs.cbrdres.execute({
              colmap: this.colmap,
              data: this.data,
              fieldDefs: this.fieldDefs,
            })
          },
        },
        methods: {
          execute(config) {
            this.data = config.data
            this.colmap = config.colmap
            this.edizm  = config.edizm
            this.fieldDefs = config.fieldDefs
            this.handler = config.pastehandler
            this.isModalDlgVisible = true
          },
          closeModalDlg() {
            this.isModalDlgVisible = false
          },
          getEdImzListInCol(data, column) {
            if (column < 0)
              return []

            const edizm = []
            data.forEach(row => {
              edizm.push(row[column])
            })

            return edizm
          },
          getEdImzList(data) {
            let edizmList = [] ;
            ['c', 'd'].forEach(side =>{
              if (this.colmap[side + '_idedizm'] > -1)
                edizmList = edizmList.concat(this.getEdImzListInCol(data, this.colmap[side + '_idedizm']))
            })

            const edizm = [...new Set(edizmList)]
            return edizm
          },
          doBuildColMap() {
            if (this.handler)
              this.handler(this.data, this.colmap)
          },
          buildColMap() {
            // Если указаны колонки с единицами измерений, то необходимо проконтролировать
            // согласование со справочником.
            if (!this.$refs.eiv.execute(this.$store.state.edizmRef.ref, this.getEdImzList(this.data), this.doBuildColMap)) {
              return
            }

            this.doBuildColMap()
          },
          colmapChange() {
            this.$refs.cbrdres.loaddata()
          },
          toggleSearch () {
            this.searchNomenAfterImport = !this.searchNomenAfterImport
          },
        },
    }
</script>

<style scoped>

</style>
