<template>
  <details open>
    <summary>Результат вставки данных</summary>
    <div id="cbrdrestbl" ref="cbrdrestbl"></div>
  </details>
</template>

<script>
    import { coordConv } from '../mixins/cbrdCoorConverter'
    import { TabulatorFull as Tabulator } from "tabulator-tables"

    export default {
        name: "specClipbrdTunerResult",
        mixins: [coordConv],
        data() {
          return {
            tabulator: false,
          }
        },
        computed: {
          resultData: function () {
            return this.tabulator.getData()
          },
        },
        methods: {
          execute(options) {
            this.colmap     = options.colmap
            this.fieldDefs  = options.fieldDefs
            this.data       = options.data
            this.createTable(options.fieldDefs)
          },
          createTable(fieldDefs) {
            const self = this

            function convertFieldDefs(fieldDef, cols) {
              if (fieldDef.visible == false)
                return

              const coldef = {
                field: fieldDef.field == undefined ? 'f' + cols.length : fieldDef.field,
                title: fieldDef.title || 'пусто было',
                visible: true,
              }

              if ('width' in fieldDef)
                coldef.width = fieldDef.width
              if ('widthGrow' in fieldDef)
                coldef.widthGrow = fieldDef.widthGrow
              if ('align' in fieldDef)
                coldef.align = fieldDef.align

              if ('columns' in fieldDef) {
                coldef.columns = []
                fieldDef.columns.forEach(fd => convertFieldDefs(fd, coldef.columns))
              }

              cols.push(coldef)
            }

            const columns = []
            fieldDefs.forEach(fieldDef => {
              if (fieldDef.visible == undefined || fieldDef.visible)
                convertFieldDefs(fieldDef, columns)
            })

            if (this.tabulator) {
              this.tabulator.setColumns(columns)
            } else {
              const options = {
                columns,
                height: 300,
                layout: 'fitDataFill'
              }

              this.tabulator = new Tabulator('#cbrdrestbl', options)
            }
            setTimeout(this.loaddata, 100)
          },
          loaddata(){
            const self = this
            const dt = []
            const keys = Object.keys(this.colmap).filter(key => {
              return self.colmap[key] > -1
            })
            this.data.forEach(row => {
              const rd = {}
              keys.forEach(key => {
                rd[key] = row[self.colmap[key]]
              })

              dt.push(rd)
            })

            this.tabulator.setData(dt)
          },
        },
    }
</script>

<style scoped>

</style>
