var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "toolbar", attrs: { id: "toolbar" } },
        [
          _vm.grant
            ? _c("dkc-tool-button", {
                attrs: {
                  title: "Новая фирма",
                  hint: "Регистрация новой фирмы",
                  size: "big",
                  iconclass: "dbtn-create",
                  action: "/firm/create",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.grant
            ? _c("dkc-tool-button", {
                attrs: {
                  title: "Изменить данные",
                  hint: "Изменить данные о фирме",
                  size: "big",
                  iconclass: "dbtn-edit",
                  action: _vm.editFirm,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.grant
            ? _c("dkc-tool-button", {
                attrs: {
                  title: "Удалить фирму",
                  hint: "Удалить фирму из справочника",
                  size: "big",
                  iconclass: "dbtn-delete",
                  action: _vm.queryDeleteFirm,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { ref: "table" }),
      _vm._v(" "),
      _c(
        "dkc-model-dlg",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isModalDlgVisible,
              expression: "isModalDlgVisible",
            },
          ],
          attrs: { buttons: _vm.buttons, actions: _vm.actions },
          on: { close: _vm.closeModalDlg },
        },
        [
          _c("span", { attrs: { slot: "body" }, slot: "body" }, [
            _vm._v(_vm._s(_vm.bodyText)),
          ]),
          _vm._v(" "),
          _c("span", { attrs: { slot: "header" }, slot: "header" }, [
            _vm._v(_vm._s(_vm.headerText)),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }