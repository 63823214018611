var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { ref: "urltbl", attrs: { id: "url-tbl" } }),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "dkc-model-dlg",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isModalDlgVisible,
              expression: "isModalDlgVisible",
            },
          ],
          attrs: { buttons: _vm.buttons, actions: _vm.actions },
          on: { close: _vm.closeModalDlg },
        },
        [
          _c("span", { attrs: { slot: "body" }, slot: "body" }, [
            _vm._v(_vm._s(_vm.bodyText)),
          ]),
          _vm._v(" "),
          _c("span", { attrs: { slot: "header" }, slot: "header" }, [
            _vm._v(_vm._s(_vm.headerText)),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-group help-group" }, [
      _c("label", { staticClass: "control-label" }, [
        _vm._v("Шаблоны для формирования адресной строки"),
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._v(
          "\n      При формировании адресной строки шаблон будет заменен на соответствующее\n      значение номенклатуры\n    "
        ),
      ]),
      _vm._v(" "),
      _c("table", { staticClass: "table-help" }, [
        _c("tr", [
          _c("td", { staticClass: "td-templ" }, [_vm._v("@code@")]),
          _vm._v(" "),
          _c("td", { staticClass: "td-desc" }, [
            _vm._v("\n          Строка "),
            _c("strong", [_vm._v("@code@")]),
            _vm._v(" будет заменена кодом номенклатуры\n        "),
          ]),
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", { staticClass: "td-templ" }, [_vm._v("@mark@")]),
          _vm._v(" "),
          _c("td", { staticClass: "td-desc" }, [
            _vm._v("\n          Строка "),
            _c("strong", [_vm._v("@mark@")]),
            _vm._v(
              " будет заменена типом/маркой\n          номенклатуры\n        "
            ),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }