var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "fulltwin_box" }, [
    _vm.circleVisible
      ? _c(
          "div",
          [
            _c("circle-animation", {
              attrs: {
                maxvalue: _vm.twins_count,
                value: _vm.twins_index,
                title: _vm.progresstitle,
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "toolbar" }, [
      _c("div", { staticClass: "btn-group" }, [
        _c("div", { staticClass: "btn-group" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("ul", { staticClass: "dropdown-menu" }, [
            _c("li", [
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      return _vm.deleteFullTwins(false)
                    },
                  },
                },
                [_vm._v("Для выделенных строк")]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      return _vm.deleteFullTwins(true)
                    },
                  },
                },
                [_vm._v("Для всех строк текущей страницы")]
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-default",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.loadFullTwins()
              },
            },
          },
          [_vm._v("\n        Загрузить дубли\n      ")]
        ),
      ]),
    ]),
    _vm._v(" "),
    _vm._m(1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "btn btn-default dropdown-toggle",
        attrs: { type: "button", "data-toggle": "dropdown" },
      },
      [
        _vm._v("\n          Заменить дубли основной номенклатурой\n          "),
        _c("span", { staticClass: "caret" }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "fulltwin_body" }, [
      _c("div", { attrs: { id: "fulltwins" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }