<template>
  <div>
    <method-search-analogues-selector
      ref="msas"
      @select="prepareSearchAnalogues"
    />

    <nomen-collect-selector ref="ncs" />

    <spec-clipboard-comp ref="specclp" @datapasted="batchResolveNomen" />

    <div v-if="circleVisible">
      <circle-animation :title="progresstitle"></circle-animation>
    </div>

    <nomen-selector-dialog
      :firms="firmList"
      :idFirm="idFirm"
      :source="nomenSelectorSource"
      :visible="visibleNomenSelector"
      @selectnomen="selectNomen"
      @close="hideNomenSelector"
    />

    <dkc-modal-dlg
      :buttons="buttons"
      :actions="actions"
      v-show="isModalDlgVisible"
      @close="closeModalDlg"
    >
      <div slot="body" v-html="bodyText"></div>
      <span slot="header">{{ headerText }}</span>
    </dkc-modal-dlg>

    <dkc-modal-dlg
      :buttons="buttons"
      :actions="actions"
      v-show="analogVisible"
      @close="closeAnalogModalDlg"
    >
      <div slot="body">
        <div v-html="bodyAnalogText"></div>
        <div>
          <dkc-grid
            ref="analogs"
            class="analog-grid"
            tableid="analog-table"
          ></dkc-grid>
        </div>
      </div>
      <span slot="header">Выберите вариант замены номенклатуры</span>
    </dkc-modal-dlg>

    <excel-import-tuner-dialog
      v-if="importVisible"
      :fieldDefs="shortColumnsDefs"
      :colTitles="colTitles"
      :firm="idFirm"
      @click-ok="loadSpecFromExcel"
      @click-close="closeImportTunerDlg"
    />

    <dkc-modal-dlg
      v-if="structEditVisible"
      :buttons="structEditbtns"
      :actions="structEditacts"
      @close="closeStructEditDlg"
    >
      <span slot="header">{{ structEditHeader }}</span>
      <div slot="body" class="form-group">
        <label for="columnHeader" class="control-label"
          >Заголовок колонки</label
        >
        <input
          type="text"
          id="columnHeader"
          class="form-control"
          v-model="structEditTitle"
        />
      </div>
    </dkc-modal-dlg>

    <ContextMenu ref="contextMenu" :menu-items="menuItems" />

    <form>
      <bpk-ribbon :tabs="tabs">
        <bpk-toolbar :index="0">
          <bpk-toolbar-panel title="Управление документом">
            <dkc-tool-button
              title="Сохранить"
              hint="Сохранить спецификацию в базе данных"
              iconclass="spec-save"
              control="tabulator-tableHolder"
              :action="saveSpec"
            >
            </dkc-tool-button>
            <dkc-tool-button
              title="Сохранить и закрыть"
              hint="Сохранить спецификацию в базе данных и закрыть документ"
              iconclass="spec-saveandclose"
              :action="saveAndCloseSpec"
            >
            </dkc-tool-button>
            <dkc-tool-button
              title="Закрыть без сохранения"
              hint="Закрыть спецификацию без сохранения в базе данных"
              iconclass="spec-close"
              :action="closeSpec"
            >
            </dkc-tool-button>
          </bpk-toolbar-panel>
          <bpk-toolbar-panel title="Excel">
            <dkc-tool-button
              title="Импорт из Excel"
              hint="Импорт спецификации из Excel-файла заказчика"
              iconclass="dbtn-import"
              control="tabulator-tableHolder"
              :action="specImportFromExcel"
            />

            <dkc-tool-button
              title="Экспорт в Excel"
              hint="Экспорт спецификации в Excel-файл для заказчика "
              iconclass="spec-export-excel"
              control="tabulator-tableHolder"
              :action="specExportToExcel"
            />
          </bpk-toolbar-panel>
          <bpk-toolbar-panel title="прочее">
            <dkc-tool-button
              title="Обновить таблицу"
              hint="обновить таблицу спецификации"
              iconclass="dbtn_refresh"
              control="tabulator-tableHolder"
              :action="redrawSpecTable"
            />
          </bpk-toolbar-panel>

          <bpk-toolbar-panel>
            <div class="alert alert-warning" v-if="show_backgroundmsg">
              {{ backgroundmsg }}
            </div>
          </bpk-toolbar-panel>
        </bpk-toolbar>
        <bpk-toolbar :index="1">
          <bpk-toolbar-panel title="Строка спецификации">
            <dkc-tool-button
              title="Новая строка"
              hint="Добавить новую строку в позицию спецификации"
              iconclass="spec-pin"
              control="tabulator-tableHolder"
              :action="specCreateSubPos"
            />
            <dkc-tool-button
              title="Удалить строку"
              hint="Удалить строку спецификации"
              iconclass="spec-minus"
              control="tabulator-tableHolder"
              :action="specDeleteRow"
            />
          </bpk-toolbar-panel>
          <bpk-toolbar-panel title="Позиции спецификации">
            <dkc-tool-button
              title="Новая позиция"
              hint="Добавить новую позицию в спецификацию"
              iconclass="spec-plus"
              control="tabulator-tableHolder"
              :action="specCreatePos"
            />
            <dkc-tool-button
              title="Удалить позицию"
              hint="Удалить позицию спецификации"
              iconclass="spec-cancel"
              control="tabulator-tableHolder"
              :action="specDeletePos"
            />
          </bpk-toolbar-panel>
          <bpk-toolbar-panel title="Буфер обмена">
            <div class="spec_toggle_block">
                     <div class="spec_toggle_label">Буфер обмена</div>
                     <div class="spec_toggle_control">
                        <VueToggles  
                           @click="changeClibboardMethod"                        
                           :value="clipboardMethod"
                           height="32"
                           width="120"
                           checkedText="импорт"
                           uncheckedText="стандартный"
                           checkedBg="skyblue"
                           uncheckedBg="skyblue"
                        />
                     </div>
                  </div>
          </bpk-toolbar-panel>
          </bpk-toolbar-panel>
          <bpk-toolbar-panel title="Дополнительные колонки">
            <dkc-tool-button
              title="Добавить колонку"
              hint="Добавить новую колонку в спецификацию"
              iconclass="spec-column-add"
              control="tabulator-tableHolder"
              :action="specAddColumn"
            />
            <dkc-tool-button
              title="Редактировать колонку"
              hint="Редактировать данные о колонке спецификации"
              iconclass="spec-column-edit"
              control="tabulator-tableHolder"
              :action="specEditColumn"
            />
            <dkc-tool-button
              title="Удалить колонку"
              hint="Удалить колонку спецификации"
              iconclass="spec-column-delete"
              control="tabulator-tableHolder"
              :action="specDeleteColumn"
            />
          </bpk-toolbar-panel>

          <bpk-toolbar-panel title="прочее">
            <dkc-tool-button
              title="Обновить таблицу"
              hint="обновить таблицу спецификации"
              iconclass="dbtn_refresh"
              control="tabulator-tableHolder"
              :action="redrawSpecTable"
            />
          </bpk-toolbar-panel>
        </bpk-toolbar>
        <bpk-toolbar :index="2">
          <bpk-toolbar-panel title="Выбор">
            <dkc-tool-button
              title="Выбор строк"
              hint="Показать инструменты выбора строк"
              iconclass="spec_show_selector"
              control="tabulator-tableholder"
              :action="toggeleVisibleRowSelector"
            />

            <dkc-tool-button
              title="Выбрать всё"
              hint="Выбрать все строки"
              :iconclass="{
                dbtn_begingroup: true,
                spec_select_all: true,
              }"
              control="tabulator-tableholder"
              :action="selectAllRows"
              v-if="visibleRowSelector"
            />

            <dkc-tool-button
              title="Убрать выделение"
              hint="Убрать выделение со всех строк"
              iconclass="spec_deselect_all"
              control="tabulator-tableholder"
              :action="deselectAllRows"
              v-if="visibleRowSelector"
            />

            <dkc-tool-button
              title="Обратный выбор"
              hint="Изменить выбор всех строк на обратный"
              iconclass="spec_select_inverse"
              control="tabulator-tableholder"
              :action="inversSelectAllRows"
              v-if="visibleRowSelector"
            />
          </bpk-toolbar-panel>
          <bpk-toolbar-panel title="Номенклатура заказчика">
            <dkc-tool-button
              :title="refnomenTitle"
              :hint="refnomenHint"
              iconclass="dbtn-show-refnomen"
              control="tabulator-tableHolder"
              :action="toggeleVisibleRefNomen"
            />

            <dkc-tool-button
              title="Выбрать номенклатуру"
              hint="Выбрать номенклатуру из справочника"
              iconclass="spec_nomen_selector"
              control="tabulator-tableHolder"
              :action="showNomenSelector"
            />

            <dkc-tool-button
              title="Не учитывать номенклатуру"
              hint="Не использовать найденную номенклатуру"
              iconclass="dbtn-nomen-unlink"
              control="tabulator-tableHolder"
              :action="unlinkNomen"
            />

            <dkc-tool-button
              title="Учитывать номенклатуру"
              hint="Использовать найденную номенклатуру"
              iconclass="dbtn-nomen-link"
              control="tabulator-tableHolder"
              :action="linkNomen"
            />
          </bpk-toolbar-panel>

          <bpk-toolbar-panel title="Поиск">
            <dkc-tool-button
              title="Поиск номенклатуры"
              hint="Поиск номенклатуры заказчика в выделенных строках"
              iconclass="spec_nomen_search"
              control="tabulator-tableholder"
              :action="findNomenBySelectedRows"
            />
            <dkc-tool-button
              title="Поиск аналогов"
              hint="Выполнить поиск аналогов"
              iconclass="dbtn-search"
              control="tabulator-tableHolder"
              :action="selectMethodSearchAnalogues"
            />
          </bpk-toolbar-panel>

          <bpk-toolbar-panel title="Вид">
            <dkc-tool-button
              title="Информация о строке"
              hint="Показать информацию о строке спецификации"
              iconclass="dbtn-show-specrow-info"
              control="tabulator-tableHolder"
              :action="showRowInfo"
            />
          </bpk-toolbar-panel>
          <bpk-toolbar-panel title="прочее">
            <dkc-tool-button
              title="Обновить таблицу"
              hint="обновить таблицу спецификации"
              iconclass="dbtn_refresh"
              control="tabulator-tableHolder"
              :action="redrawSpecTable"
            />
          </bpk-toolbar-panel>
        </bpk-toolbar>
        <bpk-toolbar :index="3">
          <bpk-toolbar-panel title="поз.">
            <dkc-tool-button
              title="Номера позиций"
              :hint="pos_visible_title"
              :iconclass="{
                dbtn_begingroup: true,
                spec_pos_visible: !pos_visible,
                spec_pos_hidden: pos_visible,
              }"
              control="tabulator-tableHolder"
              :action="changePosVisible"
            />
          </bpk-toolbar-panel>
          <bpk-toolbar-panel title="Финансы">
            <dkc-tool-button
              title="Цены заказчика"
              :hint="customerMoneyHint"
              :iconclass="{
                dbtn_begingroup: true,
                spec_show_money: !showCustomerMoney,
                spec_hide_money: showCustomerMoney,
              }"
              control="tabulator-tableHolder"
              :action="changeVisibleCustomerMoney"
            />
            <dkc-tool-button
              title="Обновить цены ДКС"
              hint="Установить в спецификации актуальные цены ДКС из справочника номенклатуры"
              iconclass="spec_recalc_money"
              control="tabulator-tableHolder"
              :action="updateDKCPrice"
            />
          </bpk-toolbar-panel>

          <bpk-toolbar-panel title="прочее">
            <dkc-tool-button
              title="Обновить таблицу"
              hint="обновить таблицу спецификации"
              iconclass="dbtn_refresh"
              control="tabulator-tableHolder"
              :action="redrawSpecTable"
            />
          </bpk-toolbar-panel>
        </bpk-toolbar>
      </bpk-ribbon>

      <div class="control-group">
        <div class="form-group">
          <label for="docnum" class="control-label">Номер</label>
          <input
            type="text"
            id="docnum"
            class="form-control control-docnum"
            v-model="docnum"
          />
        </div>
        <div class="form-group">
          <label for="docdate" class="control-label">Дата</label>
          <input
            type="date"
            id="docdate"
            class="form-control"
            v-model="docdate"
          />
        </div>
        <div class="form-group">
          <label for="customer" class="control-label">Наименование</label>
          <input
            type="text"
            id="customer"
            class="form-control control-customer"
            v-model="customer"
          />
        </div>
        <div class="form-group" :class="{ 'has-warning': isNewFirm }">
          <label for="idFirm" class="control-label"
            >Производитель <span v-if="isNewFirm">(новый)</span></label
          >
          <autocomplete
            ref="accelled"
            id="idFirm"
            :items="firms"
            v-model="firm"
            @update-items="updateFirms"
            :min-len="minFirmLen"
          >
          </autocomplete>
        </div>
        <div class="form-group">
          <label for="sign" class="control-label">Статус</label>
          <select
            name="sign"
            id="sign"
            class="form-control"
            v-model="sign"
            :disabled="!grant"
          >
            <option label="Черновик" value="D"></option>
            <option label="Утвержден" value="A"></option>
            <option label="Закрыт" value="C"></option>
            <option label="Аннулирован" value="E"></option>
          </select>
        </div>
        <div class="form-group">
          <label for="author" class="control-label">Автор</label>
          <input
            type="text"
            id="author"
            class="form-control"
            readonly
            v-model="authorname"
          />
        </div>
      </div>
      <div class="form-group">
        <label for="note" class="control-label">Примечание</label>
        <input type="text" class="form-control" v-model="note" id="note" />
      </div>

      <dkc-grid
        ref="dkcgrid"
        class="spec-table"
        tableid="spec-table"
        @get_insertdata="getInsertData"
        @after_celledited="cellValueChanged"
        @cell_pasted="cellPasted"
      ></dkc-grid>
    </form>
    <ed-izm-validator ref="eiv" />
    <firm-validator ref="fv" />
  </div>
</template>

<script>
import dkcModalDlg from "./components/dkcModalDlg";
import bpkRibbon from "./components/bpkRibbon";
import bpkToolbar from "./components/bpkToolbar";
import bpkToolbarPanel from "./components/bpkToolbarPanel";
import dkcToolButton from "./components/dkcToolButton";
import DkcGrid from "./components/dkcGrid";
import getUrlVar from "./units/urlparser";
import getNormEdIzm from "./units/edizmnorm";
import { SpecChecker } from "./units/specChecker";
import SpecStatus from "./units/SpecStatus";
import moneycalc from "./units/MoneyCalculator";
import removeStandart from "./units/standartremover";
import CellFormatters from "./units/SpecCellFormatters";
import { grantctl } from "./mixins/grantcontroller";
import { longtextFormatter } from "./mixins/LongTextFormatter";
import SpecClipboardComp from "./components/specClipboardComp";
import EdIzmValidator from "./components/edizmValidator";
import FirmValidator from "./components/firmValidator";
import MethodSearchAnaloguesSelector from "./components/MethodSearchAnaloguesSelector";
import ContextMenu from "./components/dkcContextMenu";
import circleAnimation from "./components/circleAnimation";
import Autocomplete from "v-autocomplete";
import "../css/autocomp.css";
import qs from "qs";
import { SpecLoaderAsync } from "./units/SpecLoaderAsync";
import { SpecSaverAsync } from "./units/SpecSaverAsync";
import { NomenResolver } from "./units/NomenResolver";
import { AnalogsWriter } from "./units/AnalogsWriter";
import NomenSelectorDialog from "./components/NomenSelectorDialog.vue";
import ExcelImportTunerDialog from "./components/ExcelImportTunerDialog.vue";
import NomenCollectSelector from "./components/NomenCollectSelector.vue";
import VueToggles from 'vue-toggles'

export default {
  name: "SpecEditor",
  mixins: [grantctl, longtextFormatter],
  components: {
    circleAnimation,
    dkcModalDlg,
    bpkRibbon,
    bpkToolbar,
    bpkToolbarPanel,
    dkcToolButton,
    DkcGrid,
    Autocomplete,
    ContextMenu,
    SpecClipboardComp, 
    MethodSearchAnaloguesSelector, 
    EdIzmValidator, 
    FirmValidator,  
    NomenSelectorDialog, 
    ExcelImportTunerDialog,
    NomenCollectSelector,
    VueToggles,
  },
  data() {
    return {
      menuItems: [
        {
          label: "Найти номенклатуру в интернете",
          name: "findInInternet",
          disabled: false,
          icon: "/images/url.svg",
          icon_size: "24",
          handler: this.URLMenuItemClick,
        },
        {
          label: "Найти номенклатуру в справочнике",
          name: "findInReference",
          disabled: false,
          icon: "/images/view.svg",
          icon_size: "24",
          handler: this.refMenuItemClick,
        },
        {
          label: "Выбрать вариант замены",
          name: "analogs",
          disabled: false,
          icon: "/images/refresh.svg",
          icon_size: "24",
          handler: this.selectAnalogMenuClick,
        },
        {
          label: "Поиск замены",
          name: "search_analogs",
          disabled: false,
          icon: "/images/binoculars.svg",
          icon_size: "24",
          handler: this.searchAnalogueForCurrentRow,
        },
        {
          label: "-",
        },
        {
          label: "Новая позиция",
          icon: "/images/plus.svg",
          icon_size: "24",
          handler: () => {
            this.specCreatePos();
          },
        },
        {
          label: "Новая строка в позиции",
          icon: "/images/pin2.svg",
          icon_size: "24",
          handler: () => {
            this.specCreateSubPos();
          },
        },
        {
          label: "Удалить позицию",
          icon: "/images/minus.svg",
          icon_size: "24",
          handler: () => {
            this.specDeletePos();
          },
        },
        {
          label: "-",
        },
        {
          label: "Сохранить спецификацию",
          icon: "/images/save.svg",
          icon_size: "24",
          handler: () => {
            this.saveSpec();
          },
        },
        {
          label: "-",
        },
        {
          label: "Утвердить позицию",
          icon: "/images/checkmark.svg",
          icon_size: "24",
          data: {
            code: "A",
            label: "утверждено",
          },
          handler: this.setStatusCurrentPos,
        },
        {
          label: "Черновик",
          icon: "/images/help.svg",
          icon_size: "24",
          data: {
            code: "D",
            label: "черновик",
          },
          handler: this.setStatusCurrentPos,
        },
        {
          label: "Исключить позицию",
          icon: "/images/multiply.svg",
          icon_size: "24",
          data: {
            code: "E",
            label: "исключить",
          },
          handler: this.setStatusCurrentPos,
        },
      ],

      idspec: 0,
      docnum: "",
      docdate: false,
      author: 0,
      customer: "",
      idFirm: 0,
      sign: "D",
      note: "",
      edizm: [],
      edizmfull: {},
      firmList: [],
      pasteData: false,
      extColID: 1,
      searcherCount: 0, // количество активных запросов на поиск
      showCustomerMoney: false,
      search_cache: [],

      // Видимость номера группы
      pos_visible: false,

      // Данные для поиска фирмы
      firms: [],
      firm: null,
      minFirmLen: 1,

      // данные для модального окна
      isModalDlgVisible: false,
      analogVisible: false,
      importVisible: false,
      headerText: "Заголовок модального окна",
      bodyText: "Сообщение в модальном окне",
      bodyAnalogText: "",
      buttons: ["yes", "no", "ok", "close"],
      actions: {
        yes: () => {
          alert("YESSSS!");
        },
      },
      colTitles: [
        {
          fields: ["c_idFirm"],
          titles: ["Завод", "Вендор"],
          start: false,
        },
        {
          fields: ["c_code", "d_code"],
          titles: ["Код"],
          start: false,
        },
        {
          fields: ["c_mark"],
          titles: ["Тип", "Марка"],
          start: false,
        },
        {
          fields: ["c_name", "d_name"],
          titles: ["Наименование"],
          start: false,
        },
        {
          fields: ["c_idedizm", "d_idedizm"],
          titles: ["Ед. изм", "ЕдИзм", "Единица измерения"],
          start: false,
        },
        {
          fields: ["c_quant", "d_quant"],
          titles: ["Кол-во", "Кол", "Количество"],
          start: false,
        },
        {
          fields: ["d_price"],
          titles: ["Цена"],
          start: false,
        },
        {
          fields: ["note"],
          titles: ["Примечание"],
          start: false,
        },
      ],

      structEditVisible: false,
      structEditbtns: ["ok", "close"],
      structEditacts: {
        ok: this.changeStructureGrid,
      },
      structEditHeader: "",
      structEditTitle: "",
      structEditNewColumn: false,
      cellformatters: CellFormatters,
      visibleRefNomen: false,
      // данные для кольца прогресса
      progresstitle: "",
      circleVisible: false,
      tabs: [
        { index: 0, title: "Файл", hint: "" },
        { index: 1, title: "Правка", hint: "" },
        { index: 2, title: "Расчет", hint: "" },
        { index: 3, title: "Вид", hint: "" },
      ],
      backgroundmsg: "",
      show_backgroundmsg: false,
      gridUpdated: false,
      visibleRowSelector: false,
      visibleNomenSelector: false,
      nomenSelectorSource: [],
      searchNomenAfterImport: false,
      resizeTimer: false,
      clipboardMethod: false,
    };
  },
  computed: {
    authorname: function () {
      return this.$store.state.user.fio;
    },
    datetype: function () {
      return typeof this.docdate;
    },
    grid: function () {
      return this.$refs.dkcgrid;
    },
    tabulator: function () {
      return this.grid.tabulator;
    },
    firmArray: function () {
      const arr = [];
      
      this.firmList.forEach( firm => {
        arr.push({value: firm.idFirm, label: firm.name})
      });

      return arr;
    },
    isNewFirm: function () {
      return this.firm != null && this.firm != "" && this.idFirm == 0;
    },
    searchActive: function () {
      return this.searcherCount > 0;
    },
    shortColumnsDefs: function () {
      return this.tabulator
        ? this.truncColumnDefinitions(this.tabulator.getColumnDefinitions())
        : [];
    },
    draftCount: function () {
      const data = this.tabulator.getData();
      let rr = 0;
      data.forEach((row) => {
        if (row.sign == "D") rr++;
      });

      return rr;
    },
    extcols: function () {
      const cols = [];
      const tbldefs = this.tabulator.getColumnDefinitions();
      const blockdef = tbldefs.find((def) => def.field == "extblock");
      if (blockdef.columns != undefined) {
        blockdef.columns.forEach((coldef) => {
          cols.push({
            field: coldef.field,
            title: coldef.title,
          });
        });
      }
      return cols;
    },
    customerMoneyHint: function () {
      return (
        (this.showCustomerMoney ? "Скрыть" : "Показать") +
        " цену и сумму заказчика"
      );
    },
    edizmRef: function () {
      return this.$store.state.edizmRef.ref;
    },
    pos_visible_title: function () {
      return this.pos_visible ? "Скрыть номера групп" : "Показать номера групп";
    },
    refnomenTitle: function () {
      return (this.visibleRefNomen ? "Скрыть" : "Показать") + " номенклатуру";
    },
    refnomenHint: function () {
      return (
        (this.visibleRefNomen ? "Скрыть" : "Показать") +
        " найденную номенклатуру заказчика"
      );
    },
  },
  watch: {
    firm: function () {
      if (this.firm == "" || this.firm == null) {
        this.idFirm = 0;
        return;
      }
      this.idFirm = this.getFirmByName(this.firm).idFirm;
    },
    idFirm: function () {    
      if (this.idFirm == 0) {
        this.firm = "";
        return;
      }
      this.firm = this.getFirmByID(this.idFirm).name;
    },
    sign: function () {
      const dc = this.draftCount;
      if (this.sign != "A" || dc == 0) return;

      this.headerText = "Изменение статуса позиций спецификации";
      this.bodyText = `В спецификации обнаружено ${dc} позиций со статусом черновик. Присвоить им статус "Утверждено"?`;
      this.buttons = ["yes", "no"];
      this.actions = { yes: this.setStatusAFromD };
      this.showModalDlg();
    },
    note: function () {
      //this.setModified(true)
    },
    showCustomerMoney: function () {
      const self = this;
      const fields = ["c_price", "c_summa"];
      fields.forEach((field) => {
        const col = self.tabulator.getColumn(field);
        if (!col) return;
        if (self.showCustomerMoney) {
          col.show();
        } else {
          col.hide();
        }
      });
    },
    pos_visible: function () {
      const tbl = this.tabulator;
      const column = tbl.getColumn("pos");
      if (this.pos_visible) {
        column.show();
      } else {
        column.hide();
      }
    },
  },
  methods: {
    beginUpdateGrid: function () {
      if (this.tabulator) {
        this.tabulator.options.dataChanged = false;
        this.tabulator.options.rowAdded = () => {};
      }

      this.gridUpdated = true;
    },
    endUpdateGrid: function () {
      if (this.tabulator) {
        this.tabulator.options.dataChanged = this.dataChanged;
        this.tabulator.options.rowAdded = this.rowAdded;
      }
      this.gridUpdated = false;
    },
    redrawSpecTable: function () {
      this.tabulator.redraw();
    },
    changeVisibleCustomerMoney: function () {
      this.showCustomerMoney = !this.showCustomerMoney;
    },
    deleteColumn: function () {
      const field = this.grid.currentCell.getField();
      if (field.substr(0, 2).toLowerCase() != "ec") return;

      const tbldefs = this.tabulator.getColumnDefinitions();
      const blockdef = tbldefs.find((def) => def.field == "extblock");
      const coldef = blockdef.columns.find((def) => def.field == field);
      const ind = blockdef.columns.indexOf(coldef);
      blockdef.columns.splice(ind, 1);
      blockdef.visible = !!blockdef.columns.length;
      this.tabulator.setColumns(tbldefs);
      this.setModified();
    },
    appendColumn: function (field, title) {
      const coldef = {
        title: title == undefined ? this.structEditTitle : title,
        field: field == undefined ? "ec" + this.extColID++ : field,
        visible: true,
        editor: "input",
        headerFilter: true,
        resizable: "header",
        width: 250,
        variableHeight: true,
        formatter: this.formatcell,
      };

      if (field != undefined) {
        const colID = +field.substring(2) + 1;
        if (colID > this.extColID) this.extColID = colID;
      }

      const tbldefs = this.tabulator.getColumnDefinitions();
      const blockdef = tbldefs.find((def) => def.field == "extblock");

      if (blockdef.columns == undefined) blockdef.columns = [];
      blockdef.columns.push(coldef);
      blockdef.visible = true;
      this.tabulator.setColumns(tbldefs);
    },
    updateColumn: function (field, title) {
      if (field == undefined) field = this.grid.currentCell.getField();
      if (field.substr(0, 2).toLowerCase() != "ec") return;

      const tbldefs = this.tabulator.getColumnDefinitions();
      const blockdef = tbldefs.find((def) => def.field == "extblock");
      const coldef = blockdef.columns.find((def) => def.field == field);
      coldef.title = title == undefined ? this.structEditTitle : title;
      this.tabulator.setColumns(tbldefs);
    },
    findColumnDefinition: function (field) {
      const tbldefs = this.tabulator.getColumnDefinitions();
      const blockdef = tbldefs.find((def) => def.field == "extblock");

      if (blockdef.columns == undefined) return undefined;

      return blockdef.columns.find((def) => def.field == field);
    },
    changeStructureGrid: function () {
      if (this.structEditNewColumn) {
        this.appendColumn();
      } else {
        this.updateColumn();
      }

      this.setModified();
    },
    closeStructEditDlg: function () {
      this.structEditVisible = false;
    },
    specAddColumn: function () {
      this.structEditHeader = "Новая колонка";
      this.structEditTitle = "Новая колонка";
      this.structEditNewColumn = true;
      this.structEditVisible = true;
    },
    specEditColumn: function () {
      const grid = this.grid 
      const cell = grid.currentCell
      const col = cell.getColumn()
      const coldef = col.getDefinition()
      // const coldef = this.grid.currentCell.getColumn().getDefinition();
      const field = coldef.field;
      if (field.substr(0, 2).toLowerCase() != "ec") return;

      this.structEditHeader = "Редактирование колонки";
      this.structEditTitle = coldef.title;
      this.structEditNewColumn = false;
      this.structEditVisible = true;
    },
    specDeleteColumn: function () {
      const field = this.grid.currentCell.getField();
      if (field.substr(0, 2).toLowerCase() != "ec") {
        this.showErrorMessage("Эту колонку удалять нельзя!");
        return;
      }

      this.headerText = "Подтверждение удаления колонки";
      this.bodyText = "Вы хотите удалить текущую колонку?";
      this.buttons = ["yes", "no"];
      this.actions = {
        yes: this.deleteColumn,
      };
      this.showModalDlg();
    },
    setModified: function (modified = true) {
      window.dkcapp.$store.state.modified = modified;
    },
    /**
       Меняет статус позиций с statFrom на statTo
       */
    changePosStatus: function (statFrom, statTo) {
      const statS = new SpecStatus();
      const groups = this.tabulator.getGroups();
      try {
        this.beginUpdateGrid();
        groups.forEach((group) => {
          const rows = group.getRows();
          const data = rows[0].getData();
          if (statS.key(data.sign) == statFrom) {
            data.sign = statTo;
            rows[0].update(data);
          }
        });
      } finally {
        this.endUpdateGrid();
      }
    },
    /**
       Устанавливает статус на утверждено для позиций со статусом черновик
       */
    setStatusAFromD: function () {
      this.changePosStatus("D", "A");
    },

    /**
       Возвращает гуппу с заданным pos
       */
    searchGroup: function (pos) {
      return this.tabulator.getGroups().find((group) => group.getKey() == pos);
    },
    /**
     *   Возвращаем список номенклатуры с обоих частей спецификации
     *   где не заполнен idnomen
     */
    getEmptyNomen: function () {
      const self = this;
      const data = this.tabulator.getData();
      const nomens = [];
      const getnomen = (row, side) => {
        return {
          idnomen: row[side + "_idnomen"],
          code: removeStandart(row[side + "_code"]),
          mark: removeStandart(row[side + "_mark"]),
          name: row[side + "_name"],
          idFirm: side == "c" ? row["c_idFirm"] : "DKC",
          left: side == "c",
          ids: [],
        };
      };

      data.forEach((row) => {
        ["c", "d"].forEach((side) => {
          const nomen = getnomen(row, side);
          if (!nomen.code && !nomen.mark && !nomen.name) return;

          if (!nomen.idnomen) {
            const emptynomen = nomens.find((nm) => {
              return self.equalNomen(nomen, nm);
            });

            if (!emptynomen) {
              nomens.push(nomen);
              nomen.ids.push(row.innerID);
            } else {
              emptynomen.ids.push(row.innerID);
            }
          }
        });
      });

      return nomens;
    },
    getCustomerNomen: function (rowData) {
      return {
        idnomen: rowData.с_idnomen,
        idFirm: rowData.c_idFirm,
        code: rowData.c_code,
        mark: rowData.c_mark,
        name: rowData.c_name,
      };
    },
    getUsNomen: function (rowData) {
      return {
        idnomen: rowData.d_idnomen,
        idFirm: 1,
        code: rowData.d_code,
        mark: "",
        name: rowData.d_name,
      };
    },
    defineNomenField: function (nomen) {
      if (nomen.code == undefined) nomen.code = "";
      if (nomen.mark == undefined) nomen.mark = "";
      if (nomen.name == undefined) nomen.name = "";
    },
    equalNomen: function (nomen1, nomen2) {
      this.defineNomenField(nomen1);
      this.defineNomenField(nomen2);
      return (
        (nomen1.code && nomen2.code && nomen1.code == nomen2.code) ||
        (nomen1.mark && nomen2.mark && nomen1.mark == nomen2.mark) ||
        (!nomen1.code &&
          !nomen1.mark &&
          nomen1.name &&
          nomen1.name == nomen2.name)
      );
    },

    doFindNomen: function (nomens) {
      if (!nomens || !nomens.length) return;
      this.circleVisible = true;
      const self = this;

      const resolver = new NomenResolver({
        nomens,
        idFirm: this.idFirm,
        tabulator: self.tabulator,
        firmMutator: self.firmMutator,
        hideCircle: function () {
          self.circleVisible = false;
        },
        showError: function (message, error) {
          self.circleVisible = false;
          self.showErrorMessage(message, error);
        },
        showMessage: function (message) {
          self.progresstitle = message;
        },
      });
      resolver
        .execute()
        .then((response) => {
          self.circleVisible = false;
        })
        .catch((error) => {
          self.circleVisible = false;
          self.showErrorMessage("Ошибка поиска номенклатуры", error);
        });
    },
    /**
     * Собирает с таблицы "пустую" номенклатуру (нет idnomen) и
     * отправлят на сервер (всё сразу) для поиска номенклатуры
     * далее необходимо запустить поиск аналогов (так же всех сразу)
     */
    batchResolveNomen: function () {
      this.progresstitle =
        "Поиск номенклатуры в справочнике (подготовка данных)";

      const nomens = this.getEmptyNomen();
      nomens.forEach((nomen) => {
        nomen.code = removeStandart(nomen.code);
        nomen.mark = removeStandart(nomen.mark);
      });

      if (nomens.length) {
        this.doFindNomen(nomens);
      }
    },
    loadSpecFromExcel: function (data) {
      this.progresstitle = "Чтение данных из выбранного Excel-файла";
      this.circleVisible = true;
      const cmp = this;
      const excelData = data.excel; // this.$refs.excelimp.excelData;
      if (!excelData.length) {
        this.circleVisible = false;
        return;
      }

      const search_immediate = data.search_immediate;
      const tbl = cmp.tabulator;
      tbl
          .setData(excelData)
          .then((data) => {
            if (search_immediate) {
              cmp.batchResolveNomen();
            } else {
              cmp.circleVisible = false;
            }
          })
          .catch((error) => {
            cmp.circleVisible = false;
          })
    },
    getFirmByID: function (id) {
      let firm = { idFirm: 0, name: "" };
      this.firmList.forEach((frm) => {
        if (frm.idFirm == id) firm = frm;
      });

      return firm;
    },
    getFirmByName: function (firm_name) {
      let firm = { idFirm: 0, name: "" };
      if (firm_name != null) {
        this.firmList.forEach((frm) => {
          if (frm.name.toLowerCase() == firm_name.toLowerCase()) firm = frm;
        });
      }

      return firm;
    },
    updateFirms: function (text) {
      const cmp = this;
      this.firms = [];
      if (text == null) return;

      this.firmList.forEach((frm) => {
        if (
          frm.isUs == 0 &&
          frm.name.toLowerCase().indexOf(text.toLowerCase()) > -1
        )
          cmp.firms.push(frm.name);
      });
    },
    showErrorMessage: function (context, error) {
      this.headerText = "Ошибка выполнения программы";
      this.bodyText =
        context +
        ("message" in error
          ? "<p>" + error.message + "</p>" + error.stack
          : "") +
        ("response" in error ? "\n" + error.response.data : "");
      this.buttons = ["ok"];
      this.actions = { ok: this.closeModalDlg };
      this.showModalDlg();

      if (error) {
        const el = document.getElementById("debug");
        if (el != undefined) {
          if ("response" in error) {
            if ("data" in error.response) {
              el.innerHTML = error.response.data;
            } else {
              el.innerHTML = error.response;
            }
          } else {
            el.innerHTML = error;
          }
        }

        if ("message" in error) {
          console.error(error.message + "\n" + error.stack);
        }
      }
    },
    showModalDlg(afterShow = false) {
      this.isModalDlgVisible = true;
      if (afterShow) {
        afterShow();
      }
    },
    closeModalDlg() {
      this.isModalDlgVisible = false;
    },
    closeAnalogModalDlg() {
      this.analogVisible = false;
    },
    closeImportTunerDlg() {
      this.importVisible = false;
    },
    specCreatePos: function () {
      const cmp = this;
      const insData = {};
      this.getInsertData(insData);
      this.tabulator
        .addRow(insData)
        .then(function (row) {
          cmp.$emit("after_insert", row);
          const cells = row.getCells();
          const firstCell = cells.find((cell) => {
            const column = cell.getColumn();
            const coldef = column.getDefinition();
            return column.isVisible() && coldef.editor != undefined;
          });

          if (firstCell) {
            cmp.grid.setCellCoordinates(firstCell);
            cmp.grid.setTableFocus();
            firstCell.edit();
          }
        })
        .catch((error) => {
          console.error("Ошибка добавления строки в таблицу", error);
        });
    },
    specCreateSubPos: function () {
      const cmp = this
      const grid = this.grid
      const row = grid.currentRow 
      const data = row.getData()
      const insData = {};
      this.getInsertData(insData);
      insData.pos = data.pos;
      this.tabulator
        .addRow(insData)
        .then(function (row) {
          cmp.$emit("after_insert", row);
          const cells = row.getCells();
          let firstCell = false;
          cells.forEach(function (cell) {
            const column = cell.getColumn();
            const coldef = column.getDefinition();
            if (typeof coldef.editor != "undefined" && !firstCell) {
              firstCell = cell;
            }
          });

          if (firstCell) {
            cmp.grid.setCellCoordinates(firstCell);
            cmp.grid.setTableFocus();
            firstCell.edit(true);
          }
        })
        .catch((error) => {
          console.error("Ошибка добавления строки в таблицу", error);
        });
    },
    specDeletePos: function () {
      const group = this.grid.currentRow.getGroup();
      const rows = group.getRows();
      rows.forEach((row) => {
        row.delete();
      });
    },
    specDeleteRow: function () {
      this.grid.currentRow.delete();
    },
    loadSpec: function (spec) {
      this.progresstitle = "Загрузка спецификации из базы данных";
      this.circleVisible = true;

      try {
        this.idspec = spec.idspec;
        this.docnum = spec.docnum;
        this.docdate = spec.docdate;
        this.author = spec.author;
        this.customer = spec.customer;
        this.idFirm = spec.idFirm;
        this.sign = spec.sign;
        this.note = spec.note;

        const self = this;
        const extc = spec.extcols;
        if (extc) {
          extc.forEach((col) => {
            if (self.findColumnDefinition(col.field) == undefined) {
              self.appendColumn(col.field, col.title);
            } else {
              self.updateColumn(col.field, col.title);
            }
          });
        }

        spec.table.forEach((row) => {
          if (row.analogs && typeof row.analogs == "string")
            try {
              row.analogs = JSON.parse(row.analogs);
            } catch {
              row.analogs = [];
            }

          row.innerID = this.grid.sequenceID.nextval();
          if (row.c_quant && row.c_price)
            row.c_summa = row.c_quant * row.c_price;
          if (row.d_quant && row.d_price)
            row.d_summa = row.d_quant * row.d_price;

          if (row.c_nomen) {
            row.r_idnomen = row.c_nomen.idnomen;
            row.r_firmname = row.c_nomen.firmname;
            row.r_code = row.c_nomen.code;
            row.r_mark = row.c_nomen.mark;
            row.r_name = row.c_nomen.name;
          }
        });

        this.tabulator.setData(spec.table);
        this.circleVisible = false;
        this.setModified(false);
      } catch (error) {
        this.circleVisible = false;
        this.showErrorMessage("Ошибка загрузки спецификации", error);
      }
    },
    getSpecFormData: function () {
      const self = this;
      const tblData = this.tabulator.getData();
      tblData.forEach((td) => {
        const anl = [];
        if (td.analogs) {
          td.analogs.forEach((a) => {
            anl.push({
              itemscode: a.itemscode,
              idspecpos: a.idspecpos,
            });
          });
        }
        td.analog_index = self.analogindexByInnerID(td.innerID);
        td.analogs = anl;
        td.c_found = false;
        td.d_found = false;
        td.c_nomen = false;
        td.d_nomen = false;

        if (!td.c_idedizm)
          td.c_idedizm = td.c_quant ? (td.c_nomen ? td.c_nomen.idedizm : 1) : 0;
        if (!td.d_idedizm && td.d_idnomen)
          td.d_idedizm = td.d_quant ? (td.d_nomen ? td.d_nomen.idedizm : 1) : 0;
      });

      const formData = new FormData();
      formData.append("command", "init");
      formData.append("idspec", this.idspec);
      formData.append("docnum", this.docnum);
      formData.append("docdate", this.docdate);
      formData.append("author", this.author);
      formData.append("customer", this.customer);
      formData.append("idFirm", this.idFirm);
      formData.append("firmName", this.firm);
      formData.append("sign", this.sign);
      formData.append("note", this.note);
      formData.append("extcols", JSON.stringify(this.extcols));
      formData.append("table", JSON.stringify(tblData));

      return formData;
    },
    dosaveSpec: function (closeAfterSave) {
      const self = this;
      this.progresstitle = "Сохранение спецификации в базе данных...";
      this.circleVisible = true;
      if (this.author == 0) {
        this.author = this.$store.state.user.id;
      }

      const handlers = {
        hideCircle: function () {
          self.circleVisible = false;
        },
        showError: function (message, error) {
          self.circleVisible = false;
          self.showErrorMessage(message, error);
        },
        showMessage: function (message) {
          self.progresstitle = message;
        },
      };
      const saver = new SpecSaverAsync(handlers);
      saver
        .execute(this.getSpecFormData())
        .then((data) => {
          self.circleVisible = false;
          self.setModified(false);
          if (closeAfterSave) {
            self.closeSpec();
          } else if (window.location.pathname != this.baseUrl + "/spec/update") {
            window.location = this.baseUrl + "/spec/update?id=" + data.idspec;
          }
        })
        .catch((error) => {
          self.circleVisible = false;
          self.showErrorMessage("Ошибка сохранения спецификации", error);
        });
    },
    saveSpec: function (event, data) {
      const self = this;
      let closeAfterSave = false;
      switch (typeof data) {
        case "boolean":
          closeAfterSave = data;
          break;
        case "object":
          closeAfterSave = !!data.closeAfterSave;
          break;
      }

      const checker = new SpecChecker(self);
      checker
        .check()
        .then((result) => {
          self.dosaveSpec(closeAfterSave);
        })
        .catch((error) => {
          alert(error);
        });
    },
    closeSpec: function () {
      window.location = this.baseUrl + "/spec"
    },
    saveAndCloseSpec: function (event, data) {
      this.saveSpec(event, true);
    },
    specPasteParser: function (pasteData) {
      this.$refs.specclp.execute({
        clpdata: pasteData,
        grid: this.grid,
        firmList: this.firmList,
        edizm: this.edizm,
        fieldDefs: this.shortColumnsDefs,
      });
    },
    cellPasted: function (data) {
      if (!this.clipboardMethod) {
        return 
      }
      const clpdata = data.event.clipboardData.getData("text/plain");
      data.event.preventDefault();
      return this.$refs.specclp.execute({
        clpdata: clpdata,
        grid: this.grid,
        firmList: this.firmList,
        edizm: this.edizm,
        fieldDefs: this.shortColumnsDefs,
      });
    },
    /**
     *  Вставка подготовленных данных в таблицу
     *  Восле вставки данных запускаем пакетный поиск номенклатуры
     */
    clipboardPasteAction: function (data) {
      return this.$refs.specclp.clipboardPasteAction(data);
    },
    posFormatter: function (cell, formatterParams, onRendered) {
      const row = cell.getRow();
      const group = row.getGroup();
      if (!group) return cell.getValue();
      const rows = group.getRows();
      if (rows.length < 2) {
        return cell.getValue();
      }

      if (row.getData().innerID == rows[0].getData().innerID)
        return cell.getValue();
      return "";
    },
    signFormatter: function (cell, formatterParams, onRendered) {
      //const field  = cell.getField()
      const sign = cell.getValue();
      const row = cell.getRow();
      const group = row.getGroup();
      if (!group) return;
      const rows = group.getRows();
      if (rows.length < 2) {
        return sign;
      }

      let rowData = row.getData();
      const innerID = rowData.innerID;
      for (let i = 1; i < rows.length; i++) {
        rowData = rows[i].getData();
        if (rowData.innerID == innerID) {
          return "";
        }
      }
      return sign;
    },
    quantFormatter: function (cell, formatterParams, onRendered) {
      const field = cell.getField();
      const side = field.substr(0, 1);
      const row = cell.getRow();
      const data = row.getData();
      const idedizm = data[side + "_idedizm"];
      const quant = data[side + "_quant"];
      if (idedizm || quant) {
        return cell.getValue();
      }

      return "";
    },
    firmAccessor: function (value, data, type, params, column) {
      if (!value) {
        return 0;
      }

      const firm = this.firmArray.find(
        item => item.label.toLowerCase() == value.toLowerCase()
      )

      if (!firm) {
        return 0
      }

      return firm.value
    },
    firmMutator: function (value, data, type, params, component) {
      if (isNaN(value)) {
        return value;
      }

      const firm = this.firmArray.find( item => item.value == value) ;

      return firm ? firm.label : '' ;
    },
    numberMutator: function (value, data, type, params, component) {
      if (typeof value == "string") {
        return +value.replace(" ", "");
      }

      return value;
    },
    edIzmAccessor: function (value, data, type, params, column) {
      //value - original value of the cell
      //data - the data for the row
      //type - the type of access occurring  (data|download|clipboard)
      //params - the accessorParams object passed from the column definition
      //column - column component for the column this accessor is bound to

      if (typeof value == "undefined") {
        return 0;
      }

      const Result = Math.max(0, this.edizm.indexOf(value.toLowerCase()));

      if (Result < 1) return value;
      return Result;
    },
    edIzmMutator: function (value, data, type, params, component) {
      if (!value) return "";

      let eii = parseInt(value);
      if (isNaN(eii)) {
        eii = this.$store.getters.idedizm(value.toLowerCase());
        //eii = this.edizmfull[value.toLowerCase()]
        if (!eii) return getNormEdIzm(value);
      }

      //return this.edizm[eii]
      return this.$store.getters.name(eii);
    },
    signAccessor: function (value, data, type, params, column) {
      if (typeof value == "undefined") {
        return "D";
      }

      const sstat = new SpecStatus();
      return sstat.key(value);
    },
    signMutator: function (value, data, type, params, component) {
      if (value == undefined) return "-";

      const sstat = new SpecStatus();
      if (value.length > 1) value = sstat.key(value);
      return sstat.title(value);
    },
    keytextMutator: function (value, data, type, params, component) {
      return value ? ("" + value).replace("\r\n", " ").trim() : "";
    },

    getInsertData: function (data) {
      const tabdata = this.tabulator.getData()
      const nextPos = tabdata.reduce( (pos, row) => Math.max(row.pos, pos), 0) + 1

      const cdf = this.tabulator.getColumnDefinitions();
      cdf.forEach((clmn) => { data[clmn.field] = false });

      data.pos = nextPos;
      data.sign = "D";
      data.c_idFirm = "";
      data.c_idFirm = tabdata.length ? tabdata[tabdata.length - 1].c_idFirm : 0 
      data.c_idedizm = 0;
      data.d_idedizm = 0;
      data.note = "";
    },
    /**
     * Конвертирует группу спецификации в объект, для организации поиска аналогов
     */
    convertGroupToSAObject: function (group) {
      const rows = group.getRows();
      const nomens = [];
      let cellvalue = false;
      rows.forEach((row) => {
        const data = row.getData();
        cellvalue = data.c_idnomen;
        if (cellvalue) nomens.push(cellvalue);
      });

      nomens.sort();
      return {
        pos: group.getKey(),
        nomens,
        analogs: [],
      };
    },
    /**
     * Возвращает выделенные строки или текущую строку, если выделенных строк нет
     * @returns array of row - массив строк
     */
    getSelectedRows() {
      const selectedRows = this.tabulator.getSelectedRows();
      if (!selectedRows.length && this.grid.currentRow) {
        selectedRows.push(this.grid.currentRow);
      }

      return selectedRows;
    },
    /**
     * Метод восстанавливает данные о найденой номенклатуре
     *  1. из блока r_*
     *  2. еслив блоке r_* ничего нет, то запускаем поиск номенклатуры
     */
    linkNomen() {
      const selectedRows = this.getSelectedRows();
      if (!selectedRows.length) {
        return;
      }

      const nomens = [];

      selectedRows.forEach((row) => {
        const data = row.getData();
        if (data.r_idnomen) {
          // Данные в блоке r_* найдены, восстанавливаем код номенклатуры
          data.c_idnomen = data.r_idnomen;
        } else {
          // Данные в блоке r_* не найдены, закидывам данные в массив поиска номенклатуры
          data.c_idnomen = 0;
          data.r_idnomen = 0;
          nomens.push({
            idnomen: 0,
            idFirm: data.c_idFirm,
            code: removeStandart(data.c_code),
            mark: removeStandart(data.c_mark),
            name: data.c_name,
            left: true,
            ids: [data.innerID],
          });
        }
        row.update(data);
        row.reformat();
      });

      // Если есть что искать, то запускаем поиск
      if (nomens.length /*> 0 && nomens.length < 21*/) {
        this.doFindNomen(nomens);
      }
    },
    /**
     * Метод стирает ID номенклатуры заказчика, оставляя информацию о ранее найденной номенклатуре в блоке r_*
     */
    unlinkNomen() {
      const selectedRows = this.getSelectedRows();
      if (!selectedRows.length) {
        return;
      }

      selectedRows.forEach((row) => {
        const data = row.getData();
        data.c_idnomen = -1;
        //data.r_idnomen = -1;
        row.update(data);
        row.reformat();
      });
    },
    findNomenBySelectedRows() {
      this.$refs.ncs
        .execute()
        .then((data) => {
          let rows = [];
          switch (+data) {
            case 0:
              rows = this.tabulator.getRows();
              break;
            case 1:
              rows = this.tabulator.getRows().filter((row) => {
                const data = row.getData();
                return !data.c_idnomen || !data.r_idnomen;
              });
              break;
            case 2:
              rows = this.tabulator.getSelectedRows();
              if (!rows.length && this.grid.currentRow) {
                rows.push(this.grid.currentRow);
              }
              break;

            case 3:
              if (this.grid.currentRow) {
                rows.push(this.grid.currentRow);
              }
              break;
          }

          if (!rows.length) {
            return;
          }

          const nomens = [];

          rows.forEach((row) => {
            const data = row.getData();
            if (data.r_idnomen == -1) {
              return;
            }

            nomens.push({
              idnomen: 0,
              idFirm: data.c_idFirm,
              code: removeStandart(data.c_code),
              mark: removeStandart(data.c_mark),
              name: data.c_name,
              left: true,
              ids: [data.innerID],
            });
          });

          if (nomens.length > 0) {
            this.doFindNomen(nomens);
          }
        })
        .catch(() => {
          console.log("Юзер отказался искать номенклатуру");
        });
    },
    selectAllRows() {
      const rows = this.tabulator.getRows();
      rows.forEach((row) => {
        row.select();
      });
    },
    deselectAllRows() {
      const rows = this.tabulator.getRows();
      rows.forEach((row) => {
        row.deselect();
      });
    },
    inversSelectAllRows() {
      const rows = this.tabulator.getRows();
      rows.forEach((row) => {
        row.toggleSelect();
      });
    },
    toggeleVisibleRowSelector() {
      this.visibleRowSelector = !this.visibleRowSelector;
      const column = this.tabulator.getColumn("rowselector");
      if (this.visibleRowSelector) {
        column.show();
      } else {
        column.hide();
      }
    },
    toggeleVisibleRefNomen() {
      this.visibleRefNomen = !this.visibleRefNomen;
      const tbl = this.tabulator;

      ["r_firmname", "r_code", "r_mark", "r_name"].forEach((field) => {
        const column = tbl.getColumn(field);
        if (this.visibleRefNomen) {
          column.show();
        } else {
          column.hide();
        }
      });
    },
    showRowInfo() {
      const self = this;
      const caps = [];
      const fields = ["c_code", "c_mark", "c_name", "d_code"];
      fields.forEach((field) => {
        self.cellformatters
          .filter((cf) => {
            return cf.fields.some((fl) => {
              return fl == field;
            });
          })
          .forEach((cf) => {
            const cell = self.grid.currentRow.getCell(field);
            if (cf.handle(cell)) {
              caps.push({
                field: field,
                class: cf.class,
                caption: cf.caption,
              });
            }
          });
      });

      let msg = "";
      if (!caps.length) msg = "Цветовой кодировки не обнаружено";

      if (caps.length == 1)
        msg =
          '<span class="' + caps[0].class + '">' + caps[0].caption + "</span>";

      if (caps.length > 1) {
        msg = "<ol>";
        caps.forEach((cp) => {
          if (msg.indexOf(cp.caption) < 0)
            msg =
              msg +
              '<li><span class="' +
              cp.class +
              '">' +
              cp.caption +
              "</span></li>";
        });
        msg = msg + "</ol>";
      }

      msg =
        "<div>" +
        msg +
        "</div><div>Служебная информация о строке</div><div><table><tr><td>Атрибут</td><td>Значение</td></tr>";
      const crow = this.grid.currentRow.getData();
      const keys = Object.keys(crow);
      keys.forEach((key) => {
        msg = msg + "<tr><td>" + key + "</td><td>" + crow[key] + "</td></tr>";
      });

      msg = msg + "</table></div>";

      this.headerText = "Цветовая кодировка строки";
      this.bodyText = msg;
      this.buttons = ["close"];
      this.showModalDlg();
    },
    selectMethodSearchAnalogues() {
      const data = this.tabulator.getData();
      const filledRows = data.some((row) => row.d_idnomen);
      this.$refs.msas.execute(filledRows);
    },
    prepareSearchAnalogues(methodIndex) {
      /* console.log(
        "Начали prepareSearchAnalogues: " + new Date().toLocaleTimeString()
      ); */
      if (methodIndex == 2) {
        this.searchAnalogueForCurrentRow();
        return;
      }

      const self = this;
      const saList = [];
      const groups = this.tabulator.getGroups();
      groups.forEach((group) => {
        const rows = group.getRows();
        const rd = rows[0].getData();
        if (!methodIndex || !rd.d_idnomen) {
          saList.push(self.convertGroupToSAObject(group));
        }
      });

      if (saList.length > 0) self.doSearchAnalogs(saList);
    },
    searchAnalogueForCurrentRow() {
      const row = this.grid.actRow;
      if (row){
        const idnomen = row.getData().c_idnomen;
        this.searchAnalogue(idnomen, row);
      }
    },
    searchAnalogue: function (idnomen, row) {
      //const cr = this.tabulator.getRowFromPosition(rowIndex);
      if (!row) {
        return
      }
      const group = this.convertGroupToSAObject(row.getGroup());
      if (idnomen && !group.nomens.includes(idnomen))
        group.nomens.push(idnomen);
      this.doSearchAnalogs([group]);
    },
    /**
       Выполнение поиска аналогов для массива групп
       */
    doSearchAnalogs: function (groupsArr, firstLength) {
      firstLength = firstLength || groupsArr.length;
      const partLength = 200;
      const partArray = groupsArr.splice(0, partLength);
      const prots = firstLength
        ? Math.round((100 * (firstLength - groupsArr.length)) / firstLength)
        : 100;
      this.progresstitle = "Выполняется поиск аналогов: " + prots + "%";
      this.circleVisible = true;

      const cmp = this;
      const formData = new FormData();
      formData.append("groups", JSON.stringify(partArray));
      const options = {
        method: "POST",
        url: this.baseUrl + "/spec/analogs",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "application/json; charset=utf-8",
          "X-CSRF-Token": tkn_csfr,
        },
        data: formData,
      };

      const respF = function (response) {
        const writer = new AnalogsWriter({
          tabulator: cmp.tabulator,
          searchResult: response.data,
          setAnalog: cmp.setAnalog,
          beginUpdateGrid: cmp.beginUpdateGrid,
          endUpdateGrid: cmp.endUpdateGrid,
        });
        writer.write();
        const self = cmp;        

        if (groupsArr.length) {
          self.doSearchAnalogs(groupsArr, firstLength);
          return;
        }

        cmp.circleVisible = false;        
      };
      const errF = function (error) {
        cmp.circleVisible = false;
        cmp.showErrorMessage("Ошибка поиска аналогов", error);
      };

      axios(options).then(respF).catch(errF);
    },
    setAnalog: function (cellInfo) {
      const cmp = this;
      const row =
        "row" in cellInfo && cellInfo.row != undefined
          ? cellInfo.row
          : cellInfo.cell.getRow();
      const rowIndex = this.tabulator.getRowPosition(row);
      const rd = row.getData() 
      const innerID = rd.innerID
      const pos = rd.pos
      const aind = rd.analog_index
      /* const innerID = this.grid.getFieldValue("innerID", rowIndex);
      const pos = this.grid.getFieldValue("pos", rowIndex);
      const aind = this.grid.getFieldValue("analog_index", rowIndex); */
      if (aind === false || aind < 0) return;
      //const analogs = this.grid.getFieldValue("analogs", rowIndex);

      const analogs = rd.analogs
      if (!analogs) return;
      let nind = 0;
      analogs.forEach((a, index) => {
        a.analog_index = index;
      });

      const analog = analogs[aind].nomens;
      const group = row.getGroup();
      let rows = group.getRows();
      if (analog.length > rows.length) {
        let insData;
        for (let i = rows.length; i < analog.length; i++) {
          insData = {};
          this.getInsertData(insData);
          insData.pos = pos;
          this.tabulator
            .addRow(insData)
            .then(function (row) {
              cmp.$emit("after_insert", row);
            })
            .catch((error) => {
              console.error("Ошибка добавления строки в таблицу", error);
            });
        }
      }

      nind = 0;
      rows = group.getRows();
      rows.forEach((row) => {
        const rd = row.getData();
        const emptyCNomen = !(rd.c_code || rd.c_mark || rd.c_nomen);
        if (emptyCNomen && nind > analog.length) {
          // Если номенклатура заказчика пустая и нашей то же нет, то удаляем строку
          row.delete();
          return;
        }

        if (nind < analog.length) {
          cmp.setUsNomen(row, analog, "", nind);
          nind++;
        }
      });
    },
    calcUsNomenQuant: function (cellInfo) {
      const self = this;
      const oldval = cellInfo.cell.getOldValue();
      const group = cellInfo.cell.getRow().getGroup();
      let row_data = cellInfo.cell.getRow().getData();
      if (!row_data.c_quant) {
        const row = group.getRows().find((r) => {
          return r.getData().c_quant;
        });
        if (!row) return;
        row_data = row.getData();
      }

      if (
        !row_data.analogs ||
        (!row_data.analogs.length && cellInfo.field != "c_quant")
      )
        return; // Выходим, если аналогов нет

      let factor = 1;
      if (row_data.analogs && row_data.analogs.length) {
        const c_idedizm = this.edIzmAccessor(row_data.c_idedizm);
        const d_idedizm = this.edIzmAccessor(row_data.d_idedizm);

        const analog = row_data.analogs[row_data.analog_index];
        // находим позицию в аналогах с заданной в строке номенклатурой и единицей измерения заказчика
        const anom = analog.nomens.find((nm) => {
          return (
            nm.c_idnomen == row_data.c_idnomen && nm.c_idedizm == c_idedizm
          );
        });

        // расчёт коэффициента пересчёта
        factor =
          !anom || anom.c_quant == 0 ? 0 : row_data.c_quant / anom.c_quant;
        // пересчитываем наши количества
        group.getRows().forEach((row) => {
          const rd = row.getData();
          const anom = analog.nomens.find((nm) => {
            return nm.idnomen == rd.d_idnomen;
          });
          if (anom) {
            rd.d_idedizm = anom.idedizm;
            rd.d_quant = anom.quant * factor;
            row.update(rd);
          }
        });
      } else if (oldval) {
        factor = row_data.c_quant / oldval;
        // пересчитываем наши количества
        group.getRows().forEach((row) => {
          const rd = row.getData();
          rd.d_quant = rd.d_quant * factor;
          row.update(rd);
        });
      }
    },
    setCustomerNomen: function (row, nomenList, search_text, searchAnalogueReq = true) {
      const grid = this.grid;
      //const rowIndex = grid.tabulator.getRowPosition(row);
      let nomen = false;
      if (!search_text) {
        nomen = nomenList[0];
      } else {
        nomen = nomenList.find((nm) => {
          return (
            nm.code.toLowerCase() == search_text.toLowerCase() ||
            nm.mark.toLowerCase() == search_text.toLowerCase() ||
            nm.name.toLowerCase() == search_text.toLowerCase()
          );
        });
      }

      if (!nomen) nomen = nomenList[0];

      const rdata = row.getData();
      rdata.c_nomen = nomen;
      rdata.c_found = nomenList;
      rdata.c_idnomen = nomen.idnomen;
      rdata.c_idedizm = rdata.c_idedizm || nomen.idedizm;
      rdata.c_code = !rdata.c_code ? nomen.code : rdata.c_code;
      rdata.c_mark = !rdata.c_mark ? nomen.mark : rdata.c_mark;
      rdata.c_name = !rdata.c_name ? nomen.name : rdata.c_name;
      rdata.c_price = rdata.c_price || nomen.price;
      rdata.c_idFirm =
        !nomen.idFirm || +nomen.idFirm < 1
          ? rdata.c_idFirm
          : this.firmMutator(+nomen.idFirm);

      if (nomen.idnomen > 0) {
        rdata.r_idnomen = nomen.idnomen;
        rdata.r_firmname = nomen.firmname;
        rdata.r_code = nomen.code;
        rdata.r_mark = nomen.mark;
        rdata.r_name = nomen.name;
      }

      // console.log('before row.update  c_code = ' + rdata.c_code)
      row.update(rdata).catch(() => {
        console.error("error row.update c_code = " + rdata.c_code);
      });

      if (nomen.isUs > 0) {
        this.setUsNomen(row, [nomen], search_text);
      } else if (this.idFirm < 1) {
        this.idFirm = nomen.idFirm;
      }

      if ("analogs" in nomen && nomen.analogs.length > 0) {
        grid.setFieldValue("analogs", nomen.analogs, rowIndex);
        grid.setFieldValue("analog_index", 0, rowIndex);
      } else {
        const c_idnomen = grid.getFieldValue("c_idnomen", rowIndex);
        const d_code = grid.getFieldValue("d_code", rowIndex);
        if (searchAnalogueReq && c_idnomen > 0 && !d_code) {
          this.searchAnalogue(nomen.idnomen, row);
        }
      }
      moneycalc({ row });
    },
    setUsNomen: function (row, nomenList, search_text, nomenIndex = 0) {
      if (nomenList.length == 0) return;

      const grid = this.$refs.dkcgrid;
      let rowIndex = false;
      if (typeof row == "object") {
        rowIndex = this.tabulator.getRowPosition(row);
      } else {
        rowIndex = row;
      }
      let nomen = false;
      if (!search_text) nomen = nomenList[nomenIndex];
      else
        nomen = nomenList.find((nm) => {
          return (
            nm.code.toLowerCase() == search_text.toLowerCase() ||
            nm.mark.toLowerCase() == search_text.toLowerCase() ||
            nm.name.toLowerCase() == search_text.toLowerCase()
          );
        });

      if (!nomen) return;

      try {
        const rd = row.getData();
        rd.d_nomen = nomen;
        rd.d_found = nomenList;
        rd.d_idnomen = nomen.idnomen;
        rd.d_idedizm = nomen.idedizm;
        rd.d_code = nomen.code;
        rd.d_name = nomen.name;

        if (nomen.price) rd.d_price = nomen.price;

        row
          .update(rd)
          .then(moneycalc({ row }))
          .catch((error) => {
            grid.setFieldValue("d_nomen", nomen, rowIndex);
            grid.setFieldValue("d_found", nomenList, rowIndex);
            grid.setFieldValue("d_idnomen", nomen.idnomen, rowIndex);
            grid.setFieldValue("d_code", nomen.code, rowIndex);
            grid.setFieldValue("d_name", nomen.name, rowIndex);
            if (nomen.price != undefined)
              grid.setFieldValue("d_price", nomen.price, rowIndex);
          });
      } catch (e) {
        grid.setFieldValue("d_nomen", nomen, rowIndex);
        grid.setFieldValue("d_found", nomenList, rowIndex);
        grid.setFieldValue("d_idnomen", nomen.idnomen, rowIndex);
        grid.setFieldValue("d_code", nomen.code, rowIndex);
        grid.setFieldValue("d_name", nomen.name, rowIndex);
        if (nomen.price != undefined)
          grid.setFieldValue("d_price", nomen.price, rowIndex);
      }
    },
    searchNomen: function (cellInfo, searchField) {
      if (!cellInfo.value) return;

      // получаем ссылку на строку
      const cmp = this;
      let row = false;
      if (!cellInfo.cell) {
        row = cellInfo.row;
      } else {
        row = cellInfo.cell.getRow();
      }

      // Получаем ссылку на номенклатуру
      const field = cellInfo.field;
      const pref = field.substr(0, 2);
      const nomen = this.$refs.dkcgrid.getFieldValue(
        pref + "nomen",
        this.tabulator.getRowPosition(row)      
      );
      // Если номенклатура определена и данные совпадают с набранным текстом, то искать ничего не надо
      if (nomen && nomen[searchField] == cellInfo.value) return;

      // на время поиска делаем шрифт строки курсивом
      const el = row.getElement();
      el.style.fontStyle = "italic";
      cmp.searcherCount++;
      // и делаем запрос на поиск
      axios({
        method: "post",
        url: this.baseUrl + "/nomen/find",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          "X-CSRF-Token": tkn_csfr,
        },
        data: qs.stringify({
          search_text: cellInfo.value,
          where: searchField,
          limit: 10,
        }),
      })
        .then((response) => {
          // возвращаем форматирование строки и присваиваем значение найденной номенклатуры
          el.style.fontStyle = "normal";
          cmp.searcherCount--;
          if (response.data.nomens.length == 0) {
            return;
          }
          if (pref == "c_") {
            cmp.setCustomerNomen(
              row,
              response.data.nomens,
              response.data.search_text
            );
          } else {
            cmp.setUsNomen(
              row,
              response.data.nomens,
              response.data.search_text
            );
          }

          // Возвращаем фокус в зад
          this.grid.setTableFocus();
        })
        .catch((error) => {
          el.style.fontStyle = "normal";
          cmp.searcherCount--;
        });
    },
    searchNomenByCode: function (cellInfo) {
      this.searchNomen(cellInfo, "code");
    },
    searchNomenByMark: function (cellInfo) {
      this.searchNomen(cellInfo, "mark");
    },
    searchNomenByName: function (cellInfo) {
      this.searchNomen(cellInfo, "name");
    },
    cellValueChanged: function (cellInfo) {
      switch (cellInfo.field) {
        case "pos":
          this.$refs.dkcgrid.tabulator.setGroupBy("pos");
          const c_idnomen = this.grid.getFieldValue(
            "c_idnomen",
            this.grid.actRow
          );
          if (c_idnomen > 0) {
            this.searchAnalogue(c_idnomen, this.grid.actRow);
          }
          break;
        case "c_code":
        case "d_code":
          this.searchNomenByCode(cellInfo);
          break;
        case "c_mark":
        case "d_mark":
          this.searchNomenByMark(cellInfo);
          break;
        case "c_name":
        case "d_name":
          this.searchNomenByName(cellInfo);
          break;
        case "analog_index":
          this.setAnalog(cellInfo);
          break;
        case "d_idnomen":
        case "c_idedizm":
        case "c_quant":
        case "d_idedizm":
        case "analog_index":
          this.calcUsNomenQuant(cellInfo);
      }
      moneycalc(cellInfo);
    },
    selectAnalogMenuClick: function (data, event) {
      const row = this.grid.currentRow;
      if (!row) return;
      const analogs = row.getData().analogs;
      if (!analogs || !analogs.length) return;
      if (analogs[0].nomens) {
        this.DCodeClick(event);
        return;
      }

      const nomens = [];
      analogs.forEach((anl) => {
        anl.itemscode
          .split("%")
          .filter(Boolean)
          .forEach((id) => {
            nomens.push(id);
          });
      });

      const cmp = this;
      const formData = new FormData();
      formData.append("nomens", JSON.stringify(nomens));
      const options = {
        method: "POST",
        url: this.baseUrl + "/nomen/load",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "application/json; charset=utf-8",
          "X-CSRF-Token": tkn_csfr,
        },
        data: formData,
      };
      axios(options).then((response) => {
        const self = cmp;
        const nomens = response.data;
        const analogs = row.getData().analogs;
        analogs.forEach((anl) => {
          anl.nomens = [];
          anl.itemscode
            .split("%")
            .filter(Boolean)
            .forEach((id) => {
              const nomen = nomens.find((nm) => {
                return nm.idnomen == id;
              });
              if (nomen) anl.nomens.push(nomen);
            });
        });
        row.update({ analogs: analogs });
        this.DCodeClick(event);
      });
    },
    DCodeClick: function (event) {
      event.preventDefault();
      //event.currentTarget.style.display = 'none'
      // Показываем диалог и создаем таблицу, если её ещё нет
      this.analogVisible = true;
      const analogs = this.$refs.analogs;
      let tbl = analogs.tabulator;
      if (tbl == null) {
        tbl = this.createAnalogTable();
      }

      // получаем с текущей группы список аналогов
      const innerID = event.target.dataset.rowInnerId;
      const row = innerID
        ? this.grid.getRowByInnerID(innerID)
        : this.grid.currentRow;
      let adata = false;
      let analogRowIndex = 0;
      const group = row.getGroup();
      group.getRows().some((row) => {
        const data = row.getData();
        if (data.analogs != undefined) {
          adata = data.analogs;
          analogRowIndex = this.tabulator.getRowPosition(row);
          return true;
        }
        return false;
      });

      if (!adata) {
        this.analogVisible = true;
        return;
      }

      // конвертируем список аналогов для таблицы
      const tbldata = [];
      adata.forEach((adt, index) => {
        const resdt = tbldata;
        const idpos = adt.idspecpos;
        const ai = adt.analog_index ? adt.analog_index + 1 : index + 1;
        const nomens = adt.nomens;
        nomens.forEach((nomen) => {
          const row = {
            idspecpos: idpos,
            analog_index: ai,
            idnomen: nomen.idnomen,
            code: nomen.code,
            name: nomen.name,
            idedizm: nomen.idedizm,
            quant: nomen.quant,
            rest: nomen.rest,
            price: nomen.price,
            sign: nomen.sign,
            signdesc: nomen.sign == "A" ? "утвержден" : "черновик",
          };
          resdt.push(row);
        });
      });

      // грузим данные в диалог
      this.bodyAnalogText =
        "Выберите вариант замены номенклатуры заказчика на номенклатуру ДКС:";
      this.buttons = ["ok", "cancel"];
      this.actions = {
        ok: () => {
          const analogs = this.$refs.analogs;
          const aind = analogs.getFieldValue("analog_index") - 1;
          this.grid.setFieldValue("analog_index", aind, analogRowIndex);
        },
      };

      setTimeout(() => {
        tbl.setData(tbldata);
        tbl.redraw(true);
      }, 50);
    },
    getNomenInfo: function (row, side) {
      const rd = row.getData();
      const nomen = {
        idnomen: rd[side + "_idnomen"],
        code: rd[side + "_code"],
        mark: side == "c" ? rd["c_mark"] : undefined,
        name: rd[side + "_name"],
        idFirm:
          side == "d"
            ? -1
            : rd["c_idFirm"] == undefined || !rd["c_idFirm"]
            ? this.idFirm
            : rd["c_idFirm"],
      };

      if (nomen.idFirm == undefined) nomen.idFirm = 0;

      if (typeof nomen.idFirm == "string") {
        nomen.idFirm = this.firmAccessor(nomen.idFirm);
      }

      return nomen;
    },
    URLButtonFocus: function (e) {
      e.stopPropagation();
    },
    URLBtnClick: function () {
      event.stopPropagation();
      event.preventDefault();
      const innerID = event.target.dataset.rowInnerId;
      const side = event.target.dataset.nomenSide;
      const row = this.grid.getRowByInnerID(innerID);
      if (!row) return;
      const nomen = this.getNomenInfo(row, side);

      axios({
        method: "post",
        url: this.baseUrl + "/nomen/get-url",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          "X-CSRF-Token": tkn_csfr,
        },
        data: qs.stringify(nomen),
      })
        .then((response) => {
          const url = response.data.url;
          if (!url || url == undefined) return;

          const win = window.open(url, "_blank");
          win.focus();
        })
        .catch((error) =>
          this.showErrorMessage(
            "Ошибка поиска номенклатуры в сети Интернет",
            error
          )
        );
    },
    URLMenuItemClick: function (data, event) {
      if (!data) return;

      axios({
        method: "post",
        url: this.baseUrl + "/nomen/get-url",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          "X-CSRF-Token": tkn_csfr,
        },
        data: qs.stringify(data),
      })
        .then((response) => {
          const url = response.data.url;
          if (!url) return;

          const win = window.open(url, "_blank");
          win.focus();
        })
        .catch((error) =>
          this.showErrorMessage(
            "Ошибка поиска номенклатуры в сети Интернет",
            error
          )
        );
    },
    openNomenRef: function (idnomen) {
      axios({
        method: "get",
        url: this.baseUrl + "/nomen/get",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          "X-CSRF-Token": tkn_csfr,
        },
        params: {
          idnomen: idnomen,
        },
        //data: qs.stringify({'idnomen': idnomen})
      })
        .then((response) => {
          const idgroup = response.data.idNomenGroup;
          const url = this.baseUrl + "/nomen/index?idNG=" + idgroup + "&idnomen=" + idnomen;

          const win = window.open(url, "_blank");
          win.focus();
        })
        .catch((error) =>
          this.showErrorMessage(
            "Ошибка поиска номенклатуры в сети Интернет",
            error
          )
        );
    },
    refMenuItemClick: function (data, event) {
      if (!data) return;

      if (data.idnomen) {
        this.openNomenRef(data.idnomen);
      } else {
        const url =
          this.baseUrl + "/nomen/index?search_text=" +
          encodeURI(data.code ? data.code : data.mark ? data.mark : data.name);
        const win = window.open(url, "_blank");
        win.focus();
      }
    },
    createAnalogTable: function () {
      const wrkopt = {
        columns: [
          { title: "idspecpos", field: "idspecpos", visible: false },
          { title: "Вариант", field: "analog_index", visible: true },
          {
            title: "Номенклатура ДКС",
            columns: [
              { title: "idnomen", field: "idnomen", visible: false },
              { title: "Код", field: "code", visible: true, width: 100 },
              {
                title: "Наименование",
                field: "name",
                visible: true,
                width: 250,
              },
              {
                title: "Остатки",
                field: "rest",
                visible: true,
                hozAlign: "right",
              },
              {
                title: "Цена",
                field: "price",
                visible: true,
                hozAlign: "right",
              },
            ],
          },
          { title: "Статус", field: "sign", visible: false },
          { title: "Статус", field: "signdesc", visible: true, width: 90 },
        ],
        data: [],
        layout: "fitDataFill",
        width: 800,
        selectable: true,
        selectableRangeMode: "click",
        groupBy: "analog_index",
        groupToggleElement: false,
        groupHeader: function (value, count, data, group) {
          if (count == 1) return count + " позиция";
          if (count > 1 && count < 5) return count + " позиции";

          return count + " позиций";
        },
        rowFormatter: this.analogRowFormatter,
      };

      const grid = this.$refs.analogs;
      grid.createGrid(wrkopt);
      return grid.tabulator;
    },
    analogRowFormatter: function (row) {
      const sign = row.getData().sign;
      const grid = this.$refs.analogs;
      const el = row.getElement();
      const isActRow = grid.tabulator.getRowPosition(row) == grid.actRow;
      if (sign == "D") {
        el.style.fontStyle = "italic";
        el.style.color = "gray";
        el.style.backgroundColor = "white";
      }

      if (isActRow) {
        el.style.backgroundColor = "lightblue";
        el.style.border = "1px dotted blue";
      }

      return sign == "D" || isActRow;
    },
    beforeAJAXSearch(term) {
      if (term in this.search_cache) {
        return this.search_cache[term];
      }
      return false;
    },
    afterAJAXSearch(term, searchResults) {
      this.search_cache[term] = searchResults;
    },
    specHeight: function () {
      const noteEl = document.getElementById("note");
      const h =
        document.documentElement.clientHeight -
        noteEl.offsetTop -
        noteEl.offsetHeight -
        8;
      return h;
    },
    createSpecTable: function () {
      const self = this;
      let edizmValidator = "in:";
      this.edizm.forEach(
        (item) => (edizmValidator = edizmValidator + item.toLowerCase() + "|")
      );
      edizmValidator = edizmValidator.slice(0, edizmValidator.length - 1);

      const grid = this.$refs.dkcgrid;
      /*  */
      grid.createGrid(
        {
          columns: [
            { title: "idspecpos", field: "idspecpos", visible: false },
            {
              field: "rowselector",
              formatter: "rowSelection",
              titleFormatter: "rowSelection",
              headerSort: false,
              hozAlign: "center",
              vertAlign: "middle",
              visible: false,
            },
            {
              title: "№ поз",
              columns: [
                {
                  title: "Группа",
                  field: "pos",
                  visible: this.pos_visible,
                  headerFilter: "input",
                  editor: "input",
                  validator: "integer",
                  width: 60,
                },
                {
                  title: "В спец.",
                  field: "specpos",
                  visible: true,
                  formatter: this.posFormatter,
                  headerFilter: "input",
                  editor: "input",
                  /*validator: 'integer',*/ width: 60,
                },
              ],
            },
            {
              title: "Заказчик",
              field: "customerblock",
              columns: [
                {
                  title: "c_idspecitem",
                  field: "c_idspecitem",
                  visible: false,
                },
                { title: "c_nomen", field: "c_nomen", visible: false },
                { title: "c_found", field: "c_found", visible: false },
                { title: "c_idnomen", field: "c_idnomen", visible: false },
                {
                  title: "Завод-изготовитель, вендор",
                  field: "c_idFirm",
                  visible: true,
                  width: 174,
                  variableHeight: true,
                  headerFilter: "input",
                  mutator: this.firmMutator,
                  accessor: this.firmAccessor,
                  editor: 'list',
                  editorParams: {
                    values: this.firmArray,
                    clearable: true,
                    autocomplete: true,
                    allowEmpty: true,
                    listOnEmpty: true,
                    freetext: true, 
                  },
                },
                {
                  title: "Код",
                  field: "c_code",
                  visible: true,
                  width: 230,
                  variableHeight: true,
                  headerFilter: "input",
                  mutator: this.keytextMutator,
                  editor: "autocompleteAJAX",
                  editorParams: {
                    allowEmpty: true,
                    showListOnEmpty: true,
                    freetext: true,
                    values: [],
                    AJAX: {
                      method: "post",
                      url: this.baseUrl + "/nomen/find",
                      headers: {
                        "content-type": "application/x-www-form-urlencoded",
                        "X-CSRF-Token": tkn_csfr,
                      },
                      data: {
                        where: "code",
                        limit: 30,
                      },
                      searchFunc: function (data) {
                        const matches = {};
                        data.nomens.forEach((nomen) => {
                          let title = "";
                          if (nomen.code)
                            title = nomen.code;
                          if (nomen.mark)
                            title = title + " / " + nomen.mark;
                          title = title + "  - " + nomen.name;

                          matches[nomen.code] = title;
                        });
                        return matches;
                      },
                    },
                  },
                  formatter: this.formatcell,
                },
                {
                  title: "Тип, марка",
                  field: "c_mark",
                  visible: true,
                  width: 90,
                  variableHeight: true,
                  headerFilter: "input",
                  mutator: this.keytextMutator,
                  editor: "autocompleteAJAX",
                  editorParams: {
                    allowEmpty: true,
                    showListOnEmpty: true,
                    freetext: true,
                    values: [],
                    AJAX: {
                      method: "post",
                      url: this.baseUrl + "/nomen/find",
                      headers: {
                        "content-type": "application/x-www-form-urlencoded",
                        "X-CSRF-Token": tkn_csfr,
                      },
                      data: {
                        where: "mark",
                        limit: 30,
                      },
                      searchFunc: function (data) {
                        const matches = {};
                        data.nomens.forEach((nomen) => {
                          let title = "";
                          if (nomen.mark != undefined && nomen.mark)
                            title = nomen.mark;
                          if (nomen.code != undefined && nomen.code)
                            title = nomen.mark + " / " + title;
                          title = title + "  - " + nomen.name;

                          matches[nomen.mark] = title;
                        });
                        return matches;
                      },
                    },
                  },
                  formatter: this.formatcell,
                },
                {
                  title: "Наименование",
                  field: "c_name",
                  visible: true,
                  width: 300,
                  variableHeight: true,
                  headerFilter: "input",
                  mutator: this.keytextMutator,
                  //formatter: "textarea",
                  editor: "input",
                  formatter: this.formatcell,
                },
                {
                  title: "Кол-во",
                  field: "c_quant",
                  visible: true,
                  hozAlign: "right",
                  mutator: this.numberMutator,
                  formatter: this.quantFormatter,
                  editor: "input",
                  validator: "numeric",
                  width: 80,
                },
                {
                  title: "Ед.Изм.",
                  field: "c_idedizm",
                  visible: true,
                  hozAlign: "center",
                  width: 87,
                  mutator: this.edIzmMutator,
                  accessor: this.edIzmAccessor,
                  validator: edizmValidator,
                  editor: "autocomplete",
                  editorParams: {
                    showListOnEmpty: true,
                    freetext: true,
                    allowEmpty: true,
                    values: this.edizm,
                  },
                },
                {
                  title: "Цена",
                  field: "c_price",
                  visible: false,
                  mutator: this.numberMutator,
                  editor: true,
                  validator: "numeric",
                  hozAlign: "right",
                  width: 80,
                  formatter: "money",
                  formatterParams: {
                    decimal: ",",
                    thousand: " ",
                  },
                },
                {
                  title: "Сумма",
                  field: "c_summa",
                  visible: false,
                  hozAlign: "right",
                  width: 110,
                  mutator: this.numberMutator,
                  topCalc: "sum",
                  topCalcFormatter: "money",
                  topCalcFormatterParams: {
                    decimal: ",",
                    thousand: " ",
                  },
                  bottomCalc: "sum",
                  bottomCalcFormatter: "money",
                  bottomCalcFormatterParams: {
                    decimal: ",",
                    thousand: " ",
                  },
                  formatter: "money",
                  formatterParams: {
                    decimal: ",",
                    thousand: " ",
                  },
                },
              ],
            },
            {
              title: "Справочник номенклатуры",
              field: "refnomen",
              visible: false,
              columns: [
                { title: "r_idnomen", field: "r_idnomen", visible: false },
                {
                  title: "Завод изготовитель",
                  field: "r_firmname",
                  visible: false,
                  width: 180,
                  formatter: this.formatcell,
                },
                {
                  title: "Код",
                  field: "r_code",
                  visible: false,
                  width: 180,
                  formatter: this.formatcell,
                },
                {
                  title: "Тип",
                  field: "r_mark",
                  visible: false,
                  width: 90,
                  formatter: this.formatcell,
                },
                {
                  title: "Наименование",
                  field: "r_name",
                  visible: false,
                  width: 300,
                  formatter: this.formatcell,
                },
              ],
            },
            {
              title: "Дополнительные колонки",
              field: "extblock",
              visible: false,
            },
            {
              title: "DKC",
              field: "dkcblock",
              columns: [
                {
                  title: "d_idspecitem",
                  field: "d_idspecitem",
                  visible: false,
                },
                { title: "d_nomen", field: "d_nomen", visible: false },
                { title: "d_found", field: "d_found", visible: false },
                { title: "analogs", field: "analogs", visible: false },
                {
                  title: "analog_index",
                  field: "analog_index",
                  visible: false,
                },
                { title: "d_idnomen", field: "d_idnomen", visible: false },
                {
                  title: "Код",
                  field: "d_code",
                  visible: true,
                  width: 180,
                  variableHeight: true,
                  headerFilter: "input",
                  mutator: this.keytextMutator,
                  formatter: this.formatcell,
                  editor: "autocompleteAJAX",
                  editorParams: {
                    allowEmpty: true,
                    showListOnEmpty: true,
                    freetext: true,
                    values: [],
                    AJAX: {
                      method: "post",
                      url: this.baseUrl + "/nomen/find",
                      headers: {
                        "content-type": "application/x-www-form-urlencoded",
                        "X-CSRF-Token": tkn_csfr,
                      },
                      data: {
                        where: "code",
                        limit: 30,
                      },
                      searchFunc: function (data) {
                        const matches = {};
                        data.nomens.forEach((nomen) => {
                          let title = "";
                          if (nomen.code != undefined && nomen.code)
                            title = nomen.code;
                          if (nomen.mark != undefined && nomen.mark)
                            title = title + " / " + nomen.mark;
                          title = title + "  - " + nomen.name;

                          matches[nomen.code] = title;
                        });
                        return matches;
                      },
                    },
                  },
                },
                {
                  title: "Наименование",
                  field: "d_name",
                  visible: true,
                  width: 300,
                  variableHeight: true,
                  headerFilter: "input",
                  mutator: this.keytextMutator,
                  formatter: this.formatcell,
                  editor: "autocompleteAJAX",
                  editorParams: {
                    allowEmpty: true,
                    showListOnEmpty: true,
                    freetext: true,
                    values: [],
                    AJAX: {
                      method: "post",
                      url: this.baseUrl + "/nomen/find",
                      headers: {
                        "content-type": "application/x-www-form-urlencoded",
                        "X-CSRF-Token": tkn_csfr,
                      },
                      data: {
                        where: "name",
                        limit: 30,
                      },
                      searchFunc: function (data) {
                        const matches = {};
                        data.nomens.forEach((nomen) => {
                          let title = "";
                          if (nomen.code != undefined && nomen.code)
                            title = nomen.code;

                          if (nomen.mark != undefined && nomen.mark) {
                            if (title) title = title + " / ";
                            title = title + nomen.mark;
                          }
                          title = title + "  - " + nomen.name;

                          matches[nomen.name] = title;
                        });
                        return matches;
                      },
                    },
                  },
                },
                {
                  title: "Кол-во",
                  field: "d_quant",
                  visible: true,
                  mutator: this.numberMutator,
                  editor: true,
                  hozAlign: "right",
                  validator: "numeric",
                  width: 80,
                },
                {
                  title: "Ед.Изм.",
                  field: "d_idedizm",
                  visible: true,
                  hozAlign: "center",
                  width: 87,
                  mutator: this.edIzmMutator,
                  accessor: this.edIzmAccessor,
                  validator: edizmValidator,
                  editor: "autocomplete",
                  editorParams: {
                    showListOnEmpty: true,
                    freetext: true,
                    values: this.edizm,
                  },
                },
                {
                  title: "Цена",
                  field: "d_price",
                  visible: true,
                  mutator: this.numberMutator,
                  editor: true,
                  validator: "numeric",
                  hozAlign: "right",
                  width: 80,
                  formatter: "money",
                  formatterParams: {
                    decimal: ",",
                    thousand: " ",
                  },
                },
                {
                  title: "Сумма",
                  field: "d_summa",
                  visible: true,
                  hozAlign: "right",
                  width: 110,
                  mutator: this.numberMutator,
                  topCalc: "sum",
                  topCalcFormatter: "money",
                  topCalcFormatterParams: {
                    decimal: ",",
                    thousand: " ",
                  },
                  bottomCalc: "sum",
                  bottomCalcFormatter: "money",
                  bottomCalcFormatterParams: {
                    decimal: ",",
                    thousand: " ",
                  },
                  formatter: "money",
                  formatterParams: {
                    decimal: ",",
                    thousand: " ",
                  },
                },
              ],
            },
            {
              title: "Статус",
              columns: [
                {
                  title: ":",
                  field: "sign",
                  visible: true,
                  hozAlign: "center",
                  formatter: this.signFormatter,
                  headerFilter: "input",
                  width: 90,
                  mutator: this.signMutator,
                  accessor: this.signAccessor,
                  validator: "in:D|A|E",
                  editor: "select",
                  editorParams: {
                    showListOnEmpty: true,
                    freetext: true,
                    values: {
                      D: "черновик",
                      A: "утверждено",
                      E: "исключить",
                    },
                  },
                  editable: () => {
                    return self.grant;
                  },
                },
              ],
            },
            {
              title: "Примечание",
              columns: [
                {
                  title: ":",
                  field: "note",
                  visible: true,
                  widthGrow: 4,
                  width: 300,
                  variableHeight: true,
                  formatter: "textarea",
                  headerFilter: "input",
                  editor: "textarea",
                },
              ],
            },
          ],
          data: [],
          groupBy: "pos",
          groupHeader: function (value, count, data, group) {
            const rows = Array.from(new Set(group.getRows()));
            if (rows.length == 1) {
              const el = group.getElement();
              if (el) {
                el.style.display = "none";
              }
              return "*";
            }

            // подчеркиваем группу
            const lastrow = rows[rows.length - 1];
            const el = lastrow.getElement();
            if (el) {
              el.style.borderBottomStyle = "ridge";
              el.style.borderBottomWidth = "3px";
              el.style.borderBottomColor = "#5d448c";
            }

            return "Сборная позиция  (" + count + " элем.)";
          },
          groupToggleElement: false,
          columnDefaults: {
            headerFilterPlaceholder: "фильтр...",
          },
          columnCalcs: "table",
          height: this.specHeight(),
          layout: "fitData",
          rowFormatter: this.rowFormatter,
          movableColumns: true,
          clipboard: true,
          clipboardPasteParser: this.specPasteParser,
          clipboardPasteAction: "update", // this.clipboardPasteAction,
        },
        {
          tableBuilt: function () {
            const elems = document.getElementsByClassName(
              "tabulator-col-title"
            );
            if (typeof elems != "object") return;

            for (let i = 0; i < elems.length; i++) {
              if (elems[i].innerText == ":") elems[i].style.opacity = 0;
            }
          },
          rowUpdated: function () {
            if (self.gridUpdated) return;
            self.setModified();
          },
          rowDeleted: function () {
            self.setModified();
          },
          cellContext: function (e, cell) {
            self.showCellContextMenu(e, cell);
          },
        }
      );
      setTimeout(this.subscribeResizeWindow, 1000);
    },
    subscribeResizeWindow: function () {
      const self = this;
      window.addEventListener("resize", (event) => {
        if (self.resizeTimer) {
          clearTimeout(self.resizeTimer);
        }

        self.resizeTimer = setTimeout(this.doResizeGrid, 500);
      });
    },
    doResizeGrid: function () {
      this.resizeTimer = 0;
      this.tabulator.setHeight(this.specHeight());
    },
    dataChanged: function (data) {
      if (this.gridUpdated) return;
      this.setModified();
      this.grid.currentRow.normalizeHeight();
    },
    rowAdded: function () {
      if (this.gridUpdated) return;
      this.setModified();
    },
    truncColumnDefinitions: function (colDefs) {
      const shortDefs = [];
      colDefs.forEach((colDef) => {
        const sd = {};
        if ("title" in colDef) {
          sd.title = colDef.title;
        }
        if ("field" in colDef) {
          sd.field = colDef.field;
        }
        if ("visible" in colDef) {
          sd.visible = colDef.visible;
        }
        if ("width" in colDef) {
          sd.width = colDef.width;
        }
        if ("widthGrow" in colDef) {
          sd.widthGrow = colDef.widthGrow;
        }
        if ("columns" in colDef) {
          sd.columns = this.truncColumnDefinitions(colDef.columns);
        }
        shortDefs.push(sd);
      });
      return shortDefs;
    },
    specImportFromExcel: function () {
      this.importVisible = true;
    },
    specExportToExcel: function () {
      this.progresstitle = "Выполняется экспорт спецификации в Excel";
      this.circleVisible = true;
      const self = this;
      const formData = this.getSpecFormData();
      const options = {
        method: "POST",
        url: this.baseUrl + "/spec/expxlsx",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "application/json; charset=utf-8",
          "X-CSRF-Token": tkn_csfr,
        },
        data: formData,
      };
      axios(options)
        .then((response) => {
          const w = window.open(this.baseUrl + "/download?fn=" + response.data, "_blank");
          self.circleVisible = false;
        })
        .catch((error) => {
          self.circleVisible = false;
          self.showErrorMessage("Ошибка печати спецификации", error);
        });
    },
    confirmSaveSpec: function () {
      const modified = window.dkcapp.$store.state.modified;
      if (modified) {
        return window.confirm(
          "У вас есть несохранённые изменения! Чтобы сохранить изменения, нажмите кнопку Отмена, а затем сохраните спецификацию."
        );
      }

      return true;
    },
    setStatusCurrentPos(data, event) {
      if (!data) return;

      this.grid.currentRow.update({ sign: data.code });
    },
    showCellContextMenu(event, cell) {
      event.stopPropagation();
      event.preventDefault();

      const menu = this.$refs.contextMenu;

      if (cell.getField().substr(1, 1) == "_") {
        const rd = cell.getRow().getData();
        const side = cell.getField().substr(0, 1).toLowerCase();
        const data = {};

        if (side == "c" || side == "d") {
          data.side = side;
          data.idnomen = rd[side + "_idnomen"];
          data.code = rd[side + "_code"];
          data.mark = side == "c" ? rd.c_mark : "";
          data.name = rd[side + "_name"];
          data.idFirm =
            side == "d" ? -1 : rd.c_idFirm ? rd.c_idFirm : this.idFirm;

          if (typeof data.idFirm == "string") {
            data.idFirm = this.firmAccessor(data.idFirm);
          }
        }

        const analogs = rd.analogs;

        menu.updateItem("analogs", false, !(!!analogs && analogs.length > 1));
        menu.updateItem(
          "findInInternet",
          data,
          !(data.code || data.mark || data.name)
        );
        menu.updateItem(
          "findInReference",
          data,
          !(data.idnomen || data.code || data.mark || data.name)
        );
      }

      menu.open(event);
    },
    hideContextMenu() {
      this.$refs.kiContext.hide();
    },
    updateDKCPrice() {
      const self = this;
      const formData = new FormData();
      const data = this.tabulator.getData();
      if (data.length == 0) return;
      const ida = [];
      data.forEach((row) => {
        if (row.d_idnomen) ida.push(row.d_idnomen);
      });

      const idnomens = [...new Set(ida)];
      const nomens = [];
      idnomens.forEach((id) => {
        nomens.push({ idnomen: id });
      });
      formData.append("nomens", JSON.stringify(nomens));
      const options = {
        method: "POST",
        url: this.baseurl + "/nomen/list",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "application/json; charset=utf-8",
          "X-CSRF-Token": tkn_csfr,
        },
        data: formData,
      };
      axios(options)
        .then((response) => {
          self.recalcDKCPrice(response.data);
        })
        .catch((error) => {
          self.showErrorMessage("Ошибка пересчёта цен ДКС", error);
        });
    },
    recalcDKCPrice(nomens) {
      const self = this;
      const rows = this.tabulator.getRows();
      rows.forEach((row) => {
        const rd = row.getData();
        if (!rd.d_idnomen) return;

        const nomen = nomens.find((nm) => {
          return nm.idnomen == rd.d_idnomen;
        });

        if (!nomen) return;

        if (nomen.price != rd.d_price)
          row.update({
            d_price: nomen.price,
            d_summa: rd.d_quant * nomen.price,
          });
      });
    },   
    itemscodeByRow(row) {
      const group = row.getGroup();
      if (!group) return "";
      const idl = [];
      group.getRows().forEach((r) => {
        idl.push(r.getData().d_idnomen);
      });
      if (!idl.length) return "";
      return "%" + idl.sort().join("%") + "%";
    },
    itemscodeByInnerID(innerID) {
      const row = this.grid.getRowByInnerID(innerID);
      if (!row) return "";

      return this.itemscodeByRow(row);
    },
    analogindexByRow(row) {
      const data = row.getData();
      if (!data.analogs || !data.analogs.length) return -1;
      const itmcode = this.itemscodeByRow(row);
      return data.analogs.findIndex((a) => {
        return a.itemscode == itmcode;
      });
    },
    analogindexByCell(cell) {
      if (!cell) return -1;
      return this.analogindexByRow(cell.getRow());
    },
    analogindexByInnerID(innerID) {
      const row = this.grid.getRowByInnerID(innerID);
      if (!row) return -1;

      return this.analogindexByRow(row);
    },
    changePosVisible() {
      this.pos_visible = !this.pos_visible;
    },
    showBckgroundMessage(message) {
      this.backgroundmsg = message;
      this.show_backgroundmsg = true;
    },
    hideBckgroundMessage() {
      this.backgroundmsg = "";
      this.show_backgroundmsg = false;
    },
    showNomenSelector() {
      if (this.grid.currentRow) {
        const data = this.grid.currentRow.getData();
        this.nomenSelectorSource = [
          {
            firmname: data.c_idFirm,
            code: data.c_code,
            mark: data.c_mark,
            name: data.c_name,
          },
          {
            firmname: data.r_idFirm,
            code: data.r_code,
            mark: data.r_mark,
            name: data.r_name,
          },
        ];
      }
      this.visibleNomenSelector = true;
    },
    hideNomenSelector() {
      this.visibleNomenSelector = false;
    },
    selectNomen(nomen) {
      if (!this.tabulator.getDataCount()) {
        this.specCreatePos();
      }

      if (!this.grid.currentRow) return;

      this.setCustomerNomen(this.grid.currentRow, [nomen], "", false);
    },
    changeClibboardMethod() {
      this.clipboardMethod = !this.clipboardMethod
    }

  },
  mounted() {
    const cmp = this;
    this.docdate = new Date().toISOString().split("T")[0];
    this.author = this.$store.state.user.id;
    this.authorfio = this.$store.state.user.fio;

    dkcapp.title = "Новая спецификация";
    const specid = getUrlVar("id");
    if (specid) {
      this.progresstitle = "Загрузка спецификации...";
      this.circleVisible = true;
    }

    const loader = new SpecLoaderAsync({
      spec: this,
    });
    this.beginUpdateGrid();

    loader
      .execute(specid)
      .then((response) => {
        cmp.setModified(false);
        this.circleVisible = false;
        cmp.endUpdateGrid();
      })
      .catch((error) => {
        this.circleVisible = false;
        cmp.endUpdateGrid();
        cmp.showErrorMessage("Ошибка загрузки спецификации", error);
      });

    window.onbeforeunload = () => {
      return this.confirmSaveSpec() ? null : true;
    };

    const imps = document.getElementsByTagName("input");
    for (let i = 0; i < imps.length; i++) {
      imps[i].addEventListener("keypress", () => {
        cmp.setModified();
      });
    }

    const sls = document.getElementsByTagName("select");
    for (let i = 0; i < sls.length; i++) {
      sls[i].addEventListener("change", () => {
        cmp.setModified();
      });
    }
  },
  beforeRouteLeave: function (to, from, next) {
    next(this.confirmSaveSpec());
  },
  beforeDestroy: function () {
    this.setModified(false);
  },
  destroyed: function () {
    window.onbeforeunload = undefined;
  },
};
</script>

<style scoped>
.control-docnum {
  width: 16ch;
}

.control-customer {
  width: 45ch;
}

.spec-filter {
  background-image: url("../images/filter.svg");
}

.spec-paste {
  background-image: url("../images/paste.svg");
}

.spec-paste-spec {
  background-image: url("../images/paste_spec.svg");
}

.inpradio {
  display: flex;
  align-items: center;
  margin-left: 15%;
}

.inpradio span {
  margin-left: 16px;
}

.toolbar-hint {
  margin-left: 32px;
  font-size: 14px;
  line-height: 1.42857143;
  color: navy;
}
</style>
<style>
.nomencell_container {
  display: flex;
  justify-content: space-around;
  flex-wrap: nowrap;
}

.analog-selector {
  display: block;
  position: absolute;
  right: 0px;
  top: 0px;
  width: 28px;
  height: 28px;
  z-index: 100;
  background-color: #ffffff;
  border: 1px solid #cc0000;
  border-radius: 2px;
  text-align: center;
  font-weight: bolder;
  color: #cc0000;
  padding-top: 3px;
}

.spec-web {
  flex: 0 0 10px;
  transition: all 500ms ease;
  opacity: 1;
}

.spec-web:hover {
  flex: 0 0 28px;
  background-image: url("../images/url.svg");
  background-size: 28px 28px;
  background-repeat: no-repeat;
  opacity: 1;
}

.spec-web:after {
  content: " ";
  width: 0px;
  height: 0px;
  margin-right: -4px;
  margin-top: -7px;
  float: right;
  border-top: 11px solid #cc0000;
  border-left: 11px solid transparent;
  opacity: 1;
}

.nomen-beacon {
  flex: 0 1 auto;
  white-space: pre-wrap;
  min-height: 28px;
}

.spec_toggle_block {
  display: flex;
  flex-direction: column;
}

.spec_toggle_label {
  margin: 8px 0 4px 0;
  text-align: center;
}

.spec_toggle_control {
  margin: 0 8px;
}

</style>
