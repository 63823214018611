var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible
    ? _c(
        "dkc-modal-dlg",
        {
          attrs: { buttons: _vm.btns, actions: _vm.acts },
          on: { close: _vm.click_close },
        },
        [
          _c("div", { attrs: { slot: "body" }, slot: "body" }, [
            _c("div", [
              _c("span", [
                _vm._v(
                  "Для продолжения поиска номенклатуры заказчика необходимо выбрать метод поиска:"
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", [
              _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.methodIndex,
                      expression: "methodIndex",
                    },
                  ],
                  attrs: { type: "radio", id: "all_rows", value: "0" },
                  domProps: { checked: _vm._q(_vm.methodIndex, "0") },
                  on: {
                    change: function ($event) {
                      _vm.methodIndex = "0"
                    },
                  },
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "all_rows" } }, [
                  _vm._v("поиск для всех строк спецификации"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.methodIndex,
                      expression: "methodIndex",
                    },
                  ],
                  attrs: { type: "radio", id: "empty_rows", value: "1" },
                  domProps: { checked: _vm._q(_vm.methodIndex, "1") },
                  on: {
                    change: function ($event) {
                      _vm.methodIndex = "1"
                    },
                  },
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "empty_rows" } }, [
                  _vm._v("поиск для строк без выбранной номенклатуры"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.methodIndex,
                      expression: "methodIndex",
                    },
                  ],
                  attrs: { type: "radio", id: "selected_rows", value: "2" },
                  domProps: { checked: _vm._q(_vm.methodIndex, "2") },
                  on: {
                    change: function ($event) {
                      _vm.methodIndex = "2"
                    },
                  },
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "selected_rows" } }, [
                  _vm._v("поиск для выбранных строк спецификации"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.methodIndex,
                      expression: "methodIndex",
                    },
                  ],
                  attrs: { type: "radio", id: "current_rows", value: "3" },
                  domProps: { checked: _vm._q(_vm.methodIndex, "3") },
                  on: {
                    change: function ($event) {
                      _vm.methodIndex = "3"
                    },
                  },
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "current_rows" } }, [
                  _vm._v("поиск для текущей строки спецификации"),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("span", { attrs: { slot: "header" }, slot: "header" }, [
            _vm._v("Выберите вариант поиска номенклатуры"),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }