var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("details", { attrs: { open: "" } }, [
    _c("summary", [_vm._v("Содержимое буфера обмена")]),
    _vm._v(" "),
    _c("div", { ref: "cbrddata", attrs: { id: "cbrddata" } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }