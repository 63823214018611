<template>
  <div>
      <splitpanes vertical>
          <div>
              <dkc-nomen-group ref="tree" @selectTreeNode="selectTreeNode"
                      >
              </dkc-nomen-group>
          </div>

          <div>
              <dkc-nomen-group-info
                      :ngInfo="ngInfo"
                      :isError="isError"
                      @select_group="selectGroup">
              </dkc-nomen-group-info>
          </div>

      </splitpanes>
  </div>
</template>

<script>
  import DkcNomenGroup from './components/jsTreePort'
  import DkcNomenGroupInfo from './components/dkcNomenGroupInfo'
  import Splitpanes from 'splitpanes'
  import 'splitpanes/dist/splitpanes.css'
  import '../css/splitstyle.css'

  export default {
    name: "NomenGroupTree",
    components: {
      DkcNomenGroup,
      DkcNomenGroupInfo,
        Splitpanes
    },
    data() {
      return {
        isError: false,
        selectedID: 0,
        ngInfo: {
          id: 0,
          idParent: 0,
          group_name: "",
          nomen_count: "",
          full_path: [],
        },
      }

    },
    methods: {
      ngInfoError: function() {
        this.isError = true ;
      },
      selectTreeNode: function(node) {
          const cmp = this ;
          const fd = new FormData()
          fd.append('id', node.id)
          this.queryAjax('/nomen-group/info', fd)
              .then(function (response) {
                  cmp.ngInfoSuccess(response.data) ;
              })
              .catch(function (response) {
                  cmp.ngInfoError() ;
              });
      },
      ngInfoSuccess: function(data) {
        this.isError = false ;
        this.ngInfo.id          = data.id   ;
        this.ngInfo.idParent    = data.idprnt ;
        this.ngInfo.group_name  = data.name ;
        this.ngInfo.full_path   = data.path ;
        this.ngInfo.nomen_count = data.nomenCount ;
        this.ngInfo.urls        = data.urls ;
      },
      selectGroup: function(idNG) {
        this.selectedID = idNG ;
        const tr = this.$refs.tree
        tr.selectedID = idNG ;
      }
    },
    mounted() {
        window.dkcapp.title = 'Справочник групп номенклатуры'
    }
  }
</script>

<style scoped>
  .ng-details {
    width: 100%;
    margin-top: 24px;
  }
</style>

<style>
  .splitpanes__pane {
    padding-top: 16px;
  }
  .navbar {
    margin-bottom: 0;
  }
</style>
