import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import router from './routes'
import store from './store'
import Vuelidate from 'vuelidate'
import VueCookie from 'vue-cookie'
import { base_url } from './pages/mixins/BaseUrlGetter' 
import { queryAJAX } from './pages/mixins/QueryAJAX'

Vue.use(VueRouter)
Vue.use(Vuelidate)
Vue.use(VueCookie)
Vue.mixin(base_url)
Vue.mixin(queryAJAX)

const app = new Vue({
              el: '#app',
              render: h => h(App),
              router,
              store,                        
            })
