import XLSX from "xlsx";

export default function RangeNomenGroupReader(data) {
  const {xls, colMap, foliage, branch} = data
  let _row = -1

  /**
   * Чтение данных из колонок с группами номенклатуры
   * @param row
   * @returns {Array}
   */
  function readGroups (row) {
    if (!colMap.firstGroup && !colMap.lastGroup)
      return []

    const firstCol = xls.decode_col(colMap.firstGroup ? colMap.firstGroup : colMap.lastGroup)
    const lastCol  = xls.decode_col(colMap.lastGroup ? colMap.lastGroup : colMap.firstGroup) + 1
    const cols = []
    for (let col = firstCol; col < lastCol; col++)
      cols.push(xls.value(col, row))

    return cols
  }

  /**
   * Возвращает группу номенклауры по прочинатым в Excel-данным
   * @param groupcols - значения ячеек с наименованием группы
   * @param row - строка, где пытаемся получить группу номенклатуры
   * @returns {*|NomenGroup}
   */
  function getGroup(groupcols, row) {
    if (groupcols.length > 0) {
      let group = false
      let index = -1
      for (let i = 0; i < groupcols.length; i++) {
        if (groupcols[i]) {
          index = branch[i].indexOf(groupcols[i])
          group = index < 0 ? branch[i].appendChild(groupcols[i], i) : branch[i].children[index]
          group.row = row

          // обрезаем ветку до родительской группы
          branch.splice(i + 1, branch.length - i, group)
        }
      }
    }
    return branch[branch.length - 1]
  }

  this.getRow = () => _row
  this.getGroup = () => branch[branch.length - 1]
  this.read = (row) => {
    _row = row
    return getGroup(readGroups(row), row)
  }
}

RangeNomenGroupReader.prototype = {
  get row() {
    return this.getRow()
  },
  get group() {
    return this.getGroup()
  },
}
