export const longtextFormatter = {
  methods: {
    formatcell(cell) {
      const cellval = cell.getValue() || "";
      if (!cellval) return "";

      const classes = ["cs_longtext"];
      const field = cell.getField();
      this.cellformatters
        .filter((cf) => {
          return cf.fields.some((fl) => {
            return fl == field;
          });
        })
        .forEach((cf) => {
          if (cf.handle(cell)) classes.push(cf.class);
        });

      return '<div class="' + classes.join(" ") + '">' + cellval + "</div>";
    },
  },
};
