<template>
  <div>
    <dkc-modal-dlg
      :buttons="buttons"
      :actions="actions"
      v-show="isModalDlgVisible"
      @close="closeModalDlg">
      <div slot="body" class="form-group col-3">
        <label class="control-label">Перед вставкой, программа может проанализировать данные в буфере обмена и осуществить вставку в наиболее подходящие колонки.<br>Выберите метод всавки и нажмите кнопку "Ок":</label>
        <div style="margin-left: 15%">
          <div style="display: flex; align-items: center;">
            <input type="radio" name="pmethod" value="spec" checked v-model="pmethod">
            <span  style="margin-left: 16px;">вставка с анализом данных</span>
          </div>
          <div style="display: flex; align-items: center;">
            <input type="radio" name="pmethod" value="standart" v-model="pmethod">
            <span style="margin-left: 16px;">cтандартная вставка</span>
          </div>
        </div>
      </div>

      <span slot="header">Вставка данных в таблицу из буфера обмена</span>
    </dkc-modal-dlg>
    <dkc-modal-dlg
      :buttons="confirmbtn"
      :actions="confirmactions"
      v-show="isConfirmVisible"
      @close="closeConfirmDlg"
    >
      <div slot="body">Количество колонок в буфере обмена превышает количество доступных колонок в таблице. Продолжить вставку с обрезкой данных?</div>
      <span slot="header">Вставка данных в таблицу из буфера обмена</span>
    </dkc-modal-dlg>

    <spec-clipbrd-tuner ref="cbrdtuner"></spec-clipbrd-tuner>
  </div>
</template>

<script>
    import dkcModalDlg from "./dkcModalDlg"
    import getNormEdIzm from '../units/edizmnorm'
    import SpecClipbrdTuner from './specClipbrdTuner'

    export default {
        name: "clipboardParser",
        components: {SpecClipbrdTuner, dkcModalDlg},
        data () {
            return {
                clpdata: false,
                grid: false,
                firmList: [],
                edizm: [],
                fieldDefs: [],
                pmethod: 'spec',

                isModalDlgVisible: false,
                modalResult: false,
                buttons: ["ok", "cancel"],
                actions: {
                    ok: this.execPasteSelectedMethod,
                    cancel:  this.closeModalDlg,
                },

                isConfirmVisible: false,
                confirmbtn: ['yes', 'cancel'],
                confirmactions: {
                    yes: this.standartPaste,
                    cancel: this.closeConfirmDlg,
                },
            }
        },
        methods: {
          execute: function (env) {
            this.clpdata = env.clpdata
            this.grid = env.grid
            this.firmList = env.firmList
            this.edizm = env.edizm
            this.fieldDefs = env.fieldDefs

            if (this.simpleData(this.clpdata))
              return this.clipboardPasteAction(this.getSimpleData(this.clpdata, this.grid))

            this.showModalDlg()
            return false
          },
            closeModalDlg () {
                this.isModalDlgVisible = false
            },
            showModalDlg() {
                this.isModalDlgVisible = true
            },
            simpleData(clpdata) {
                const rows = clpdata.split("\n").filter(item => {return item})
                const cols = rows[0].split("\t").filter(item => {return item})
                return cols.length < 2 && rows.length < 2
            },
            getSimpleData(clpdata, grid) {
                const result = {}
                result[grid.currentCell.getField()] = clpdata

                return [result]
            },
            execPasteSelectedMethod () {
                switch (this.pmethod) {
                    case 'spec': this.doSpecPaste()
                        break
                    case 'standart': this.doStandartPaste()
                        break
                }
            },
            doSpecPaste: function() {
                const pdarr = this.pasteDataToArray(this.clpdata)
                const colmap = this.buildColMap(pdarr)

                if (this.idFirm < 1 && colmap.firm > -1) {
                    this.setFirmByClipboard(pdarr, colmap.firm)
                }

                this.$refs.cbrdtuner.execute({
                  data: pdarr,
                  colmap,
                  edizm: this.edizm,
                  fieldDefs: this.fieldDefs,
                  pastehandler: this.execSpecPaste
                })
            },
            execSpecPaste: function(pdarr, colmap) {
              this.clipboardPasteAction(this.convertPasteData(pdarr, colmap))
            },
            standartPaste: function() {
                // получаем данные из буфера обмена в виде таблицы
                const pdarr  = this.pasteDataToArray(this.clpdata)
                //получаем список видимых полей
                const fields = []
                const cells = this.grid.currentRow.getCells()
                let column = false
                let coldef = false
                cells.forEach(cell => {
                    if (cell.getColumn().getDefinition().visible) {
                        fields.push(cell.getField())
                    }
                })

                let rowIndex = this.grid.actRow
                const trows = this.grid.tabulator.getRows()
                const data   = []

                pdarr.forEach(row => {
                    // получаем данные из таблицы, если вставка в её пределах
                    let rd = false
                    if (rowIndex < trows.length) {
                        rd = trows[rowIndex].getData()
                        rowIndex++
                    }
                    else {
                        rd = {
                            pos: 0, sign: 'D', note: '', idFirm: 0,
                            c_idnomen: '', c_code: '', c_name: '', c_idedizm: 0, c_quant: '', c_price: '',
                            d_idnomen: '', d_code: '', d_name: '', d_idedizm: 0, d_quant: '', d_price: ''
                        }
                    }

                    for (let i = this.grid.actCol; i < fields.length && (i - this.grid.actCol) < row.length; i++){
                        rd[fields[i]] = row[i - this.grid.actCol]
                    }

                    data.push(rd)
                })

                this.clipboardPasteAction(data)
            },
            doStandartPaste: function() {
                const dstlen = this.grid.colCount - this.grid.actCol
                const pdarr = this.pasteDataToArray(this.clpdata)
                if (pdarr[0].length < dstlen) {
                    this.standartPaste()
                    return
                }

                this.isConfirmVisible = true
            },
            pasteDataToArray: function(pdata) {
                const pdarr = []
                const pdrows = pdata.split('\n')
                pdrows.forEach((row, ind, arr) => {
                    if (row) {
                        if (row.charAt(row.length - 1) == '\r')
                            row = row.substring(0, row.length - 1)
                        pdarr.push(row.split('\t'))
                    }
                    else if (ind > 0 && (ind < arr.length - 1) && pdarr.length > 0) {
                        pdarr.push(new Array(pdarr[0].length))
                    }
                })
                return pdarr
            },
            isDigitCol: function(arr, col) {
                return arr.every(item => {
                    return item[col] ? !isNaN(+item[col].replace(',', '.')) : true
                })
            },
            isFirmCol: function(arr, col) {
                if (this.firmList.length == 0) {
                    return false
                }
                return arr.some(item => {
                    const firmName = item[col].toLowerCase()
                    if (firmName) {
                        return this.firmList.some(firm => {return firm.name.toLowerCase() == firmName})
                    }
                    return false
                })
            },
            isEdIzmCol: function(arr, col) {
                if (this.edizm.length == 0) {
                    return false
                }

                return arr.every(item => {
                    const edizm = getNormEdIzm(item[col])
                    return this.edizm.indexOf(edizm) >= 0
                })
            },
            getAverageLen: function(arr, col) {
                if (arr.length == 0) {
                    return -1
                }
                let sumlen = 0
                let itmcount = 0
                arr.forEach(item => {
                    if (item[col].length > 0) {
                      sumlen = sumlen + item[col].length
                      itmcount++
                    }
                })

                return itmcount == 0 ? -1 : sumlen / itmcount
            },
            buildColMap: function(data) {
                const colmap = {c_idFirm: -1,
                  c_code: -1, c_mark: -1, c_name: -1, c_idedizm: -1, c_quant: -1, c_price: -1, c_summa: -1,
                  d_code: -1, d_mark: -1, d_name: -1, d_idedizm: -1, d_quant: -1, d_price: -1, d_summa: -1,
                }
                let lenmap = new Array(data[0].length)

                for (let i = 0; i < data[0].length; i++) {
                    lenmap[i] = {i, avglen: 0}
                    if (this.isDigitCol(data, i)) {
                        if (colmap.c_quant < 0) {
                            colmap.c_quant = i
                        }
                        else if (colmap.c_price < 0) {
                            colmap.c_price = i
                        }
                        else if (colmap.c_summa < 0) {
                            colmap.c_summa = i
                        }
                        else if (colmap.d_quant < 0) {
                          colmap.d_quant = i
                        }
                        else if (colmap.d_price < 0) {
                          colmap.d_price = i
                        }
                        else if (colmap.d_summa < 0) {
                          colmap.d_summa = i
                        }
                    }
                    else if (this.isFirmCol(data, i)) {
                        colmap.c_idFirm = i
                    }
                    else if (this.isEdIzmCol(data, i)) {
                      if (colmap.c_idedizm < 0) {
                        colmap.c_idedizm = i
                      } else {
                        colmap.d_idedizm = i
                      }
                    }
                    else {
                        lenmap[i].avglen = this.getAverageLen(data, i)
                    }
                }

                lenmap = lenmap.filter(a => {return a.avglen > 0})
                lenmap.sort((a, b) => {
                        return a.avglen < b.avglen ? 1 : (a.avglen > b.avglen ? -1 : 0)
                    })

                colmap.c_name = lenmap.length > 0 ? lenmap[0].i : -1
                colmap.c_code = lenmap.length > 1 ? lenmap[1].i : -1
                colmap.c_mark = lenmap.length > 2 ? lenmap[2].i : -1

                return colmap ;
            },
            setFirmByClipboard: function(arr, col) {
                if (this.firmList.length == 0) {
                    return
                }
                const cmp = this
                return arr.some(item => {
                    const firmName = item[col].toLowerCase()
                    if (firmName) {
                        return this.firmList.some(firm => {
                            if (firm.name.toLowerCase() == firmName) {
                                cmp.idFirm = firm.idFirm
                                return true
                            }
                            return false ;
                        })
                    }
                    return
                })
            },
            convertPasteData: function(data, map) {
                if (data.length == 0) {
                    return false
                }
                const self = this
                const cd = []
                const keys = Object.keys(map)
                let pos = 1
                const gridrow = this.grid.currentRow
                const gridrowdata = gridrow.getData()
                pos = gridrowdata.pos - 1

                data.forEach(row => {
                    const rd = {pos: 0, sign: 'D', note: '', c_idFirm: 0,
                        c_idnomen: '', c_code: '', c_name: '', c_idedizm: 0, c_quant: '', c_price: '',
                        d_idnomen: '', d_code: '', d_name: '', d_idedizm: 0, d_quant: '', d_price: ''}

                    keys.forEach(key => {
                      if (map[key] < 0)
                        return

                      if (key.substr(2) == 'idedizm') {
                        rd[key] = self.$store.getters.basename(row[map[key]])
                      }
                      else if (key == 'pos' && map[key] > -1) {
                        pos = row[map[key]] || pos
                        rd[key] = pos
                      } else {
                        rd[key] = row[map[key]]
                      }
                    })

                    if (map['pos'] == undefined || map['pos'] < 0) {
                      if (rd.c_name || rd.c_code)
                                                pos++

                      rd.pos = pos
                    }

                    if (!rd.c_name && !rd.c_code && !rd.c_mark) {
                        rd.c_idedizm = ''
                        rd.c_quant = ''
                        rd.c_price = ''
                        rd.c_summa = ''
                    }
                    if (!rd.d_name && !rd.d_code) {
                      rd.d_idedizm = ''
                      rd.d_quant = ''
                      rd.d_price = ''
                      rd.d_summa = ''
                    }

                    cd.push(rd)
                })

                return cd
            },
            /**
             *  Вставка подготовленных данных в таблицу
             *  Восле вставки данных запускаем пакетный поиск номенклатуры
             */
            clipboardPasteAction: function(data) {
                if (!data.length)
                    return false

                const grid = this.grid
                const rows = grid.tabulator.getRows()
                const baseind = grid.actRow
                let cellInfo = false
                let row = false
                let rd = false
                for (let i = baseind; i < grid.rowCount && data.length > 0; i++) {
                    row = rows[i]
                    rd  = data.shift()
                    if (!rd.innerID)
                      rd.innerID = grid.sequenceID.nextval()
                    row.update(rd)
                }

                const beginIndex = this.grid.rowCount
                if (data.length > 0) {
                  data.forEach(rd => {
                    if (!rd.innerID)
                      rd.innerID = grid.sequenceID.nextval()
                  })

                  grid.tabulator.addRow(data)
                }

                if (this.$refs.cbrdtuner.searchNomenAfterImport)  {
                  this.$emit('datapasted')
                }
                return true
            },
            closeConfirmDlg() {
                this.isConfirmVisible = false
            },
        },
    }
</script>

<style scoped>

</style>
