export default function getNormEdIzm(ShortName) {
    if (!ShortName || typeof (ShortName) != 'string') {
        return ShortName
    }

    let edizm = ShortName.toLowerCase()
      .replace('.', '')
      .replace(' ', '')

/*    switch (edizm) {
        case 'штук':
        case 'штука':
        case 'штуки':
        case 'шт.':
        case 'in.':
        case 'in': edizm = 'шт'
            break
        case 'метров':
        case 'метра':
        case 'метр':
        case 'м.':
        case 'm.':
        case 'm': edizm = 'м'
            break ;
        case 'комплект':
        case 'компл':
        case 'комп':
        case 'компл.':
        case 'ком':
        case 'ком.':
        case 'к':
        case 'к.': edizm = 'компл.'
            break
        case 'упаковка':
        case 'упак':
        case 'упак.':
        case 'уп':
        case 'у.':
        case 'у': edizm = 'уп.'
            break
    }*/

    return edizm
}
