<template>
  <dkc-modal-dlg
    :buttons="buttons"
    :actions="actions"
    v-show="invalidList"
    @close="closeModalDlg"
  >
    <span slot="header"
      >Обнаружены фирмы производители отсутствующие в справочнике.<br />Исправте
      исходные данные и повторите операцию.</span
    >
    <span slot="body">
      <div v-if="invalidList">
        <div v-for="firm in errors" :key="firm">
          <firm-validator-item :firmname="firm" :firmlist="firmList" @change="checkChoises">
          </firm-validator-item>
        </div>
      </div>
    </span>
      <div slot="buttons">
        <button class="btn" id="btn_sign_firm" @click="querySignFirm()">          
          <i class="fa-solid fa-envelope-circle-check"></i>
          Запрос на утверждение
        </button>
        
        <button class="btn" id="btn_firm_update" @click="updateFirmList()">          
          <i class="fa-solid fa-arrows-rotate"></i>
          Обновить
        </button>
      </div>
  </dkc-modal-dlg>
</template>

<script>
import dkcModalDlg from './dkcModalDlg'
import FirmValidatorItem from './firmValidatorItem'
import qs from 'qs'

export default {
  name: "FirmValidator",
  components: { FirmValidatorItem, dkcModalDlg },
  data() {
    return {
      errors: [],
      firmList: [],
      invalidList: false,
      buttons: ["ok", "cancel"],
      actions: {
        ok: () => {
          this.replaceFirms();
          this.resolve({ excelData: this.excelData });          
        },
        cancel: this.cancelCheck,
      },
      spec: false,
      resolve: false,
      reject: false,
    };
  },
  computed: {
    dialog() {
      return this.$children[0];
    },
    items() {
      return this.dialog.$children
    },
  },
  methods: {
    querySignFirm() {
      const btn = document.getElementById('btn_sign_firm')
      btn.setAttribute('disabled', 'true')
      axios({
        method: "post",
        url: this.baseUrl + "/firm/query-sign",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          "X-CSRF-Token": tkn_csfr,
        },
        data: qs.stringify({
          firms: this.errors,
        }),
      })
      .then( response => { btn.removeAttribute('disabled') })
      .catch( error => { btn.removeAttribute('disabled') })
    },
    updateFirmList() {
      const btn = document.getElementById('btn_firm_update')
      btn.setAttribute('disabled', 'true')
      axios({
        method: "post",
        url: this.baseUrl + "/firm/select",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          "X-CSRF-Token": tkn_csfr,
        }
      })
      .then( response => {
        this.firmList = response.data.data
          .filter( firm => firm.sign != 0)
          .sort( (firm1, firm2) => firm1.name.toLocaleUpperCase().localeCompare(firm2.name.toLocaleUpperCase()))    
        btn.removeAttribute('disabled')  
      })
      .catch( error => { btn.removeAttribute('disabled') })
    },
    executeAsyc(environ) {
      this.excelData = environ.excelData;
      this.resolve = environ.resolve;
      this.reject = environ.reject;
      if (this.execute(environ.ref, environ.firms))
        return this.resolve({ excelData: this.excelData });
    },
    execute(ref, list) {
      const self = this;
      this.errors = [];
      const signref = ref.filter( item => item.sign != "0")
      this.firmList = signref;


      list.forEach((firm) => {
        const refitm = signref.find(item => firm.toLocaleUpperCase().localeCompare(item.name.toLocaleUpperCase()) == 0)

        if (!refitm) 
          self.errors.push(firm);
      });

      this.invalidList = self.errors.length > 0;
      return !this.invalidList;
    },
    closeModalDlg() {
      this.invalidList = false;
      this.cancelCheck();
    },
    cancelCheck() {
      return this.reject("Отмена пользователем");
    },
    replaceFirms() {
      const self = this;
      const dlg = this.$children[0];
      this.items.forEach((item) => {
        if (item.replacement < 1) return;

        this.excelData.forEach((row, index, array) => {
          if (row.c_idFirm == item.firmname) {
            array[index].c_idFirm = item.replacement;
          }
        });
      });
    },
    checkChoises () {
      const isBad = !this.items.length ? true : this.items.some( item => item.replacement < 0 )
      const dlg = this.dialog.$el 
      Array.from(dlg.getElementsByTagName('button'))
        .filter( btn => btn.classList.contains('btn-primary'))
        .forEach( btn => {
          if (isBad) {
            btn.setAttribute('disabled', 'true')
          } else {
            btn.removeAttribute('disabled')
          }
        })
    },
  },
  mounted() {
    this.checkChoises() 
  },
};
</script>

<style scoped>
</style>
