var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { width: "1000px" } },
    [
      _c(
        "dkc-modal-dlg",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.visibleDialog,
              expression: "visibleDialog",
            },
          ],
          attrs: { buttons: _vm.buttons, actions: _vm.actions },
          on: { close: _vm.closeModalDlg },
        },
        [
          _c("span", { attrs: { slot: "header" }, slot: "header" }, [
            _vm._v("Выбери номенклатуру"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "body" }, slot: "body" },
            [
              _vm.circleAnimationVisible
                ? _c(
                    "div",
                    [
                      _c("circle-animation", {
                        attrs: { title: _vm.progresstitle },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("nomen-selector-header", {
                attrs: {
                  firms: _vm.firms,
                  c_nomen: _vm.source[0],
                  r_nomen: _vm.source[1],
                  inDeepSearch: _vm.inDeepSearch,
                  deepSearchStep: _vm.deepSearchStep,
                  deepSearchMessage: _vm.deepSearchMessage,
                },
                on: {
                  dosearch: function ($event) {
                    return _vm.dosearch($event)
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "nomen_selector_table" },
                [
                  _c("nomen-selector-table", {
                    ref: "nst",
                    attrs: {
                      firms: _vm.firms,
                      visible: _vm.visible,
                      inSearch: _vm.inSearch,
                      inDeepSearch: _vm.inDeepSearch,
                      idFirm: _vm.idFirm,
                      nomenForSearch: _vm.nomenForSearch,
                    },
                    on: {
                      select: _vm.select,
                      nextdeepsearchstep: _vm.nextDeepSearchStep,
                      stopdeepsearch: _vm.stopDeepSearch,
                      stopsearch: _vm.stopSearch,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }