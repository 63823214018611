/* eslint-disable space-before-blocks */
export class AnalogsWriter {
  #data
  #tabulator
  #table
  #setAnalog
  #beginUpdateGrid
  #endUpdateGrid
  #saRows = []
  #cursor = 0

  constructor (config) {
    this.#tabulator = config.tabulator
    this.#setAnalog = config.setAnalog
    this.#beginUpdateGrid = config.beginUpdateGrid
    this.#endUpdateGrid = config.endUpdateGrid

    this.#data = config.searchResult
    this.#data.sort((a, b) => {
      if (parseFloat(a.pos) < parseFloat(b.pos)) return -1
      if (parseFloat(a.pos) > parseFloat(b.pos)) return 1
      return 0
    })

    this.#table = config.tabulator.getData()
    this.#table.sort((a, b) => {
      if (parseFloat(a.pos) < parseFloat(b.pos)) return -1
      if (parseFloat(a.pos) > parseFloat(b.pos)) return 1
      if (parseFloat(a.innerID) < parseFloat(b.innerID)) return -1
      if (parseFloat(a.innerID) > parseFloat(b.innerID)) return 1
      return 0
    })
  }

  write () {
    this.#data.forEach(item => {
      this.#writeItem(this.#table, item)
    })

    this.#updateTable(this.#table)
  }

  #getIndexFirstRow (table, item) {
    if (table[this.#cursor].pos != item.pos) {
      for (let i = this.#cursor; i < table.length; i++) {
        if (table[i].pos == item.pos) {
          return i
        }
      }
    }

    return this.#cursor
  }

  #writeItem (table, item) {
    // получаем индекс первой строки таблицы для заданнойт позиции item
    this.#cursor = this.#getIndexFirstRow(table, item)

    // перебираем таблицу от указанной строки и до конца
    for (let i = this.#cursor; i < table.length; i++) {
      // сохраняем текущий индекс в таблице
      this.#cursor = i
      // если строка таблицы принадлежит другой позиции, то пррываем запись
      if (table[i].pos != item.pos)
        break

      const analogs = item.analogs
      if (analogs.length == 0) {
        // если аналогов не найдено, то затираем нашу сторону спецификации
        table[i].d_idnomen = undefined
        table[i].d_code = undefined
        table[i].d_name = undefined
        table[i].d_idedizm = undefined
        table[i].d_quant = undefined
        table[i].d_price = undefined
        table[i].d_nomen = undefined
        table[i].d_found = undefined
      } else {
        // аналоги найдены. Сохраняем информацию об аналогах в первой строке позиции
        table[i].analog_index = 0
        table[i].analogs = analogs

        this.#saRows.push({
          field: 'analog_index',
          value: 0,
          innerID: parseInt(table[i].innerID)
        })

        // ищем индекс начала следующей группы
        for (let j = i + 1; j < table.length; j++) {
          this.#cursor = j
          if (table[j].pos != item.pos)
            break
        }

        // на первой итерации сохранили данные в первой строке, нашли начало следующей позиции и прерываем цикл
        break
      }
    }
  }

  #updateTable (table) {
    try {
      this.#beginUpdateGrid()
      this.#tabulator.replaceData(table)
        this.#saRows.forEach(row => {
          row.row = this.#tabulator.getRow(row.innerID)
          this.#setAnalog(row)
       })
   } finally {
     this.#endUpdateGrid()
   }
  }
}
