var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("splitpanes", { attrs: { vertical: "" } }, [
        _c(
          "div",
          { attrs: { "splitpanes-min": "18", "splitpanes-default": "28" } },
          [
            _c("dkc-nomen-group", {
              ref: "groups",
              on: { selectTreeNode: _vm.selectTreeNode },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { attrs: { "splitpanes-default": "70" } },
          [_c("nomen-table", { on: { setNomenGroup: _vm.setNomenGroup } })],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }