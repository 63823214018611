export function NomenResolver (options) {
  const {
    tabulator: _tabulator,
    nomens: _nomens,
    idFirm: _idFirm,
    firmMutator: _firmMutator,
    hideCircle: _hideCircle,
    showMessage: _showMessage,
    showError: _showError
  } = options

  const bdata = _tabulator.getData()
  let stopped = false
  const nomencount = _nomens.length
  const portionLen = Math.min(nomencount, 10)
  const startOfMessage = 'Поиск номенклатуры в справочнике'

  function hideCircle () {
    if (_hideCircle) { _hideCircle() }
  }

  function showMessage (message) {
    if (_showMessage) {
      _showMessage(message)
    }
  }

  function showErrors (error) {
    if (_showError) { _showError('Ошибка сохранения спецификации', error) }
  }

  function writeNomenToData (row, nomen, side) {
    row[side + '_nomen'] = nomen
    row[side + '_found'] = []
    row[side + '_idnomen'] = nomen.idnomen
    row[side + '_idedizm'] = row[side + '_idedizm'] || nomen.idedizm
    if (side == 'd') {
      row[side + '_code'] = row[side + '_code'] == undefined ? nomen.code : row[side + '_code']
      row[side + '_mark'] = row[side + '_mark'] == undefined ? nomen.mark : row[side + '_mark']
      row[side + '_name'] = row[side + '_name'] == undefined ? nomen.name : row[side + '_name']
      row[side + '_price'] = row[side + '_price'] || nomen.price
    }
    if (side == 'c') {
      // row.c_idFirm = row.c_idFirm || !nomen.idFirm || +nomen.idFirm < 1 ? row.c_idFirm : _firmMutator(+nomen.idFirm)
      if (nomen.idnomen > 0) {
        row.r_idnomen = nomen.idnomen
        row.r_firmname = nomen.idFirm ? _firmMutator(+nomen.idFirm) : ''
        row.r_code = nomen.code
        row.r_mark = nomen.mark
        row.r_name = nomen.name
      }
    }

    if ('analogs' in nomen && nomen.analogs.length > 0) {
      row.analogs = nomen.analogs
      row.analog_index = 0
    }
  }

  function writeResponseData (data) {
    const rows = bdata // _tabulator.getData()
    rows.forEach((row, index, array) => {
      const innerID = row.innerID
      const nomens = data.filter(nm => {
        return nm.ids.some(id => {
          return id == innerID
        })
      })

      nomens.forEach(nomen => {
        writeNomenToData(row, nomen, nomen.left ? 'c' : 'd')
      })
    })

    //_tabulator.setData(rows)
  }

  function portitionCount () {
    if (_nomens.length < 31) return 1
    if (_nomens.length < 51) return 2
    if (_nomens.length < 101) return 4
    if (_nomens.length < 1001) return 20
    return 200
  }

  function getPortion () {
    return _nomens.splice(0, portionLen)
  }

  function doResolve (resolve, reject) {
    self = this
    const portion = getPortion()
    if (!portion.length) {
      // Номенклатура кончилась, завершаем (удачно) процесс
      _tabulator.setData(bdata)
      resolve()
      return
    }

    if (stopped) {
      return
    }

    const formData = new FormData()
    formData.append('nomens', JSON.stringify(portion))
    formData.append('idFirm', _idFirm)
    const options = {
      method: 'POST',
      url: burl + '/nomen/batch-resolve',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json; charset=utf-8',
        'X-CSRF-Token': tkn_csfr
      },
      data: formData,
    }
    axios(options)
      .then(response => {
        const message = startOfMessage + ': ' + Math.round(100 * (nomencount - _nomens.length) / nomencount, 0) + '%'
        showMessage(message)
        if (response.data.rest.length) {

          response.data.rest.forEach( nm => { _nomens.push(nm) })

        }
        doResolve(resolve, reject)          // запускаем следующую итерацию поиска номенклатуры
        writeResponseData(response.data.nomens)    // записываем текущего поиска результаты в таблицу
      })
      .catch(error => {
        stopped = true
        reject(error)
      })
  }

  this.execute = function () {
    showMessage(startOfMessage)
    //self = this;
    return new Promise(function (resolve, reject) {
      doResolve(resolve, reject)
    })
  }
}

