var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "col-lg-8 col-lg-offset-2" }, [
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.onSubmit.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("label", { staticClass: "control-label" }, [
              _vm._v("Группа номенклатуры"),
            ]),
            _vm._v(" "),
            _c("dkc-ng-navy", {
              attrs: { idgroup: _vm.idgroup },
              on: { select_group: _vm.selectGroup },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "control-group" }, [
          _c("div", { staticClass: "form-group field-nomen-code" }, [
            _c(
              "label",
              { staticClass: "control-label", attrs: { for: "code" } },
              [_vm._v("Код")]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.code,
                  expression: "code",
                },
              ],
              staticClass: "form-control",
              attrs: { type: "text", name: "code" },
              domProps: { value: _vm.code },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.code = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "help-block" }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group field-nomen-code" }, [
            _c(
              "label",
              { staticClass: "control-label", attrs: { for: "mark" } },
              [_vm._v("Тип/марка")]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.mark,
                  expression: "mark",
                },
              ],
              staticClass: "form-control",
              attrs: { type: "text", name: "mark" },
              domProps: { value: _vm.mark },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.mark = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "help-block" }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "form-group field-nomen-name",
              class: {
                "has-error": _vm.$v.name.$error,
                "has-success": _vm.$v.name.$dirty && _vm.$v.name.required,
              },
            },
            [
              _c(
                "label",
                { staticClass: "control-label", attrs: { for: "name" } },
                [_vm._v("Наименование")]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.name,
                    expression: "name",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text", name: "name" },
                domProps: { value: _vm.name },
                on: {
                  blur: function ($event) {
                    return _vm.$v.name.$touch()
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.name = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _vm.$v.name.$error
                ? _c("div", { staticClass: "help-block" }, [
                    _vm._v("Наименование номенклатуры должно быть заполнено"),
                  ])
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "link-container" }, [
            _c("img", {
              staticClass: "link",
              attrs: {
                src: require("../images/url.svg"),
                width: "32px",
                height: "32px",
              },
              on: { click: _vm.URLBtnClick },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "control-group" }, [
          _c("div", { staticClass: "form-group" }, [
            _c(
              "label",
              { staticClass: "control-label", attrs: { for: "idFirm" } },
              [_vm._v("Изготовитель")]
            ),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.idFirm,
                    expression: "idFirm",
                  },
                ],
                staticClass: "form-control",
                attrs: { name: "idFirm", id: "idFirm" },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.idFirm = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              _vm._l(_vm.firmList, function (firm) {
                return _c("option", {
                  attrs: { label: firm.name },
                  domProps: { value: firm.idFirm },
                })
              }),
              0
            ),
            _vm._v(" "),
            _c("div", { staticClass: "help-block" }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _c(
              "label",
              { staticClass: "control-label", attrs: { for: "idedizm" } },
              [_vm._v("Базовая eдиница измерения")]
            ),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.idedizm,
                    expression: "idedizm",
                  },
                ],
                staticClass: "form-control",
                attrs: { name: "idedizm", id: "idedizm" },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.idedizm = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              _vm._l(_vm.edizmList, function (edizm) {
                return _c("option", {
                  attrs: { label: edizm.shortname },
                  domProps: { value: edizm.idedizm },
                })
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _c(
              "label",
              { staticClass: "control-label", attrs: { for: "isActive" } },
              [_vm._v("Статус")]
            ),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.isActive,
                    expression: "isActive",
                  },
                ],
                staticClass: "form-control",
                attrs: { name: "isActive", id: "isActive" },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.isActive = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              [
                _c("option", { attrs: { value: "0" } }, [
                  _vm._v("аннулированная номенклатура"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "1" } }, [
                  _vm._v("действующая номенклатура"),
                ]),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "control-group" }, [
          _c("div", { staticClass: "control-group" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                { staticClass: "control-label", attrs: { for: "price" } },
                [_vm._v("цена")]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.price,
                    expression: "price",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text", id: "price" },
                domProps: { value: _vm.price },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.price = $event.target.value
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                { staticClass: "control-label", attrs: { for: "priceDate" } },
                [_vm._v("на дату")]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.priceDate,
                    expression: "priceDate",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text", id: "priceDate", readonly: "" },
                domProps: { value: _vm.priceDate },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.priceDate = $event.target.value
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "control-group" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                { staticClass: "control-label", attrs: { for: "rest" } },
                [_vm._v("количество")]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.rest,
                    expression: "rest",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text", id: "rest" },
                domProps: { value: _vm.rest },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.rest = $event.target.value
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                { staticClass: "control-label", attrs: { for: "restDate" } },
                [_vm._v("на дату")]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.restDate,
                    expression: "restDate",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text", id: "restDate", readonly: "" },
                domProps: { value: _vm.restDate },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.restDate = $event.target.value
                  },
                },
              }),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _vm.sverror
          ? _c("div", { staticClass: "form-group has-error" }, [
              _c("div", { staticClass: "control-label error-message" }, [
                _vm._v(_vm._s(_vm.sverrormsg)),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "form-group button-group" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-success",
              attrs: { type: "submit", disabled: _vm.$v.$invalid },
            },
            [_vm._v("Сохранить")]
          ),
          _vm._v(" "),
          _c(
            "a",
            { staticClass: "btn btn-danger", attrs: { href: _vm.backUrl } },
            [_vm._v("Отмена")]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }