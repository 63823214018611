<template>
  <div>
    <bpk-toolbar-panel>
      <dkc-tool-button
        title="Новый пользователь"
        hint="Регистрация нового пользователя"
        iconclass="dbtn-create"
        action="/user/create"
      />
      <dkc-tool-button
        title="Профиль пользователя"
        hint="Изменить данные пользователя"
        iconclass="dbtn-edit"
        :action="editUser"
      />
      <dkc-tool-button
        title="Удалить пользователя"
        hint="Удалить пользователя"
        iconclass="dbtn-delete"
        :action="queryDeleteUser"
      />

      <dkc-tool-button
        title="Обновить данные"
        hint="Обновить данные в списке пользователей"
        iconclass="dbtn_refresh"
        :begingroup="true"
        :action="refreshGrid"
        control="tabulator-tableHolder"
      />
    </bpk-toolbar-panel>

    <div ref="table" />

    <dkc-model-dlg
      :buttons="buttons"
      :actions="actions"
      v-show="isModalDlgVisible"
      @close="closeModalDlg"
    >
      <span slot="body">{{ bodyText }}</span>
      <span slot="header">{{ headerText }}</span>
    </dkc-model-dlg>
  </div>
</template>

<script>
import dkcModelDlg from "./components/dkcModalDlg";
import dkcToolButton from "./components/dkcToolButton";
import bpkToolbarPanel from "./components/bpkToolbarPanel";
import { TabulatorFull as Tabulator } from "tabulator-tables";

export default {
  data() {
    return {
      tabulator: null,
      isModalDlgVisible: false,
      headerText: "Подтверждение удаления",
      bodyText: "?!",
      buttons: ["yes", "no", "ok", "close"],
      actions: {
        yes: () => {
          alert("!");
        },
      },
    };
  },
  watch: {
    tableData: {
      handler: (newData) => this.tabulator.replaceData(newData),
      deep: true,
    },
  },
  computed: {
    selectURL: function () {
      return this.baseUrl + "/user/select";
    },
  },
  methods: {
    refreshGrid: function () {
      this.tabulator.setData();
    },
    editUser: function () {
      const idl = this.tabulator.getSelectedData();
      if (idl.length > 0) {
        const id = idl[0].id;
        window.location = this.baseUrl + "/user/update?id=" + id;
      }
    },
    queryDeleteUser: function () {
      const idl = this.tabulator.getSelectedData();
      switch (idl.length) {
        case 0:
          this.bodyText = "Выберите пользователя, которого хотите удалить";
          this.buttons = ["ok"];
          break;
        case 1:
          this.bodyText =
            'Вы действительно хотите удалить пользователя "' +
            idl[0].username +
            '"?';
          this.buttons = ["yes", "no"];
          break;
        default:
          this.bodyText =
            "Вы действительно хотите удалить " + idl.length + "пользователей?";
          this.buttons = ["yes", "no"];
      }

      this.actions = { yes: this.deleteUser };
      this.showModalDlg();
    },
    deleteUser: function () {
      const idarr = this.tabulator.getSelectedData().map((row) => row["id"]);
      if (!idarr.length) {
        return;
      }

      const tbl = this.tabulator;
      const fd = new FormData();
      fd.append("id", JSON.stringify(idarr));
      this.queryAjax("/user/delete", fd)
        .then(function (response) {
          tbl.clearData();
          tbl.replaceData();
        })
        .catch(function (error) {
          console.log("Ошибка удаления пользователя:", error.message);
        });
    },
    showModalDlg() {
      this.isModalDlgVisible = true;
    },
    closeModalDlg() {
      this.isModalDlgVisible = false;
    },
    roleMutator(value) {
      if (value) {
        return window.dkcapp.$store.state.roles[value - 1].name;
      } else {
        return "";
      }
    },
  },
  mounted() {
    const self = this;

    this.tabulator = new Tabulator(this.$refs.table, {
      //data: this.tableData,
      columns: [
        { title: "ID", field: "id", visible: false },
        { title: "Имя пользователя", field: "username", widthGrow: 1 },
        { title: "ФИО пользователя", field: "fio", widthGrow: 3 },
        { title: "Телефон", field: "phone", widthGrow: 2 },
        {
          title: "Адрес e-mail",
          field: "email",
          widthGrow: 1,
          formatter: "link",
          formatterParams: {
            labelField: "email",
            urlPrefix: "mailto:",
            target: "_blank",
          },
        },
        {
          title: "Роль",
          field: "idRole",
          visible: true,
          mutator: this.roleMutator,
        },
        { title: "Статус", field: "status", visible: false },
      ],
      layout: "fitColumns",
      placeholder: "Нет данных",
      selectable: true,
      locale: "ru-RU",
      langs: {
        "ru-RU": {
          data: {
            loading: "загрузка",
            error: "ошибочка вышла",
          },
          pagination: {
            first: String.fromCharCode(57449),
            first_title: "первая страница",
            prev: String.fromCharCode(57457),
            prev_title: "предыдущая страница",
            next: String.fromCharCode(57461),
            next_title: "следущая страница",
            last: String.fromCharCode(57463),
            last_title: "последняя страница",
          },
        },
      },
      ajaxURL: this.selectURL, // "http://dkc-sa/deva/user-select",
      ajaxContentType: "json",
      ajaxResponse: (url, params, response) => {
        return response.data;
      },
      rowFormatter: function (row) {
        const data = row.getData();
        const role = window.dkcapp.$store.state.roles.find((role) => {
          return role.name == data.idRole;
        });

        const el = row.getElement();
        if (data.status == 0) {
          el.style.color = "gray";
          el.style.fontStyle = "italic";
          el.style.setProperty("text-decoration", "line-through");
        } else {
          switch (+role.idRole) {
            case 1:
              el.style.color = "maroon";
              el.style.fontWeight = "bolder";
              el.style.textShadow = "0px 0px 16px yellow";
              break;
            case 2:
              el.style.fontWeight = "bolder";
              el.style.color = "navy";
              break;
            case 3:
              el.style.fontWeight = "bold";
              break;
          }
        }
      },
    });

    this.tabulator.on("rowDblClick", function (e, row) {
      console.log("Даблкликнули");
      const data = row.getData();
      const id = data["id"];

      if (id > 0) {
        window.location = self.baseUrl + "/user/update?id=" + id;
      }
    });
  },
  components: {
    dkcToolButton,
    dkcModelDlg,
    bpkToolbarPanel,
  },
};
</script>

<style scoped>
</style>
