var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "col-lg-6 col-lg-offset-3" }, [
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.onSubmit.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "div",
          {
            staticClass: "form-group required",
            class: {
              "has-error": _vm.$v.name.$error,
              "has-success": _vm.$v.name.$dirty && _vm.$v.name.required,
            },
          },
          [
            _c(
              "label",
              { staticClass: "control-label", attrs: { for: "name" } },
              [_vm._v("Наименование")]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.name,
                  expression: "name",
                },
              ],
              staticClass: "form-control",
              attrs: { type: "text", id: "name" },
              domProps: { value: _vm.name },
              on: {
                blur: function ($event) {
                  return _vm.$v.name.$touch()
                },
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.name = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _vm.$v.name.$error
              ? _c("div", { staticClass: "help-block" }, [
                  _vm._v("Наименование группы должно быть заполнено!"),
                ])
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c(
            "label",
            {
              staticClass: "control-label",
              on: {
                dblclick: function ($event) {
                  _vm.idprnt = 0
                },
              },
            },
            [_vm._v("Родительская группа")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "path-control-group" },
            [
              _c("dkc-ng-namy", {
                attrs: { idgroup: _vm.idprnt },
                on: { select_group: _vm.selectGroup },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("label", { staticClass: "control-label" }, [
              _vm._v("Ссылки на сторонние ресурсы"),
            ]),
            _vm._v(" "),
            _c("dkc-nomen-group-url", {
              ref: "urls",
              attrs: { idng: _vm.rid, offline: "true" },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _vm.sverror
          ? _c("div", { staticClass: "form-group has-error" }, [
              _c("div", { staticClass: "help-block" }, [
                _vm._v(
                  "Ошибка сохранения группы номенклатуры: " +
                    _vm._s(_vm.sverrormsg)
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "form-group button-group" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-success",
              attrs: { type: "submit", disabled: _vm.$v.$invalid },
            },
            [_vm._v("\n                Сохранить\n            ")]
          ),
          _vm._v(" "),
          _c(
            "a",
            { staticClass: "btn btn-danger", attrs: { href: _vm.backUrl } },
            [_vm._v("Отмена")]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }