export default function RowFormatter(formatParam) {
  formatParam = formatParam || {}
  // const _selectRow = !!formatParam.selectRow
  const _cellStyle = {backgroundColor: 'transparent'}
  const _focusedCellStyle = formatParam.focusedCellStyle || {backgroundColor: 'LightBlue'}
  const _focusedRowStyle = formatParam.focusedRowStyle ||
    {
      backgroundColor: 'lemonchiffon',
      borderTopWidth: '1px',
      borderTopColor: 'darkblue',
      borderTopStyle: 'dotted',
      borderBottomWidth: '1px',
      borderBottomColor: 'darkblue',
      borderBottomStyle: 'dotted',
    }
  const _selectedRowStyle = formatParam.focusedRowStyle ||
    {
      backgroundColor: '#14aeff',
      borderTopWidth: '1px',
      borderTopColor: '#333',
      borderTopStyle: 'solid',
      borderBottomWidth: '1px',
      borderBottomColor: '#333',
      borderBottomStyle: 'solid',
    }
  const _groupStyle = formatParam.groupStyle ||
    {
      backgroundColor: '#fdfdfd',
      borderTopWidth: '2px',
      borderTopColor: '#333',
      borderTopStyle: 'solid',
      borderInnerWidth: '0px',
      borderInnerColor: 'gray',
      borderInnerStyle: 'dotted',
      borderBottomWidth: '2px',
      borderBottomColor: '#333',
      borderBottomStyle: 'solid',
      borderSingleWidth: '1px',
      borderSingleColor: 'gray',
      borderSingleStyle: 'solid',
    }
  const _firstRowInGroupStyle = {
    backgroundColor: _groupStyle.backgroundColor,
    borderTopWidth: _groupStyle.borderTopWidth,
    borderTopColor: _groupStyle.borderTopColor,
    borderTopStyle: _groupStyle.borderTopStyle,
    borderBottomWidth: _groupStyle.borderInnerWidth,
    borderBottomColor: _groupStyle.borderInnerColor,
    borderBottomStyle: _groupStyle.borderInnerStyle,
  }
  const _lastRowInGroupStyle = {
    backgroundColor: _groupStyle.backgroundColor,
    borderTopWidth: _groupStyle.borderInnerWidth,
    borderTopColor: _groupStyle.borderInnerColor,
    borderTopStyle: _groupStyle.borderInnerStyle,
    borderBottomWidth: _groupStyle.borderBottomWidth,
    borderBottomColor: _groupStyle.borderBottomColor,
    borderBottomStyle: _groupStyle.borderBottomStyle,
  }
  const _innerRowInGroupStyle = {
    backgroundColor: _groupStyle.backgroundColor,
    borderTopWidth: _groupStyle.borderInnerWidth,
    borderTopColor: _groupStyle.borderInnerColor,
    borderTopStyle: _groupStyle.borderInnerStyle,
    borderBottomWidth: _groupStyle.borderInnerWidth,
    borderBottomColor: _groupStyle.borderInnerColor,
    borderBottomStyle: _groupStyle.borderInnerStyle,
  }
  const _singleRowInGroupStyle = {
    backgroundColor: _groupStyle.backgroundColor,
    borderTopWidth: _groupStyle.borderSingleWidth,
    borderTopColor: _groupStyle.borderSingleColor,
    borderTopStyle: _groupStyle.borderSingleStyle,
    borderBottomWidth: _groupStyle.borderSingleWidth,
    borderBottomColor: _groupStyle.borderSingleColor,
    borderBottomStyle: _groupStyle.borderSingleStyle,
  }

  let focusedCellIndex = -1

  function isRow (row) {
     return row._row.type == 'row'
     return !(row.constructor.name == 'CalcComponent')
  }

  function setCellStyle(cell, style) {
    if (!cell)
      return
    const el = cell.getElement()
    if (!el)
      return

    const keys = Object.keys(style)
    keys.forEach(key => {el.style[key] = style[key]})
  }

  function setRowStyle(row, style) {

    const el = row.getElement()
    if (!el)
      return

    const keys = Object.keys(style)
    keys.forEach(key => {el.style[key] = style[key]})
  }

  function selectedRow(row) {
     return isRow(row) ? row.isSelected() : false
  }

  function focusedRow(row, actRow) {
     return isRow(row) && row == actRow
  }

  function resetStyles( row ) {
    setRowStyle(row, _innerRowInGroupStyle)
    row.getCells().forEach( cell => {
      if (cell.getColumn().isVisible())
        setCellStyle(cell, _cellStyle)
    })
  }

  this.execute = function (data) {
    const {row, actRow, actCol} = data

    if (!isRow(row))
      return

    resetStyles(row)

    if (selectedRow(row)) {
      setRowStyle(row, _selectedRowStyle)
      return
    }

    if (focusedRow(row, actRow)) {
      setRowStyle(row, _focusedRowStyle)
      const vcells = row.getCells().filter( cell => cell.getColumn().isVisible() )

      /* if (focusedCellIndex > -1 && focusedCellIndex < vcells.length) {
         setCellStyle(vcells[focusedCellIndex], _cellStyle)
      } */

      setCellStyle(actCol, _focusedCellStyle)
      if (vcells.length > actCol) {
        focusedCellIndex = vcells.indexOf(actCol)
      }
      return
    }

    try {
      const group = isRow(row) ? row.getGroup() : false
      if (!group || group.getRows().length == 1) {
        setRowStyle(row, _singleRowInGroupStyle)
        return
      }

      const rowcount = group.getRows().length
      const rowindex = group.getRows().indexOf(row)

      if (rowindex == 0) {
        setRowStyle(row, _firstRowInGroupStyle)
        return
      }
      if (rowindex == (rowcount - 1)) {
        setRowStyle(row, _lastRowInGroupStyle)
        return
      }

      setRowStyle(row, _innerRowInGroupStyle)
    }
    catch (e) {
      setRowStyle(row, _innerRowInGroupStyle)
    }

  }
}
