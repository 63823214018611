<template>
    <div class="context-menu context-menu-container"
         :class="openPosition"
         v-if="visible"
         :style="contextMenuPosition"
         v-click-outside="close" @click="close"
         ref="contextMenu"
    >
        <ul>
            <context-menu-item
                    v-for="(menuItem, index) in menuItems"
                    :item="menuItem"
                    :key="index">
            </context-menu-item>
        </ul>
    </div>
</template>

<script>
/* eslint-disable prefer-destructuring */

import ContextMenuItem from './dkcContextMenuItem.vue';
import ClickOutside from './dkcContextMenuClickOutside';

/**
 * A simple context menu component
 *
 * ```html
 * <ContextMenu :menu-items="[....]"/>
 * ```
 */
export default {
  props: {
    menuItems: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      visible: false,
      contextMenuPosition: {
        top: 0,
        left: 0,
      },
      openPosition: 'context-menu-open-right',
    };
  },

  methods: {
    close() {
      this.visible = false;
    },

    /**
     * Accepts an Object with an `x, y` position or an instance of Event
     */
    open(position) {
      this.visible = true;

      this.$nextTick(() => {
        let x = 0;
        let y = 0;

        if (typeof position !== 'undefined' && typeof position === 'object') {
          if (position instanceof Event) {
            const windowWidth = window.innerWidth;
            const contextMenuWidth = this.$refs.contextMenu.getBoundingClientRect().width;

            if (position.pageX >= (windowWidth - contextMenuWidth)) {
              this.openPosition = 'context-menu-open-left';
              x = windowWidth - contextMenuWidth - 10;
            } else {
              this.openPosition = 'context-menu-open-right';
              x = position.pageX;
            }

            y = position.pageY;
          } else {
            x = position.x;
            y = position.y;
          }
        }

        this.contextMenuPosition = {
          left: `${x}px`,
          top: `${y}px`,
        };
      });
    },
    getItem(name) {
        return this.menuItems.find(item => {
            return item.name && item.name.toLowerCase() == name.toLowerCase()
        })
    },
    updateItem(name, data, disabled) {
        const item = this.getItem(name)
        if (item) {
            item.data = data
            item.disabled = disabled
        }

        return item
    }
  },

  components: {
    'context-menu-item': ContextMenuItem,
  },

  directives: {
    'click-outside': ClickOutside,
  },
};
</script>

<style>
    /*$context-menu-border-radius: 4px;*/

    .context-menu-container {
        /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
        font-size: .8em;*/
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 14px;
        color: #333;
        position: absolute;
        user-select: none;
        z-index: 100;
    }

    .context-menu {
      /*background: #e4e4e4;*/
      border-radius: 4px;
      /*box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);*/
    }

    .context-menu  ul {
      background: #e4e4e4;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
      margin: 0;
      padding: 0;
      list-style: none;
    }

    .context-menu  ul:first-child {
                border-top-right-radius: 4px;
                border-top-left-radius: 4px;
    }

    .context-menu  ul:last-child {
                border-bottom-right-radius: 4px;
                border-bottom-left-radius: 4px;
    }

    .context-menu li {
      position: relative;
      padding: .5em 0.3em;
      cursor: pointer;
    }

    .cm-label {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      white-space: nowrap;
    }

    .begin-menu-group {
      height: 4px;
      border-bottom: 2px groove #cccccc;
    }

    .context-menu li img {
      margin-right: 0.3em;
    }

    .cm-item-label {
      margin-right: 1em;
    }


     .cm-item-disabled {
       cursor: not-allowed;
       opacity: 0.3;
     }

/*      ul {
        display: none;
      }*/

    .context-menu:hover {
      background: rgba(0, 0, 0, 0.1);
    }

    .context-menu > ul {
                        position: absolute;
                        left: calc(100% + 2px);
                        top: 0;
                        display: block;
                        min-width: 100%;
    }

</style>
