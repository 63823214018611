var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ng-container", style: { maxWidth: _vm.maxWidth } },
    [
      !_vm.hideToolbar
        ? _c(
            "bpk-toolbar-panel",
            [
              _vm.grant
                ? _c("dkc-tool-button", {
                    attrs: {
                      title: "Новая группа",
                      hint: "Регистрация новой группы номенклатуры",
                      iconclass: "dbtn-create",
                      action: _vm.createNomenGroup,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.grant
                ? _c("dkc-tool-button", {
                    attrs: {
                      title: "Изменить группу",
                      hint: "Изменить данные о группе номенклатуры",
                      iconclass: "dbtn-edit",
                      action: _vm.editNomenGroup,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.grant
                ? _c("dkc-tool-button", {
                    attrs: {
                      title: "Удалить группу",
                      hint: "Удалить группу номенклатуры",
                      iconclass: "dbtn-delete",
                      action: _vm.queryDeleteNomenGroup,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("dkc-search-control", { on: { onsearch: _vm.doSearchGroup } }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "ng-tree",
          style: { maxHeight: _vm.maxHeight, height: _vm.maxHeight },
        },
        [_c("div", { attrs: { id: "tree" } })]
      ),
      _vm._v(" "),
      _c(
        "dkc-model-dlg",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isModalDlgVisible,
              expression: "isModalDlgVisible",
            },
          ],
          attrs: { buttons: _vm.buttons, actions: _vm.actions },
          on: { close: _vm.closeModalDlg },
        },
        [
          _c("span", { attrs: { slot: "body" }, slot: "body" }, [
            _vm._v(_vm._s(_vm.bodyText)),
          ]),
          _vm._v(" "),
          _c("span", { attrs: { slot: "header" }, slot: "header" }, [
            _vm._v(_vm._s(_vm.headerText)),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }