/**
    Компонент для управления группной номенклатуры
    в справочниках номенклатура и группы номенклатуры.
    Отображает полный путь от корня до текущей группы, с возможностью выбора другой группы
    Параметры:
        idgroup int         - id группы до которой необходимо показать путь
        hideToolbar boolean - скрывает кнопки выбора другой группы
    События:
        select-group - пользователь изменил выбранную группу

*/
<template>
  <div style="width: 100%">
    <div class="link-container" v-if="pathLen > 1">
        <div class="link-panel" v-if="selectedID>0">
            <div v-for="path in paths" v-if="path.id != 0" class="link-block">
                <button type="button" class="btn btn-link"
                        @click="selectGroup(path.id)">
                  {{path.name}}
                </button>
                <span class="path-delim">></span>
            </div>
        </div>
        <div v-else>Корневая группа</div>
        <div class="speed-btn-container" v-if="!hideToolbar">
            <button type="button" class="speed-btn" @click="showModalDlg">...</button>
            <button type="button" class="speed-btn" @click="setRootGroup">/</button>
        </div>


        <dkc-modal-dlg
                :buttons="buttons"
                :actions="actions"
                v-show="isModalDlgVisible"
                @close="isModalDlgVisible=false"
        >
            <span slot="header">Выберите группу номенклатуры</span>
            <dkc-nomen-group ref="ngtree" slot="body" height="400px" width="400px" hide-toolbar="true"></dkc-nomen-group>
        </dkc-modal-dlg>
    </div>
  </div>
</template>

<script>
    import DkcModalDlg from './dkcModalDlg'
    import DkcNomenGroup from './jsTreePort'

    export default {
        name: "dkcNgNavy",
        components: {DkcModalDlg, DkcNomenGroup},
        props: ['idgroup', 'hideToolbar'],
        data() {
            return {
                selectedID: 0,
                paths: [],
                isModalDlgVisible: false,
                buttons: ["ok", "cancel"],
                actions: {
                    ok: this.setSelectedGroup,
                },
            }
          },
        computed: {
            pathLen: function() {
            if (Array.isArray(this.paths)) {
              return this.paths.length ;
            }
            return 0 ;
          },
        },
        watch: {
            idgroup: function() {
                this.loadGroup(this.idgroup)
            },
        },
        methods: {
            loadGroup: function(idng) {
                // при изменении параметра запускаем запрос, который должен вернуть путь.
                if (idng < 1) {
                    this.setRootGroup()
                    return
                }

                const comp = this                
                axios({
                    method: 'get',
                    url: this.baseUrl + '/nomen-group/parent-path',
                    headers: {'X-CSRF-Token': tkn_csfr},
                    params: {
                        id: idng,
                    },
                })
                    .then(function(response) {
                        comp.selectedID = idng
                        comp.paths = response.data
                    })
                    .catch(function(error) {
                        this.selectedID = 0
                        this.paths = []
                        console.error('Ошибка получения пути к родительской группе: ' + error.message)
                    }) ;
            },
            selectGroup: function (idng) {
                this.$emit('select_group', idng) ;
            },
            setRootGroup: function() {
                this.selectedID = -1 ;
                this.paths = [{id: 0, name: ''}, {id: -1, name: 'Корневая группа'}] ;
                this.$emit('select_group', this.selectedID) ;
            },
            setSelectedGroup: function() {
                const node = this.$refs.ngtree.getSelectedNode()
                this.selectedID = node[0].id
                this.loadGroup(node[0].id)
                this.selectGroup(node[0].id)
                this.isModalDlgVisible = false
            },
            showModalDlg: function() {
                this.isModalDlgVisible = true
            },
        },

    }
</script>

<style scoped>
  .link-panel, .link-container {
    display: flex;
    align-items: center;


    /*border: 3px ridge gray ;
    border-left: 0px solid transparent ;
    border-right: 0px solid transparent ;*/
  }

  .link-container {
      flex-wrap: nowrap;
      justify-content: space-between;

  }

  .link-panel {
      flex-wrap: wrap;
  }

  .path-delim {
    font-family: "Courier New";
  }

  .link-block {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }

  .link-block:last-child .path-delim {
    display: none;
  }

  .speed-btn-container {
      display: flex;
      flex-wrap: nowrap;
      margin-left: 24px;
  }

  .speed-btn {
      font-size: 1.2em;
      font-weight: bolder;
      color: #c00;
      width: 28px;
      height: 28px;
  }
</style>
