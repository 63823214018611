import XLSX from 'xlsx'

export default function ColsMap() {
    this._col_firstGroup = ''
    this._col_lastGroup = ''
    this._col_code = ''
    this._col_mark = ''
    this._col_name = ''
    this._col_edizm = ''
    this._col_price = ''
    this._col_rest = ''
    this._groupRange = true

    this._onchangeGroup = false
    this._onchangeGrid  = false
}

ColsMap.prototype = {
    doChangeGroup: function(colName, oldCol, newCol) {
        if (this._onchangeGroup && oldCol != newCol)
            this._onchangeGroup(colName, oldCol, newCol)
    },
    doChangeGrid: function(colName, oldCol, newCol) {
        if (this._onchangeGrid && oldCol != newCol)
            this._onchangeGrid(colName, oldCol, newCol)
    },
    get firstGroup () {
        return this._col_firstGroup
    },
    set firstGroup (newCol) {
        const oldCol = this._col_firstGroup
        this._col_firstGroup = newCol
        this.doChangeGroup('firstGroup', oldCol, newCol)
    },
    get lastGroup () {
        return this._col_lastGroup
    },
    set lastGroup (newCol) {
        const oldCol = this._col_lastGroup
        this._col_lastGroup = newCol
        this.doChangeGroup('lastGroup', oldCol, newCol)
    },
    get code () {
        return this._col_code
    },
    set code (newCol) {
        const oldCol = this._col_code
        this._col_code = newCol
        this.doChangeGrid('code', oldCol, newCol)
    },
    get mark () {
        return this._col_mark
    },
    set mark (newCol) {
        const oldCol = this._col_mark
        this._col_mark = newCol
        this.doChangeGrid('mark', oldCol, newCol)
    },
    get name () {
        return this._col_name
    },
    set name (newCol) {
        const oldCol = this._col_name
        this._col_name = newCol
        this.doChangeGrid('name', oldCol, newCol)
    },
    get edizm () {
      return this._col_edizm
    },
    set edizm (newCol) {
      const oldCol = this._col_edizm
      this._col_edizm = newCol
      this.doChangeGrid('edizm', oldCol, newCol)
    },
    get price () {
        return this._col_price
    },
    set price (newCol) {
        const oldCol = this._col_price
        this._col_price = newCol
        this.doChangeGrid('price', oldCol, newCol)
    },
    get rest () {
        return this._col_rest
    },
    set rest (newCol) {
        const oldCol = this._col_rest
        this._col_rest = newCol
        this.doChangeGrid('rest', oldCol, newCol)
    },
    get groupRange () {
        return this._groupRange
    },
    set groupRange (newval) {
        this._groupRange = newval
    },
    get isEmptyGroup () {
        return !(this._col_firstGroup || this._col_lastGroup)
    },
    get isEmptyNomen () {
        return !(this._col_code || this._col_mark || this._col_name)
    },
    get cols() {
        const cols = []
        const result = []
        if (this.groupRange && this.firstGroup && this.lastGroup) {
          for (let i = XLSX.utils.decode_col(this.firstGroup); i < iXLSX.utls.decode_col(this.lastGroup); i++) {
            cols.push(XLSX.utils.encode_col(i))
          }
        }
        cols.concat(Object.values(this))
          .forEach(col => {
            if (col && result.indexOf(col) < 0)
              result.push(col)
          }
          )

        return result
    },
    get onchangeGroup () {
        return this._onchangeGroup ;
    },
    set onchangeGroup (f) {
        this._onchangeGroup = f
    },
    get onchangeGrid () {
        return this._onchangeGrid
    },
    set onchangeGrid (f) {
        this._onchangeGrid = f
    },
}
