<template>

  <div class="site-index">

    <div class="jumbotron">
      <h1>Рады приветствовать Вас<span v-show="userfio">,<br>{{userfio}}</span>!</h1>

      <p class="lead">Вы авторизовались в программе расчёта спецификаций DKC. В данной программе вы можете:</p>
    </div>

    <div class="body-content">
      <div class="task">
        <a :href="baseUrl + '/spec/index'">
          <div class="task-img">
            <img :src="baseUrl + '/images/doc_list.jpg'">
          </div>
          <div class="task-desc">
            Открыть журнал спецификаций
          </div>
        </a>
      </div>
      <div class="task">
        <a :href="baseUrl + '/spec/create'">
          <div class="task-img">
            <img :src="baseUrl + '/images/doc_new.jpg'">
          </div>
          <div class="task-desc">
            Создать новую спецификацию
          </div>
        </a>
      </div>
       <div class="task" v-if="userIsAdmin">
          <a :href="baseUrl + '/spec/solutions'">
             <div class="task-img">
                <img :src="baseUrl + '/images/solutions.jpg'">
             </div>
             <div class="task-desc">
                База решений по аналогам
             </div>
          </a>
       </div>

      <router-link class="task" tag="div" :to="baseUrl+ '/nsi'">
        <a>
          <div class="task-img">
            <img :src="baseUrl + '/images/catalog.jpg'">
          </div>
          <div class="task-desc">
            Управлять нормативно-справочной информацией
          </div>
        </a>
      </router-link>
    </div>
  </div>


</template>

<script>
  export default {
    computed: {
      userfio() {
        return this.$store.state.user.fio ;
      },
       userIsAdmin: function () {
          return this.$store.state.user.idRole == 1
       },
    },    
  }

</script>

<style scoped>

</style>
