var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "tabs" },
    _vm._l(_vm.tabs, function (tab) {
      return _c("bpk-tab", {
        key: tab.index,
        attrs: { title: tab.title, hint: tab.hint, index: tab.index },
        on: { "tab-click": _vm.changeActiveTab },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }